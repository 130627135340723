import React, { useState } from 'react';
import moment from 'moment';

import './CalendarHourWeek.scss';
import { msToTime } from '../../../utils/dateFormats';
import CalendarClass from './CalendarClass';
import CalendarMoreClass from './CalendarMoreClass';

function MoodWeek(props) {
  const { hour, week, mode } = props;
  const { Mon, Tue, Wed, Thu, Fri, Sat } = week;

  const [ visible, setVisible ] = useState(false);

  function makeDay(day, dayKey, minimun, long) {
    const classes = day.slice(0,visible ? long : minimun);
    const items = classes.map((item, index) => (
      <CalendarClass 
        dayKey={dayKey}
        hour={hour}
        key={index}
        mode={mode}
        {...item}
      />
    ));
    return (
      <div className='CalendarHourWeek__week__day'>
        {items}
        {
          !visible ? (
            long > minimun && <CalendarMoreClass label={`${long - minimun} más`} onClick={toggleMore} />
          ) : (
            long > minimun && <CalendarMoreClass label={`Ver menos`} onClick={toggleMore} />
          )
        }
      </div>
    );
  }

  function toggleMore() {
    setVisible(!visible);
  }

  return (
    <div className='CalendarHourWeek'>
      <div className='CalendarHourWeek__hour'>
        { msToTime(hour) } hrs.
      </div>
      <div className='CalendarHourWeek__week'>
        { makeDay(Mon, 'Mon', 4, Mon.length ) }
        { makeDay(Tue, 'Tue', 4, Tue.length ) }
        { makeDay(Wed, 'Wed', 4, Wed.length ) }
        { makeDay(Thu, 'Thu', 4, Thu.length ) }
        { makeDay(Fri, 'Fri', 4, Fri.length ) }
        { makeDay(Sat, 'Sat', 4, Sat.length ) }
      </div>
    </div>
  );
}

function MoodDay(props){
  const { hour, week, currentDate, mode } = props;
  const days = { lun: 'Mon', mar: 'Tue', mié: 'Wed', jue: 'Thu', vie: 'Fri', sáb: 'Sat', dom: 'Sun' };
  const keyDay = moment(currentDate,'YYYY-MM-DD').format('dddd');
  const key = keyDay.slice(0,3);
  const arrayDay = week[days[key]];
  const long = arrayDay.length;

  if(!!long){
    return (
      <div className='CalendarHourWeekDay'>
        <div className='CalendarHourWeek__hour'>
          {msToTime(hour)} hrs.
        </div>
        <div className='CalendarHourWeek__days'>
          <div className='CalendarHourWeek__days__day'>
            {
              arrayDay.map((event, index) => (
                <CalendarClass 
                  dayKey={days[key]} 
                  hour={hour} 
                  key={index} 
                  mode={mode}
                  {...event}
                />
              ))
            }
          </div>
        </div>
      </div>
    );
  }
  return null;
}

function CalendarHourWeek(props) {
  
  const { mode } = props;
  
  if (mode === 'day') {
    return <MoodDay {...props} />;
  } else {
    return <MoodWeek {...props} />;
  }
}

export default CalendarHourWeek;
