import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Form,
  Select,
} from 'antd';
import { assignTeacher, setTeacherTariff } from '../../../reducers/groups/actions';
import { getTeachersByLanguage, getTeacherTariffs } from '../../../reducers/teachers/actions';
import FormWrapper from '../../../components/custom/FormWrapper';
import { useHistory } from 'react-router';

import './FormGroupDetail.scss';

const { Option } = Select;

const FormGroupDetail = props => {

  const { groupsReducer, teachersReducer, match, location: { state } } = props;
  
  const { clientId, groupId } = match.params;
  
  const { group } = groupsReducer;

  const { teachersByLang, tariffs: teacherTariffs } = teachersReducer;

  const [ form ] = Form.useForm();

  const [ values, setValues ] = useState({ });

  const history = useHistory();

  const onChange = formValue => setValues({
    ...values,
    ...formValue
  });

  const getTeacherObject = _ => teachersByLang.find(teacher => teacher.id === values.teacher_id);

  const onFinish = _ => {
    
    const teacher = getTeacherObject();

    props.assignTeacher(groupId, teacher);
    
    props.setTeacherTariff(groupId, values.tariff);
  };

  const onRedirectCancelButton = () => {
    if (state?.statusGroup === 'completed') {
      history.goBack();
    } else {
      history.push({
        pathname: `/clients/detail/${clientId}`,
        state: {
          activeKey: 1, 
        },
      });
    }
    
  };

  useEffect(_ => {
    if (group?.study_plan_id) {
      props.getTeachersByLanguage(group.study_plan_id);
    }
  }, [ group?.study_plan_id ]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    props.getTeacherTariffs();
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    const initialValues = {
      teacher_id: group?.teacher?.id,
      tariff: group?.tariff_teacher || null
    };

    form.setFieldsValue(initialValues);

    onChange(initialValues);
  }, [ form, group, groupId ]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div className='FormGroupDetail'>
      <FormWrapper
        { ...props }
        name='group-detail'
        form={ form }
        initialValues={ values }
        onValuesChange={ onChange }
        onFinish={ onFinish }
        loading={ groupsReducer.loading.ASSIGN_TEACHER?.state || groupsReducer.loading.GET_GROUP?.state }
        cancelButtonText='Regresar'
        onRedirectCancelButton={ onRedirectCancelButton }
      >
        <Form.Item
          rules={[
            { required: true },
          ]}
          label='Asignar profesor'
          name='teacher_id'
        >
          <Select
            showSearch
            placeholder='Seleccionar profesor'
            optionFilterProp="children"
            loading={ teachersReducer.loading.GET_BY_LANGUAGE?.state }
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            { (teachersByLang || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.teacher_name }</Option>) }
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            { required: true },
          ]}
          label='Tarifa profesor'
          name='tariff'
        >
          <Select
            showSearch
            placeholder='Seleccionar tarifa'
            optionFilterProp="children"
            loading={ teachersReducer.loading.GET_TARIFFS?.state }
          >
            { (teacherTariffs || [ ]).map((tariff, index) => <Option key={ index } value={ tariff }>{ tariff }</Option>) }
          </Select>
        </Form.Item>
      </FormWrapper>
    </div>
  )
}

FormGroupDetail.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getTeachersByLanguage, assignTeacher, getTeacherTariffs, setTeacherTariff }, dispatch);

const mapStateToProps = state => ({
  groupsReducer: state.get('groupsReducer'),
  teachersReducer: state.get('teachersReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(FormGroupDetail);
