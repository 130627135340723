import React from 'react';
import './Flags.scss';
import { selectFlagFile } from '../../../contants/flags';

function Flags(props) {
  let { value } = props;
  if (typeof value !== 'object')
    value = [ value ];
  return (
    <div { ...props } className='Flags'>
      {
        value.map((flag, index) => (<img key={index} src={selectFlagFile(flag)} alt='Language'/>))
      }
    </div>
  );
}

Flags.defaultProps = {
  value: []
}

export default Flags;