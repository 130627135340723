import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

function TypographyText(props){
  const { children } = props;
  return(
    <Typography.Text className='TypographyText' {...props}>
      {children}
    </Typography.Text>
  );
}

TypographyText.propTypes = {
  children: PropTypes.string.isRequired,
}

export default TypographyText;