import React, { useEffect } from 'react';
import WeekCalendar from 'react-week-calendar';
import './WeeklyCalendar.scss';
import Flags from '../../../components/simple/Flags'
import Popover from '../../../components/simple/Popover';
import Button from '../../../components/simple/Button';
import { Radio } from 'antd';

function WeeklyCalendar(props) {
  let { showTeacher, removeAll, update, toDelete, removeLock, loading, selectedIntervals } = props;
  useEffect(() => {
    setTimeout(() =>{
      let elm = document.getElementsByClassName('weekCalendar__overlay')[0]
      if (elm) {
        document.getElementsByClassName('weekCalendar__content')[0].scrollTo({
          top: Number(window.getComputedStyle(elm).getPropertyValue('top').replace('px', '')) - 10
        })
      }
    }, 200)
  }, [
    selectedIntervals
  ])
  return(
    <div className='WeeklyCalendar'>
      <WeekCalendar
        dayFormat={'dddd DD'}
        cellHeight={18}
        numberOfDays={6}
        useModal={false}
        eventComponent={item => {
          let cancelButton = toDelete?.eventGroupType === 'none' && removeAll;
          return <div style={{
            borderColor: showTeacher ? item.color : null,
            backgroundColor: showTeacher ? item.color : null,
            color: showTeacher ? item.color : null,
          }} className={ `event ${ item.isUnavailable && 'lock' } ${ showTeacher && 'multiple' }`}>
            <label className='bold'>{ item.level } { item.title }</label>
            <label>{ item.classGroup }</label>
            <label>{ item.scheduleTime }</label>
            { showTeacher && <label className='bold'>{ item.teacher }</label> }
            { !item.isUnavailable && <Flags value={ item.languageID }/> }
            { item.isUnavailable && (
              <Popover visible={ toDelete?.eventGroupID === item.eventGroupID && toDelete.startDateMS === item.startDateMS } placement="bottom" trigger="click" content={(() => {
                let duration = `de ${ item.scheduleTime.replace('-', 'a') }`,
                    justThisOne = `Solo este (${ item.start.format('dddd DD [de] MMMM') } ${ duration })`,
                    conf = {
                      title: '¿Deseas desbloquear este o todos los espacios bloqueados siguientes?',
                      options: [
                          {
                            label: justThisOne,
                            value: false
                          },
                          {
                            label: `Todos los ${ item.start.format('dddd') } ${ duration }`,
                            value: true
                          }
                      ]
                    };

                if (item.eventGroupType !== 'day') {
                  conf.options[1] = {
                    label: `Todos los eventos de lunes a domingo ${ duration }`,
                    value: true
                  };
                }
                
                return (
                  <div className="remove-lock">
                    <i className="material-icons">warning</i>
                    <label>{ conf.title }</label>
                    <Radio.Group name='removeAll' value={removeAll} onChange={input => {
                        update('removeAll')(input.target.value);
                    }} options={conf.options}/>
                    <Button loading={ !!loading } type={'primary'} onClick={() => {
                      if (cancelButton)
                        update('toDelete')(null);
                      else
                        removeLock(toDelete.teacherID, toDelete, removeAll)
                    }}>{ cancelButton ? 'CANCELAR' : 'DESBLOQUEAR' }</Button>
                    { !cancelButton && (
                      <Button onClick={() => update('toDelete')(null)}>CANCELAR</Button>
                    )}
                  </div>
                )
              })()}>
                <i className="material-icons" onClick={() => {
                  update('toDelete')(toDelete ? null : item);
                }}>delete</i>
              </Popover>
            )}
          </div>
        }}
        {
          ...props
        }
      ></WeekCalendar>
    </div>
  );
}

export default WeeklyCalendar;