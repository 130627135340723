import client from '../axios';

class TicketService {
    getAllTickets(range, filters, page, orderBy) {
        if (!range)
            range = { };
        return client.post('v1/auth/tickets', {
            filters: {
                startdate: range.startdate,
                enddate: range.enddate,
                ...(filters || {})
            },
            pagination: {
                page,
                limit: 10,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        })
    }
    getMyTickets(range, filters, page, orderBy) {
        if (!range)
            range = { };
        return client.post('/v1/auth/tickets/userTickets', {
            filters: {
                startdate: range.startdate,
                enddate: range.enddate,
                ...(filters || {})
            },
            pagination: {
                page,
                limit: 10,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        })
    }
    getCoordinators() {
        return client.get('v1/auth/coordinators')
    }
    getStatus() {
        return client.get('v1/auth/ticket/status')
    }
    getTotals() {
        return client.get('v1/auth/tickets')
    }
    update(id, responsableUID, statusID) {
        return client.put(`v1/auth/tickets/${ id }`, {
            responsableUID,
            statusID
        })
    }
}

export const ticketService = new TicketService();
