import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './InputFile.scss';
import { useEffect } from 'react';

const { Dragger } = Upload;

function InputFile(props) {
  
  useEffect(() => {
    window.addEventListener('paste', props.handlePaste);
    return () => {
      window.removeEventListener('paste', props.handlePaste);
    }
  }, [])
  return (
    <Dragger {...props}>
      <div className="container-info">
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text"></p>
        <p className="ant-upload-hint">
          Sube hasta 5 documentos.
          Formatos permitidos: png, jpg, pdf. 
          Tamaño máximo 5 mb.  
        </p>
      </div>
      
    </Dragger>
  )
}

export default InputFile;