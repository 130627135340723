import React from 'react';

import RadioButtonTab from '../../simple/RadioButtonTab';
import CalendarRange from '../../simple/CalendarRange';
import './CalendarTab.scss';

function CalendarTab(props){
  const { range, views, valueView, onChangeView, onSelectDate, currentDate } = props;

  const defineRanges = () => {
    if(valueView === 'week'){
      return {
        start: new Date(`${range.init} 00:00:00`),
        end: new Date(`${range.end} 00:00:00`),
      }
    }
    return {
      start: new Date(`${currentDate} 00:00:00`),
      end: new Date(`${currentDate} 00:00:00`),
    }
  }

  return(
    <div className='CalendarTab' data-testid='CalendarTab'>
      <RadioButtonTab
        className='CalendarTab__tabs'
        value={valueView}
        onChange={onChangeView}
        items={views}
      />
      <CalendarRange
        daysOfWeek={['L','M','X','J','V','S','D']}
        weekStartsOn={1}
        onSelect={onSelectDate}
        mode={'simple'}
        highlighted={defineRanges()}
        activeMonth={new Date(currentDate)}
      />
    </div>
  );
}

CalendarTab.propTypes = {};

export default CalendarTab;
