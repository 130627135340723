import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Tabs, Calendar, Select, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import FormWrapper from '../../../components/custom/FormWrapper';
import Tags from '../../../components/simple/Tags/Tags';
import ConfirmationModal from '../../../components/custom/ConfirmationModal/ConfirmationModal';
import moment from 'moment';

import './FormHolidays.scss';

const { TabPane } = Tabs;

const FormHolidays = props => {

  const { loadingGet, loadingSave, loadingDelete, holidays, clientId } = props;

  const [ form ] = Form.useForm();

  const startDate = moment().startOf('year');

  const endDate = moment().add(1, 'year').endOf('year');

  const currentYear = startDate.format('YYYY');
  
  const currentMonth = startDate.format('MM');
  
  const nextYear = endDate.format('YYYY');

  const [ values, setValues ] = useState({
    year: currentYear,
    month: currentMonth
  });
  
  const [ holidayToDelete, setHolidayToDelete ] = useState();
  
  const mapDatesFromYear = selectedYear => mapDates(values.dates).filter(date => date.value.format('YYYY') === selectedYear);

  const mapDisabledDates = d => d.isBefore(startDate) || d.isAfter(endDate) || !!(values.dates || [ ]).find(date => date.value.format('LL') === d.format('LL'));

  const mapDates = dates => (dates || [ ]).map(date => {

    const value = date.value || date;

    return ({
      ...date,
      label: value.format('DD [de] MMMM'),
      value
    })
  });

  const getUnsavedBadge = year => {
    
    const unsavedDatesLength = mapDatesFromYear(year).filter(item => !item.id).length;

    return (
      !!unsavedDatesLength && <ExclamationCircleFilled/>
    );
  }

  const onChange = formValue => {

    let dates = values.dates || [ ];

    const year =  form.getFieldValue('year');

    const month = form.getFieldValue('month');

    const day = formValue?.date?.format('YYYY-MM-DD').split('-')[2];

    if (formValue.date) {
      dates = dates.concat(mapDates([ formValue.date ]));
    };

    form.setFieldsValue({
      date: moment(`${ year }-${ month }-${ day && day }`, 'YYYY-MM-DD')
    });

    setValues({
      ...values,
      ...formValue,
      dates,
      year,
      month
    });
  }
  
  const onFinish = _ => props.saveHolidays(values.dates.filter(item => !item.id).map(item => item.value.format('YYYY-MM-DD')), clientId).then(
    _ => notification['success']({
      description: 'Días inhabiles guardados con éxito',
      placement: 'bottomRight'
    })
  );

  const onTagDelete = tag => {
    
    const { dates } = values;

    if (tag.id) {
      setHolidayToDelete(tag);
    } else {
      const index = dates.findIndex(item => item.label === tag.label);

      dates.splice(index, 1)

      setValues({
        ...values,
        dates
      })
    }
  }

  const onDeleteConfirmed = _ => {
    props.deleteHoliday(holidayToDelete.id).then(_ => 
      notification['success']({
        description: 'Se ha eliminado el día inhábil con éxito',
        placement: 'bottomRight'
      })
    );
    setHolidayToDelete(null);
  }

  useEffect(_ => {
    setValues({
      ...values,
      dates: [ ]
    })
    props.getHolidays(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), clientId);
  }, [ loadingSave, loadingDelete  ]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    setValues({
      ...values,
      dates: mapDates(
        (holidays || [ ]).map(event => ({
          ...event,
          category: 'saved',
          value: moment(event.date, 'YYYY-MM-DD')
        })
      ))
    })
  }, [ holidays ]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setFieldsValue({
      month: moment().format('MM'),
      year: moment().format('YYYY')
    });
    onChange({date: moment()});
  }, [])
  
  return (
    <div className='FormHolidays'>
      <FormWrapper
        { ...props }
        loading={ loadingGet || loadingSave || loadingDelete }
        form={ form }
        initialValues={ values }
        onValuesChange={ onChange }
        onFinish={ onFinish }
        name='holidays-form'
        enabledButton
        cancelButtonText='Regresar'
      >
        <Row>
          <Col className='left-panel' xs={ 7 } sm={ 7 } md={ 7 } lg={ 7 } xl={ 7 } xxl={ 7 }>
            <Row>
              <Col className='year-control' xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={ 12 } xxl={ 12 }>
                <Form.Item
                  name='year'
                >
                  <Select>
                    <Select.Option value={ currentYear }>{ currentYear }</Select.Option>
                    <Select.Option value={ nextYear }>{ nextYear }</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={ 12 } xxl={ 12 }>
                <Form.Item
                  name='month'
                >
                  <Select>
                    <Select.Option value={ '01' }>Enero</Select.Option>
                    <Select.Option value={ '02' }>Febrero</Select.Option>
                    <Select.Option value={ '03' }>Marzo</Select.Option>
                    <Select.Option value={ '04' }>Abril</Select.Option>
                    <Select.Option value={ '05' }>Mayo</Select.Option>
                    <Select.Option value={ '06' }>Junio</Select.Option>
                    <Select.Option value={ '07' }>Julio</Select.Option>
                    <Select.Option value={ '08' }>Agosto</Select.Option>
                    <Select.Option value={ '09' }>Septiembre</Select.Option>
                    <Select.Option value={ '10' }>Octubre</Select.Option>
                    <Select.Option value={ '11' }>Noviembre</Select.Option>
                    <Select.Option value={ '12' }>Diciembre</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name='date'
              rules={[
                {
                  required: true,
                  message: 'Ingresa un rango de fechas válido'
                }
              ]}
            >
              <Calendar
                fullscreen={ false }
                disabledDate={ mapDisabledDates }
              />
            </Form.Item>
          </Col>
          <Col xs={ 17 } sm={ 17 } md={ 17 } lg={ 17 } xl={ 17 } xxl={ 17 }>
            <label>Días inhábiles seleccionados</label>
            <Tabs>
              <TabPane
                key="1"
                tab={
                  <span>
                    { currentYear }
                    { getUnsavedBadge(currentYear) }
                  </span>
                }
              >
                <Tags
                  className='gray'
                  list={ mapDatesFromYear(currentYear) }
                  onDelete={ onTagDelete }
                />
              </TabPane>
              <TabPane
                key="2" 
                tab={
                  <span>
                    { nextYear }
                    { getUnsavedBadge(nextYear) }
                  </span>
                }
              >
                <Tags
                  className='gray'
                  list={ mapDatesFromYear(nextYear) }
                  onDelete={ onTagDelete }
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </FormWrapper>
      <ConfirmationModal
        visible={ !!holidayToDelete }
        title={ `¿Estas seguro que deseas eliminar el ${ holidayToDelete?.value.format('LL') } de los días inhábiles?` }
        modalProps={{
          onOk: onDeleteConfirmed,
          onCancel: _ => setHolidayToDelete(null)
        }}
      />
    </div>
  )
}

FormHolidays.propTypes = {};

export default FormHolidays;
