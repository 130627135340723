import React from 'react';

import SimpleReactCalendar from 'simple-react-calendar';
import './CalendarRange.scss';

function CalendarRange(props){
  return(
    <div className='CalendarRange'>
      <SimpleReactCalendar {...props} />
    </div>
  );
}

CalendarRange.propTypes = {};

export default CalendarRange;
