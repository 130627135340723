import React, { useEffect } from "react";
import { Col, Row } from "antd";
import InputFile from "../../../../components/simple/InputFile/InputFile";
import ItemFileList from "../../../../components/custom/ItemFileList/ItemFileList";
import InputButton from "../../../../components/custom/InputButton/InputButton";
import { validationFile } from "../../../../utils/validationFile";
import { useManageFile } from "../../../../customhooks";

import "./EvidenceActivateModal.scss";

function EvidenceActivateModal(props) {
  const {
    currentFile,
    currentFileName,
    listFile,
    setCurrentFileName,
    setListFile,
    handleInputFile,
    handleInputFilePaste,
    checkNameFile,
  } = useManageFile({});

	useEffect(() => {
		props.onListFiles(listFile);
	}, [ listFile ]);

  return (
    <div className="EvidenceActivateModal">
      <p>Para activar un grupo es necesario subir al menos una evidencia</p>
      <Col>
        <Row>
          <label>Subir evidencia</label>
          <InputFile
            accept=".png,.jpg,.pdf"
            beforeUpload={validationFile}
            disabled={listFile.length >= 5 ? true : false}
            customRequest={(data) => handleInputFile(data)}
            showUploadList={false}
            handlePaste={handleInputFilePaste}
          />
        </Row>
      </Col>

      {currentFile && (
        <InputButton
          currentValue={currentFileName}
          setCurrentValue={setCurrentFileName}
          onClickButton={checkNameFile}
          title="Cambia el nombre de la imagen y valídala dando click al icono"
        />
      )}

      {listFile.map((data, index) => (
        <ItemFileList
          key={data.uid || index}
          base64={data.receipt}
          fileType={data.mime_type}
          uid={data.uid}
          nameFile={data.name}
          listFile={listFile}
          onRemove={setListFile}
        />
      ))}
    </div>
  );
}

export default EvidenceActivateModal;
