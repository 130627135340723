import React, { useState } from 'react';
import AdvancedRange from '../../../components/custom/AdvancedRange';
import Select from '../../../components/simple/Select';
import InputCheckBox from '../../../components/simple/InputCheckBox';
import Button from '../../../components/simple/Button';
import ModalClosable from '../ModalClosable';
import { Row } from 'antd';
import './ReportModal.scss';

let advancedFilters = [
    {
        value: 'month',
        label: 'Mes'
    },
    {
        value: 'trimester',
        label: 'Trimestre'
    },
    {
        value: 'range',
        label: 'Rango'
    }
];
  
export default function ReportModal(props) {
    const [ range, setRange ] = useState('month');
    const [ dates, setDates ] = useState();
    const [ addDelays, setAddDelays ] = useState(false);
    return (
        <ModalClosable
            width={500}
            wrapClassName='ReportModal'
            { ...props }
        >
            <h3>Reporte mensual de cliente</h3>
            <Row>
                <Select
                    selectProps={{
                        showSearch: false,
                        value: range,
                        onChange: type => {
                            setRange(type);
                        }
                    }}
                    items={advancedFilters}
                />
                <AdvancedRange onChange={ setDates } mode={ range } defaults={ true }/>
            </Row>
            <Row>
                <InputCheckBox value={ addDelays } onChange={ input => setAddDelays(input.target.checked) }>Incluir retardos</InputCheckBox>
            </Row>
            <Row>
                <Button disabled={dates ? false : true} onClick={() => {
                    props.downloadClientReport(props.client.id, dates, addDelays);
                }}>Descargar</Button>
            </Row>
        </ModalClosable>
    );
}