import { tableSortProps } from '../helpers/table';
import * as ACTION from "./types";

const initialState = {
  loading: { },
  errors: { },
  sort: { },
  page: {
    clientList: 1
  },
  orderBy: {
    clientList: {
      column: "created",
      order: "ASC"
    }
  },
  client: {}
};

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case ACTION.TABLE_PROPS:
            return {...tableSortProps(state, action) };

        case ACTION.LOADING_GET_CLIENTS:
            return {...state, loading: {...state.loading, GET_CLIENTS: { state: true } } }
        case ACTION.RECEIVE_GET_CLIENTS:
            return {...state, loading: {...state.loading, GET_CLIENTS: undefined }, clients: action.result.data.result }
        case ACTION.ERROR_GET_CLIENTS:
            return {...state, loading: {...state.loading, GET_CLIENTS: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_ALL_CLIENTS:
            return {...state, loading: {...state.loading, GET_ALL_CLIENTS: { state: true } } }
        case ACTION.RECEIVE_GET_ALL_CLIENTS:
            return {...state, loading: {...state.loading, GET_ALL_CLIENTS: undefined }, allClients: action.result.data }
        case ACTION.ERROR_GET_ALL_CLIENTS:
            return {...state, loading: {...state.loading, GET_ALL_CLIENTS: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_CLIENT:
            return {...state, loading: {...state.loading, GET_CLIENT: { state: true } } }
        case ACTION.RECEIVE_GET_CLIENT:
            return {...state, loading: {...state.loading, GET_CLIENT: undefined }, client: action.result.data }
        case ACTION.ERROR_GET_CLIENT:
            return {...state, loading: {...state.loading, GET_CLIENT: { state: false, detail: action.error } } }

        case ACTION.LOADING_CREATE_CLIENT:
            return {...state, loading: {...state.loading, CREATE_CLIENT: { state: true } } }
        case ACTION.RECEIVE_CREATE_CLIENT:
            return {...state, loading: {...state.loading, CREATE_CLIENT: undefined }, newClient: action.result.data.result }
        case ACTION.ERROR_CREATE_CLIENT:
            return {...state, loading: {...state.loading, CREATE_CLIENT: { state: false, detail: action.error } } }

        case ACTION.LOADING_EDIT_CLIENT:
            return {...state, loading: {...state.loading, EDIT_CLIENT: { state: true } } }
        case ACTION.RECEIVE_EDIT_CLIENT:
            return {...state, loading: {...state.loading, EDIT_CLIENT: undefined } }
        case ACTION.ERROR_EDIT_CLIENT:
            return {...state, loading: {...state.loading, EDIT_CLIENT: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_TARIFFS:
            return {...state, loading: {...state.loading, GET_TARIFFS: { state: true } } }
        case ACTION.RECEIVE_GET_TARIFFS:
            return {...state, loading: {...state.loading, GET_TARIFFS: undefined }, tariffsCatalog: action.result.data.tariffs }
        case ACTION.ERROR_GET_TARIFFS:
            return {...state, loading: {...state.loading, GET_TARIFFS: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_PLANS:
            return {...state, loading: {...state.loading, GET_PLANS: { state: true } } }
        case ACTION.RECEIVE_GET_PLANS:
            return {...state, loading: {...state.loading, GET_PLANS: undefined }, plansCatalog: action.result.data }
        case ACTION.ERROR_GET_PLANS:
            return {...state, loading: {...state.loading, GET_PLANS: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_CLIENT_HOLIDAYS:
            return {...state, loading: {...state.loading, GET_CLIENT_HOLIDAYS: { state: true } } }
        case ACTION.RECEIVE_GET_CLIENT_HOLIDAYS:
            return {...state, loading: {...state.loading, GET_CLIENT_HOLIDAYS: undefined }, holidays: action.result.data.result }
        case ACTION.ERROR_GET_CLIENT_HOLIDAYS:
            return {...state, loading: {...state.loading, GET_CLIENT_HOLIDAYS: { state: false, detail: action.error } } }

        case ACTION.LOADING_SAVE_CLIENT_HOLIDAYS:
            return {...state, loading: {...state.loading, SAVE_CLIENT_HOLIDAYS: { state: true } } }
        case ACTION.RECEIVE_SAVE_CLIENT_HOLIDAYS:
            return {...state, loading: {...state.loading, SAVE_CLIENT_HOLIDAYS: undefined } }
        case ACTION.ERROR_SAVE_CLIENT_HOLIDAYS:
            return {...state, loading: {...state.loading, SAVE_CLIENT_HOLIDAYS: { state: false, detail: action.error } } }

        case ACTION.LOADING_DELETE_CLIENT_HOLIDAY:
            return {...state, loading: {...state.loading, DELETE_CLIENT_HOLIDAY: { state: true } } }
        case ACTION.RECEIVE_DELETE_CLIENT_HOLIDAY:
            return {...state, loading: {...state.loading, DELETE_CLIENT_HOLIDAY: undefined } }
        case ACTION.ERROR_DELETE_CLIENT_HOLIDAY:
            return {...state, loading: {...state.loading, DELETE_CLIENT_HOLIDAY: { state: false, detail: action.error } } }
        
        case ACTION.LOADING_GET_VALIDATE_CLIENT_NAME:
            return {...state, loading: {...state.loading, GET_VALIDATE_CLIENT_NAME: { state: true } } }
        case ACTION.RECEIVE_GET_VALIDATE_CLIENT_NAME:
            return {...state, loading: {...state.loading, GET_VALIDATE_CLIENT_NAME: undefined }, existClientName: action.result.data.result.exist }
        case ACTION.ERROR_GET_VALIDATE_CLIENT_NAME:
            return {...state, loading: {...state.loading, GET_VALIDATE_CLIENT_NAME: { state: false, detail: action.error } } }

        default:
            return state;
    }
}

export default reducer;
