import { combineReducers } from 'redux-immutable';
import { routerReducer as routing } from 'react-router-redux';
import authReducer from '../containers/Login/reducers';
import classReducer from '../containers/Classes/reducers';
import teachers from '../containers/Teachers/reducers';
import teacher from '../containers/TeacherDetail/reducers';
import dashboard from '../containers/Dashboard/reducers';
import tickets from '../containers/Tickets/reducers';
import incidents from '../containers/Incidents/reducers';
import testsReducer from './tests';
import testDetailReducer from './test-detail';
import groupCancellationReducer from './group-cancellation'
import studentsReducer from './students';
import studyPlanReducer from './study-plans';
import clientsReducer from './clients';
import groupsReducer from './groups';
import teachersReducer from './teachers';
import settingsReducer from './settings';
import addressesReducer from './address';
import materialsReducer from './materials';
import justificationsReducer from './justifications';
import scheduleReducer from './schedule';
import groupEventReducer from './group-event';

const rootReducer = combineReducers({
    authReducer,
    classReducer,
    dashboard,
    testDetailReducer,
    groupCancellationReducer,
    testsReducer,
    routing,
    teacher,
    teachers,
    tickets,
    incidents,
    studentsReducer,
    studyPlanReducer,
    clientsReducer,
    groupsReducer,
    teachersReducer,
    settingsReducer,
    addressesReducer,
    materialsReducer,
    justificationsReducer,
    scheduleReducer,
    groupEventReducer,
});

export default rootReducer;