import React from 'react';
import { Tabs as AntTabs } from 'antd';

import './Tabs.scss';

function Tabs(props) {
  const { items, className } = props;
  return (
    <AntTabs {...props} className={ `Tabs ${ className }` }>
      {
        (items || [ ]).map(item => {
          const { value, label, children } = item;
          return (
            <AntTabs.TabPane { ...item } tab={label} key={value}>{children}</AntTabs.TabPane>
          );
        })
      }
    </AntTabs>
  );
}


export default Tabs;
