import React, { useEffect, useState } from 'react';
import { Button, Calendar } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import DynamicFilters from '../../components/custom/DynamicFilters';
import moment from 'moment';

import './Print.scss';
import './GroupCalendar.scss';

const GroupCalendar = props => {

  const [ range, setRange ] = useState();

  const [ groupDates, setGroupDates ] = useState({ });
  
  const { schedule } = props.classReducer.groupSchedule || { };

  const labels = {
    'start_plan': 'Inicio Plan de estudios',
    'start_workshop': 'Inicio taller',
    'feedback': 'Retroalimentación',
    'exam': 'Evaluación',
    'start_sublevel': 'Inicio subnivel',
    'change_level': 'Cambio de nivel',
    'special_exam': 'Evaluación especial',
    'holiday': 'Día inhábil'
  };

  useEffect(_ => {
    const dates = { };
    const dateIterator = moment(range?.start);

    while(dateIterator.isBefore(range?.end)) {
      const group = dateIterator.format('YYYY-MM');

      dates[group] = dates[group] || [ ];
      dateIterator.add(1, 'month');
    }

    (schedule || [ ]).forEach(date => {
      const group = moment(date.day, 'YYYY-MM-DD').format('YYYY-MM');
      
      dates[group] = dates[group] || [ ];

      dates[group].push({
        ...date,
        value: moment(date.day, 'YYYY-MM-DD')
      });
    });

    setGroupDates(dates)
  }, [ schedule, range ])

  return (
    <div className='GroupCalendar'>
      <div className='head'>
        <DynamicFilters
          { ...props }
          onRangeChange={value => {
            const { client : { id : clientId }, group : { id : groupId } } = props.classReducer.classDetail;
            
            setRange(value);
            props.getGroupCalendar(clientId, groupId, value)
          }}
          rangeProps={{
            defaults: true
          }}
        />
        <div className='download-button'>
          Descargar
          <Button onClick={ window.print } type="primary" shape="circle" icon={ <DownloadOutlined /> }></Button>
        </div>
      </div>
      <div className='labels'>
        { Object.keys(labels).map(key => <label key={ key } className={ key }>{ labels[key] }</label>) }
      </div>
      <div className='calendars'>
        { Object.keys(groupDates).map(key => {
          const currentDate = moment();
          const isCurrentMonth = currentDate.month() === groupDates[key][0]?.value.month();
          const defaultValue = isCurrentMonth ? currentDate : moment(`${ key }-01`, 'YYYY-MM-DD');

          return (
            <div key={ key } className={ `calendar-container ${ isCurrentMonth && 'current-month'}` }>
              <Calendar
                defaultValue={ defaultValue }
                headerRender={date => (
                  <label className='header-month'>
                    <b>{ date.value.format('MMMM') }</b>
                    { date.value.format('YYYY') }
                  </label>
                )}
                dateCellRender={ cellDate => (groupDates[key] || [ ]).map(
                  (date, index) => cellDate.format('YYYY-MM-DD') === date.day && <div key={ index } className={ `group-event ${ date.type }` } />
                )}
              />
            </div>
          )
        })}
      </div>
    </div>
  );
}


export default GroupCalendar;
