import { teacherService } from '../../services/teacher.service';
import * as ACTION from './types';

export function createTeacher(data) {
  return {
    types: [
      ACTION.LOADING_CREATE_TEACHER,
      ACTION.RECEIVE_CREATE_TEACHER,
      ACTION.ERROR_CREATE_TEACHER,
    ],
    promise: teacherService.createTeacher(data)
  }
}

export function editTeacher(id, data) {
  return {
    types: [
      ACTION.LOADING_EDIT_TEACHER,
      ACTION.RECEIVE_EDIT_TEACHER,
      ACTION.ERROR_EDIT_TEACHER,
    ],
    promise: teacherService.editTeacher(id, data)
  }
}

export function getTeacher(id) {
  return {
    types: [
      ACTION.LOADING_GET_TEACHER,
      ACTION.RECEIVE_GET_TEACHER,
      ACTION.ERROR_GET_TEACHER,
    ],
    promise: teacherService.getTeacher(id)
  }
}

export function getTeachersByLanguage(languageID) {
  return {
    types: [
      ACTION.LOADING_GET_BY_LANGUAGE,
      ACTION.RECEIVE_GET_BY_LANGUAGE,
      ACTION.ERROR_GET_BY_LANGUAGE,
    ],
    promise: teacherService.getByLanguage(languageID)
  }
}

export function getTeacherTariffs() {
  return {
    types: [
      ACTION.LOADING_GET_TARIFFS,
      ACTION.RECEIVE_GET_TARIFFS,
      ACTION.ERROR_GET_TARIFFS,
    ],
    promise: teacherService.getTariffs()
  }
}

export function validateEmail(email) {
  return {
    types: [
      ACTION.LOADING_VALIDATE_EMAIL,
      ACTION.RECEIVE_VALIDATE_EMAIL,
      ACTION.ERROR_VALIDATE_EMAIL,
    ],
    promise: teacherService.validateEmail(email)
  }
}

export function setEmailAsValid() {
  return {
    type: ACTION.SET_EMAIL_AS_VALID
  }
}