import React, { useEffect, useState } from 'react';
import Button from '../../../components/simple/Button';
import DateRange from '../../../components/custom/DateRange';
import { Col, Row, Select } from 'antd';
import './DeleteForm.scss';
import InputFile from '../../../components/simple/InputFile/InputFile';
import { validationFile } from '../../../utils/validationFile';
import InputButton from '../../../components/custom/InputButton/InputButton';
import ItemFileList from '../../../components/custom/ItemFileList/ItemFileList';
import { useManageFile } from '../../../customhooks';

const { Option } = Select;

function DeleteForm(props) {
  let {
    classReducer,
    groupsReducer,
    client,
    group,
    getGroupStudents,
    deleteStudents,
    getTypeEvidence,
    students,
    data
  } = props,
  {
    groupStudents,
    loading
  } = classReducer,

  {
    typeEvidence,
    loading: loadingGroup
  } = groupsReducer,

  [
    formData,
    setFormData
  ] = useState({
    students_id: [ ],
    range: null
  });

  const {
    currentFile,
    currentFileName,
    listFile,
    setCurrentFileName,
    setListFile,
    handleInputFile,
    handleInputFilePaste,
    checkNameFile
  } = useManageFile({});

  const updateForm = name => value => {
    setFormData({
      ...formData,
      [name]: value
    })
  },
  save = () => {
    formData.students_id = data;
    deleteStudents(client.id, group.id, { 
      ...formData, 
      attachments: listFile,
    }).then(() => {
      if (!loading.DELETE_STUDENTS) {
        props.close();
      }
    })
  };

  useEffect(() => {
    if(!groupStudents || !groupStudents.result){
      getGroupStudents(client.id, group.id);
    }
    getTypeEvidence();
    // eslint-disable-next-line
  },[]);

  return (
    <div className="DeleteForm">
      <h1>Dar de baja alumnos</h1>
      <Col>
        <Row>
          <label>Alumno(s)</label>
          <div className="list">
            {
              data.map((nid, index) => (
                <label key={ index }>{ students.find(student => nid === student.nid).name }</label>
              ))
            }
          </div>
        </Row>
      </Col>
      <DateRange placeholder={'Fecha de baja'} setToday={true} rows={true} disableEndDate={true} update={updateForm('range')} labels={true}/>
      <Col>
        <Row>
          <label>Motivo</label>
          <Row className="row-evidence">
            <Select 
              className="select-evidence" 
              placeholder='Seleccionar' 
              loading={ loadingGroup.GET_EVIDENCE?.state }
              onChange={ updateForm('type_evidence') }
            >
              { (typeEvidence || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.description }</Option>) }
            </Select>
          </Row>
          
        </Row>
      </Col>
      <Col>
        <Row>
          <label>Subir evidencia</label>
          <InputFile 
            accept=".png,.jpg,.pdf" 
            beforeUpload={validationFile}
            disabled={listFile.length >= 5 ? true : false}
            customRequest={(e) => handleInputFile(e)}
            showUploadList={false}
            handlePaste={handleInputFilePaste}
            />
        </Row>
      </Col>

      {
        currentFile &&
        <InputButton
          currentValue={currentFileName}
          setCurrentValue={setCurrentFileName}
          onClickButton={checkNameFile}
          title="Cambia el nombre de la imagen y valídala dando click al icono"
        />
      }

      {
        listFile.map((itemFile, index) => 
          <ItemFileList
            key={itemFile.uid || index}
            base64={itemFile.receipt}
            fileType={itemFile.mime_type}
            uid={itemFile.uid}
            nameFile={itemFile.name}
            listFile={listFile}
            onRemove={setListFile}
          />
        ) 
      }

      { loading.DELETE_STUDENTS && !loading.DELETE_STUDENTS.state && (
        <Row>
          <span className="message">{ loading.DELETE_STUDENTS?.detail?.response?.data?.message }</span>
        </Row>
      )}
      <Button 
        disabled={(listFile.length && formData?.type_evidence) ? false : true} 
        loading={ loading.DELETE_STUDENTS && loading.DELETE_STUDENTS.state ? true : false } 
        onClick={save} type={'primary'}
      >
        Aceptar
      </Button>
    </div>
  );
}


export default DeleteForm;
