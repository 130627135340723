export const LOADING = 'TEACHER_DETAIL::LOADING';
export const RECEIVE = 'TEACHER_DETAIL::RECEIVE';
export const ERROR = 'TEACHER_DETAIL::ERROR';

export const TEACHER_LOADING = 'TEACHER_DETAIL::TEACHER_LOADING';
export const TEACHER_RECEIVE = 'TEACHER_DETAIL::TEACHER_RECEIVE';
export const TEACHER_ERROR = 'TEACHER_DETAIL::TEACHER_ERROR';

export const LOCK_LOADING = 'TEACHER_DETAIL::LOCK_LOADING';
export const LOCK_RECEIVE = 'TEACHER_DETAIL::LOCK_RECEIVE';
export const LOCK_ERROR = 'TEACHER_DETAIL::LOCK_ERROR';

export const REMOVE_LOCK_LOADING = 'TEACHER_DETAIL::REMOVE_LOCK_LOADING';
export const REMOVE_LOCK_RECEIVE = 'TEACHER_DETAIL::REMOVE_LOCK_RECEIVE';
export const REMOVE_LOCK_ERROR = 'TEACHER_DETAIL::REMOVE_LOCK_ERROR';

export const LOADING_DELAYS = 'TEACHER_DETAIL::LOADING_DELAYS'
export const RECEIVE_DELAYS = 'TEACHER_DETAIL::RECEIVE_DELAYS'
export const ERROR_DELAYS = 'TEACHER_DETAIL::ERROR_DELAYS'

export const LOADING_CANCELLATIONS = 'TEACHER_DETAIL::LOADING_CANCELLATIONS'
export const RECEIVE_CANCELLATIONS = 'TEACHER_DETAIL::RECEIVE_CANCELLATIONS'
export const ERROR_CANCELLATIONS = 'TEACHER_DETAIL::ERROR_CANCELLATIONS'

export const LOADING_SCORES = 'TEACHER_DETAIL::LOADING_SCORES'
export const RECEIVE_SCORES = 'TEACHER_DETAIL::RECEIVE_SCORES'
export const ERROR_SCORES = 'TEACHER_DETAIL::ERROR_SCORES'

export const LOADING_ISSUES = 'TEACHER_DETAIL::LOADING_ISSUES'
export const RECEIVE_ISSUES = 'TEACHER_DETAIL::RECEIVE_ISSUES'
export const ERROR_ISSUES = 'TEACHER_DETAIL::ERROR_ISSUES'

export const CLEAR_ERROR = 'TEACHER_DETAIL::CLEAR_ERROR'

export const ACTIVE_KEY = 'TEACHER_DETAIL::ACTIVE_KEY'

export const SET_RANGE = 'TEACHER_DETAIL::SET_RANGE'

export const TABLE_PROPS = 'TEACHER_DETAIL::TABLE_PROPS'

export const ADD_DATA = 'TEACHER_DETAIL::ADD_DATA'
export const LOCK_RANGE = 'TEACHER_DETAIL::LOCK_RANGE'
export const TOGGLE_LOCK_FORM = 'TEACHER_DETAIL::TOGGLE_LOCK_FORM'
export const UPDATE_PROPERY = 'TEACHER_DETAIL::UPDATE_PROPERY'
export const UPDATE_RANGE = 'TEACHER_DETAIL::UPDATE_RANGE'
export const SET_COLORS = 'TEACHER_DETAIL::SET_COLORS'