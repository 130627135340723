import * as ACTION from './types';
import { justificationsService } from '../../services/justifications.service'

export function tableProps(name, page, sort) {
    return {
        type: ACTION.JUSTIFICATIONS_TABLEPROPS,
        name,
        page,
        sort
    }
};

export function saveFilters(filters) {
    return { type: ACTION.SAVE_FILTERS, filters }
};

export function getJustifications(range, filters, page, orderBy, groupId) {
    return {
        types: [
            ACTION.JUSTIFICATIONS_LOADING,
            ACTION.JUSTIFICATIONS_RECEIVE,
            ACTION.JUSTIFICATIONS_ERROR,
        ],
        promise: justificationsService.getJustifications(groupId, range, filters, page, orderBy),
    }
};

export function deleteEvidence(idEvidence) {
    return {
        types: [
            ACTION.DELETE_EVIDENCE_LOADING,
            ACTION.DELETE_EVIDENCE_RECEIVE,
            ACTION.DELETE_EVIDENCE_ERROR,
        ],
        promise: justificationsService.deleteEvidence(idEvidence),
    }
};

export function removeEvidenceOfState(idStudent, idEvidence) {
    return { type: ACTION.REMOVE_EVIDENCE_STATE, payload: { idStudent, idEvidence} };
};

export function putEvidence(idStudent, payload) {
    return {
        types: [
            ACTION.PUT_EVIDENCE_LOADING,
            ACTION.PUT_EVIDENCE_RECEIVE,
            ACTION.PUT_EVIDENCE_ERROR,
        ],
        promise: justificationsService.putEvidence(idStudent, payload),
    }
};
