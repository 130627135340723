import React from 'react';
import { Popover as AntPopover } from 'antd';
import PropTypes from 'prop-types';

import './Popover.scss'

function Popover(props){
  const { children, content } = props;
  return(
    <AntPopover
      className='Popover'
      style={{
        boxShadow: '0 2px 20px 0 rgba(0,0,0,0.4)',
        borderRadius: '30px'
      }}
      content={ content }
      { ...props }
    >
      { children }
    </AntPopover>
  );
}

Popover.propTypes = {
  children: PropTypes.any.isRequired,
  content: PropTypes.element.isRequired
};

export default Popover;