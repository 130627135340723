import * as ACTION from "./types";
 
const dataState = {
  loading: {},
  session: false,
};
 
const index = (state = dataState, action) => {
  switch (action.type) {
    case ACTION.AVAILABLE:
      return { ...state, session: true };
    case ACTION.UNAVAILABLE:
      return { ...state, session: false };

    case ACTION.LOADING_SET_LOGIN: 
      return { ...state, loading: { status: true } };
    case ACTION.RECEIVE_SET_LOGIN: 
      return { ...state, loading: undefined, session: true };
    case ACTION.ERROR_SET_LOGIN: 
      return { ...state, loading: { status: false } };
    default:
      return state;
  }
};

export default index;