import React from 'react';

import Spinner from '../../simple/Spinner';
import './SpinnerArea.scss';

function SpinnerArea(props) {
  return (
    <div className='SpinnerArea'>
      <Spinner {...props} />
    </div>
  );
}

export default SpinnerArea;