import React from 'react';
import PropTypes from 'prop-types';

import { Link } from "react-router-dom";

import InputPassword from '../../components/simple/InputPassword';
import InputEmail from '../../components/simple/InputEmail';

import Logo from '../../assets/img/logo.svg';
import Bonjour from '../../assets/img/login.svg';
import './LoginDisplay.scss';

function LoginDisplay(props) {
	const { onSubmit, onChange, userData } = props;
	return (
		<div className="login-container">
			<div className="form">
				<img alt="Logo" src={Logo} />

				<InputEmail
						name='user'
						onChange={onChange}
						value={userData.user}
						onPressEnter={onSubmit}
				/>
				<div className="password-row">
						<label>Contraseña.</label>
						<Link to="login">¿Olvidaste tu contraseña?</Link>
				</div>
				<InputPassword 
						name='password'
						onChange={onChange}
						value={userData.password}
						onPressEnter={onSubmit}
				/>

				<label className="checkbox">
						Recordarme
						<input
							type="checkbox"
							name='isRemember'
							onChange={onChange}
							checked={userData.isRemember}
						/>
						<span className="checkmark"></span>
				</label>
				
				<button onClick={onSubmit}>Iniciar sesión</button>
			</div>
			<img alt="Bonjour" src={Bonjour} />
		</div>
	);
}

LoginDisplay.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	userData: PropTypes.object.isRequired,
};

export default LoginDisplay;
