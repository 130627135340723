import React from 'react';
import GeneralConfig from './GeneralConfig';

import './Settings.scss';

const Settings = props => {
  return (
    <div className='Settings'>
      <GeneralConfig { ...props }/>
    </div>
  )
}

Settings.propTypes = {};

export default Settings;
