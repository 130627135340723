import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

import './InputCheckBox.scss';

function InputCheckBox(props){
  const { children } = props;
  return(
    <Checkbox className='InputCheckBox' {...props}>{children}</Checkbox>
  );
}

InputCheckBox.propTypes = {
  children: PropTypes.string.isRequired,
}

export default InputCheckBox;
