export const LOADING_CREATE_TEACHER = 'TEACHERS::LOADING_CREATE_TEACHER';
export const RECEIVE_CREATE_TEACHER = 'TEACHERS::RECEIVE_CREATE_TEACHER';
export const ERROR_CREATE_TEACHER = 'TEACHERS::ERROR_CREATE_TEACHER';

export const LOADING_EDIT_TEACHER = 'TEACHERS::LOADING_EDIT_TEACHER';
export const RECEIVE_EDIT_TEACHER = 'TEACHERS::RECEIVE_EDIT_TEACHER';
export const ERROR_EDIT_TEACHER = 'TEACHERS::ERROR_EDIT_TEACHER';

export const LOADING_GET_BY_LANGUAGE = 'TEACHERS::LOADING_GET_BY_LANGUAGE';
export const RECEIVE_GET_BY_LANGUAGE = 'TEACHERS::RECEIVE_GET_BY_LANGUAGE';
export const ERROR_GET_BY_LANGUAGE = 'TEACHERS::ERROR_GET_BY_LANGUAGE';

export const LOADING_GET_TEACHER = 'TEACHERS::LOADING_GET_TEACHER';
export const RECEIVE_GET_TEACHER = 'TEACHERS::RECEIVE_GET_TEACHER';
export const ERROR_GET_TEACHER = 'TEACHERS::ERROR_GET_TEACHER';

export const LOADING_GET_TARIFFS = 'TEACHERS::LOADING_GET_TARIFFS';
export const RECEIVE_GET_TARIFFS = 'TEACHERS::RECEIVE_GET_TARIFFS';
export const ERROR_GET_TARIFFS = 'TEACHERS::ERROR_GET_TARIFFS';

export const LOADING_VALIDATE_EMAIL = 'TEACHERS::LOADING_VALIDATE_EMAIL';
export const RECEIVE_VALIDATE_EMAIL = 'TEACHERS::RECEIVE_VALIDATE_EMAIL';
export const ERROR_VALIDATE_EMAIL = 'TEACHERS::ERROR_VALIDATE_EMAIL';

export const SET_EMAIL_AS_VALID = 'TEACHERS::SET_EMAIL_AS_VALID';
