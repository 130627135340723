import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './actions';
import Card from '../../components/simple/Card';
import WeeklyCalendar from '../../components/custom/WeeklyCalendar';
import WeekTab from '../../components/custom/WeekTab/WeekTab';
import { addDays } from '../../utils/dateFormats';

function Calendar(props) {
    let {
      match,
      teacher,
      changeWeek,
      setLockRange,
      update,
      removeLock,
      fetch
    } = props;
    let {
      intervals,
      currentDate,
      loading,
      form,
    } = teacher;
    const onChangeWeek = (days) => () => {
      let date = addDays(currentDate, days, true);
      changeWeek(date).then(() => fetch(date));
    };
    return (
        <div>
            <Card>
                <WeekTab
                viewSelected={'week'}
                currentDate={currentDate}
                onNextWeek={onChangeWeek}
                onBeforeWeek={onChangeWeek}
                />
            </Card>
            <WeeklyCalendar
                firstDay={currentDate}
                selectedIntervals={ intervals }
                showTeacher={ match.params.id ? false : true }
                onIntervalSelect={range => {
                setLockRange(range[0]);
                }}
                removeAll={form.removeAll}
                toDelete={form.toDelete}
                update={update}
                removeLock={(...props) => {
                removeLock(...props).then(fetch);
                }}
                loading={loading.removeLock ? true : false}
            />
        </div>
    );
}

Calendar.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
    teacher: state.get('teacher')
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
