import { Input } from "antd";
import IconButton from "../../simple/IconButton/IconButton";
import { CheckCircleOutlined } from '@ant-design/icons';
import './InputButton.scss';

const InputButton = (props) => {
  return (
    <>
      { props.title && <p className="label-list title-principal">{ props.title }</p> }
      <div className="name-file">
        <label className="label-list">Nombre del archivo: </label>
        <div>
          <Input
            placeholder="Nombre del archivo"
            bordered={false}
            value={props.currentValue}
            onChange={(e) => props.setCurrentValue(e.target.value)}
          />
        </div>
        <IconButton
          onClick={() => props.onClickButton()}
          disabled={props.currentValue ? false : true}
          icon={<CheckCircleOutlined />}
        />
      </div>
    </>
  );
};

export default InputButton;
