import client from '../axios';

class IncidentsService {
    getIncidents(range, filters, page, orderBy) {
        if (!range)
            range = { };
        return client.post('v1/auth/incidents', {
            filters: {
                startdate: range.startdate,
                enddate: range.enddate,
                ...(filters || {})
            },
            pagination: {
                page,
                limit: 10,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        })
    }
}

export const incidentsService = new IncidentsService();
