export const TABLE_PROPS = 'ADDRESS::TABLE_PROPS';

export const LOADING_GET_ADDRESSES = 'ADDRESS::LOADING_GET_ADDRESSES';
export const RECEIVE_GET_ADDRESSES = 'ADDRESS::RECEIVE_GET_ADDRESSES';
export const ERROR_GET_ADDRESSES = 'ADDRESS::ERROR_GET_ADDRESSES';

export const LOADING_SAVE_ADDRESS = 'ADDRESS::LOADING_SAVE_ADDRESS';
export const RECEIVE_SAVE_ADDRESS = 'ADDRESS::RECEIVE_SAVE_ADDRESS';
export const ERROR_SAVE_ADDRESS = 'ADDRESS::ERROR_SAVE_ADDRESS';

export const LOADING_GET_GROUP_ADDRESSES = 'ADDRESS::LOADING_GET_GROUP_ADDRESSES';
export const RECEIVE_GET_GROUP_ADDRESSES = 'ADDRESS::RECEIVE_GET_GROUP_ADDRESSES';
export const ERROR_GET_GROUP_ADDRESSES = 'ADDRESS::ERROR_GET_GROUP_ADDRESSES';

export const LOADING_GET_GROUP_COMPLETED_ADDRESSES = 'ADDRESS::LOADING_GET_GROUP_COMPLETED_ADDRESSES';
export const RECEIVE_GET_GROUP_COMPLETED_ADDRESSES = 'ADDRESS::RECEIVE_GET_GROUP_COMPLETED_ADDRESSES';
export const ERROR_GET_GROUP_COMPLETED_ADDRESSES = 'ADDRESS::ERROR_GET_GROUP_COMPLETED_ADDRESSES';

export const LOADING_LINK_GROUP_ADDRESS = 'ADDRESS::LOADING_LINK_GROUP_ADDRESS';
export const RECEIVE_LINK_GROUP_ADDRESS = 'ADDRESS::RECEIVE_LINK_GROUP_ADDRESS';
export const ERROR_LINK_GROUP_ADDRESS = 'ADDRESS::ERROR_LINK_GROUP_ADDRESS';

export const LOADING_LINK_GROUP_COMPLETED_ADDRESS = 'ADDRESS::LOADING_LINK_GROUP_COMPLETED_ADDRESS';
export const RECEIVE_LINK_GROUP_COMPLETED_ADDRESS = 'ADDRESS::RECEIVE_LINK_GROUP_COMPLETED_ADDRESS';
export const ERROR_LINK_GROUP_COMPLETED_ADDRESS = 'ADDRESS::ERROR_LINK_GROUP_COMPLETED_ADDRESS';

export const LOADING_GET_ALL_ADDRESSES = 'ADDRESS::LOADING_GET_ALL_ADDRESSES';
export const RECEIVE_GET_ALL_ADDRESSES = 'ADDRESS::RECEIVE_GET_ALL_ADDRESSES';
export const ERROR_GET_ALL_ADDRESSES = 'ADDRESS::ERROR_GET_ALL_ADDRESSES';

export const LOADING_DELETE_ADDRESS = 'ADDRESS::LOADING_DELETE_ADDRESS';
export const RECEIVE_DELETE_ADDRESS = 'ADDRESS::RECEIVE_DELETE_ADDRESS';
export const ERROR_DELETE_ADDRESS = 'ADDRESS::ERROR_DELETE_ADDRESS';

export const LOADING_DELETE_ADDRESS_IN_GROUP_COMPLETED = 'ADDRESS::LOADING_DELETE_ADDRESS_IN_GROUP_COMPLETED';
export const RECEIVE_DELETE_ADDRESS_IN_GROUP_COMPLETED = 'ADDRESS::RECEIVE_DELETE_ADDRESS_IN_GROUP_COMPLETED';
export const ERROR_DELETE_ADDRESS_IN_GROUP_COMPLETED = 'ADDRESS::ERROR_DELETE_ADDRESS_IN_GROUP_COMPLETED';

export const LOADING_CONFLICT_ADDRESS = 'ADDRESS::LOADING_CONFLICT_ADDRESS';
export const RECEIVE_CONFLICT_ADDRESS = 'ADDRESS::RECEIVE_CONFLICT_ADDRESS';
export const ERROR_CONFLICT_ADDRESS = 'ADDRESS::ERROR_CONFLICT_ADDRESS';


export const IGNORE_ADDRESS_CONFLICTS = 'ADDRESS::IGNORE_ADDRESS_CONFLICTS'