import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import {
    Row
} from 'antd';
import Select from '../../components/simple/Select'
import AdvancedRange from '../../components/custom/AdvancedRange'
import moment from 'moment';
import './CardTitle.scss';

const simpleFilters = [
    {
        value: 2,
        label: 'Últimas 48 hrs'
    },
    {
        value: 7,
        label: 'Últimos 7 días'
    },
    {
        value: 14,
        label: 'Últimos 14 días'
    }
],
advancedFilters = [
    {
        value: 'week',
        label: 'Semana'
    },
    {
        value: 'month',
        label: 'Mes'
    },
    {
        value: 'trimester',
        label: 'Trimestre'
    },
    {
        value: 'range',
        label: 'Rango'
    }
];

function CardTitle(props) {
    let { title, onChange, advanced } = props,
        format = filter => {
            return {
                startdate: moment().set('hour', 0).set('minutes', 0).set('seconds', 0).subtract(filter, 'days').valueOf(),
                enddate: moment().set('hour', 23).set('minutes', 59).set('seconds', 0).valueOf()
            }
        };
    const defaultValue = advanced ? 'week' : 2;
    const   [ range, setRange ] = useState(defaultValue);
    useEffect(() => {
        if (!advanced)
            onChange(format( defaultValue ))
        // eslint-disable-next-line
    }, []);
    return (
        <div className='CardTitle'>
            <Row>
                <h3>{ title }</h3>
                <Select
                    selectProps={{
                        showSearch: false,
                        value: range,
                        style: { width: 140 },
                        onChange: filter => {
                            setRange(filter);
                            if (!advanced)
                                onChange(format(filter))
                        }
                    }}
                    items={advanced ? advancedFilters : simpleFilters}
                />
                { advanced && <AdvancedRange allowClear={false} defaults={true} onChange={onChange} mode={ range }/> }
            </Row>
        </div>
    );
}

export default connect((state) => ({
  dashboard: state.get('dashboard')
}), (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(CardTitle);