import React from 'react';

import './CircleGraph.scss';

function CircleGraph(props) {
  let { size, color } = props;
  let level = props.level ? props.level : '0';
  if (!size)
      size = 60;
  let diameter = size - 4;
  let circumference = 3.14159 * diameter;
  let radius = diameter / 2;
  let M = size / 2;
    if (!color) {
        switch (level.split('.')[0]) {
            case 'A1':
                color = '#F39421';
            break;
            case 'A2':
                color = '#E0761E';
            break;
            case 'B1':
                color = '#5B9BD5';
            break;
            case 'B2':
                color = '#3C6EC8';
            break;
            case 'C1':
                color = '#92D050';
            break;
            case 'C2':
                color = '#70AD47';
            break;
            default:
                color = 'transparent';
            break;
        }
    }

  return(
    <div className='CircleGraph' style={{
        width: size,
        height: size,
    }}>
      <span>{level}</span>
      <div className='CircleGraph__circle'/>
      <svg style={{
          position: 'absolute',
          top: 0,
          left: 0
      }} width={size} height={size} viewBox={ "0 0 " + size + " " + size }>
          <path
              stroke={color}
              fill="none"
              strokeWidth = "3"
              strokeLinecap="round"
              strokeDasharray={ (circumference * (props.porcentage * .01)) + ', ' + circumference }
              d={ "M" + M + " 2.0845 a " + radius + " " + radius + " 0 0 1 0 " + diameter + " a " + radius + " " + radius  + " 0 0 1 0 -" + diameter}
          />
      </svg>
    </div>
  );
}

export default CircleGraph;
