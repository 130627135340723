import { Select } from "antd";

const { Option } = Select;

const ScheduleSelect = (props) => (
	<Select { ...props } >
		<Option value={1}>Lunes</Option>
		<Option value={2}>Martes</Option>
		<Option value={3}>Miércoles</Option>
		<Option value={4}>Jueves</Option>
		<Option value={5}>Viernes</Option>
		<Option value={6}>Sábado</Option>
		<Option value={7}>Domingo</Option>
	</Select>
);

export default ScheduleSelect;
