import React, { useState } from 'react';
import {
  Row,
  Col
} from 'antd';
import Card from '../../../components/simple/Card';
import Tabs from '../../../components/simple/Tabs';
import FormAdminHolidays from '../FormAdminHolidays';
import FormTeacherTariffs from '../FormTeacherTariffs';
import FormClientTariffs from '../FormClientTariffs';
import FormMaterials from '../FormMaterials';

import './GeneralConfig.scss';

const GeneralConfig = props => {

  const [ activeKey, setActiveKey ] = useState(1);
  
  return (
    <div className='GeneralConfig'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Tabs
              className='wrapper'
              defaultActiveKey={ activeKey }
              onChange={ setActiveKey }
              items={[
                {
                  value: 0,
                  key:'0',
                  label: 'Días inhábiles',
                  children: <FormAdminHolidays { ...props }/>
                },
                {
                  value: 1,
                  key:'1',
                  label: 'Tarifas clientes',
                  children: <FormClientTariffs { ...props }/>
                },
                {
                  value: 2,
                  key:'2',
                  label: 'Tarifas profesores',
                  children: <FormTeacherTariffs { ...props }/>
                },
                {
                  value: 3,
                  key: '3',
                  label: 'Material',
                  children: <FormMaterials { ...props }/>
                }
              ]}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

GeneralConfig.propTypes = {};

export default GeneralConfig;
