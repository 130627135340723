import { studentsService } from '../../services/students.service';
import * as ACTION from './types';

export function getClientStudents(cid, page, orderBy, searchQuery) {
    return {
      types: [
        ACTION.LOADING_GET_CLIENT_STUDENTS,
        ACTION.RECEIVE_GET_CLIENT_STUDENTS,
        ACTION.ERROR_GET_CLIENT_STUDENTS,
      ],
      promise: studentsService.getClientStudents(cid, page, orderBy, searchQuery)
    }
}

export function putStudents(cid, gid, students) {
  return {
    types: [
      ACTION.LOADING_PUT_STUDENTS,
      ACTION.RECEIVE_PUT_STUDENTS,
      ACTION.ERROR_PUT_STUDENTS,
    ],
    promise: studentsService.putStudents(cid, gid, students)
  }
}

export function tableProps(page, sort, name) {
    return {
      type: ACTION.TABLE_PROPS,
      page,
      sort,
      name
    }
}