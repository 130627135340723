import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import md5 from 'md5';
import { createTeacher, editTeacher, getTeacher, validateEmail, setEmailAsValid } from '../../reducers/teachers/actions';
import { getStudyPlans } from '../../reducers/study-plans/actions';
import { Card, Row, Col, Form, Radio, Input, Select } from 'antd';
import ConfirmationModal from '../../components/custom/ConfirmationModal/ConfirmationModal';
import FormWrapper from '../../components/custom/FormWrapper';
import Breadcrumb from '../../components/simple/Breadcrumb';

import './NewTeacher.scss';

const { Option } = Select;

let emailValidationTimeout;

const NewTeacher = props => {
  
  const { studyPlanReducer, teachersReducer, match : { params : { teacherId } }, history } = props;

  const { location: { state: stateLocation } } = history;

  const { teacher, invalidEmail } = teachersReducer;

  const { catalog : studyPlansCatalog } = studyPlanReducer;
  
  const [ form ] = Form.useForm();

  const [ values, setValues ] = useState({});

  const [ savedEmail, setSavedEmail ] = useState({});
  
  const validateIfEmailExists = email => {

    clearTimeout(emailValidationTimeout);
    
    const validateNewTeacher = email && !teacherId;
    
    const validateExistingTeacher = email && savedEmail && teacherId && email !== savedEmail;

    if (validateNewTeacher || validateExistingTeacher) {
      emailValidationTimeout = setTimeout(_ => props.validateEmail(email), 1000);
    } else if (!validateExistingTeacher) {
      props.setEmailAsValid();
    }
  }

  const getEmailInput = _ => {
    
    let status, helpText;

    if (invalidEmail) {
      status= 'error';
      helpText = 'Este correo electrónico ya esta en uso';
    } else if (teachersReducer.loading.VALIDATE_EMAIL?.state) {
      status= 'validating';
    }

    return (
      <Form.Item
        label='Correo electrónico'
        name='email'
        validateStatus={ status }
        help={ helpText }
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Por favor ingresa el correo electrónico'
          }, {
            type: 'email',
            message: 'Ingresa un correo valido'
          }
        ]}
      >
        <Input autoComplete="off"/>
      </Form.Item>
    );
  }

  const onChange = formValue => {
    
    setValues({
      ...values,
      ...formValue,
      password: formValue?.password ? md5(formValue?.password) : values.password
    });

    validateIfEmailExists(formValue?.email);
  };

  const onFinish = _ => (teacherId ? props.editTeacher(teacherId, values) : props.createTeacher(values)).then(props.history.goBack);

  useEffect(_ => {
    props.getStudyPlans();
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    if (teacherId) {
      props.getTeacher(teacherId);
    }
  }, [ teacherId ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    const initialValues = teacherId ? teacher : { };

    setSavedEmail(initialValues?.email);

    form.setFieldsValue(initialValues);

    onChange(initialValues);
  }, [ form, teacher, teacherId ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='NewTeacher'>
      
      {!!stateLocation?.teacherId &&
        <Breadcrumb
          routes={[
            { label:'Lista de Profesores', url: '/teachers', },
            { label: teacher?.name || '', url: `/teachers/detail/${stateLocation?.teacherId}`},
            { label:'Editar profesor' }
          ]}
        />
      }

      {!stateLocation?.teacherId &&
        <Breadcrumb
          routes={[
            { label:'Lista de Profesores', url: '/teachers', },
            { label:'Nuevo profesor' }
          ]}
        />
      }
      
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <FormWrapper
              { ...props }
              loading={ teachersReducer.loading.CREATE_TEACHER?.state || teachersReducer.loading.GET_TEACHER?.state || teachersReducer.loading.EDIT_TEACHER?.state }
              layout={'vertical'}
              form={form}
              onValuesChange={ onChange }
              onFinish={ onFinish }
              name='teacher-form'
              cancelButtonText='Regresar'
            >
              <Row className='name-row'>
                <Col>
                  <Form.Item
                    label="Nombre del profesor"
                    name="name"
                    rules={[
                      { required: true },
                    ]}
                  >
                    <Input placeholder="Nombre(s)" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="first_last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingresar apellido paterno'
                      },
                    ]}
                  >
                    <Input placeholder="Apellido paterno" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="second_last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingresar apellido materno'
                      }
                    ]}
                  >
                    <Input placeholder="Apellido materno" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Tipo de contrato"
                name="type_contract"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecciona un tipo de contrato'
                  }
                ]}
              >
                <Radio.Group>
                  <Radio value={ 1 }>Planta</Radio>
                  <Radio value={ 2 }>Freelance</Radio>
                  <Radio value={ 3 }>Efectivo</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label='Idiomas'
                name='languages_id'
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecciona al menos un idioma'
                  }
                ]}
              >
                <Select mode="multiple" placeholder="Seleccionar" loading={ studyPlanReducer.loading.GET_CATALOG?.state }>
                  { (studyPlansCatalog || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.name }</Option>) }
                </Select>
              </Form.Item>
              <Form.Item
                label='Teléfono celular'
                name='cellphone'
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa un número de teléfono'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              { getEmailInput() }
              <Form.Item
                label='Contraseña'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa la contraseña'
                  }
                ]}
              >
                <Input.Password autoComplete="off"/>
              </Form.Item>
            </FormWrapper>
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        title='Se han guardado los datos con éxito'
        visible={ false }
        values={ values }
        labels={{
          initial_date: {
            title: 'Nombre'
          },
          final_date: {
            title: 'Tipo de contrato'
          }
        }}
      />
    </div>
  )
}

NewTeacher.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getStudyPlans, createTeacher, editTeacher, getTeacher, validateEmail, setEmailAsValid }, dispatch);

const mapStateToProps = state => ({
  teachersReducer: state.get('teachersReducer'),
  studyPlanReducer: state.get('studyPlanReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTeacher);
