import React from 'react';
import moment from 'moment';

import Button from '../../simple/Button/Button';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { rangeWeekDays } from '../../../utils/dateFormats';
import './WeekTab.scss';

function WeekTab(props) {
  const { currentDate, onNextWeek, onBeforeWeek, viewSelected } = props;
  return(
    <div className='WeekTab'>
      <Button shape='round' size='default' icon={ <LeftOutlined/> } onClick={onBeforeWeek(viewSelected === 'week' ? -7 : -1)} />
      <Button shape='round' size='default' icon={ <RightOutlined/> } onClick={onNextWeek(viewSelected === 'week' ? 7 : 1)} />
      <label>
        {
          viewSelected === 'week'
          ? rangeWeekDays(currentDate)
          : moment(currentDate,'YYYY-MM-DD').format('dddd, D [de] MMMM')
        }
      </label>
    </div>
  );
}

WeekTab.propTypes = {}

export default WeekTab;