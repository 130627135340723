import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

import './RadioButtonTab.scss';

function RadioButtonTab(props){
  const { items } = props;
  return(
    <Radio.Group className='RadioButtonTab' {...props}>
      {
        items.map(item => {
          return(
            <Radio.Button
              key={item.value}
              value={item.value}>
              {item.label}
            </Radio.Button>
          );
        })
      }
    </Radio.Group>
  );
}

RadioButtonTab.propTypes = {
  items: PropTypes.array.isRequired,
};

export default RadioButtonTab;
