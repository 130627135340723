import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as Actions from './actions';
import { Avatar, Checkbox, Row, Col } from 'antd';
import { CloseCircleOutlined, DownCircleOutlined, EditOutlined } from '@ant-design/icons';
import Breadcrumb from '../../components/simple/Breadcrumb';
import Button from '../../components/simple/Button';
import Card from '../../components/simple/Card';
import Flags from '../../components/simple/Flags';
import { getInitEndWeek } from '../../utils/dateFormats';
import SpinnerArea from '../../components/custom/SpinnerArea';
import ModalClosable from '../../components/custom/ModalClosable';
import TeacherTabs from './Tabs';
import Calendar from './Calendar';
import LockForm from './LockForm';

import './TeacherDetail.scss';

function TeacherDetail(props){
  let {
    match,
    teacher,
    selection,
    getAgenda,
    getTeacher,
    toggleLockForm,
    setColors,
  } = props;
  let {
    currentDate,
    loading,
    user,
    showLockForm,
    colors
  } = teacher;

  const { teacherId } = match.params;
  
  let [ checkedItems, setCheckedItems ] = useState([]);
  let [ info, setInfoState ] = useState(false);
  let teachers = teacherId ? [ { id: Number(teacherId)} ] : selection;
  const fetch = date => {
    if (!teacherId && !selection.length)
      return;

    getAgenda(
      checkedItems,
      getInitEndWeek((date || currentDate).format("YYYY-MM-DD"))
    );
  };
  useEffect(() => {
    let ids = teachers.map(item => (item.id))
    setColors(ids);
    if (!checkedItems.length)
      setCheckedItems(ids);
    if (teacherId)
      getTeacher(teacherId)
      // eslint-disable-next-line
  },[]);
  // eslint-disable-next-line
  useEffect(fetch, [
    checkedItems
  ])
  if (!teacherId && !selection.length)
    return <Redirect to='/teachers' />
  return (
    <div className='TeacherDetail'>
      <Breadcrumb
        routes={[
          { label:'Lista de Profesores', url: '/teachers', },
          { label:'Detalle' }
        ]}
      />
      <div className='TeacherDetail__inside'>
        { teacherId && user && (
          <Card>
            <div className={`detail ${ info ? 'opened' : '' }`}>
              <div className="user">
                <Avatar size={56}>{ user.name[0] }</Avatar>
                <h1>{ user.name }</h1>
              </div>
              <div>
                <Flags value={ user.languages }/>
                <label>{ user.type }</label>
                <label>Horas del mes: { user.totalHoursOfMonth }h</label>
                <label>{ user.phone }</label>
                <label>{ user.email }</label>
                <Button 
                  onClick={() => props.history.push(
                    `/teachers/edit/${ user.teacherNID }`, 
                    { teacherId }
                  )} 
                  icon={ <EditOutlined /> }>Editar</Button>
              </div>
              <Button onClick={() => setInfoState(!info)} shape="circle" icon={ info ? <CloseCircleOutlined/> : <DownCircleOutlined/> } />
            </div>
            {
              info && (
                <div className="more-info">
                  <Row>
                    <Col>
                      <label className="title">RFC:</label>
                      <label>{ user.rfc }</label>
                    </Col>
                    <Col>
                      <label className="title">Número de cuenta:</label>
                      <label>{ user.bank.accountNumber }</label>
                    </Col>
                    <Col>
                      <label className="title">Outsourcing:</label>
                      <label>{ user.outsourcing.name }</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="title">CURP:</label>
                      <label>{ user.curp }</label>
                    </Col>
                    <Col>
                      <label className="title">CLABE:</label>
                      <label>{ user.bank.clabe }</label>
                    </Col>
                    <Col>
                      <label className="title">Sucursal{ user.branchOffice.length > 1 ? 'es' : '' }:</label>
                      <label>{ user.branchOffice.map((item, index) => item.name + (user.branchOffice.length - 1 < index ? ', ' : '') ) }</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="title">Género:</label>
                      <label>{ user.gender }</label>
                    </Col>
                    <Col>
                      <label className="title">Banco:</label>
                      <label>{ user.bank.name }</label>
                    </Col>
                    <Col></Col>
                  </Row>
                </div>
              )
            }
          </Card>
        )}
        { loading.agenda || loading.user ? (
          <Card>
            <SpinnerArea size='large'/>
          </Card>
        ) : (
          <div>
            { !teacherId && (
              <Card className="selection">
                <Checkbox.Group value={ checkedItems } onChange={values => {
                  setCheckedItems(values)
                }} options={selection.map((teacher, index) => ({
                  value: teacher.id,
                  label: (
                    <div key={index}>
                      <span className="color_id" style={{
                        backgroundColor: colors[teacher.id]
                      }}></span>
                      <a target="_blank" rel="noopener noreferrer" href={`teachers/detail/${ teacher.id }`}>{ teacher.name }</a>
                    </div>
                  )
                }))}/>
              </Card>
            )}
            { teacherId ? <TeacherTabs fetch={fetch} { ...props }/>  : <div className="CalendarCard"><Calendar fetch={fetch} { ...props }/></div> }
          </div>
        )}
      </div>
      <ModalClosable
        width={500}
        visible={showLockForm}
        onClose={toggleLockForm}
      >
        <LockForm selection={ teachers } done={() => {
          toggleLockForm();
          fetch();
        }}/>
      </ModalClosable>
    </div>
  );
}

TeacherDetail.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  teacher: state.get('teacher'),
  selection: state.get('teachers').selection,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDetail);
