import React from 'react';
import moment from 'moment';

import { getInitEndWeek, addDays } from '../../../utils/dateFormats';
import './CalendarHeaders.scss';

function mapHolidays(date, holidays) {
  const currentHolidays = (holidays || [ ]).filter(event => event.date === date && event.type === 'speak');

  return currentHolidays.map(holiday => <div className={ `holiday ${ holiday.type }` }>
    Día inhábil S-Peak
  </div>);
}

function setDayOfMonth(date, day) {
  return moment(date, 'YYYY-MM-DD').startOf('month').add(day - 1, 'day').format('YYYY-MM-DD');
}

function ModeMonth(props) {
  
  const { currentDate, holidays } = props;

  const ranges = getInitEndWeek(currentDate);
  
  const determineDay = () => {
    const day = {
      lun: 0,
      mar: 0,
      mié: 0,
      jue: 0,
      vie: 0,
      sáb: 0,
      dom: 0,
    };
    for (let index = 1; index <= 7; index++) {
      const date = addDays(ranges.init, index);
      const numberDay = moment(date,'YYYY-MMM-DD').format('D');
      const keyDay = moment(date,'YYYY-MM-DD').format('dddd');
      const key = keyDay.slice(0,3);
      day[key] = numberDay;
    }
    return day;
  }
  const data = determineDay();


  return (
    <div className={ `CalendarHeaders ${ !!(holidays || [ ]).length && 'has-holidays' }` }>
      <div className='CalendarHeaders__empty'/>
      <div className='CalendarHeaders__day'>
        Lun {data.lun}
        { mapHolidays(setDayOfMonth(ranges.init, data.lun), holidays) }
      </div>
      <div className='CalendarHeaders__day'>
        Mar {data.mar}
        { mapHolidays(setDayOfMonth(ranges.init, data.mar), holidays) }
      </div>
      <div className='CalendarHeaders__day'>
        Mié {data.mié}
        { mapHolidays(setDayOfMonth(ranges.init, data.mié), holidays) }
      </div>
      <div className='CalendarHeaders__day'>
        Jue {data.jue}
        { mapHolidays(setDayOfMonth(ranges.init, data.jue), holidays) }
      </div>
      <div className='CalendarHeaders__day'>
        Vie {data.vie}
        { mapHolidays(setDayOfMonth(ranges.init, data.vie), holidays) }
      </div>
      <div className='CalendarHeaders__day'>
        Sáb {data.sáb}
        { mapHolidays(setDayOfMonth(ranges.init, data.sáb), holidays) }
      </div>
    </div>
  );
}

function ModeDay(props) {
  const { currentDate, holidays } = props;
  const day = moment(currentDate,'YYYY-MM-DD').format('dddd D');
  const holidayMarkers = mapHolidays(currentDate, holidays);

  return (
    <div className={ `CalendarHeaders ${ !!(holidays || [ ]).length && 'has-holidays' }` }>
      <div className='CalendarHeaders__empty'/>
      <div className='CalendarHeaders__day CalendarHeaders__day__alone'>
        { day }
        { holidayMarkers }
      </div>
    </div>
  );
}

function CalendarHeaders(props) {
  const { mode } = props;
  switch(mode){
    case 'month':
      return <ModeMonth {...props} />
    case 'day':
      return <ModeDay {...props} />
    default:
      return <ModeMonth {...props} />
  }
}

export default CalendarHeaders;
