import React from 'react';
import moment from "moment";

import './CellDatePicker.scss';

const CellDatePicker = (dateCell, datesListForMark) => {
    const classesOfGroup = ( datesListForMark || [] ).map(data => data.date);
    const isDayWithClass = classesOfGroup.includes(moment(dateCell).format('DD-MM-YYYY'));
    const currentDate = moment().format('YYYY-MM-DD');
    const dayCell = Number(moment(dateCell).format('DD'));
    const getColorForDayWithClass = (color) => ( isDayWithClass ? color : '');
    return (
      <div style={{
					backgroundColor: getColorForDayWithClass('#f4f4f4'),
					color: getColorForDayWithClass('#4d9cf4'),
					border: currentDate === moment(dateCell).format('YYYY-MM-DD') ? `0.1px solid #4d9cf4` : '',
				}}
				className='cell-date-picker'
			>
        {dayCell}
      </div>
    );
  };

export default CellDatePicker;