import { clientService } from '../../services/client.service';
import * as ACTION from './types';

export function tableProps(page, sort, name) {
    return {
        type: ACTION.TABLE_PROPS,
        page,
        sort,
        name
    }
}

export function getClients(data, page, order, searchQuery) {
    return {
        types: [
            ACTION.LOADING_GET_CLIENTS,
            ACTION.RECEIVE_GET_CLIENTS,
            ACTION.ERROR_GET_CLIENTS,
        ],
        promise: clientService.getClients(data, page, order, searchQuery)
    }
}

export function getAllClients() {
    return {
        types: [
            ACTION.LOADING_GET_ALL_CLIENTS,
            ACTION.RECEIVE_GET_ALL_CLIENTS,
            ACTION.ERROR_GET_ALL_CLIENTS,
        ],
        promise: clientService.getAllClients()
    }
}

export function getClient(cid) {
    return {
        types: [
            ACTION.LOADING_GET_CLIENT,
            ACTION.RECEIVE_GET_CLIENT,
            ACTION.ERROR_GET_CLIENT,
        ],
        promise: clientService.getClient(cid)
    }
}

export function createClient(data) {
    return {
        types: [
            ACTION.LOADING_CREATE_CLIENT,
            ACTION.RECEIVE_CREATE_CLIENT,
            ACTION.ERROR_CREATE_CLIENT,
        ],
        promise: clientService.createClient(data)
    }
}

export function editClient(clientId, data) {
    return {
        types: [
            ACTION.LOADING_EDIT_CLIENT,
            ACTION.RECEIVE_EDIT_CLIENT,
            ACTION.ERROR_EDIT_CLIENT,
        ],
        promise: clientService.editClient(clientId, data)
    }
}

export function getTariffs(data) {
    return {
        types: [
            ACTION.LOADING_GET_TARIFFS,
            ACTION.RECEIVE_GET_TARIFFS,
            ACTION.ERROR_GET_TARIFFS,
        ],
        promise: clientService.getTariffs(data)
    }
}

export function getPlans(data) {
    return {
        types: [
            ACTION.LOADING_GET_PLANS,
            ACTION.RECEIVE_GET_PLANS,
            ACTION.ERROR_GET_PLANS,
        ],
        promise: clientService.getPlans(data)
    }
}

export function getHolidays(startDate, endDate, clientId) {
    return {
        types: [
            ACTION.LOADING_GET_CLIENT_HOLIDAYS,
            ACTION.RECEIVE_GET_CLIENT_HOLIDAYS,
            ACTION.ERROR_GET_CLIENT_HOLIDAYS,
        ],
        promise: clientService.getHolidays(startDate, endDate, clientId)
    }
}

export function saveHolidays(startDate, endDate, clientId) {
    return {
        types: [
            ACTION.LOADING_SAVE_CLIENT_HOLIDAYS,
            ACTION.RECEIVE_SAVE_CLIENT_HOLIDAYS,
            ACTION.ERROR_SAVE_CLIENT_HOLIDAYS,
        ],
        promise: clientService.saveHolidays(startDate, endDate, clientId)
    }
}

export function deleteHoliday(holidayId) {
    return {
        types: [
            ACTION.LOADING_DELETE_CLIENT_HOLIDAY,
            ACTION.RECEIVE_DELETE_CLIENT_HOLIDAY,
            ACTION.ERROR_DELETE_CLIENT_HOLIDAY,
        ],
        promise: clientService.deleteHolidays(holidayId)
    }
}

export function getValidateClientName(clientName) {
    return {
        types: [
            ACTION.LOADING_GET_VALIDATE_CLIENT_NAME,
            ACTION.RECEIVE_GET_VALIDATE_CLIENT_NAME,
            ACTION.ERROR_GET_VALIDATE_CLIENT_NAME,
        ],
        promise: clientService.getValidateClientName(clientName)
    }
}