import React from 'react';
import './Tags.scss';
import DangerButton from '../DangerButton';

function Tags(props){
  const { list, selection, onDelete, children, onClear, removeButton, className } = props;
  let hasTags = list && Object.values(list).length > 0 ? true : false;

  return (
    <div className={ `Tags ${ className }` }>
      { children }
      <div className={`Tags__container ${ hasTags ? '' : 'align-right' }`}>
        { hasTags && (
          <div className='Tags__container__brands'>
            {
              Object.values(list).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={ `Tags__container__brands__tag ${ item.category }` }
                    onClick={() => onDelete(item, index)}>
                    {
                      item.icon && (
                        <label>{ item.icon }</label>
                      )
                    }
                    <span>{item.label}</span>
                  </div>
                );
              })
            }
          </div>
        )}
        { (hasTags || (selection && selection.length > 0)) && removeButton && (
          <div className='Tags__delete'>
            <DangerButton onClick={onClear}>Limpiar</DangerButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default Tags;
