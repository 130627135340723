import React, { useEffect, useState } from 'react';
import { Col, Divider, notification, Row, Select } from "antd";
import { validationFile } from "../../../utils/validationFile";
import InputFile from "../../simple/InputFile/InputFile";
import InputButton from "../InputButton/InputButton";
import ItemFileList from "../ItemFileList/ItemFileList";
import { useManageFile } from '../../../customhooks';
import './EditRegistration.scss';
import Button from '../../simple/Button';
import Modal from '../../simple/Modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const EditRegistration = (props) => {

	const [ formData, setFormData ] = useState({});

  const [ modalConfirmVisible, setModalConfirmVisible ] = useState(false);

  const [ evidenceToDelete, setEvidenceToDelete ] = useState({});

  const [ isChangeForm, setIsChangeForm ] = useState(false);

	const { 
		data: { 
			evidences = [], 
			type_name,
			id: idStudent,
      id_reason
		},
		groupsReducer, 
		getTypeEvidence,
		deleteEvidence,
		removeEvidenceOfState,
    putEvidence,
    getJustifications,
    state, 
	} = props;

	const {
    typeEvidence,
    loading: loadingGroup
  } = groupsReducer;

  const {
    loading,
    filters
  } = state;

	const {
    currentFile,
    currentFileName,
    listFile,
    setCurrentFileName,
    setListFile,
    setLimitFile,
    handleInputFile,
    handleInputFilePaste,
    checkNameFile
  } = useManageFile({limitFileCount: (5 - (evidences?.length || 0))});

	const updateForm = name => value => {
    setFormData({
      ...formData,
      [name]: value
    });

    (value !== id_reason) 
      ? setIsChangeForm(true)
      : setIsChangeForm(false);
  };

  const saveChanges = () => {
    const payload = { ...formData, attachments: listFile, };
    putEvidence(idStudent, payload).then(() => {
      if (!loading.PUT_EVIDENCE_LOADING) {
        setListFile([]);
        props.onClose();
        notification['success']({
          description: 'Se han guardado los cambios con éxito',
          placement: 'bottomRight'
        });
        getJustifications && getJustifications(
          filters.range, 
          filters.filters, 
          filters.page, 
          filters.orderBy, 
          filters.entity
        );
      }
    });
  };

	const deleteFile = (evidenceId) => {
		setEvidenceToDelete( ...evidences.filter(evidence => evidence.id === evidenceId) );
    setModalConfirmVisible(true);
	};

  const onOKDeleteEvidence = () => {
		!(evidences.length === 1) && deleteEvidence(evidenceToDelete?.id).then(() => {
      if (!loading.DELETE_EVIDENCE_LOADING) {
        notification['success']({
          description: 'Se ha eliminado la evidencia con éxito',
          placement: 'bottomRight'
        });
        removeEvidenceOfState(idStudent, evidenceToDelete?.id);
        setModalConfirmVisible(false);
      };
		});
	};

	useEffect(() => {
    getTypeEvidence();
    id_reason && updateForm('type_evidence')(id_reason);
  },[]);

  useEffect(() => {
    setLimitFile(5 - (evidences?.length || 0));
  }, [ evidences ]);

  return (
    <div className="edit-registration">

			<h1>{type_name === 'Alta' ? 'Editar alta' : 'Editar baja'}</h1>

			{type_name === 'Baja' &&
				<Col>
					<Row>
						<label>Motivo</label>
						<Row className="row-evidence">
							<Select 
								className="select-evidence" 
								placeholder='Seleccionar'
                defaultValue={id_reason} 
								loading={ loadingGroup.GET_EVIDENCE?.state }
								onChange={updateForm('type_evidence')}
							>
								{ (typeEvidence || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.description }</Option>) }
							</Select>
						</Row>
						
					</Row>
				</Col>
			}

      <Col>
        <Row>
          <label>Subir evidencia</label>
          <InputFile
            accept=".png,.jpg,.pdf"
            beforeUpload={validationFile}
            disabled={(listFile.length >= (5 - (evidences?.length || 0))) ? true : false}
            customRequest={(data) => handleInputFile(data)}
            showUploadList={false}
            handlePaste={handleInputFilePaste}
          />
        </Row>
      </Col>

      {currentFile && (
        <InputButton
          currentValue={currentFileName}
          setCurrentValue={setCurrentFileName}
          onClickButton={checkNameFile}
          title="Cambia el nombre de la imagen y valídala dando click al icono"
        />
      )}

      {listFile.map((data, index) => (
        <ItemFileList
          key={data.uid || index}
          base64={data.receipt}
          fileType={data.mime_type}
          uid={data.uid}
          nameFile={data.name}
          listFile={listFile}
          onRemove={setListFile}
        />
      ))}

      {(listFile.length && evidences?.length) ? <Divider style={{margin: '8px 0'}} /> : null}

			{evidences?.map((data, index) => (
        <ItemFileList
          key={data.id || index}
          url={data.url}
          fileType=""
          id={data.id}
          nameFile={data.name}
					actionDelete={deleteFile}
        />
      ))}

			<div className="container-action">
				<Button
					disabled={(listFile.length || isChangeForm) ? false : true} 
					loading={loading.PUT_EVIDENCE_LOADING && loading.PUT_EVIDENCE_LOADING.state ? true : false} 
					onClick={() => saveChanges()} 
					type={'primary'}>
						Guardar cambios
				</Button>
			</div>

      <Modal 
        visible={modalConfirmVisible}
        modalProps={{
          placement: "left",
          closable: false,
          onOk: onOKDeleteEvidence,
          onCancel: () => setModalConfirmVisible(false),
          cancelText: 'Cancelar',
          okText: 'Continuar',
          title: 'Eliminar imagen',
          confirmLoading: loading.DELETE_EVIDENCE_LOADING && loading.DELETE_EVIDENCE_LOADING.state ? true : false,
        }}
        content={
          <div style={{textAlign: 'center'}}>
            <ExclamationCircleOutlined style={{fontSize: '48px', marginBottom: '12px', color: '#ebaa4d'}} />
            <p>¿Estas seguro de que deseas eliminar la imagen?</p>
            <p>{ evidenceToDelete.name }</p>
          </div>
        }
      />

    </div>
  );
};

export default EditRegistration;
