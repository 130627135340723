export const LOADING_EDIT_SCHEDULE = 'SCHEDULE::LOADING_EDIT_SCHEDULE';
export const RECEIVE_EDIT_SCHEDULE = 'SCHEDULE::RECEIVE_EDIT_SCHEDULE';
export const ERROR_EDIT_SCHEDULE = 'SCHEDULE::ERROR_EDIT_SCHEDULE';

export const LOADING_GET_CATALOG_REASONS = 'SCHEDULE::LOADING_GET_CATALOG_REASONS';
export const RECEIVE_GET_CATALOG_REASONS = 'SCHEDULE::RECEIVE_GET_CATALOG_REASONS';
export const ERROR_GET_CATALOG_REASONS = 'SCHEDULE::ERROR_GET_CATALOG_REASONS';

export const RESET_STATE_SCHEDULE = 'SCHEDULE::RESET_STATE_SCHEDULE';

export const LOADING_SUSPENSION_CLASS = 'SCHEDULE::LOADING_SUSPENSION_CLASS';
export const RECEIVE_SUSPENSION_CLASS = 'SCHEDULE::RECEIVE_SUSPENSION_CLASS';
export const ERROR_SUSPENSION_CLASS = 'SCHEDULE::ERROR_SUSPENSION_CLASS';

export const LOADING_GET_CATALOG_SUSPENSION = 'SCHEDULE::LOADING_GET_CATALOG_SUSPENSION';
export const RECEIVE_GET_CATALOG_SUSPENSION = 'SCHEDULE::RECEIVE_GET_CATALOG_SUSPENSION';
export const ERROR_GET_CATALOG_SUSPENSION = 'SCHEDULE::ERROR_GET_CATALOG_SUSPENSION';