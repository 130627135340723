import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { Row, Col } from 'antd';

import TyphographyTitle from '../../components/simple/TypographyTitle'; 
import Card from '../../components/simple/Card';
import WeekTab from '../../components/custom/WeekTab/WeekTab';
import FilterClasses from '../../components/custom/FiltersClasses';
import CalendarTab from '../../components/custom/CalendarTab';
import CalendarClasses from '../../components/simple/CalendarClasses';
import SpinnerArea from '../../components/custom/SpinnerArea';
import { getHolidays } from '../../reducers/settings/actions';
import * as Actions from './actions';
import { addDays, getInitEndWeek } from '../../utils/dateFormats';

import './ClassesPage.scss';

function ClassesPage(props){

  const { filters, classes, currentDate, viewSelected, loading } = props.classReducer;

  const { holidays } = props.settingsReducer;

  const [ filteredClasses, setFilteredClasses ] = useState(classes);

  const [ payload, setPayload ] = useState({});

  useEffect(() => {
    if(Object.keys(payload).length) {
      props.getClasses(payload);
      props.getHolidays(payload.startdate, payload.enddate)
    }
    // eslint-disable-next-line
  },[
    payload
  ]);

  useEffect(()=>{
    // EFECTO QUE ALIMENTA EL RESPALDO PARA LOS FILTROS.
    setFilteredClasses(classes);
  },[ classes ])

  const onChangeWeek = (days) => () => {
    const newDate = addDays(currentDate, days);
    props.addData({ currentDate: newDate });
  }

  const onChangeView = (e) => {
    props.addData({ viewSelected: e.target.value });
  }

  const determineDataOrigin = () => {
    if(filters.isTrial || filters.isPresentation || filters.isObservation){
      return applyFilters(filteredClasses.result);
    }
    return Object.values(classes?.result || { });
  }

  const applyFilters = (data) => {
    const newFilter = {};
    return Object.values(newFilter);
  }

  const onSelectDate = (e) => {
    props.addData({ currentDate: moment(e.start).format('YYYY-MM-DD') });
  }

  return (
    <div className='ClassesPage'>
      <TyphographyTitle level={4} className='ClassesPage__title'>Calendario de clases</TyphographyTitle>
      <Row>
        <Col className='ClassesPage__title' span={24}>
          <WeekTab
            viewSelected={viewSelected}
            currentDate={currentDate}
            onNextWeek={onChangeWeek}
            onBeforeWeek={onChangeWeek}
          />
        </Col>
        <Col className='ClassesPage__inside' xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
          <Card>
            <div className='ClassesPage__inside__filter'>
              <div className='ClassesPage__inside__filter__filters'>
                <FilterClasses
                  { ...props }
                  onFiltersChange={ setPayload }
                  removeButton={ false }
                />
              </div>
            </div>
            <div className='ClassesPage__inside__calendar'>
              {
                loading.GET_CLASSES
                ? <SpinnerArea size='large'/>
                : <CalendarClasses
                    currentDate={currentDate}
                    mode={viewSelected}
                    data={determineDataOrigin()}
                    holidays={ holidays }
                  />
              }
            </div>    
          </Card>
        </Col>
        <Col xs={0} sm={0} md={0} lg={6} xl={5} xxl={4}>
          <Card>
            <CalendarTab
              currentDate={currentDate}
              range={getInitEndWeek(currentDate)}
              views={[
                { value: 'week', label: 'Semana'},
                { value: 'day', label: 'Dia' }
              ]}
              valueView={viewSelected}
              onChangeView={onChangeView}
              onSelectDate={onSelectDate}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

ClassesPage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...Actions,
  getHolidays
}, dispatch);
const mapStateToProps = state => ({
  classReducer: state.get('classReducer'),
  settingsReducer: state.get('settingsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage);
