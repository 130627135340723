import client from '../axios';
import storage from '../storage';

class UserService {
    constructor() {
        this.session = {};
        this.storage = storage;
        this.client = client;
    }
    save(data) {
        this.session = data;
        this.storage.set('session', data);
        client.defaults.headers.common['Auth'] = 'Bearer ' + data.data.result.token;
    }
    localSession() {
        return this.storage.get('session').then(data => {
            if(data) { this.save(data); }
            return data;
        }, err => { });
    }

    login(data) {
        return new Promise((resolve, reject)=>{
            client.post('v1/login',{ username: data.user, password: data.password })
            .then((response)=>{
                this.save(response);
                resolve(response);
            }).catch(e => {
                reject(e);
            });
        });
    }

    logout() {
        this.session = {};
        this.storage.remove('session');
        client.defaults.headers.common['Authorization'] = null;
    }
}

export const userService = new UserService();
