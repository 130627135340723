import * as ACTION from './types';

import { ticketService } from '../../services/ticket.service';

export function setRange(name, value) {
  return {
    type: ACTION.SET_RANGE,
    name,
    value
  }
}

export function tableProps(name, page, sort) {
  return {
    type: ACTION.TABLE_PROPS,
    name,
    page,
    sort
  }
}

export function setActiveKey(key) {
  return {
    type: ACTION.ACTIVE_KEY,
    key
  }
}

export function setSort(name, value) {
  return {
    type: ACTION.SET_SORT,
    name,
    value
  }
}

export function getTickets(range, filters, page, orderBy) {
  return {
    types: [
      ACTION.LOADING_TICKETS,
      ACTION.RECEIVE_TICKETS,
      ACTION.ERROR_TICKETS,
    ],
    promise: ticketService.getMyTickets(range, filters, page, orderBy)
  }
}

export function getAllTickets(range, filters, page, orderBy) {
  return {
    types: [
      ACTION.LOADING_ALL_TICKETS,
      ACTION.RECEIVE_ALL_TICKETS,
      ACTION.ERROR_ALL_TICKETS,
    ],
    promise: ticketService.getAllTickets(range, filters, page, orderBy)
  }
}

export function getCoordinatorsOptions() {
  return {
    types: [
      ACTION.LOADING_COORDINATORS_OPTIONS,
      ACTION.RECEIVE_COORDINATORS_OPTIONS,
      ACTION.ERROR_COORDINATORS_OPTIONS,
    ],
    promise: ticketService.getCoordinators()
  }
}

export function getStatusOptions() {
  return {
    types: [
      ACTION.LOADING_STATUS_OPTIONS,
      ACTION.RECEIVE_STATUS_OPTIONS,
      ACTION.ERROR_STATUS_OPTIONS,
    ],
    promise: ticketService.getStatus()
  }
}

export function getTotals() {
  return {
    types: [
      ACTION.LOADING_TOTALS,
      ACTION.RECEIVE_TOTALS,
      ACTION.ERROR_TOTALS,
    ],
    promise: ticketService.getTotals()
  }
}

export function updateTicket(id, responsable, status) {
  return {
    types: [
      ACTION.LOADING_UPDATE_TICKET,
      ACTION.RECEIVE_UPDATE_TICKET,
      ACTION.ERROR_UPDATE_TICKET,
    ],
    promise: ticketService.update(id, responsable, status)
  }
}