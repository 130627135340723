import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';

import './FormWrapper.scss';

const FormWrapper = props => {
  
  const { 
    children, 
    loading, 
    name, 
    enabledButton, 
    hideCancel, 
    saveButtonText,
    cancelButtonText, 
    saveButtonProps, 
    listNames = [], 
    isCustomValidation,
    onRedirectCancelButton 
  } = props;

  const [ invalidForm, setInvalidForm ] = useState(false);

  const validateIfFormHasErrors = () => {
    if (!isCustomValidation) {
      return setTimeout(() => {
        const idQuerySelector = name ? `#${ name }` : '';

        const formRequiredItems = document.querySelectorAll(`${ idQuerySelector } .ant-form-item-required`).length;

        const formItemsWithSuccess = document.querySelectorAll(`${ idQuerySelector } .ant-form-item-has-success`).length;
        
        const formItemsWithErrors = document.querySelectorAll(`${ idQuerySelector } .ant-form-item-has-error`).length;
        
        setInvalidForm(formRequiredItems > formItemsWithSuccess || !!formItemsWithErrors);
      }, 500);
    };
  };

  const validateIfFormHasErrorsCustom = () => {
    let listErrors =  props.form.getFieldsError(listNames);
    let listValidatedErrors = listErrors.filter(item => item.errors.length);
    setInvalidForm(listValidatedErrors.length ? true : false);
  }

  const onValidation = () => {
    validateIfFormHasErrors();
    validateIfFormHasErrorsCustom();
  }

  const onValuesChange = formValue => {
    props.onValuesChange(formValue);

    isCustomValidation 
      ? validateIfFormHasErrorsCustom()
      : validateIfFormHasErrors();
  }

  useEffect(() => {
    isCustomValidation 
      ? validateIfFormHasErrorsCustom()
      : validateIfFormHasErrors();

    return () => {
      clearTimeout(validateIfFormHasErrors);
    }
  }, [ name, loading, validateIfFormHasErrors, validateIfFormHasErrorsCustom ]);
  
  return (
    <Form
      className='FormWrapper'
      layout={'vertical'}
      { ...props }
      onBlur={ onValidation }
      onValuesChange={ onValuesChange }
      onClick={onValidation}
    >
      { children }
      <Form.Item>
        { !hideCancel && <Button shape='round' onClick={ onRedirectCancelButton ? onRedirectCancelButton : props.history.goBack }>{ cancelButtonText || 'Cancelar' }</Button> }
        <Button
          type="primary"
          shape='round'
          htmlType="submit"
          loading={ loading }
          disabled={ !enabledButton && invalidForm }
          { ...saveButtonProps }
        >{ saveButtonText || 'Guardar' }</Button>
      </Form.Item>
    </Form>
  )
}

FormWrapper.propTypes = { };

const mapDispatchToProps = dispatch => bindActionCreators({ }, dispatch);

const mapStateToProps = _ => ({ });

export default connect(mapStateToProps, mapDispatchToProps)(FormWrapper);
