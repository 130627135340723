import client from '../axios';

class TeacherService {
    getDelays(teacherId, range={}, filters, page, orderBy) {
        return client.post(`/v1/auth/teachers/${ teacherId }/delays`, {
            filters: {
                startdate: range.startdate,
                enddate: range.enddate,
                ...filters
            },
            pagination: {
                page,
                limit: 10,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        })
    }
    getCancellations(teacherId, range={}, filters, page, orderBy) {
        return client.post(`/v1/auth/teachers/${ teacherId }/cancellations`, {
            filters: {
                startdate: range.startdate,
                enddate: range.enddate,
                ...filters
            },
            pagination: {
                page,
                limit: 10,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        })
    }
    getScores(teacherId, range={}, filters, page, orderBy) {
        return client.post(`/v1/auth/teachers/${ teacherId }/scores`, {
            filters: {
                startdate: range.startdate,
                enddate: range.enddate,
                ...filters
            },
            pagination: {
                page,
                limit: 10,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        })
    }
    getIssues(teacherId, range={}, filters, page, orderBy) {
        return client.post(`/v1/auth/teachers/${ teacherId }/issues`, {
            filters: {
                startdate: range.startdate,
                enddate: range.enddate,
                ...filters
            },
            pagination: {
                page,
                limit: 10,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        })
    }
    createTeacher = data => client.put(`/v2/teacher`, data);
    editTeacher = (tid, data) => client.put(`/v2/teacher/id/${ tid }`, data);
    getByLanguage = lid => client.get(`/v2/teacher/language/${ lid }`);
    getTeacher = tid => client.get(`/v2/teacher/id/${ tid }`);
    getTariffs = _ => client.get(`/v2/tariff/teacher`);
    validateEmail = email => client.get(`/v2/teacher/validate`, {
        params: {
            email
        }
    })
}

export const teacherService = new TeacherService();
