import { tableSortProps } from "../helpers/table";
import * as ACTION from "./types";

const initialState = {
  loading: { },
  errors: { },
  sort: { },
  page: {
    activeMaterialsTable: 1,
    inactiveMaterialsTable: 1,
    materialsRecord: 1
  },
  orderBy: {
    activeMaterialsTable: {
      column: "created",
      order: "ASC"
    },
    inactiveMaterialsTable: {
      column: "created",
      order: "ASC"
    },
    materialsRecord: {
      column: "created",
      order: "ASC"
    }
  },
  range: {

  },
  filters: {

  },
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case ACTION.TABLE_PROPS:
      return { ...tableSortProps(state, action) };

    case ACTION.LOADING_CREATE_MATERIAL:
      return { ...state, loading: { ...state.loading, CREATE_MATERIAL: { state: true } }, materialSaved: false }
    case ACTION.RECEIVE_CREATE_MATERIAL:
      return { ...state, loading: { ...state.loading, CREATE_MATERIAL: undefined }, materialSaved: true }
    case ACTION.ERROR_CREATE_MATERIAL:
      return { ...state, loading: { ...state.loading, CREATE_MATERIAL: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_ACTIVE_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_ACTIVE_MATERIALS: { state: true } } }
    case ACTION.RECEIVE_GET_ACTIVE_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_ACTIVE_MATERIALS: undefined }, activeMaterials: action.result.data.result }
    case ACTION.ERROR_GET_ACTIVE_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_ACTIVE_MATERIALS: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_INACTIVE_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_INACTIVE_MATERIALS: { state: true } } }
    case ACTION.RECEIVE_GET_INACTIVE_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_INACTIVE_MATERIALS: undefined }, inactiveMaterials: action.result.data.result }
    case ACTION.ERROR_GET_INACTIVE_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_INACTIVE_MATERIALS: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_MATERIALS_BY_LEVEL:
      return { ...state, loading: { ...state.loading, GET_MATERIALS_BY_LEVEL: { state: true } } }
    case ACTION.RECEIVE_GET_MATERIALS_BY_LEVEL:
      return { ...state, loading: { ...state.loading, GET_MATERIALS_BY_LEVEL: undefined }, levelMaterials: action.result.data.result }
    case ACTION.ERROR_GET_MATERIALS_BY_LEVEL:
      return { ...state, loading: { ...state.loading, GET_MATERIALS_BY_LEVEL: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_GROUP_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_GROUP_MATERIALS: { state: true } } }
    case ACTION.RECEIVE_GET_GROUP_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_GROUP_MATERIALS: undefined }, groupMaterials: action.result.data.result }
    case ACTION.ERROR_GET_GROUP_MATERIALS:
      return { ...state, loading: { ...state.loading, GET_GROUP_MATERIALS: { state: false, detail: action.error } } }
  
    case ACTION.LOADING_GET_MATERIALS_RECORD:
        return { ...state, loading: { ...state.loading, MATERIALS_RECORD: { state: true } } }
    case ACTION.RECEIVE_GET_MATERIALS_RECORD:
        return {...state, loading: { ...state.loading, MATERIALS_RECORD: undefined }, materialsRecord: action.result.data.result } ;
    case ACTION.ERROR_GET_MATERIALS_RECORD:
        return { ...state, loading: { ...state.loading, MATERIALS_RECORD: { state: false, detail: action.error } } }
        
    case ACTION.LOADING_INACTIVATE_MATERIAL:
      return { ...state, loading: { ...state.loading, INACTIVATE_MATERIAL: { state: true } }, inactiveSucceed: false }
    case ACTION.RECEIVE_INACTIVATE_MATERIAL:
      return {...state, loading: { ...state.loading, INACTIVATE_MATERIAL: undefined }, materialConflicts: action.result?.data?.result, inactiveSucceed: !action.result?.data?.result?.groups?.length };
    case ACTION.ERROR_INACTIVATE_MATERIAL:
        return { ...state, loading: { ...state.loading, INACTIVATE_MATERIAL: { state: false, detail: action.error } } }
        
    case ACTION.LOADING_ACTIVATE_MATERIALS:
      return { ...state, loading: { ...state.loading, ACTIVATE_MATERIALS: { state: true } }, activeSucceed: false };
    case ACTION.RECEIVE_ACTIVATE_MATERIALS:
        return {...state, loading: { ...state.loading, ACTIVATE_MATERIALS: undefined }, activeSucceed: true };
    case ACTION.ERROR_ACTIVATE_MATERIALS:
        return { ...state, loading: { ...state.loading, ACTIVATE_MATERIALS: { state: false, detail: action.error } } }

    case ACTION.IGNORE_MATERIAL_CONFLICTS:
      return { ...state, materialConflicts: null }

    default:
      return state;
  }
}

export default reducer;
