export const SET_RANGE = 'DASHBOARD::SET_RANGE'
export const SET_SORT = 'DASHBOARD::SET_SORT'

export const TABLE_PROPS = 'DASHBOARD::TABLE_PROPS'

export const LOADING_ABSENCES = 'DASHBOARD::LOADING_ABSENCES'
export const RECEIVE_ABSENCES = 'DASHBOARD::RECEIVE_ABSENCES'
export const ERROR_ABSENCES = 'DASHBOARD::ERROR_ABSENCES'

export const LOADING_DELAYS = 'DASHBOARD::LOADING_DELAYS'
export const RECEIVE_DELAYS = 'DASHBOARD::RECEIVE_DELAYS'
export const ERROR_DELAYS = 'DASHBOARD::ERROR_DELAYS'

export const LOADING_CANCELLATIONS = 'DASHBOARD::LOADING_CANCELLATIONS'
export const RECEIVE_CANCELLATIONS = 'DASHBOARD::RECEIVE_CANCELLATIONS'
export const ERROR_CANCELLATIONS = 'DASHBOARD::ERROR_CANCELLATIONS'