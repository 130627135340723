import React from 'react';
import { Link } from 'react-router-dom';

import Propover from '../Popover';
import ClassDetail from './ClassDetail';
import Flags from '../Flags';
import { MinusCircleFilled } from '@ant-design/icons';

import './CalendarClass.scss';

const types = {
  0: 'isTrial',
  1: 'isPresentation',
  2: 'isObservation', 
}

function MoodWeek(props) {
  const { clientName, languageID, level, id, type_color, groupId, suspended } = props;
  const title = `${level} ${clientName}`;
  return(
    <Propover placement="right" content={<ClassDetail {...props} />}>
      <Link to={`/group/${ groupId }/class/${ id }`}>
        <div className='CalendarClass'>
          <div className={`CalendarClass__i ${types[type_color]}`}/>
          <label>{ title }</label>
          <Flags value={languageID} style={{ marginRight: suspended ? '25px' : '5px' }}/>
          {suspended && <MinusCircleFilled className='Suspension' />}
        </div>
      </Link>
    </Propover>
  );
}

function MoodDay(props) {
  const { id, level, clientName, languageID, scheduleTime, teacher, groupId } = props;
  return(
    <div className='CalendarClassDay'>
      <Propover placement="right" content={<ClassDetail {...props} />}>
        <Link to={`/group/${ groupId }/class/${ id }`}>
          <div className='CalendarClassDay__i'/>
          <label>{level} {clientName}</label>
          <label>{scheduleTime}</label>
          <label>{teacher}</label>
          <Flags value={languageID}/>
        </Link>
      </Propover>
    </div>
  );
}




function CalendarClass(props) {
  const { mode } = props;
  switch(mode){
    case 'week':
      return <MoodWeek {...props} />
    case 'day':
      return <MoodDay {...props} />
    default:
      return <MoodWeek {...props} />
  }
}

export default CalendarClass;
