import React from 'react';
import { Layout } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

import './NavBarDisplay.scss';

function NavBarDisplay(props){
  const { collapsed, onToggle } = props;
  const iconProps = {
    className: "trigger NavBarDisplay__icon",
    onClick: onToggle
  }
  return (
    <Layout.Header className='NavBarDisplay'>
      { collapsed ? <MenuOutlined { ...iconProps }/> : <CloseOutlined { ...iconProps }/> }
    </Layout.Header>
  );
}

export default NavBarDisplay;
