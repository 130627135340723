import React from 'react';

import ResetDisplay from './ResetDisplay';

function ResetPage() {
  return (
    <ResetDisplay />
  );
}

export default ResetPage;
