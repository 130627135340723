import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import NoFound from './containers/NoFound/NoFoundPage';
import Global from './containers/Global';
import LoginPage from './containers/Login/LoginPage';
import ResetPage from './containers/Reset/ResetPage';
import Layout from './containers/Layout';
import ClientsPage from './containers/Clients/ClientsPage';
import ClientConfig from './containers/Clients/ClientConfig/ClientConfig';
import ClientDetail from './containers/ClientDetail/ClientDetail';
import GroupConfig from './containers/ClientDetail/GroupConfig';
import ClassesPage from './containers/Classes/ClassesPage';
import ClasseDetail from './containers/ClasseDetail/ClasseDetail';
import ExamDetail from './containers/ClasseDetail/TestDetail/ExamDetail';
import Teachers from './containers/Teachers/TeachersPage';
import NewTeacher from './containers/NewTeacher';
import TeacherDetail from './containers/TeacherDetail/TeacherDetail';
import Dashboard from './containers/Dashboard/DashboardPage';
import Tickets from './containers/Tickets/Tickets';
import Incidents from './containers/Incidents/Incidents';
import Settings from './containers/Settings';
import AddressConfig from './containers/Clients/AddressConfig';

function Routes(props){
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/login' component={LoginPage}/>
        <Route exact path='/reset' component={ResetPage}/>
        <Route exact path='/404' component={NoFound}/>
        <Global>
          <Layout>
            <Switch>
              <Route exact path='/clients' component={ ClientsPage } />
              <Route exact path='/clients/new' component={ ClientConfig } />
              <Route exact path='/clients/detail/:clientId' component={ ClientDetail } />
              <Route exact path='/clients/edit/:clientId' component={ ClientConfig } />
              <Route exact path='/clients/:clientId/group/new' component={ GroupConfig } />
              <Route exact path='/clients/:clientId/group/:groupId' component={ GroupConfig } />
              <Route exact path='/clients/:clientId/address/new' component={ AddressConfig } />
              <Route exact path='/classes' component={ClassesPage} />
              <Route exact path='/group/:id/class/:classId' component={ClasseDetail} />
              <Route exact path='/groups/:groupId/class/:classId/exams/:examId' component={ExamDetail} />
              <Route exact path='/teachers' component={Teachers} />
              <Route exact path='/teachers/new' component={ NewTeacher } />
              <Route exact path='/teachers/edit/:teacherId' component={ NewTeacher } />
              <Route exact path='/teachers/detail/:teacherId' component={TeacherDetail} />
              <Route exact path='/agenda' component={TeacherDetail} />
              <Route exact path='/dashboard' component={Dashboard} />
              <Route exact path='/tickets' component={Tickets} />
              <Route exact path='/incidents' component={Incidents}/>
              <Route exact path='/settings' component={ Settings }/>
              <Route exact path='/' render={() => <Redirect to='/login' />}/>          
              <Route path="*" render={() => <Redirect to='/404' />}/>
            </Switch>
          </Layout>
        </Global>
      </Switch>
    </BrowserRouter>
  );
}


export default Routes;
