import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getStudyPlans, getLevels } from '../../../reducers/study-plans/actions';
import { getGroup, getGroupCompleted, createGroup, editGroupCompleted } from '../../../reducers/groups/actions';
import { getClient } from '../../../reducers/clients/actions';
import { getReplacementPolicies } from '../../Classes/actions';
import StudentsModal from '../StudentsModal';
import {
  Form,
  Input,
  Select,
  Radio,
  notification,
} from 'antd';
import FormWrapper from '../../../components/custom/FormWrapper';
import { useHistory } from "react-router-dom";
import ConfirmEdit from '../ConfirmEdit';

import './FormGroupInfo.scss';

const { Option } = Select;

const FormGroupInfo = props => {

  const { studyPlanReducer, groupsReducer, clientsReducer, match, classReducer, location: { state } } = props;
  
  const { clientId, groupId } = match.params;
  
  const { catalog : studyPlansCatalog, levels : studyPlanLevels, loading } = studyPlanReducer;

  const { group, savedGroup, loading: loadingGroup } = groupsReducer;

  const { client, loading: loadingClient } = clientsReducer;

  const { replacementPolicies, loading: loadingClass } = classReducer;

  const [ form ] = Form.useForm();

  const [ values, setValues ] = useState({ });

  const [ studentsModalVisible, setStudentsModalVisible ] = useState(false);

  const [ confirmEditGroupVisible, setConfirmEditGroupVisible ] = useState(false);

  const history = useHistory();
  
  const onChange = formValue => {
    const spid = formValue?.study_plan_id;
    
    if (spid) {
      props.getLevels(spid);
    }

    if (formValue?.plan_id) {
      const [ plan_tariff ] = (client?.plans_tariff || []).filter(plan => plan.plan_id === formValue.plan_id);
      formValue['tariff_client'] = plan_tariff?.tariff;
    }

    setValues({ 
      client_id: Number(clientId),
      ...values,
      ...formValue
    });
  }

  const getGroupById = () => {
    if (groupId) {
      if (state?.statusGroup === 'completed') {
        props.getGroupCompleted(groupId);
      } else {
        props.getGroup(groupId);
      }
    };
  };
  
  const onFinish = () => state?.statusGroup === 'completed' ? setConfirmEditGroupVisible(true) : props.createGroup([ values ]).then(getGroupById);

  const onEditGroupCompleted = () => props.editGroupCompleted(groupId, values).then(() => {
    setConfirmEditGroupVisible(false);
    
    notification['success']({
      description: 'Se han guardado los cambios con éxito',
      placement: 'bottomRight'
    });

    getGroupById();
  });

  const onRedirectCancelButton = () => {
    if (state?.statusGroup === 'completed') {
      history.goBack();
    } else {
      history.push({
        pathname: `/clients/detail/${clientId}`,
        state: {
          activeKey: 1, 
        },
      });
    }
    
  }
  
  useEffect(_ => {
    const initialValues = groupId ? {
      ...group,
      study_plan_id: group?.study_plan_id || null,
      level_id: group?.level_id || null,
      plan_id: group?.plan_id || null,
      students: (group?.students || [ ]).map(student => student.name),
      policy_id: group?.policy_id || null,
    } : { 
      policy_id: client?.policy_id === 0 ? null : client?.policy_id,
      plan_id: client?.plans_tariff?.length === 1 ? (client?.plans_tariff || [])[0]?.plan_id : null,
      tariff_client: client?.plans_tariff?.length === 1 ? (client?.plans_tariff || [])[0]?.tariff : null,
    };

    form.setFieldsValue(initialValues);
    onChange(initialValues);
  }, [ form, group, groupId ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    props.getStudyPlans();
    props.getClient(clientId);
    props.getReplacementPolicies();
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(getGroupById, [ groupId, studentsModalVisible ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    if (savedGroup && !groupId) {
      props.history.push(`/clients/${ clientId }/group/${ savedGroup[0].id }`)
    }
  }, [ savedGroup, groupId ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='FormGroupInfo'>
      <FormWrapper
        { ...props }
        name='group-info'
        form={form}
        initialValues={ values }
        onValuesChange={ onChange }
        onFinish={ onFinish }
        loading={ groupsReducer.loading.SAVE_GROUP?.state || groupsReducer.loading.GET_GROUP?.state }
        cancelButtonText='Regresar'
        onRedirectCancelButton={ onRedirectCancelButton }
      >
        <Form.Item
          label='Nombre del grupo'
          name='name'
          rules={[
            { required: true },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Curso'
          name='study_plan_id'
        >
          <Select disabled={state?.statusGroup === 'completed'} placeholder='Seleccionar' loading={ loading.GET_CATALOG?.state }>
            { (studyPlansCatalog || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.name }</Option>) }
          </Select>
        </Form.Item>
        <Form.Item
          label='Nivel'
          name='level_id'
        >
          <Select disabled={state?.statusGroup === 'completed'} placeholder='Seleccionar' loading={ loading.GET_STUDY_PLAN_LEVELS?.state }>
            { (studyPlanLevels || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.name }</Option>) }
          </Select>
        </Form.Item>
        <Form.Item
          label="Modalidad"
          name="mode"
        >
          <Radio.Group>
            <Radio value={ 1 }>Virtual</Radio>
            <Radio value={ 2 }>Presencial</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label='Tarifa cliente'
          name='plan_id'
        >
          {
            (client?.plans_tariff?.length > 1)
            ? 
              <Select disabled={state?.statusGroup === 'completed'} placeholder='Seleccionar' loading={ loadingClient?.GET_CLIENT?.state }>
                { (client?.plans_tariff || [ ]).map((item, index) => (
                  <Option key={ index } value={ item.plan_id }>{ `${item.description} - ${item.tariff}` }</Option>
                ))}
              </Select>
            : 
              <Select 
                loading={ groupsReducer.loading?.GET_GROUP?.state }
                defaultValue={ (client?.plans_tariff || [])[0]?.plan_id }
                disabled={true}>
                  { (client?.plans_tariff || [ ]).map((item, index) => (
                    <Option key={ index } value={ item.plan_id }>{ `${item.description} - ${item.tariff}` }</Option>
                  ))}
              </Select>
          }
        </Form.Item>
        <Form.Item
          label='Política de cancelación'
          name='policy_id'
        >
          <Select
            placeholder='Seleccionar'
            loading={ loadingClass?.REPLACEMENT_POLICIES?.state }
            disabled={ state?.statusGroup === 'completed' ? true : client?.policy_id ? true : false  }>
              { (replacementPolicies || [ ]).map((item, index) => (
                <Option key={ index } value={ item.id }>{ `${item.name}` }</Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Alumnos'
          name='students'
        >
          <Select loading={ groupsReducer.loading.GET_GROUP?.state } 
            disabled={ state?.statusGroup === 'completed' ? true : !groupId ? true : false } 
            onClick={ _ => !(state?.statusGroup === 'completed') && setStudentsModalVisible(!!groupId) } 
            mode="multiple" 
            removeIcon={ null } 
            placeholder={ !groupId ? "Guarda la información para activar el campo que te permitirá agregar alumnos." : "Seleccionar"} />
        </Form.Item>
      </FormWrapper>
      <StudentsModal
        { ...props }
        selection={ group?.students }
        modalProps={{
          onCancel: _ => setStudentsModalVisible(false)
        }}
        visible={ studentsModalVisible }
      />
      <ConfirmEdit 
        visible={ confirmEditGroupVisible }
        loading={ loadingGroup?.EDIT_GROUP_COMPLETED?.state }
        onCancel={ setConfirmEditGroupVisible }
        onOk={ onEditGroupCompleted }
        initialData={ group } 
        endData={ values } 
        mapKeyReview={{ name: 'Nombre del grupo', mode: 'Modalidad' }}
        mapValueReview={{ mode: { 1: 'Virtual', 2: 'Presencial' }}}
      /> 
    </div>
  )
}

FormGroupInfo.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ 
  getGroup, 
  getGroupCompleted, 
  getStudyPlans,
  getLevels, 
  createGroup, 
  getClient, 
  getReplacementPolicies,
  editGroupCompleted }, dispatch);

const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer'),
  studyPlanReducer: state.get('studyPlanReducer'),
  groupsReducer: state.get('groupsReducer'),
  classReducer: state.get('classReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormGroupInfo);
