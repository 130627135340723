import React, { useState } from 'react';
import ModalClosable from '../../components/custom/ModalClosable';
import SpinnerArea from '../../components/custom/SpinnerArea';
import Button from '../../components/simple/Button';
import { Checkbox, Popover } from 'antd';
import { CalendarOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import AddForm from './AddForm';
import DeleteForm from './DeleteForm';
import './ClasseStudents.scss';

function ClasseStudents(props) {
  const { students, update, loading } = props;
  const [ studentsAddModal, setStudentsAddModal ] = useState(false);
  const [ studentsDelModal, setStudentsDelModal ] = useState(false);
  const [ studentsToDelete, setStudentsToDelete ] = useState([]);
  const toggleStudentsAddModal = () => {
    update();
    setStudentsAddModal(!studentsAddModal)
  };
  const toggleStudentsDelModal = () => {
    update();
    setStudentsDelModal(!studentsDelModal)
  };
  return (
    <div className='ClasseStudents'>
      <div className='ClasseStudents__title'>
        <div>{ loading.GET_CLASS ? <SpinnerArea size='small'/> : students?.length || 0 }</div>
        Alumnos registrados
      </div>
      { !loading.GET_CLASS && (
        <div>
          <div className='ClasseStudents__button'>
            <Button onClick={ toggleStudentsAddModal } icon={ <PlusOutlined/> }>Alta de alumno</Button>
            <ModalClosable onClose={ toggleStudentsAddModal } visible={ studentsAddModal } placement="left" children={
              <AddForm { ...props } close={ toggleStudentsAddModal }/>
            }/>
          </div>
          { studentsToDelete.length > 0 && (
            <div className='ClasseStudents__button'>
              <Button type={'primary'} onClick={ toggleStudentsDelModal } icon={ <DeleteOutlined/> }>Baja de alumno</Button>
              <ModalClosable onClose={ toggleStudentsDelModal } visible={ studentsDelModal } placement="left" children={
                <DeleteForm { ...props } data={studentsToDelete} close={() => { toggleStudentsDelModal(); setStudentsToDelete([]) }}/>
              }/>
            </div>
          )}
          <div className='ClasseStudents__list'>
            {
              !loading.GET_CLASS && (
                <Checkbox.Group value={studentsToDelete} options={
                  (students || [ ]).map((item) => ({
                    label: (
                      <span className={ `checkbox-name ${ item.isRegisteredAtClass && 'isRegisteredAtClass' }` }>
                        { item.name }
                        { item.groupChanges && (
                            <Popover
                              placement='left'
                              content={
                                  <ul className='custom-popover'>
                                    {
                                      item.groupChanges.map((groupChange, index) => (
                                        <li key={index}>
                                          <label>{ groupChange.startDate } - { groupChange.endDate }</label>
                                          <p>{ groupChange.type }</p>
                                        </li>
                                      ))
                                    }
                                  </ul>
                              }
                          >
                            <CalendarOutlined />
                          </Popover>
                        )}
                      </span>
                    ),
                    value: item.nid
                  }))
                } onChange={ setStudentsToDelete }/>
              )
            }
          </div>
        </div>
      )}
    </div>
  );
}


export default ClasseStudents;
