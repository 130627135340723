import { useEffect, useState } from "react";
import { Divider } from "antd";
import Modal from "../../../components/simple/Modal";

import "./ConfirmEdit.scss";

const ConfirmEdit = (props) => {

  const initialState = { listInitialData: [], listEndData: [] };

  const {
    initialData = {},
    endData = {},
    mapKeyReview = {},
    mapValueReview = {},
    visible,
    loading,
    onCancel,
    onOk,
		titleText,
		okText,
		cancelText,
  } = props;

  const [listValidate, setListValidate] = useState(initialState);

  const compareObject = (obj1, obj2) => {
    const listInitialData = [];
    const listEndData = [];

    for (let key in mapKeyReview) {
      if (obj1[key] !== obj2[key]) {
        if (mapValueReview[key]) {
          listInitialData.push({ key, value: mapValueReview[key][obj1[key]] });
          listEndData.push({ key, value: mapValueReview[key][obj2[key]] });
        } else {
          listInitialData.push({ key, value: obj1[key] });
          listEndData.push({ key, value: obj2[key] });
        }
      }
    }

    setListValidate({ listInitialData, listEndData });
  };

  useEffect(() => {
    compareObject(initialData, endData);
  }, [ visible ]);

  return (
    <Modal
      visible={visible}
      modalProps={{
        onCancel: () => onCancel(false),
        title: titleText || "Confirmar edición",
        okText: okText || "Continuar",
        cancelText: cancelText || "Regresar",
        okButtonProps: {
          style: { display: !(listValidate.listInitialData.length && listValidate.listEndData.length) && "none" },
          loading: loading,
          onClick: () => onOk(),
          disabled:
            !listValidate.listInitialData.length &&
            !listValidate.listEndData.length,
        },
        cancelButtonProps: {
          onClick: () => onCancel(false),
        },
      }}
      content={
        <>
          {listValidate.listInitialData.length &&
          listValidate.listEndData.length ? (
            <div className="confirm-edit-content-modal">
              <div>
                <h1 className="subtitle-modal">Verifica los ajustes para continuar</h1>
              </div>
              <div className="container-content">
                <div className="section-modal">
                  <div>
                    <h1>
                      Información anterior
                    </h1>
                    {listValidate.listInitialData.map((item, index) => (
                      <p key={index}>{`${
                        mapKeyReview[item.key]
                      }: ${item.value}`}</p>
                    ))}
                  </div>
                </div>
                <Divider className="divider" type="vertical" />
                <div className="section-modal">
                  <div>
                    <h1>
                      Información nueva
                    </h1>
                    {listValidate.listEndData.map((item, index) => (
                      <p key={index}>{`${
                        mapKeyReview[item.key]
                      }: ${item.value}`}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="confirm-edit-content-modal">
              <div>
                <h1 className="subtitle-modal">¡No has editado ningún dato!</h1>
              </div>
            </div>
          )}
        </>
      }
    />
  );
};

export default ConfirmEdit;
