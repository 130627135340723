import * as ACTION from './types';
import { classesService } from '../../services/classes.service'

export function getLanguages() {
  return {
		types: [
			ACTION.LANGUAGES_LOADING,
			ACTION.LANGUAGES_RECEIVE,
			ACTION.LANGUAGES_ERROR,
		],
		promise: classesService.getLanguages(),    
  }
}

export function getAllTeachers() {
  return {
		types: [
			ACTION.ALL_TEACHERS_LOADING,
			ACTION.ALL_TEACHERS_RECEIVE,
			ACTION.ALL_TEACHERS_ERROR,
		],
		promise: classesService.getAllTeachers(),    
  }
}

export function getTeachers(data, page, order) {
  return {
		types: [
			ACTION.USERS_LOADING,
			ACTION.USERS_RECEIVE,
			ACTION.USERS_ERROR,
		],
		promise: classesService.getTeachers(data, page, order),    
  }
}

export function handleVisible(state) {
  return {
    type: ACTION.TOGGLE_FILTERS,
    state
  }
}

export function update(name, value, single) {
  if (single !== true)
    single = false;
  return {
    type: ACTION.UPDATE,
    name,
    value,
    single
  }
}

export function applyFilter() {
  return dispatch => {
    return dispatch({
      type: ACTION.APPLY_FILTER,
    })
  }
}

export function removeFilter(item, index) {
  return {
    type: ACTION.REMOVE_FILTER,
    item,
    index
  }
}

export function clearFilters() {
  return {
    type: ACTION.CLEAR_FILTERS
  }
}

export function setSelection(keys, items) {
  return {
    type: ACTION.ROW_SELECTION,
    items,
    keys
  } 
}

export function tableProps(page, sort) {
  return {
    type: ACTION.TABLE_PROPS,
    page,
    sort
  }
}