import React, { useState } from 'react';
import {EditFilled } from '@ant-design/icons';
import EditRegistration from '../../../components/custom/EditRegistration/EditRegistration';
import ModalClosable from '../../../components/custom/ModalClosable';

function EditModal(props){
    const { data } = props;
    let [ visible, setVisible ] = useState(false)

    const toggleVisible = () => {
      setVisible(!visible);
    }

    const onCancel = () => {
      toggleVisible();
    };

    return(
        <>
          <EditFilled style={{ color: '#4D9CF4' }} onClick={() => toggleVisible()} />
          <ModalClosable 
            onClose={ onCancel } 
            visible={ visible } 
            placement="left" 
            children={<EditRegistration { ...props } data={data} onClose={ onCancel } />}
          />
        </>
    )

}
export default EditModal;