import React from 'react';
import { Input } from 'antd';

import './SearchInput.scss';

function SearchInput(props) {
  
  let searchTimeout;

  const onSearchChange = e => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(_ => props.onChange(e.target.value), 1500);
  }
  
  return (
    <Input className='SearchInput' { ...props } onChange={ onSearchChange } />
  );
}

SearchInput.propTypes = { }

export default SearchInput;