import * as ACTION from './types';

import { dashboardService } from '../../services/dashboard.service';

export function setRange(name, value) {
  return {
    type: ACTION.SET_RANGE,
    name,
    value
  }
}

export function tableProps(page, sort) {
  return {
    type: ACTION.TABLE_PROPS,
    page,
    sort
  }
}

export function setSort(name, value) {
  return {
    type: ACTION.SET_SORT,
    name,
    value
  }
}

export function getAbsences(range) {
    return {
      types: [
        ACTION.LOADING_ABSENCES,
        ACTION.RECEIVE_ABSENCES,
        ACTION.ERROR_ABSENCES,
      ],
      promise: dashboardService.getAbsences(range)
    }
}

export function getDelays(range) {
  return {
    types: [
      ACTION.LOADING_DELAYS,
      ACTION.RECEIVE_DELAYS,
      ACTION.ERROR_DELAYS,
    ],
    promise: dashboardService.getDelays(range)
  }
}

export function getCancellations(range, filters, page, orderBy) {
  return {
    types: [
      ACTION.LOADING_CANCELLATIONS,
      ACTION.RECEIVE_CANCELLATIONS,
      ACTION.ERROR_CANCELLATIONS,
    ],
    promise: dashboardService.getCancellations(range, filters, page, orderBy)
  }
}
