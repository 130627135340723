import React, { useState, useEffect } from 'react';
import CircleGraph from '../../simple/CircleGraph';
import SpinnerArea from '../../custom/SpinnerArea';
import Modal from '../../simple/Modal';
import Flags from '../../simple/Flags';
import ReportModal from '../ReportModal';
import { ArrowDownOutlined, CalendarOutlined } from '@ant-design/icons';
import './ClassDetailCard.scss';
import Gmaps from '../Gmaps';
import { Radio, Button, Dropdown, Divider } from 'antd';
import EditScheduleModal from '../EditScheduleModal';
import ModalClosable from '../ModalClosable';
import ClassSuspensionModal from '../ClassSuspensionModal';
import moment from 'moment';

function ClassDetailCard(props) {
  const {
    getStudyPlan,
    gettingClass,
    scheduleTime,
    teacher,
    client,
    group,
    replacementPolicies,
    updateReplacementPolicy,
    refresh,
    isVirtualClass,
    classReducer
  } = props;

  const {
    groupStudyPlan
  } = classReducer;

  const [ modalPolicyVisible, setModalReplacementPolicy ] = useState(false);

  const [ modalReportVisible, toggleModalReport ] = useState(false);

  const [ modalScheduleVisible, setModalScheduleVisible ] = useState(false);

  const [ modalClassSuspension, setModalClassSuspension ] = useState(false);

  const [ replacementPolicy, setReplacementPolicy ] = useState(1);

  const toggleModalEdition = () => {
    setModalReplacementPolicy(!modalPolicyVisible);
  };

  const onOk = () => {
    updateReplacementPolicy(group.id, replacementPolicy).then(() => {
      toggleModalEdition();
      refresh();
    })
  };

  const onCancel = () => {
    toggleModalEdition();
  };

  useEffect(() => {
    setReplacementPolicy(group.replacementPolicy.id);
    if (group.id && client.id) {
      getStudyPlan(client.id, group.id);
    }
  }, [ group, client, getStudyPlan ]);

  const menu = (
    <div className="menu-schedule">
      <ul>
        <li onClick={() => setModalScheduleVisible(true)} >Editar agenda</li>
        <Divider className="spacing" />
        <li onClick={() => setModalClassSuspension(true)}>Suspensión de clases</li>
      </ul>
    </div>
  );

  if (gettingClass || !client.name) {
    return (
      <div className='ClassDetailCard'>
        <SpinnerArea size='large'/>
      </div>
    );
  }
  return(
    <div className='ClassDetailCard'>
      <div className='ClassDetailCard__data'>
        <div className='ClassDetailCard__data__level'>
          <CircleGraph level={group.level} porcentage={(group.currentClass * 100) / group.totalClassesOfLevel} />
        </div>
        <div className='ClassDetailCard__data__data'>
          <div className='ClassDetailCard__data__data__title'>
            <div className='ClassDetailCard__data__data__title__name'>
              <h1>{ client.name }</h1>
              <h2>{ group.name }</h2>
            </div>
            <Button onClick={() => toggleModalReport(true)} type="primary" shape="circle" icon={ <ArrowDownOutlined /> } />
            <ReportModal { ...props } visible={modalReportVisible} onClose={() => toggleModalReport(false)}/>
            <Button 
              onClick={() => props.history.push({pathname: `/clients/${ client?.id }/group/${ group?.id }`, state: { statusGroup: 'completed'}})} 
              type="primary" 
              shape="circle"
              style={{backgroundColor: '#ebaa4d'}} 
              icon={ <i className="material-icons button icon-button-custon">edit</i> } 
            />
            <Dropdown overlay={menu}>
              <Button
                type="primary" 
                shape="circle" 
                style={{backgroundColor: '#335267'}} 
                icon={ <CalendarOutlined /> } 
              />
            </Dropdown>
            
          </div>
          <div className='ClassDetailCard__data__data__more'>
            <div className='ClassDetailCard__data__data__more__section'>
              <div>
                <i className="material-icons">schedule</i>
                { scheduleTime || 'No especificado' }
              </div>
              <div>
                <i className="material-icons">card_travel</i>
                { group.scheduleDays }
              </div>
              <div>
                <a target="blank" href={`https://www.google.com/maps?q=${group.latitude},${group.longitude}`}>
                  <i className="material-icons">location_on</i>
                  { group.address || 'No especificado' }
                </a>
              </div>
              <div className="edit" onClick={toggleModalEdition}>
                <i className="material-icons">sms_failed</i>
                <span>Política de cancelación:<br/>{ group.replacementPolicy.name || 'No especificado' }</span>
                <i className="material-icons button">edit</i>
              </div>
            </div>
            <div className='ClassDetailCard__data__data__more__section'>
              <div>
                <i className="material-icons">local_atm</i>
                Plan { `${groupStudyPlan?.plan_description}` || 'no especificado' } {' '}
                - Tarifa $ { groupStudyPlan?.tariff_client || 'no especificada' }
              </div>
              {isVirtualClass && (
                <div>
                  <i className="material-icons">videocam</i>
                  Videoconferencia
                </div>
              )}
              <div>
                <i className="material-icons">person_outline</i>
                { teacher.name || 'No especificado' }
              </div>
              <div>
                <i className="material-icons">chat_bubble_outline</i>
                { groupStudyPlan?.description || 'No especificado'}
                <Flags value={ groupStudyPlan?.study_plan_id }/>
              </div>
              <div>
                <i className="material-icons">date_range</i>
                Fecha de inicio: { moment(groupStudyPlan?.initial_date).format('DD/MM/YYYY') || 'No especificado' }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='ClassDetailCard__map'>
        { group.latitude && group.longitude ? (
            <Gmaps
              markers={[
                { latitude: parseFloat(group.latitude), longitude: parseFloat(group.longitude) },
              ]}
            />
          ) : <img src={require(`../../../assets/img/sin-direccion.jpg`)} alt={`${ group.language }`} />
        }
      </div>
      <Modal modalProps={{
        placement: "left",
        closable: false,
        onOk,
        onCancel,
        cancelText: 'Regresar',
        okText: 'Guardar cambio',
        title: 'Editar política de cancelación',
      }} visible={ modalPolicyVisible } content={
        <Radio.Group className='replacementPolicy' style={{
          margin: 'auto',
          display: 'table'
        }} name='replacementPolicy' value={replacementPolicy} onChange={input => {
            setReplacementPolicy(input.target.value);
        }} options={replacementPolicies.map(item => ({
          value: item.id,
          label: item.name
        }))}/>
      }/>

      {modalScheduleVisible &&
        <ModalClosable
          visible={ modalScheduleVisible } 
          placement="left"
          children={ 
            <EditScheduleModal 
              { ...props } 
              onClose={ () => setModalScheduleVisible(false) } 
            /> 
          }
        />
      }

      {modalClassSuspension &&
        <ModalClosable
          visible={ modalClassSuspension } 
          placement="left"
          children={ 
            <ClassSuspensionModal 
              { ...props } 
              onClose={ () => setModalClassSuspension(false) } 
            /> 
          }
        />
      }

    </div>
  );
}


ClassDetailCard.propTypes = {}

export default ClassDetailCard;