import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import promiseMiddlware from './middlewares/promiseMiddlware';
import reducer from './reducers';

const env = process.env.NODE_ENV;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = (initialState = null) => {
  if (env === 'production') {
    if (initialState) {
      return createStore(reducer, initialState, applyMiddleware(thunk, promiseMiddlware));
    }
    return createStore(reducer, applyMiddleware(thunk, promiseMiddlware));
  }
  if (initialState) {
    return createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk, promiseMiddlware)));
  }
  return createStore(reducer, composeEnhancers(applyMiddleware(thunk, promiseMiddlware)));
};

export default store;
