import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { getAddresses, deleteAddress, tableProps, ignoreAddressConflicts, updateConflictAddress } from '../../../reducers/address/actions';
import IconButton from '../../../components/simple/IconButton/IconButton';
import AddressTable from '../../../components/custom/AddressTable';
import ConflictsModal from '../../../components/custom/ConflictsModal';

import './AddressesList.scss';

const AddressesList = props => {

  const [ addressToDelete, setAddressToDelete ] = useState();
  
  const { addressesReducer, match } = props;
  
  const { filter, page, orderBy, addresses, addressConflicts } = addressesReducer;
  
  const { clientId } = match.params;

  const update = p => props.getAddresses(clientId, filter, p || page.addressList, orderBy.addressList);

  const onDeleteConfirmed = _ => props.deleteAddress(addressToDelete?.id);

  const getGroupEditRoute = group => `/clients/${ group.client_id }/group/${ group.id }`;
  
  const changeConflictValue = (singleAddress)=>{
    props.updateConflictAddress(singleAddress.id, {conflict:!singleAddress.conflict})
  }

  useEffect(_ => update(), [ clientId, addressesReducer.loading.DELETE_ADDRESS?.state,addressesReducer.loading.CONFLICT_ADDRESS?.state]);
  
  return (
    <div className='AddressesList'>
      <IconButton
        label='Agregar'
        onClick={() => {
          props.history.push(`/clients/${ clientId }/address/new`);
        }}
        icon={ <PlusOutlined /> }
      />
      <AddressTable
        { ...props }
        changeConflictValue = {changeConflictValue}
        deleteAddress={ setAddressToDelete }
        name='addressList'
        update={ update }
        loading={ addressesReducer.loading.GET_ADDRESSES?.state || addressesReducer.loading.CONFLICT_ADDRESS?.state }
        data={ addresses }
      />
      <ConflictsModal
        modalConfirmationTitle={ `¿Estás seguro que deseas eliminar ${ addressToDelete?.name } de la lista?` }
        modalConflictsTitle={ `La dirección "${ addressToDelete?.name }" no se puede eliminar porque se encuentra asignada a los siguientes grupos` }
        notificationDescription={ `Se ha eliminado ${ addressToDelete?.name } con éxito` }
        itemToDeleteState={ setAddressToDelete }
        itemToDelete={ addressToDelete }
        conflicts={ addressConflicts?.groups }
        editRoute={ getGroupEditRoute }
        loading={ addressesReducer.loading.DELETE_ADDRESS?.state }
        ignoreConflicts={ props.ignoreAddressConflicts }
        onDeleteConfirmed={ onDeleteConfirmed }
      />
    </div>
  )
}

AddressesList.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getAddresses, tableProps, deleteAddress, ignoreAddressConflicts, updateConflictAddress }, dispatch);

const mapStateToProps = state => ({
  addressesReducer: state.get('addressesReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressesList);
