import React from 'react';
import { Link } from "react-router-dom";

import Card from '../../components/simple/Card';
import TypographyTitle from '../../components/simple/TypographyTitle';
import TypographyText from '../../components/simple/TypographyText';
import InputEmail from '../../components/simple/InputEmail';
import Button from '../../components/simple/Button';

import Logo from '../../assets/img/logo.svg';
import './ResetDisplay.scss';

const styles = {
  card: {
    width: '100%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.08)',
    borderRadius: '6px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  button: {
    width: '100%',
    marginTop: 70,
    marginBottom: 30,
  }
}

function ResetDisplay() {
  return (
    <div className='ResetDisplay'>
      <div className='ResetDisplay__container'>
        <Card style={styles.card}>
          <Link to='login'>Regresar</Link>
          <div className='ResetDisplay__form'>
            <img alt='logo' src={Logo} />
            <div className='ResetDisplay__form__row'>
              <TypographyTitle level={4}>Solicita una nueva contraseña</TypographyTitle>
              <TypographyText>Por favor ingresa tu e-mail para enviarte una nueva contraseña inmediatamente</TypographyText>
            </div>
            <InputEmail
              name='email'
              placeholder='correo@electronico.com'
            />
            <Button type='primary' shape='round' size='large' style={styles.button}>Solicitar</Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default ResetDisplay;
