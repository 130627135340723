import client from '../axios';

class StudentsService {
    getClientStudents = (cid, page, orderBy, searchQuery) => client.get(`v1/auth/clients/${ cid }/students`, {
        params: {
          filters: {
            name: searchQuery
          },
          pagination: {
              page,
              limit: 5,
              orderBy: orderBy.column,
              sort: orderBy.order
          }
        }
    });
    putStudents = (cid, gid, students) => client.put(`v1/auth/clients/${ cid }/groups/${ gid }/students`, students)
}

export const studentsService = new StudentsService();
