import * as ACTION from "./types";

const initialState = {
  loading: {
    EDIT_SCHEDULE: {
      state: false,
      detail: undefined,
    },
    GET_CATALOG_REASONS: {
      state: false,
      detail: undefined,
    },
    SUSPENSION_CLASS: {
      state: false,
      detail: undefined,
    }
  },
  errors: {},
	groupListWithConflict: [],
	isResponseConflict: false,
  catalogReasonEdit: [],
  catalogReasonSuspension: [],
  responseSuspensionClass: undefined,
  responseEditSchedule: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTION.LOADING_EDIT_SCHEDULE:
      return { ...state, loading: { ...state.loading, EDIT_SCHEDULE: { state: true } } }
    case ACTION.RECEIVE_EDIT_SCHEDULE:
      return { 
        ...state, 
        loading: { ...state.loading, EDIT_SCHEDULE: undefined }, 
        groupListWithConflict: action.result?.result === 'succes' ? [] : (action.result?.data?.result?.groups || []), 
        responseEditSchedule: action.result?.data || undefined,
        isResponseConflict: true, 
      }
    case ACTION.ERROR_EDIT_SCHEDULE:
      return { ...state, loading: { ...state.loading, EDIT_SCHEDULE: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_CATALOG_REASONS:
      return { ...state, loading: { ...state.loading, GET_CATALOG_REASONS: { state: true } } }
    case ACTION.RECEIVE_GET_CATALOG_REASONS:
      return { ...state, loading: { ...state.loading, GET_CATALOG_REASONS: undefined }, catalogReasonEdit:  action.result?.data}
    case ACTION.ERROR_GET_CATALOG_REASONS:
      return { ...state, loading: { ...state.loading, GET_CATALOG_REASONS: { state: false, detail: action.error } } }

    case ACTION.RESET_STATE_SCHEDULE:
      return { ...initialState }
    
    case ACTION.LOADING_SUSPENSION_CLASS:
      return { ...state, loading: { ...state.loading, SUSPENSION_CLASS: { state: true } } }
    case ACTION.RECEIVE_SUSPENSION_CLASS:
      return { ...state, loading: { ...state.loading, SUSPENSION_CLASS: undefined }, responseSuspensionClass:  action.result?.data }
    case ACTION.ERROR_SUSPENSION_CLASS:
      return { ...state, loading: { ...state.loading, SUSPENSION_CLASS: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_CATALOG_SUSPENSION:
      return { ...state, loading: { ...state.loading, GET_CATALOG_SUSPENSION: { state: true } } }
    case ACTION.RECEIVE_GET_CATALOG_SUSPENSION:
      return { ...state, loading: { ...state.loading, GET_CATALOG_SUSPENSION: undefined }, catalogReasonSuspension:  action.result?.data}
    case ACTION.ERROR_GET_CATALOG_SUSPENSION:
      return { ...state, loading: { ...state.loading, GET_CATALOG_SUSPENSION: { state: false, detail: action.error } } }

    default:
      return state;
  }
};

export default reducer;
