import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from '../../components/simple/Table';
import './ClientList.scss';
import moment from 'moment';
import { ArrowRightOutlined } from '@ant-design/icons';
import IconButton from '../../components/simple/IconButton/IconButton';
import { fakeSort } from '../../utils/table';

function ClientList(props) {
  
  let { data, clientsReducer } = props;

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      ...fakeSort
    },
    {
      title: 'Fecha de alta',
      dataIndex: 'created',
      key: 'created',
      render: date => moment(date).format('DD/MM/YYYY'),
      ...fakeSort
    },
    {
      align: 'right',
      title: 'Detalle',
      dataIndex: 'name',
      render: (name, { id }) => <IconButton
        onClick={() => {
          props.history.push(`/clients/detail/${ id }`);
        }}
        className='secondary'
        icon={ <ArrowRightOutlined /> }
      />
    }
  ];
  

  return (
    <div className='ClientList'>
      { props.children }
      <Table
        { ...props }
        columns={ columns }
        loading={ !!clientsReducer.loading.GET_CLIENTS?.state }
        data={ data }
      />
    </div>
  );
}

export default withRouter(connect((state) => {
  return ({
    clientsReducer: state.get('clientsReducer')
  });
}, (dispatch) => {
  return bindActionCreators({ }, dispatch);
})(ClientList));