import client from '../axios';

class SettingsService {
  constructor() {
    this.client = client;
  }

  getHolidays = (startdate, enddate) => client.get(`v2/holiday`, {
    params: {
      startdate,
      enddate
    }
  });

  saveHolidays = (holidays) => client.post(`v2/holiday`, {
    holidays
  });

  deleteHolidays = (id) => client.delete(`v2/holiday/id/${ id }`);

  getTeacherTariffs = _ => client.get(`v2/tariff/teacher`);
  
  saveTeacherTariffs = tariffs => client.post(`v2/tariff/teacher`, {
    tariffs
  });

  deleteTeacherTariff = tariff => client.delete(`v2/tariff/teacher`, {
    data: {
      tariff
    }
  });

  getClientTariffs = _ => client.get(`v2/tariff/client`);
  
  saveClientTariffs = tariffs => client.post(`v2/tariff/client`, {
    tariffs
  });

  deleteClientTariff = tariff => client.delete(`v2/tariff/client`, {
    data: {
      tariff
    }
  });
}

export const settingsService = new SettingsService();
