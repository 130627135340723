import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormTariffs from '../../../components/custom/FormTariffs';
import { getTeacherTariffs, saveTeacherTariffs, deleteTeacherTariff, ignoreTeacherTariffConflicts } from '../../../reducers/settings/actions';

const FormTeacherTariffs = props => {
  
  const { settingsReducer } = props;

  const { teacherTariffs, loading, teacherTariffConflicts } = settingsReducer;
  
  const getGroupEditRoute = group => `/clients/${ group.client_id }/group/${ group.id }`;

  return (
    <div className='FormTeacherTariffs'>
      <FormTariffs
        { ...props }
        inputLabel='Agregar tarifas profesores'
        getConflictConfirmationTitle={tariff => `La tarifa ${ tariff } no puede eliminarse porque se encuentra asignada a los siguientes grupos`}
        getEditRoute={ getGroupEditRoute }
        saveTariffs={ props.saveTeacherTariffs }
        deleteTariff={ props.deleteTeacherTariff }
        ignoreTariffConflicts={ props.ignoreTeacherTariffConflicts }
        getTariffs={ props.getTeacherTariffs }
        tariffs={ teacherTariffs }
        tariffConflicts={ teacherTariffConflicts?.groups }
        loadingGet={ loading.GET_TEACHER_TARIFFS?.state }
        loadingSave={ loading.SAVE_TEACHER_TARIFFS?.state }
        loadingDelete={ loading.DELETE_TEACHER_TARIFF?.state }
      />
    </div>
  )
}

FormTeacherTariffs.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({
  getTeacherTariffs,
  saveTeacherTariffs,
  deleteTeacherTariff,
  ignoreTeacherTariffConflicts
}, dispatch);

const mapStateToProps = state => ({
  settingsReducer: state.get('settingsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(FormTeacherTariffs);
