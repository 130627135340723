import React, { useState, useEffect } from 'react';
import { AutoComplete, Select } from 'antd';
import './AutocompleteSearch.scss'

export default function AutocompleteSearch(props) {

  const { dataSource, placeholder } = props;
  const [ thisDataSource, setThisDataSource ] = useState(dataSource);

  useEffect(()=>{
    setThisDataSource(props.dataSource);
    // eslint-disable-next-line
  },[dataSource]);

  function renderTitle(title) {
    switch(title) {
      case 'clients':
        title = 'Clientes'
      break;
      case 'teachers':
        title = 'Profesores'
      break;
      case 'languages':
        title = 'Idiomas'
      break;
      case 'students':
        title = 'Alumnos'
      break;
      case 'groups':
        title = 'Grupos'
      break;
      default:
        title = 'Niveles'
      break;
    }

    return (
      <span>{title}</span>
    );
  }

  const options = () => {
    const { OptGroup, Option } = Select;

    return (thisDataSource || []).map((group, index) => {
      return (
        <OptGroup
          key={index}
          label={renderTitle(group.title)}
        >
          { group.children.map((opt) => {
            return (
              <Option key={ `${group.title}/${opt.id}` } value={ opt.title || opt.name }></Option>
            )
          }) }
        </OptGroup>
      )
    })
  };

  const setFilterCategory = (name, filters) => {
    if(!filters[name]){
      filters[name] = {};
      filters[name].title = thisDataSource[name].title || thisDataSource[name].name;
      filters[name].children = [];
    }
    return filters;
  }

  const filterOption = (inputValue) => {
    
    let filters = {};

    if (!!inputValue.length) {  
      (thisDataSource || []).forEach((_, indexCategory) => {
        (thisDataSource[indexCategory]?.children || [ ]).forEach(row => {
          const validation = (row.title || row.name).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
          if (validation) {
            filters = setFilterCategory(indexCategory, filters);
            filters[indexCategory].children.push(row);
          }
        });
      });
      
      const newFilter = Object.values(filters);

      if (!!newFilter.length) {
        setThisDataSource(newFilter);
        return null;
      }
    }
    setThisDataSource(dataSource);
  }

  const onSelectOption = (value, option) => {
    (thisDataSource || []).forEach(group => {
      const item = group.children.find((opt) => value === (opt.title || opt.name));

      if (item) {
        value = {
          id: item.id,
          label: item.title || item.name,
          category: group.title,
        };
      }
    })
    props.onSelect({ ...value })
  }

  return (
    <div className="certain-category-search-wrapper">
      <AutoComplete
        className='AutocompleteSearch'
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 300 }}
        style={{ width: '100%' }}
        placeholder={placeholder}
        onSearch={filterOption}
        onSelect={onSelectOption}
      >
        { options() }
      </AutoComplete>
    </div>
  );
}
