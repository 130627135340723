import moment from 'moment-timezone';
import 'moment/locale/es';
moment.locale();

export function rangeWeekDays(curr){
  const date = moment(curr,"YYYY-MM-DD");

  const first = Number(date.day(1).format("DD"))
  const firstMont = date.day(1).format("MMMM")
  
  const last = date.day(6).format("DD");
  const lastMont = date.day(6).format("MMMM");
  
  if (firstMont !== lastMont) {
    return `Semana del ${first} de ${firstMont} al ${last} de ${lastMont}`;
  }
  return `Semana del ${first} al ${last} de ${lastMont}`;
}

export function getInitEndWeek(curr){
  const date = moment(curr,"YYYY-MM-DD");
  const initialDate = date.day(0).format("YYYY-MM-DD");
  const endDate = date.day(6).format("YYYY-MM-DD");
  return {
    init: initialDate,
    end: endDate
  }
}

export function msToTime(duration) {
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const theHours = (hours < 10) ? "0" + hours : hours;
  const theMinutes = (minutes < 10) ? "0" + minutes : minutes;
  
  return theHours + ":" + theMinutes;
}

export function determineDayOfWeek(date){
  var weekday = new Array(7);
  weekday[0] = 'Mon';
  weekday[1] = 'Tue';
  weekday[2] = 'Wed';
  weekday[3] = 'Thu';
  weekday[4] = 'Fri';
  weekday[5] = 'Sat';
  weekday[6] = 'Sun';
  return weekday[date.getDay()];
}

export const addDays = (theDate, days = 0, unformatted) => {
  let newDate = moment(theDate,"YYYY-MM-DD").add(days, 'days');
  if (unformatted)
    return newDate;
  else
    return newDate.format("YYYY-MM-DD");
};

export const getLocalDate = () => {
  return moment().format("YYYY-MM-DD");
}

export const daysOfWeek = (days) => {
  let weekday = {
    MO: 'Lunes',
    TU: 'Martes',
    WE: 'Miércoles',
    TH: 'Jueves',
    FR: 'Viernes',
    SA: 'Sábado',
    SU: 'Domingo',
  };
  return days.split(',').map(day => weekday[day.replace(' ', '')]).join(' - ');
}