import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import { Row, Col } from 'antd';
import TyphographyTitle from '../../components/simple/TypographyTitle';
import Card from '../../components/simple/Card';
import Table from './Table'
import './Incidents.scss';

function Incidents(props) {
  let { incidents } = props;
  let { loading } = incidents;
  
  return (
    <div className='Incidents'>
      <TyphographyTitle level={4}>Excepciones de clases</TyphographyTitle>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Table { ...props } name='incidents' loading={ loading.INCIDENTS && loading.INCIDENTS.state }/>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect((state) => ({
  incidents: state.get('incidents')
}), (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(Incidents);