import React from 'react';
import CircleGraph from '../../components/simple/CircleGraph';
import Popover from '../../components/simple/Popover';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { fakeSort } from '../../utils/table';

let key = {
    title: '#',
    dataIndex: 'key',
    key: 'key',
    render: key => key + 1
},
teacherSort = {
    sorter: (a, b) => a.teacher.localeCompare(b.teacher),
    sortDirections: ['descend','ascend']
},
numberSort = name => ({
    sorter: (a, b) => {
        return a[name] - b[name]
    },
    sortDirections: ['descend','ascend']
});

const tableSettings = {
    absences: [
        key,
        {
            title: 'Profesor',
            dataIndex: 'teacher',
            key: 'teacher',
            ...teacherSort
        },
        {
            title: '%',
            dataIndex: 'absencePercentage',
            key: 'absencePercentage',
            ...numberSort('absencePercentage'),
            render: (percentage, data) => {
                let color;
                if (percentage > 49)
                    color = '#D05757';
                else if (percentage > 10)
                    color = '#EBAA4D';
                else
                    color = '#B1D630';
                let rounded = `${Math.round(percentage)}%`;
                return (
                    <Popover
                        placement='left'
                        title={`Total de clases de la semana pasada: ${ data.totalOfClasses }`}
                        content={
                            <div className='custom-popover'>
                                <span className='dot' style={{ backgroundColor: color }}/>
                                Ausencias { data.totalOfCancellations } ({ rounded })
                            </div>
                        }
                    >
                        <div>
                            <CircleGraph color={color} size={30} level={rounded} porcentage={percentage}/>
                        </div>
                    </Popover>
                );
            },
        },
        {
            title: 'Todas',
            dataIndex: 'totalOfCancellations',
            key: 'totalOfCancellations',
            ...numberSort('totalOfCancellations'),
            render: (totalOfCancellations, data) => {
                return (
                    <Popover
                        placement='left'
                        content={
                            <ul className='custom-popover'>
                                {
                                    data.details.map((item, index) => (
                                        <li key={index}>
                                            <label>{ item.startDate } <span>{ item.type }</span></label>
                                            <p>{ item.client.name }</p>
                                            <label>{ item.group.name }</label>
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                    >
                        <div>{ totalOfCancellations }</div>
                    </Popover>
                );
            },
        },
        {
            title: 'M+',
            dataIndex: 'earlyCancellationsCount',
            key: 'earlyCancellationsCount',
            ...numberSort('earlyCancellationsCount'),
        },
        {
            title: 'M-',
            dataIndex: 'lateCancellationsCount',
            key: 'lateCancellationsCount',
            ...numberSort('lateCancellationsCount'),
        }
    ],
    delays: [
        key,
        {
            title: 'Profesor',
            dataIndex: 'teacher',
            key: 'teacher',
            ...teacherSort
        },
        {
            title: '%',
            dataIndex: 'delayPercentage',
            key: 'delayPercentage',
            ...numberSort('delayPercentage'),
            render: (percentage, data) => {
                let color;
                if (percentage > 49)
                    color = '#D05757';
                else if (percentage > 10)
                    color = '#EBAA4D';
                else
                    color = '#B1D630';
                let rounded = `${Math.round(percentage)}%`;
                return <CircleGraph color={color} size={30} level={rounded} porcentage={percentage}/>
            },
        },
        {
            title: 'Veces',
            dataIndex: 'totalOfDelays',
            key: 'totalOfDelays',
            ...numberSort('totalOfDelays'),
            render: (totalOfCancellations, data) => {
                return (
                    <Popover
                        placement='left'
                        content={
                            <ul className='custom-popover'>
                                {
                                    data.details.map((item, index) => (
                                        <li key={index}>
                                            <label>{ item.arrivalDate } <span><ClockCircleOutlined /> { `${ item.delayMinutes } ${ item.delayMinutes > 1 ? 'minutos' : 'minuto' }` }</span></label>
                                            <p>{ item.client.name }</p>
                                            <label>{ item.group.name }</label>
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                    >
                        <div>{ totalOfCancellations }</div>
                    </Popover>
                );
            },
        },
        {
            title: 'Minutos',
            dataIndex: 'totalOfDelayMinutes',
            key: 'totalOfDelayMinutes',
            ...numberSort('totalOfDelayMinutes')
        }
    ],
    cancellations: [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            ...fakeSort
        },
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            ...fakeSort
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Profesor',
            dataIndex: 'teacher',
            key: 'teacher',
            render: teacher => <div>{ teacher.name }</div>
        },
        {
            title: 'Cliente',
            dataIndex: 'client',
            key: 'client',
            render: client => <div>{ client.name }</div>
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            render: group => <div>{ group.name }</div>
        },
        {
            title: 'Reagendado',
            dataIndex: 'status',
            key: 'status',
            render: (status, data) => {
                if (data.replacements && data.replacements.length) {
                    let partial = data.replacements[0].type === 'by-parts';
                    return (
                        <Popover
                            placement='left'
                            title={ partial ? 'Por fracciones' : 'Completa' }
                            content={
                                <ul className='custom-popover'>
                                    {
                                        data.replacements.map((item, index) => (
                                            <li key={index}>
                                                { item.type === 'by-parts' && <label>{ `Fracción ${ index + 1}` }</label> }
                                                <p className='regular'><CalendarOutlined /> { item.date } <ClockCircleOutlined /> { item.time }</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                                <span className={`replace-type-icon ${ data.status.id !== 3 ? 'fraction' : ''}`}/>
                                { partial ? `Por fracciones ${ data.totalReplacementParts }/${ data.totalAvailableParts }` : 'Completa' }
                            </div>
                        </Popover>
                    );
                } else {
                    return <span>-</span>
                }
            },
            ...fakeSort
        }
    ]
};

export default tableSettings;