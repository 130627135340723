import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../reducers/tests/actions';
import TableContainer from '../../../components/custom/TableContainer'
import './TableTests.scss';
import ResultColumn from './ResultColumn';

const columns = {
  tests: [
    {
      title: 'Fecha',
      dataIndex: 'examDate',
      key: 'examDate',
    }, {
      title: 'Tipo',
      dataIndex: 'examType',
      key: 'examType',
    }, {
      title: 'Alumno',
      dataIndex: 'studentName',
      key: 'studentName',
    }, {
      title: 'Resultado',
      dataIndex: 'isApproved',
      key: state => (
        <div>{state}</div>
      ),
      render: value => <ResultColumn isApproved={value}/>,
    }, {
      title: 'Nivel',
      key: 'examLevel',
      dataIndex: 'examLevel',
    },{
      title: 'Calificación',
      key: 'score',
      dataIndex: 'score',
    }
  ]
};

function TableTests(props){
  let { match, state } = props;
  let {
    loading,
  } = state;
  return(
    <TableContainer
      downloadDisabled={ true }
      entity={ match.params }
      loading={ loading.TESTS && loading.TESTS.state }
      name='tests'
      columns={ columns }
      { ...props }
      onRow={item => ({
        onClick: () => {
          props.history.push(`/groups/${ match.params.id }/class/${ match.params.classId }/exams/${ item.examNID }`);
        }
      })}
    />
  );
}

TableTests.propTypes = {}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  state: state.get('testsReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableTests);