import React from 'react';
import { Modal as AntModal } from 'antd';

import './Modal.scss';

function Modal(props) {
  const { children, content, modalProps, visible, onOpen } = props;
  return (
    <div>
      <div onClick={onOpen}>
        { visible && children}
      </div>
      <AntModal
        className='Modal'
        centered
        visible={visible}
        {...modalProps}
      >
        <div className='Modal'>
          {content}
        </div>
      </AntModal>
    </div>
  );
}

export default Modal;
