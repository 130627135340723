import { DatePicker, Col, Row } from 'antd';
import React from 'react';
import './DateRange.scss';
import moment from 'moment';

class DateRange extends React.Component {
  state = {
    startDate: this.props.startDate ? moment(this.props.startDate, 'YYYY-MM-DD') : moment(),
    endDate: null,
    endOpen: false,
  };
  disabledStartDate = startDate => {
    const endDate = this.state.endDate;
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  };

  disabledEndDate = endDate => {
    const startDate = this.state.startDate;
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  };

  onChange = async (field, value) => {
    await this.setState({
      [field]: value,
    });
    this.props.update(this.state);
  };

  onStartChange = value => {
    this.onChange('startDate', value);
  };

  onEndChange = value => {
    this.onChange('endDate', value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  componentDidMount() {
    if (this.props.setToday)
      this.props.update(this.state);
  }

  render() {
    const { props, state } = this;
    const { startDate, endDate, endOpen } = state;
    const { disableEndDate, rows, labels, placeholder } = props;
    const inRows = (value, content) => (value ? <Row>{ content }</Row> : content);
    return (
        <Col className='DateRange'>
            { inRows(rows,
                <div className="flex">
                  { labels && <label>{ placeholder || 'Fecha de alta' }</label> }
                  <DatePicker
                      disabledDate={this.disabledStartDate}
                      format="DD/MM/YYYY"
                      value={startDate}
                      placeholder="dd/mm/aaaa"
                      onChange={this.onStartChange}
                      onOpenChange={this.handleStartOpenChange}
                      { ...props }
                  />
                </div>
              )
            }
            { !disableEndDate && (
              inRows(rows,
                <div className="flex">
                  { labels && <label>{ placeholder || 'Fecha de baja' }</label> }
                  <DatePicker
                      disabledDate={this.disabledEndDate}
                      format="DD/MM/YYYY"
                      value={endDate}
                      placeholder="dd/mm/aaaa"
                      onChange={this.onEndChange}
                      open={endOpen}
                      onOpenChange={this.handleEndOpenChange}
                      { ...props }
                  />
                </div>
              )
            )}
        </Col>
    );
  }
}

export default DateRange;