import { tableSortProps } from '../helpers/table';
import * as ACTION from "./types";

const initialState = {
    loading: {},
    errors: {},
    sort: {},
    page: {
        addressList: 1,
        groupAddressList: 1
    },
    orderBy: {
        addressList: {
            column: "created",
            order: "ASC"
        },
        groupAddressList: {
            column: "created",
            order: "ASC"
        }
    },
    filter: {

    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.TABLE_PROPS:
            return {...tableSortProps(state, action) };

        case ACTION.LOADING_GET_ADDRESSES:
            return {...state, loading: {...state.loading, GET_ADDRESSES: { state: true } } }
        case ACTION.RECEIVE_GET_ADDRESSES:
            return {...state, loading: {...state.loading, GET_ADDRESSES: undefined }, addresses: action.result.data.result, saved: false }
        case ACTION.ERROR_GET_ADDRESSES:
            return {...state, loading: {...state.loading, GET_ADDRESSES: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_ALL_ADDRESSES:
            return {...state, loading: {...state.loading, GET_ALL_ADDRESSES: { state: true } } }
        case ACTION.RECEIVE_GET_ALL_ADDRESSES:
            return {...state, loading: {...state.loading, GET_ALL_ADDRESSES: undefined }, allAddresses: action.result.data.result, saved: false }
        case ACTION.ERROR_GET_ALL_ADDRESSES:
            return {...state, loading: {...state.loading, GET_ALL_ADDRESSES: { state: false, detail: action.error } } }

        case ACTION.LOADING_SAVE_ADDRESS:
            return {...state, loading: {...state.loading, SAVE_ADDRESS: { state: true } }, saved: false }
        case ACTION.RECEIVE_SAVE_ADDRESS:
            return {...state, loading: {...state.loading, SAVE_ADDRESS: undefined }, saved: true }
        case ACTION.ERROR_SAVE_ADDRESS:
            return {...state, loading: {...state.loading, SAVE_ADDRESS: { state: false } }, saved: false }

        case ACTION.LOADING_GET_GROUP_ADDRESSES:
            return {...state, loading: {...state.loading, GET_GROUP_ADDRESSES: { state: true } } }
        case ACTION.RECEIVE_GET_GROUP_ADDRESSES:
            return {...state, loading: {...state.loading, GET_GROUP_ADDRESSES: undefined }, groupAddresses: action.result.data.result }
        case ACTION.ERROR_GET_GROUP_ADDRESSES:
            return {...state, loading: {...state.loading, GET_GROUP_ADDRESSES: { state: false, detail: action.error } } }

        case ACTION.LOADING_GET_GROUP_COMPLETED_ADDRESSES:
            return {...state, loading: {...state.loading, GET_GROUP_COMPLETED_ADDRESSES: { state: true } } }
        case ACTION.RECEIVE_GET_GROUP_COMPLETED_ADDRESSES:
            return {...state, loading: {...state.loading, GET_GROUP_COMPLETED_ADDRESSES: undefined }, groupAddresses: action.result.data.result }
        case ACTION.ERROR_GET_GROUP_COMPLETED_ADDRESSES:
            return {...state, loading: {...state.loading, GET_GROUP_COMPLETED_ADDRESSES: { state: false, detail: action.error } } }

        case ACTION.LOADING_LINK_GROUP_ADDRESS:
            return {...state, loading: {...state.loading, LINK_GROUP_ADDRESS: { state: true } } }
        case ACTION.RECEIVE_LINK_GROUP_ADDRESS:
            return {...state, loading: {...state.loading, LINK_GROUP_ADDRESS: undefined } }
        case ACTION.ERROR_LINK_GROUP_ADDRESS:
            return {...state, loading: {...state.loading, LINK_GROUP_ADDRESS: { state: false } } }

        case ACTION.LOADING_LINK_GROUP_COMPLETED_ADDRESS:
            return {...state, loading: {...state.loading, LINK_GROUP_COMPLETED_ADDRESS: { state: true } } }
        case ACTION.RECEIVE_LINK_GROUP_COMPLETED_ADDRESS:
            return {...state, loading: {...state.loading, LINK_GROUP_COMPLETED_ADDRESS: undefined } }
        case ACTION.ERROR_LINK_GROUP_COMPLETED_ADDRESS:
            return {...state, loading: {...state.loading, LINK_GROUP_COMPLETED_ADDRESS: { state: false } } }

        case ACTION.LOADING_DELETE_ADDRESS:
            return {...state, loading: {...state.loading, DELETE_ADDRESS: { state: true } } }
        case ACTION.RECEIVE_DELETE_ADDRESS:
            return {...state, loading: {...state.loading, DELETE_ADDRESS: undefined }, addressConflicts: action.result?.data?.result }
        case ACTION.ERROR_DELETE_ADDRESS:
            return {...state, loading: {...state.loading, DELETE_ADDRESS: { state: false } } }

        case ACTION.LOADING_DELETE_ADDRESS_IN_GROUP_COMPLETED:
            return {...state, loading: {...state.loading, DELETE_ADDRESS_IN_GROUP_COMPLETED: { state: true } } }
        case ACTION.RECEIVE_DELETE_ADDRESS_IN_GROUP_COMPLETED:
            return {...state, loading: {...state.loading, DELETE_ADDRESS_IN_GROUP_COMPLETED: undefined }, addressConflicts: action.result?.data?.result }
        case ACTION.ERROR_DELETE_ADDRESS_IN_GROUP_COMPLETED:
            return {...state, loading: {...state.loading, DELETE_ADDRESS_IN_GROUP_COMPLETED: { state: false } } }

        case ACTION.LOADING_CONFLICT_ADDRESS:
            return {...state, loading: {...state.loading, CONFLICT_ADDRESS: { state: true } } }
        case ACTION.RECEIVE_CONFLICT_ADDRESS:
            return {...state, loading: {...state.loading, CONFLICT_ADDRESS: undefined }, addressConflicts: action.result?.data?.result }
        case ACTION.ERROR_CONFLICT_ADDRESS:
            return {...state, loading: {...state.loading, CONFLICT_ADDRESS: { state: false } } }

        case ACTION.IGNORE_ADDRESS_CONFLICTS:
            return {...state, addressConflicts: null }

        default:
            return state;
    }
}

export default reducer;