import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { 
  getAllAddresses, 
  getGroupAddresses, 
  getGroupCompletedAddresses, 
  linkGroupAddress, 
  linkGroupCompletedAddress,
  deleteAddressInGroupCompleted
} from '../../../reducers/address/actions';
import {
  Form,
  Select
} from 'antd';
import FormWrapper from '../../../components/custom/FormWrapper';
import { PlusOutlined } from '@ant-design/icons';
import AddressTable from '../../../components/custom/AddressTable';
import ConfirmationModal from '../../../components/custom/ConfirmationModal/ConfirmationModal';

import './FormGroupAddress.scss';

const { Option } = Select;


const FormGroupAddress = props => {

  const initialStateModalConfirm = { visible: false, id: null, addressName: null};

  const { addressesReducer, match, location: { state } } = props;
  
  const { clientId, groupId } = match.params;
  
  const { allAddresses, groupAddresses, filter, page, orderBy, loading } = addressesReducer;

  const [ form ] = Form.useForm();

  const [ values, setValues ] = useState({ });

  const [confirmModalVisible, setConfirmModalVisible] = useState(initialStateModalConfirm);

  const onChange = formValue => {
    setValues({
      ...values,
      ...formValue
    });
  }
  
  const update = (p) => {
    if (state?.statusGroup === 'completed') {
      props.getGroupCompletedAddresses(groupId, filter, p || page.groupAddressList, orderBy.groupAddressList);
    } else {
      props.getGroupAddresses(groupId, filter, p || page.groupAddressList, orderBy.groupAddressList);
    }
  };
  
  const clearForm = () => {
    const clearedValues = {
      addresses_id: [ ]
    };
    
    onChange(clearedValues);
    form.setFieldsValue(clearedValues);
  }
  const onFinish = () => {
    if (state?.statusGroup === 'completed') {
      props.linkGroupCompletedAddress(groupId, values)
        .then(clearForm)
        .then(update);
    } else {
      props.linkGroupAddress(groupId, values)
        .then(clearForm)
        .then(update); 
    }
  };

  const onOk = () => {
    props.deleteAddressInGroupCompleted( groupId, confirmModalVisible?.id).then(() => {
      update(page);
      onCancel();
    });
  };

  const onCancel = () => {
    setConfirmModalVisible(initialStateModalConfirm);
  };

  useEffect(() => {
    const initialValues = {
      
    };

    form.setFieldsValue(initialValues);
    onChange(initialValues);
  }, [ form, groupId ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    props.getAllAddresses(clientId);
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(_ => {
    if (groupId) {
      update();
    }
  }, [ groupId ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='FormGroupAddress'>
      <FormWrapper
        { ...props }
        name='group-address'
        form={ form }
        initialValues={ values }
        onValuesChange={ onChange }
        onFinish={ onFinish }
        loading={ 
          loading?.GET_ALL_ADDRESSES?.state 
          || loading?.LINK_GROUP_ADDRESS?.state
          || loading?.LINK_GROUP_COMPLETED_ADDRESS?.state
        }
        hideCancel
        saveButtonText='Asignar'
        saveButtonProps={{
          icon: <PlusOutlined />
        }}
      >
        <Form.Item
          label="Dirección"
          name='addresses_id'
        >
          <Select loading={ loading?.GET_GROUP_ADDRESSES?.state } showSearch mode="multiple" placeholder='Seleccionar'>
            { (allAddresses || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.name }</Option>) }
          </Select>
        </Form.Item>
      </FormWrapper>
      <AddressTable
        { ...props }
        name='groupAddressList'
        update={ update }
        loading={ loading?.GET_GROUP_ADDRESSES?.state || loading?.GET_GROUP_COMPLETED_ADDRESSES?.state }
        data={ groupAddresses }
        onConfirm= { setConfirmModalVisible }
      />
      <ConfirmationModal 
        title="¡Atención!"
        visible={ confirmModalVisible?.visible }
        modalProps={{
          onOk,
          onCancel,
          okText: 'Continuar',
          cancelText: 'Regresar',
          confirmLoading: loading?.DELETE_ADDRESS_IN_GROUP_COMPLETED?.state,
        }}
      >
        <div style={{textAlign: 'center'}}>
            <p>¿Estas seguro que deseas eliminar la siguiente dirección?</p>
            <p><b>{ confirmModalVisible?.addressName }</b></p>
        </div>
      </ConfirmationModal>
    </div>
  )
}

FormGroupAddress.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ 
  getAllAddresses, 
  getGroupAddresses, 
  getGroupCompletedAddresses, 
  linkGroupAddress, 
  linkGroupCompletedAddress, 
  deleteAddressInGroupCompleted 
}, dispatch);

const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer'),
  studyPlanReducer: state.get('studyPlanReducer'),
  groupsReducer: state.get('groupsReducer'),
  addressesReducer: state.get('addressesReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(FormGroupAddress);
