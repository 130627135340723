import client from '../axios';

class DashboardService {
    getAbsences(range) {
        return client.get('v1/auth/dashboard/absences', {
            params: {
                ...range
            }
        })
    }
    getDelays(range) {
        return client.get('v1/auth/dashboard/delays', {
            params: {
                ...range
            }
        })
    }
    getCancellations(range, filters, page, orderBy) {
        return new Promise((resolve, reject) => {
            client.post('v1/auth/dashboard/cancellations', {
                filters: {
                    startdate: range.startdate,
                    enddate: range.enddate,
                    ...filters
                },
                pagination: {
                    page,
                    limit: 10,
                    orderBy: orderBy.column,
                    sort: orderBy.order
                }
            }).then(res => {
                this.exportCancellations(range, filters, page, orderBy).then(exportable => resolve({
                    ...res,
                    data: {
                        ...res.data,
                        exportable: exportable.data.result
                    },
                })).catch(() => resolve(res))
            }).catch(err => reject(err));
        })
    }
    exportCancellations(range, filters, page, orderBy) {
        return client.post('v1/auth/dashboard/cancellations', {
            filters: {
                startdate: range.startdate,
                enddate: range.enddate,
                ...filters
            },
            pagination: {
                page: 1,
                limit: -1,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        })
    }
}

export const dashboardService = new DashboardService();
