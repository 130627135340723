import React, { useState } from "react";
import { Image } from "antd";

const ImagePreview = (props) => {
  const {
    data = [],
    isIcon,
    iconColor = "#4D9CF4",
    component: Component,
  } = props;

  const [visible, setVisible] = useState(false);

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setVisible(true)}>
        <Component style={{ color: isIcon ? iconColor : "" }} />
      </div>
      <div style={{ display: "none" }}>
        {data && (
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
          >
            {data.map((image, index) => (
              <Image key={index} src={image.url} alt={image.name} />
            ))}
          </Image.PreviewGroup>
        )}
      </div>
    </>
  );
};

export default ImagePreview;
