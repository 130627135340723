
import React, { useState, useEffect, useRef } from "react";

import './SearchLocationInput.scss'

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  if (!window.google) {
    return;
  }
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { }
  );
  autoComplete.setFields([ "formatted_address", "geometry", "place_id" ]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();

  updateQuery(addressObject);
}

const SearchLocationInput = props => {

  const [ address, setAddress ] = useState({ });

  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (props.loadScript) {
      loadScript(
        `https://google.com`,
        () => handleScriptLoad(setAddress, autoCompleteRef)
      );
    } else {
      handleScriptLoad(setAddress, autoCompleteRef)
    }
  }, [ window.google, props.loadScript ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    props.onAddressChange(address);
  }, [ address ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="SearchLocationInput">
      <input
        ref={ autoCompleteRef }
        onChange={ event => setAddress(event.target.value) }
      />
    </div>
  );
}

export default SearchLocationInput;