import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import { Row, Col } from 'antd';
import TyphographyTitle from '../../components/simple/TypographyTitle'; 
import Card from '../../components/simple/Card';
import Tags from '../../components/simple/Tags';
import { PlusOutlined } from '@ant-design/icons';
import Button from '../../components/simple/Button';
import TableTeachers from './TableTeachers';
import SearchTeachers from './SearchTeachers';

import './TeachersPage.scss';
import IconButton from '../../components/simple/IconButton/IconButton';

function TeacherPage(props) {
  let { removeFilter, clearFilters, teachers, tableProps, setSelection } = props;
  let { languages, tags, data, filter, page, orderBy, selectedRowKeys, selection } = teachers;
  let update = (p) => {
    props.getTeachers(filter, p || page, orderBy);
  };
  let clearAll = () => clearFilters().then(() => tableProps(1).then(() => update(1)));
  useEffect(() => {
    if(!languages || !languages.result){
      props.getLanguages();
    }
    update();
    return () => {
      props.update('full_name', '', true);
    }
    // eslint-disable-next-line
  },[]);
  return (
    <div className='TeacherPage'>
      <TyphographyTitle level={4} className='TeacherPage__title'>Lista de profesores</TyphographyTitle>
      <Row className='TeacherPage__row'>
        <Col className='TeacherPage__col TeacherPage__table' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card className='TeacherPage__card'>
            <TableTeachers
              onChange={(pager, filter, sort) =>
                tableProps(pager.current, sort).then(() => {
                  update(pager.current)
                })
              }
              rowSelection = {{
                onChange: (...props) => {
                  setSelection(...props);
                },
                getCheckboxProps: record => ({
                  disabled: false,
                  name: record.name,
                }),
                selectedRowKeys
              }}
              data={data}
              availability={tags.find(i => i.type === 'periods')}
            >
              <SearchTeachers onSubmit={() => 
                tableProps(1).then(() => {
                  update(1)
                })
              }/>
              <Row>
                <Col className='TagsContainer' xs={21} sm={21} md={21} lg={21} xl={21} xxl={21}>
                  <Tags selection={selection} list={tags} onDelete={(item, index) => { removeFilter(item, index).then(() => update()); }} onClear={ clearAll }>
                    <Button className='teachers-detail' disabled={selection.length < 2} onClick={() => {
                      props.history.push(`/agenda`);
                    } }>{ selection.length ? `Ver detalle (${ selection.length })` : 'Ver detalle' }</Button>
                  </Tags>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <IconButton
                    label='Nuevo profesor'
                    onClick={() => {
                      props.history.push(`/teachers/new`);
                    }}
                    icon={ <PlusOutlined /> }
                  />
                </Col>
              </Row>
            </TableTeachers>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect((state) => ({
  teachers: state.get('teachers')
}), (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(TeacherPage);