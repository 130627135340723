export const SET_RANGE = 'TICKETS::SET_RANGE'
export const SET_SORT = 'TICKETS::SET_SORT'

export const TABLE_PROPS = 'TICKETS::TABLE_PROPS'

export const ACTIVE_KEY = 'TICKETS::ACTIVE_KEY'

export const LOADING_TICKETS = 'TICKETS::LOADING_TICKETS'
export const RECEIVE_TICKETS = 'TICKETS::RECEIVE_TICKETS'
export const ERROR_TICKETS = 'TICKETS::ERROR_TICKETS'

export const LOADING_ALL_TICKETS = 'TICKETS::LOADING_ALL_TICKETS'
export const RECEIVE_ALL_TICKETS = 'TICKETS::RECEIVE_ALL_TICKETS'
export const ERROR_ALL_TICKETS = 'TICKETS::ERROR_ALL_TICKETS'

export const LOADING_TOTALS = 'TICKETS::LOADING_TOTALS'
export const RECEIVE_TOTALS = 'TICKETS::RECEIVE_TOTALS'
export const ERROR_TOTALS = 'TICKETS::ERROR_TOTALS'

export const LOADING_COORDINATORS_OPTIONS = 'TICKETS::LOADING_COORDINATORS_OPTIONS'
export const RECEIVE_COORDINATORS_OPTIONS = 'TICKETS::RECEIVE_COORDINATORS_OPTIONS'
export const ERROR_COORDINATORS_OPTIONS = 'TICKETS::ERROR_COORDINATORS_OPTIONS'

export const LOADING_UPDATE_TICKET = 'TICKETS::LOADING_UPDATE_TICKET'
export const RECEIVE_UPDATE_TICKET = 'TICKETS::RECEIVE_UPDATE_TICKET'
export const ERROR_UPDATE_TICKET = 'TICKETS::ERROR_UPDATE_TICKET'


export const LOADING_STATUS_OPTIONS = 'TICKETS::LOADING_STATUS_OPTIONS'
export const RECEIVE_STATUS_OPTIONS = 'TICKETS::RECEIVE_STATUS_OPTIONS'
export const ERROR_STATUS_OPTIONS = 'TICKETS::ERROR_STATUS_OPTIONS'