import * as ACTION from './types';
import { classesService } from '../../services/classes.service'

export function getTests(range, filters, page, orderBy, groupId) {
  return {
		types: [
			ACTION.GROUP_EXAMS_LOADING,
			ACTION.GROUP_EXAMS_RECEIVE,
			ACTION.GROUP_EXAMS_ERROR,
		],
		promise: classesService.getGroupExams(groupId, range, filters, page, orderBy),    
  }
};

export function tableProps(name, page, sort) {
	return {
	  type: ACTION.GROUP_EXAMS_TABLEPROPS,
	  name,
	  page,
	  sort
	}
  }

export function saveFilters(filters) {
  return { type: ACTION.SAVE_FILTERS, filters }
}

export function getStudents() {
	return {
		  types: [
			  ACTION.STUDENTS_LOADING,
			  ACTION.STUDENTS_RECEIVE,
			  ACTION.STUDENTS_ERROR,
		  ],
		  promise: classesService.getStudents(),    
	}
  };