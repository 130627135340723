import React from 'react';
import Popover from '../../components/simple/Popover';
import ScoreStarts from '../../components/simple/ScoreStarts';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { fakeSort } from '../../utils/table';
import moment from 'moment-timezone';

const tableSettings = {
    delays: [
        {
            title: 'Fecha',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            ...fakeSort
        },
        {
            title: 'Minutos',
            dataIndex: 'delay',
            key: 'delay',
            ...fakeSort
        },
        {
            title: 'Cliente',
            dataIndex: 'client',
            key: 'client',
            render: client => <div>{ client.name }</div>,
            ...fakeSort
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            render: group => <div>{ group.name }</div>,
            ...fakeSort
        },
        {
            title: 'Hora he llegado',
            dataIndex: 'arrivalTime',
            key: 'arrivalTime',
        },
        {
            title: 'Hora inicio',
            dataIndex: 'startTime',
            key: 'startTime',
        },
    ],
    cancellations: [
        {
            title: 'Fecha de cancelación',
            dataIndex: 'date',
            key: 'date',
            ...fakeSort
        },
        {
            title: 'Cliente',
            dataIndex: 'client',
            key: 'client',
            render: client => <div>{ client.name }</div>,
            ...fakeSort
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            render: group => <div>{ group.name }</div>,
            ...fakeSort
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Motivo',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Reagendado',
            dataIndex: 'status',
            key: 'status',
            render: (status, data) => {
                if (data.replacements && data.replacements.length) {
                    let partial = data.replacements[0].type === 'by-parts';
                    return (
                        <Popover
                            placement='left'
                            title={ partial ? 'Por fracciones' : 'Completa' }
                            content={
                                <ul className='custom-popover'>
                                    {
                                        data.replacements.map((item, index) => (
                                            <li key={index}>
                                                { item.type === 'by-parts' && <label>{ `Fracción ${ index + 1}` }</label> }
                                                <p className='regular'><CalendarOutlined /> { item.date } <ClockCircleOutlined /> { item.time }</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                                <span className={`replace-type-icon ${ data.status.id !== 3 ? 'fraction' : ''}`}/>
                                { partial ? `Por fracciones ${ data.totalReplacementParts }/${ data.totalAvailableParts }` : 'Completa' }
                            </div>
                        </Popover>
                    );
                } else {
                    return <span>-</span>
                }
            },
            ...fakeSort
        }
    ],
    scores: [
        {
            title: 'Fecha',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            ...fakeSort
        },
        {
            title: 'Cliente',
            dataIndex: 'client',
            key: 'client',
            render: client => <div>{ client.name }</div>,
            ...fakeSort
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            render: group => <div>{ group.name }</div>,
            ...fakeSort
        },
        {
            title: 'Alumno',
            dataIndex: 'student',
            key: 'student',
            render: student => <div>{ student.name }</div>,
        },
        {
            title: 'Calificación',
            dataIndex: 'score',
            key: 'score',
            render: value => <ScoreStarts numberOfStars={value}/>,
        },
        {
            title: 'Motivo',
            dataIndex: 'reasons',
            key: 'reasons',
        }
    ],
    issues: [
        {
            title: 'Fecha',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            ...fakeSort
        },
        {
            title: 'Cliente',
            dataIndex: 'client',
            key: 'client',
            render: client => <div>{ client.name }</div>,
            ...fakeSort
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            render: group => <div>{ group.name }</div>,
            ...fakeSort
        },
        {
            title: 'Incidencia',
            dataIndex: 'issueType',
            key: 'issueType',
            render: issue => <div>{ issue.name }</div>,
        }
    ],
    materialsRecord: [
        {
            title: 'Nivel',
            dataIndex: 'name_level',
            key: 'name_level',
            ...fakeSort,
        },
        {
            title: 'Material',
            dataIndex: 'name_material',
            key: 'name_material',
            ...fakeSort,
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
            ...fakeSort,
            render: type => <div>{ type === 1 ? 'Base' : 'Extra' }</div>
        },
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            ...fakeSort,
            render: date => <div>{ moment(date).format('DD/MM/YYYY') }</div>
        }
    ]
};

export default tableSettings;