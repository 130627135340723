import { groupService } from '../../services/group-service';
import * as ACTION from './types';

export function tableProps(page, sort, name) {
  return {
    type: ACTION.TABLE_PROPS,
    page,
    sort,
    name
  }
}

export function getCompletedGroups(cid, page, orderBy, searchQuery) {
  return {
    types: [
      ACTION.LOADING_GET_COMPLETED_GROUPS,
      ACTION.RECEIVE_GET_COMPLETED_GROUPS,
      ACTION.ERROR_GET_COMPLETED_GROUPS,
    ],
    promise: groupService.getCompleted(cid, page, orderBy, searchQuery)
  }
}

export function getIncompleteGroups(cid, page, orderBy, searchQuery) {
    return {
      types: [
        ACTION.LOADING_GET_INCOMPLETE_GROUPS,
        ACTION.RECEIVE_GET_INCOMPLETE_GROUPS,
        ACTION.ERROR_GET_INCOMPLETE_GROUPS,
      ],
      promise: groupService.getIncomplete(cid, page, orderBy, searchQuery)
    }
}

export function createGroup(data) {
  return {
    types: [
      ACTION.LOADING_SAVE_GROUP,
      ACTION.RECEIVE_SAVE_GROUP,
      ACTION.ERROR_SAVE_GROUP,
    ],
    promise: groupService.createGroup(data)
  }
}

export function editGroupCompleted(gid, data) {
  return {
    types: [
      ACTION.LOADING_EDIT_GROUP_COMPLETED,
      ACTION.RECEIVE_EDIT_GROUP_COMPLETED,
      ACTION.ERROR_EDIT_GROUP_COMPLETED,
    ],
    promise: groupService.editGroupCompleted(gid, data)
  }
}

export function getGroup(gid) {
  return {
    types: [
      ACTION.LOADING_GET_GROUP,
      ACTION.RECEIVE_GET_GROUP,
      ACTION.ERROR_GET_GROUP,
    ],
    promise: groupService.getGroup(gid)
  }
}

export function getGroupCompleted(gid) {
  return {
    types: [
      ACTION.LOADING_GET_GROUP,
      ACTION.RECEIVE_GET_GROUP,
      ACTION.ERROR_GET_GROUP,
    ],
    promise: groupService.getGroupCompleted(gid)
  }
}

export function saveSchedule(gid, data) {
  return {
    types: [
      ACTION.LOADING_SAVE_SCHEDULE,
      ACTION.RECEIVE_SAVE_SCHEDULE,
      ACTION.ERROR_SAVE_SCHEDULE,
    ],
    promise: groupService.saveSchedule(gid, data)
  }
}

export function saveScheduleInGroupCompleted(gid, data) {
  return {
    types: [
      ACTION.LOADING_EDIT_SCHEDULE,
      ACTION.RECEIVE_EDIT_SCHEDULE,
      ACTION.ERROR_EDIT_SCHEDULE,
    ],
    promise: groupService.saveScheduleInGroupCompleted(gid, data)
  }
}

export function assignTeacher(gid, data) {
  return {
    types: [
      ACTION.LOADING_ASSIGN_TEACHER,
      ACTION.RECEIVE_ASSIGN_TEACHER,
      ACTION.ERROR_ASSIGN_TEACHER,
    ],
    promise: groupService.assignTeacher(gid, data)
  }
}

export function activateGroups(gids, listFile) {
  return {
    types: [
      ACTION.LOADING_ACTIVATE_GROUPS,
      ACTION.RECEIVE_ACTIVATE_GROUPS,
      ACTION.ERROR_ACTIVATE_GROUPS,
    ],
    promise: groupService.activateGroups(gids, listFile)
  }
}

export function setIncompleteGroupsSelection(keys, items) {
  return {
    type: ACTION.INCOMPLETE_GROUP_ROW_SELECTION,
    items,
    keys
  } 
}

export function setOneIncompleteGroupSelection(keys, items) {
  return {
    type: ACTION.ONE_INCOMPLETE_GROUP_ROW_SELECTION,
    items,
    keys
  } 
}

export function setTeacherTariff(gid, tariff) {
  return {
    types: [
      ACTION.LOADING_SET_TEACHER_TARIFF,
      ACTION.RECEIVE_SET_TEACHER_TARIFF,
      ACTION.ERROR_SET_TEACHER_TARIFF
    ],
    promise: groupService.setTeacherTariff(gid, tariff)
  }
}

export function asignMaterials(gid, materials) {
  return {
    types: [
      ACTION.LOADING_ASSIGN_MATERIALS,
      ACTION.RECEIVE_ASSIGN_MATERIALS,
      ACTION.ERROR_ASSIGN_MATERIALS
    ],
    promise: groupService.assignMaterials(gid, materials)
  }
}

export function getTypeEvidence() {
  return {
    types: [
      ACTION.LOADING_GET_TYPE_EVIDENCE,
      ACTION.RECEIVE_GET_TYPE_EVIDENCE,
      ACTION.ERROR_GET_TYPE_EVIDENCE,
    ],
    promise: groupService.getTypeEvidence()
  }
}

export function resetLoadingState() {
  return {
    type: ACTION.RESET_LOADING_STATE,
  }
}