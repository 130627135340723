import * as ACTION from "./types";
import moment from 'moment';
import { tableSortProps } from '../../reducers/helpers/table';

const dataState = {
    loading: { },
    errors: { },
    tickets: {
      result: [],
      catalogues: {}
    },
    allTickets: {
      result: [],
      catalogues: {}
    },
    coordinators: [],
    status: [],
    sort: { },
    range: { },
    page: {
      tickets: 1,
      allTickets: 1
    },
    totals: {},
    orderBy: {
      tickets: {
          column: "priority",
          order: "DESC"
      },
      allTickets: {
          column: "priority",
          order: "DESC"
      }
    },
};
let addFromNow = collection => ({ ...collection, result: (collection.result || []).map(item => ({ ...item, createdDate: moment(item.createdDate).format('DD/MM/YYYY HH:mm'), fromNow: moment(item.createdDate).fromNow()}))})

const tickets = (state = dataState, action) => {
    switch (action.type) {
        case ACTION.LOADING_TICKETS:
          return { ...state, loading: { ...state.loading, TICKETS: { state: true } } }
        case ACTION.RECEIVE_TICKETS:
          return { ...state, loading: { ...state.loading, TICKETS: undefined }, tickets: addFromNow(action.result.data) }
        case ACTION.ERROR_TICKETS:
          return { ...state, tickets: { result: [], catalogues: [] }, loading: { ...state.loading, TICKETS: { state: false, detail: action.error } } }
        

        case ACTION.LOADING_ALL_TICKETS:
          return { ...state, loading: { ...state.loading, ALL_TICKETS: { state: true } } }
        case ACTION.RECEIVE_ALL_TICKETS:
          return { ...state, loading: { ...state.loading, ALL_TICKETS: undefined }, allTickets: addFromNow(action.result.data) }
        case ACTION.ERROR_ALL_TICKETS:
          return { ...state, allTickets: { result: [], catalogues: [] }, loading: { ...state.loading, ALL_TICKETS: { state: false, detail: action.error } } }
        
        case ACTION.LOADING_TOTALS:
          return { ...state, loading: { ...state.loading, TOTALS: { state: true } } }
        case ACTION.RECEIVE_TOTALS:
          return { ...state, loading: { ...state.loading, TOTALS: undefined }, totals: action.result.data.result }
        case ACTION.ERROR_TOTALS:
          return { ...state, loading: { ...state.loading, TOTALS: { state: false, detail: action.error } } }

        
        case ACTION.LOADING_UPDATE_TICKET:
          return { ...state, loading: { ...state.loading, UPDATE_TICKET: { state: true } } }
        case ACTION.RECEIVE_UPDATE_TICKET:
          return { ...state, loading: { ...state.loading, UPDATE_TICKET: undefined }, updateTicket: action.result.data.result }
        case ACTION.ERROR_UPDATE_TICKET:
          return { ...state, loading: { ...state.loading, UPDATE_TICKET: { state: false, detail: action.error } } }
        
        case ACTION.LOADING_COORDINATORS_OPTIONS:
          return { ...state, loading: { ...state.loading, COORDINATORS_OPTIONS: { state: true } } }
        case ACTION.RECEIVE_COORDINATORS_OPTIONS:
          action.result.data.result.push({id: 0, name: 'Sin asignar'})
          return { ...state, loading: { ...state.loading, COORDINATORS_OPTIONS: undefined }, coordinators: action.result.data.result.map(item => ({ value: item.id, label: item.name })) }
        case ACTION.ERROR_COORDINATORS_OPTIONS:
          return { ...state, loading: { ...state.loading, COORDINATORS_OPTIONS: { state: false, detail: action.error } } }
        
        
        case ACTION.LOADING_STATUS_OPTIONS:
          return { ...state, loading: { ...state.loading, STATUS_OPTIONS: { state: true } } }
        case ACTION.RECEIVE_STATUS_OPTIONS:
          return { ...state, loading: { ...state.loading, STATUS_OPTIONS: undefined }, status: action.result.data.result.map(item => ({ value: item.id, label: item.value })) }
        case ACTION.ERROR_STATUS_OPTIONS:
          return { ...state, loading: { ...state.loading, STATUS_OPTIONS: { state: false, detail: action.error } } }
        
        case ACTION.SET_SORT:
          return { ...state, sort: { ...state.sort, [action.name]: action.value } }
        case ACTION.SET_RANGE:
            return { ...state, range: { ...state.range, [action.name]: action.value } }

        case ACTION.ACTIVE_KEY:
          return { ...state, activeKey: action.key };
        
        case ACTION.TABLE_PROPS:
          return { ...tableSortProps(state, action) };
          
        default:
            return state;
    }
};

export default tickets;