import React from 'react';
import StarRatings from 'react-star-ratings';

function ScoreStarts(props) {
  return (
    <StarRatings 
      rating={4}
      starRatedColor="#EBCD70"
      name='rating'
      starDimension='15px'
      starSpacing='1px'
      {...props}
    />
  );
}


export default ScoreStarts;

