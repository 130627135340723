export function onEdit(values, editValues, plansCatalog) {



    Object.keys(values).forEach(key => {
        if (key.includes('plan-')) {
            const planId = key.split("-")[1];
            const founded = editValues.plans_tariff.findIndex((plan, i) => plan.id === parseInt(planId));
            if (values[key]) {
                const descriptionPlan = plansCatalog.filter(plan => plan.id === parseInt(planId))

                if (editValues.plans_tariff[founded] === undefined) {
                    let newTarif = {
                        id: parseInt(planId),
                        tariff: values[`tariffs-${planId}`],
                        description: descriptionPlan[0].description
                    }
                    editValues.plans_tariff.push(newTarif)
                } else {
                    editValues.plans_tariff[founded].id = parseInt(planId)
                    editValues.plans_tariff[founded].tariff = values[`tariffs-${planId}`]
                    editValues.plans_tariff[founded].description = descriptionPlan[0].description
                }
            } else {
                if (founded !== -1) {
                    editValues.plans_tariff.splice(founded, 1)
                }

            }
        }
    })

    return editValues
}