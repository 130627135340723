import React, { useState } from 'react';
import { Row, DatePicker, TimePicker, Checkbox, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Propover from '../../components/simple/Popover';
import Select from '../../components/simple/Select';
import Button from '../../components/simple/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import moment from 'moment';
import './FilterTeachers.scss';

function FilterTeachers(props) {
  let { languages, scores, teacherTypes, dateTypes, days, form, filter, loading, applyFilter, handleVisible, onSubmit } = props;
  let onChange = name => (value, single) => {
    props.update(name, value, single)
  }, onInputChange = name => value => {
    props.update(name, value.target.value)
  };

  const [ selectedEndTime, setSelectedEndTime ] = useState();

  const [ selectedStartTime, setSelectedStartTime ] = useState();


  const disabledStartHours = () => {
    let endHour = 0;
    const disabledHours = [];

    if (selectedEndTime && selectedEndTime.isValid()) {
      endHour = Number(selectedEndTime.format('H'));
      for (let hour = endHour + 1; hour < 24; hour++) {
        disabledHours.push(hour);
      }
    }
    return disabledHours;
  }

  const disabledStartMinutes = () => {
    let initialHour = 0;
    let endMinutes = 0;
    let endHour = 0;
    
    const disabledMinutes = [];

    if (selectedStartTime) {
      initialHour = Number(selectedStartTime.format('H'));
    }
    if (selectedEndTime && selectedEndTime.isValid()) {
      endHour = Number(selectedEndTime.format('H'));
      endMinutes = Number(selectedEndTime.format('mm'));
      for (let minute = endMinutes; minute < 60; minute++) {
        if (initialHour === endHour && minute >= endMinutes) {
          disabledMinutes.push(minute);
        }
      }
    }
    return disabledMinutes;
  }

  const disabledEndHours = () => {
    let initialHour = 0;
    const disabledHours = [];
    
    if (selectedStartTime && selectedStartTime.isValid()) {
      initialHour = Number(selectedStartTime.format('H'));
    }
    for (let hour = 0; hour < initialHour; hour++) {
      disabledHours.push(hour);
    }
    return disabledHours;
  }

  const disabledEndMinutes = () => {
    const disabledMinutes = [];
    let endHour = 0;
    let initialMinutes = 0;
    let initialHour = 0;

    if (selectedStartTime && selectedStartTime.isValid()) {
      initialHour = Number(selectedStartTime.format('H'));
      initialMinutes = Number(selectedStartTime.format('mm'));
    }
    if (selectedEndTime) {
      endHour = Number(selectedEndTime.format('H'));
    }
    for (let minute = 0; minute < 60; minute++) {
      if (endHour <= initialHour && minute <= initialMinutes) {
        disabledMinutes.push(minute);
      }
    }
    return disabledMinutes;
  }

  const onSelectStartTime = value => {
    value = moment(value);
    
    if (value >= selectedEndTime) {
      value = selectedEndTime.clone().subtract(1, 'minute');
    }
    setSelectedStartTime(value);
    onChange('startTime')(value);
  }

  const onSelectEndTime = value => {
    value = moment(value);
    
    if (value <= selectedStartTime) {
      value = selectedStartTime.clone().add(1, 'minute');
    }
    setSelectedEndTime(value);
    onChange('endTime')(value);
  }
  
  return (
    <div className='FilterTeachers'>
      <Row className='FilterTeachers__row'>
        <Select
          defaultValue={form.language}
          label='Idioma'
          selectProps={{
            placeholder: 'Selecciona idioma',
            onChange: onChange('language')
          }}
          items={languages}
          loading={loading.languages}
        />
        <Select
          defaultValue={form.score}
          label='Calificación mínima'
          selectProps={{
            placeholder: 'Selecciona calificación',
            onChange: onChange('score')
          }}
          items={scores}
        />
        <div className='FilterTeachers__type'>
          <label>Tipo</label>
          <div>
            <Checkbox.Group value={filter.teacherTypes} options={teacherTypes} onChange={value => onChange('teacherTypes')(value, true)}/>
          </div>
        </div>
      </Row>
      <Row className='FilterTeachers__row'>
        <Propover placement="bottom" trigger="click" content={
          <div className='FilterTeachers__range'>
            <Radio.Group defaultValue={form.dateFilterType} options={dateTypes} onChange={onInputChange('dateFilterType')} name="dateFilterType"/>
            <div className="row">
              { form.dateFilterType === 'range' && (
                <DatePicker.RangePicker className='range-picker' dropdownClassName='range-picker' defaultValue={[form.startDate, form.endDate]} onChange={dates => {
                  onChange('startDate')(dates[0]);
                  onChange('endDate')(dates[1]);
                }} placeholder={[ 'Inicio', 'Fin' ]}/>
              )}
              { form.dateFilterType === 'date' && (
                <DatePicker defaultValue={form.startDate} onChange={onChange('startDate')} placeholder={'Seleccione una fecha'}/>
              )}
            </div>
            <label>Días</label>
            <Checkbox.Group options={days.map(day => {
              let disabled = false;
              if (form.startDate && form.endDate) {
                let startDow = form.startDate.day() || 7,
                    endDow = form.endDate.day() || 7,
                    allEnabled = form.endDate.diff(form.startDate, 'week') ? false : true;
                if (form.dateFilterType === 'date')
                  disabled = true;
                else if (form.startDate.isSame(form.endDate, 'date'))
                  disabled = true;
                else if (allEnabled && startDow < endDow && (day.value < startDow || day.value > endDow))
                  disabled = true;
                else if (allEnabled && startDow > endDow && (day.value < startDow && day.value > endDow))
                  disabled = true;
              }
              return { ...day, disabled };
            })} onChange={onChange('days')}/>
            <label>Horario</label>
            <div className="row">
              <TimePicker
                format={'HH:mm'}
                defaultValue={form.startTime}
                placeholder='Inicio'
                onSelect={onSelectStartTime}
                disabledHours={disabledStartHours}
                disabledMinutes={disabledStartMinutes}
                value={ selectedStartTime }
                popupClassName='without-ok-button'
              />
              <TimePicker
                format={'HH:mm'}
                defaultValue={form.endTime}
                placeholder='Fin'
                onSelect={onSelectEndTime}
                disabledHours={disabledEndHours}
                disabledMinutes={disabledEndMinutes}
                value={ selectedEndTime }
                popupClassName='without-ok-button'
              />
            </div>
          </div>
        }>
          <div className="fake-select Select">
            <label>Disponibilidad</label>
            <div>
              <span>{ form.period ? form.period.label : 'Selecciona disponibilidad' }</span>
              <DownOutlined />
            </div>
          </div>
        </Propover>
      </Row>
      <Button onClick={ () => applyFilter().then(() => { handleVisible(false); onSubmit() }) }>Aplicar filtros</Button>
    </div>
  );
}

export default connect((state) => {
  state = state.get('teachers');
  return ({
    languages: state.languages,
    scores: state.scores,
    teacherTypes: state.teacherTypes,
    days: state.days,
    form: state.form,
    filter: state.filter,
    loading: state.loading,
    dateTypes: state.dateTypes
  });
}, (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(FilterTeachers);