export function onCreateClient(values) {
    const plans_tariff = [];
    Object.keys(values).forEach(key => {

        const index = key.split("plan")[1];
        if (values[key] && key.includes('plan')) {
            let json = {}
            if (index === '0') {
                json = {
                    plan_id: values[key],
                    tariff: values[`tariffs0`]
                }
            } else {
                json = {
                    plan_id: parseInt(index.split("-")[1]),
                    tariff: values[`tariffs${index}`]
                }
            }
            plans_tariff.push(json)

        }
        if (key.includes('plan')) {
            delete values[key]
            delete values[`tariffs${index}`]
        }
    })
    values['plans_tariff'] = plans_tariff;

    if (!values.hasOwnProperty('parent_company')) {
        values['parent_company'] = null;
    }

    return values
}