import * as ACTION from "./types";

const initialState = {
  loading: { },
  errors: { }
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case ACTION.LOADING_GET_CATALOG:
      return { ...state, loading: { ...state.loading, GET_CATALOG: { state: true } } }
    case ACTION.RECEIVE_GET_CATALOG:
      return { ...state, loading: { ...state.loading, GET_CATALOG: undefined }, catalog: action.result.data.result }
    case ACTION.ERROR_GET_CATALOG:
      return { ...state, loading: { ...state.loading, GET_CATALOG: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_STUDY_PLAN_LEVELS:
      return { ...state, loading: { ...state.loading, GET_STUDY_PLAN_LEVELS: { state: true } } }
    case ACTION.RECEIVE_GET_STUDY_PLAN_LEVELS:
      return { ...state, loading: { ...state.loading, GET_STUDY_PLAN_LEVELS: undefined }, levels: action.result.data.result }
    case ACTION.ERROR_GET_STUDY_PLAN_LEVELS:
      return { ...state, loading: { ...state.loading, GET_STUDY_PLAN_LEVELS: { state: false, detail: action.error } } }

    default:
      return state;
  }
}

export default reducer;
