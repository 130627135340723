import { Button, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { activeKeyDetailPanels as keys, classSuspensionFlow } from "../../../contants";
import moment from "moment";

import './WarningClassSuspension.scss';
import { useEffect } from "react";

const WarningClassSuspension = (props) => {

  const { 
		setStateFlow, 
		payload,
		clientName,
		groupName,
    groupId,
    suspensionClass,
    resetStateSchedule,
    onClose,
    loadingBtnOk,
    fetchStatus,
    getEvents,
    filters,
    setActiveKey,
	} = props;

  const previous = () => setStateFlow(classSuspensionFlow.MODAL_SUSPENSION);

  const onFinish = () => suspensionClass(payload, groupId);

  useEffect(() => {
    if (fetchStatus === "success") {
      notification["success"]({
        description: "Se han guardado los datos con éxito",
        placement: "bottomRight",
      });

      (getEvents && Object.keys(filters).length) && getEvents(
        filters?.range, 
        filters?.filters, 
        filters?.page, 
        filters?.orderBy, 
        filters?.entity
      );
      setActiveKey(keys.KEY_EVENTS);
      resetStateSchedule();
      onClose();
    }
  }, [ fetchStatus ]);

  return (
    <div className="warning-suspension ">
      <ExclamationCircleOutlined className="icon-warning" />

      <h1>¿Estas seguro que deseas crear una suspensión de clase?</h1>

      <div className="container-list">
          <section>
            <p className="item-list" >
              {clientName} - {groupName}
            </p>
						<p className="item-list" >
              A partir de: {moment(payload?.initial_date).format('DD/MM/YYYY')} hasta {moment(payload?.final_date).format('DD/MM/YYYY')}
            </p>
          </section>

					<p>
						¡Recuerda que esta suspensión no se puede deshacer!
					</p>
      </div>

      <div>
        <Button 
          shape="round" 
          onClick={previous}
        >
          Regresar
        </Button>

        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          loading={loadingBtnOk?.state}
          onClick={onFinish}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default WarningClassSuspension;
