import React from 'react';

import CalendarHourWeek from './CalendarHourWeek';
import CalendarHeaders from './CalendarHeaders';
import CalendarClientHolidays from './CalendarClientHolidays';

import './CalendarClasses.scss';

function CalendarClasses(props) {
  const { mode, data, currentDate } = props;

  return (
    <div className='CalendarClasses'>
      <CalendarHeaders currentDate={currentDate} {...props} />
      <CalendarClientHolidays currentDate={currentDate} {...props} />
      {
        data.map((event, index) => {
          if (event.date) {
            return <div key={ index }/>
          }
          return(
            <CalendarHourWeek key={ index } currentDate={ currentDate } { ...event } mode={ mode } />
          );
        })
      }
    </div>
  );
}


export default CalendarClasses;
