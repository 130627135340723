import React, { useState } from 'react';
import { Layout as AntLayout } from 'antd';

import MenuDisplay from './Menu/MenuDisplay';
import NavBarDisplay from './NavBar/NavBarDisplay';
import FixedAvatar from './FixedAvatar/FixedAvatar';
import { useWindowWidth } from '../customhooks/customhooks';
import './Layout.scss';

function Layout(props) {

  const { children } = props;

  const dimension = useWindowWidth();

  const [collapsed, setCollapsed] = useState(true);
  
  const onToggle = _ => setCollapsed(!collapsed);
  
  return (
    <AntLayout className='Layout'>
      <MenuDisplay
        collapsed={collapsed}
        onToggle={onToggle}
      />
      <AntLayout.Content style={dimension >= 992 ? { backgroundColor: '#EAECF8' } : { backgroundColor: 'white' } }>
        <NavBarDisplay
          collapsed={collapsed}
          onToggle={onToggle}
        />
        <FixedAvatar />
        <div className={collapsed ? 'MyLayout__content' : 'MyLayout__content MyLayout__displace'}>
          {children}
        </div>
      </AntLayout.Content>
    </AntLayout>
  );
}

export default Layout;
