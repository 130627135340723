import { useEffect, useState } from "react";
import { DatePicker, Form, Select } from "antd";
import { useManageFile } from "../../../customhooks";
import { validationFile } from "../../../utils/validationFile";
import InputFile from "../../simple/InputFile/InputFile";
import FormWrapper from "../FormWrapper";
import InputButton from "../InputButton/InputButton";
import ItemFileList from "../ItemFileList/ItemFileList";
import { classSuspensionFlow } from "../../../contants";

import './FormClassSuspension.scss'

const { Option } = Select;

const { RangePicker } = DatePicker;

const FormClassSuspension = (props) => {

  const { 
		form, 
		values, 
		setValues,
		payload,
		setPayload,
		reasonCatalog, 
		onClose,
		setStateFlow,
	} = props;

  const [isValidateForm, setIsValdateForm] = useState([]);

  const {
    currentFile,
    currentFileName,
    listFile,
    setCurrentFileName,
    setListFile,
    handleInputFile,
    handleInputFilePaste,
    checkNameFile,
  } = useManageFile({ listFilesDefault: payload?.attachments || [] });

  const onChange = (formValue) => {
    setValues({
      ...values,
      ...formValue,
    });
  };

  const validateForm = () => {
    const values = form.getFieldsValue(
      Object.keys(form.getFieldsValue() || []).map((key) => key)
    );
    const fieldsValidation = Object.keys(values).filter(
      (item) => !values[item]
    );
    const isValidate = fieldsValidation.length ? false : true;
    setIsValdateForm(isValidate);
  };

  const onFinish = () => {
    const payload = {
      attachments: listFile,
      initial_date: values.range[0].format('YYYY-MM-DD'),
			final_date: values.range[1].format('YYYY-MM-DD'),
      reason: values.reason,
    };

		setPayload(payload);
		setStateFlow(classSuspensionFlow.WARNING);
  };

  useEffect(validateForm, [onChange]);

  return (
    <div className="class-suspension-modal">
      <h1>Suspensión de clase</h1>

      <div>
        <FormWrapper
          {...props}
          name="group-schedule"
          form={form}
          initialValues={values}
          onValuesChange={onChange}
          onFinish={onFinish}
          loading={false}
          cancelButtonText="Regresar"
          saveButtonText="Continuar"
          saveButtonProps={{ disabled: !listFile.length || !isValidateForm }}
          onRedirectCancelButton={onClose}
        >
          <div className="container-form">

            <Form.Item
              label="Período"
              name="range"
              rules={[{ required: true }]}
            >
              <RangePicker format="DD/MM/YYYY" />
            </Form.Item>

            <Form.Item
              label="Motivo"
              name="reason"
              rules={[{ required: true }]}
            >
              <Select
                className="select-evidence"
                placeholder="Seleccionar"
                loading={false}
              >
                {(reasonCatalog || []).map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Subir evidencia" name="files">
              <InputFile
                accept=".png,.jpg,.pdf"
                beforeUpload={validationFile}
                disabled={listFile.length >= 5 ? true : false}
                customRequest={handleInputFile}
                showUploadList={false}
                handlePaste={handleInputFilePaste}
              />
            </Form.Item>

            {currentFile && (
              <InputButton
                currentValue={currentFileName}
                setCurrentValue={setCurrentFileName}
                onClickButton={checkNameFile}
                title="Cambia el nombre de la imagen y valídala dando click al icono"
              />
            )}

            {listFile.map((itemFile, index) => (
              <ItemFileList
                key={itemFile.uid || index}
                base64={itemFile.receipt}
                fileType={itemFile.mime_type}
                uid={itemFile.uid}
                nameFile={itemFile.name}
                listFile={listFile}
                onRemove={setListFile}
              />
            ))}
          </div>
        </FormWrapper>
      </div>
    </div>
  );
};

export default FormClassSuspension;
