import { scheduleService } from "../../services/schedule.service";
import * as ACTION from "./types";

export function editSchedule(data, groupId, conflictCheck = false) {
  return {
    types: [
      ACTION.LOADING_EDIT_SCHEDULE,
      ACTION.RECEIVE_EDIT_SCHEDULE,
      ACTION.ERROR_EDIT_SCHEDULE,
    ],
    promise: scheduleService.editSchedule(data, groupId, conflictCheck),
  };
}

export function getCatalogReasonsEditSchedule() {
  return {
    types: [
      ACTION.LOADING_GET_CATALOG_REASONS,
      ACTION.RECEIVE_GET_CATALOG_REASONS,
      ACTION.ERROR_GET_CATALOG_REASONS,
    ],
    promise: scheduleService.getCatalogReasonsEditSchedule(),
  };
}

export function resetStateSchedule() {
  return {
    type: ACTION.RESET_STATE_SCHEDULE,
  }
}

export function suspensionClass(data, groupId) {
  return {
    types: [
      ACTION.LOADING_SUSPENSION_CLASS,
      ACTION.RECEIVE_SUSPENSION_CLASS,
      ACTION.ERROR_SUSPENSION_CLASS,
    ],
    promise: scheduleService.suspensionClass(data, groupId),
  };
}

export function getCatalogReasonsSuspensionClass() {
  return {
    types: [
      ACTION.LOADING_GET_CATALOG_SUSPENSION,
      ACTION.RECEIVE_GET_CATALOG_SUSPENSION,
      ACTION.ERROR_GET_CATALOG_SUSPENSION,
    ],
    promise: scheduleService.getCatalogReasonsSuspensionClass(),
  };
}

