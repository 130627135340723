import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  PieChartOutlined,
  CalendarOutlined,
  UserOutlined,
  ProfileOutlined,
  TagOutlined,
  TeamOutlined,
  SettingOutlined
} from '@ant-design/icons';
import LogoIcon from '../../assets/img/logo-icono.svg';
import LogoText from '../../assets/img/logo-letras.svg';
import './MenuDisplay.scss';

function MenuDisplay(props){
  const { collapsed, onToggle } = props;
  const { pathname } = window.location;
  const options = [
    {
      name: 'Dashboard',
      icon: <PieChartOutlined/>,
      link: '/dashboard'
    },
    {
      name: 'Clases',
      icon: <CalendarOutlined/>,
      link: '/classes'
    },
    {
      name: 'Clientes',
      icon: <TeamOutlined/>,
      link: '/clients'
    },
    {
      name: 'Profesores',
      icon: <UserOutlined/>,
      link: '/teachers'
    },
    {
      name: 'Excepciones',
      icon: <ProfileOutlined/>,
      link: '/incidents'
    },
    {
      name: 'Tickets',
      icon: <TagOutlined/>,
      link: '/tickets'
    },
    {
      name: 'Configuración',
      icon: <SettingOutlined/>,
      link: '/settings'
    }
  ];
  
  let selected = [ String(options.findIndex(i => pathname.indexOf(i.link) > -1)) ];

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onToggle}
      className={`MenuDisplay ${!collapsed ? 'MenuDisplay__mobileRules' : '' }`}
    >
      <div className="MenuDisplay__logo">
        <img src={LogoIcon} alt='IconImage' />
        { !collapsed && <img src={LogoText} alt='IconText' /> }
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={selected}
        className={`MenuDisplay__items ${!collapsed ? 'MenuDisplay__itemsRules' : '' }`}>
        {
          options.map((item, index) => {
            return (
              <Menu.Item key={index}>
                <Link to={ item.link }>
                  { item.icon }
                  <span>{ item.name }</span>
                </Link>
              </Menu.Item>
            )
          })
        }
      </Menu>
    </Layout.Sider>
  );
}

export default MenuDisplay;
