import React from 'react';
import { Card as AntCard } from 'antd';
import PropTypes from 'prop-types';

import './Card.scss';

function Card(props) {
  const { children, className } = props;
  return(
    <AntCard {...props} className={`Card ${className}`}>
      {children}
    </AntCard>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.element,
    PropTypes.func
  ])
}

export default Card;