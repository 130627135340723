import client from '../axios';

class StudyPlanService {
  constructor() {
    this.client = client;
  }
  
  getStudyPlans = _ => client.get(`/v2/group/study-plans`);

  getLevels = spid => client.get(`/v2/group/study-plan/${ spid }/levels`)
}

export const studyPlanService = new StudyPlanService();
