class Storage {
  complete(text) {
      return '@SPEAK::' + String(text).toUpperCase();
  }
  isJson(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  }
  async set(name, value) {
      value = JSON.stringify(value);
      return await localStorage.setItem(this.complete(name), value);
  }
  async get(name) {
      let data = localStorage.getItem(this.complete(name));
      if (data && this.isJson(data)) {
          // eslint-disable-next-line
          data = JSON.parse(data.replace(/\"/g, '"').replace(/\\\\/g, '\\'));
      }
      return await data
  }
  remove(name) {
      return localStorage.removeItem(this.complete(name));
  }
  clear() {
      return localStorage.clear();
  }
};
let storage = new Storage();
export default storage;