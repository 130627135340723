import React from 'react';
import Table from '../../simple/Table';
import { CopyOutlined, CloseCircleFilled } from '@ant-design/icons';
import Clipboard from 'react-clipboard.js';
import { fakeSort } from '../../../utils/table';
import { Checkbox } from 'antd';

import './AddressTable.scss';

const AddressTable = props => {

  const { 
    deleteAddress,
    changeConflictValue, 
    match: { params }, 
    location: { state },
    onConfirm, 
  } = props;
  
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      ...fakeSort
    },
    {
      title: 'URL Maps',
      dataIndex: 'url_map',
      render: value => <div className='clipboard-wrapper'>
        <Clipboard data-clipboard-text={ value }>
          <a target='_blank' rel="noreferrer" href={ value }>{ value }</a>
          <CopyOutlined/>
        </Clipboard>
      </div>
    },
    {
      title: 'Dirección con conflictos GPS',
      render: address =>
      <Checkbox 
        disabled={ !deleteAddress }
        onChange={() =>{changeConflictValue(address)}}
        checked={address.conflict}>
      </Checkbox>,
    },
    {
      dataIndex: 'id',
      render: (value, data) => (deleteAddress || state?.statusGroup === 'completed') 
        && <CloseCircleFilled 
            onClick={() => state?.statusGroup === 'completed' && params?.groupId 
              ? onConfirm({ visible: true, id: value, addressName: data?.name }) 
              : deleteAddress(data) 
            } 
          />
    }
  ];
  
  return (
    <div className='AddressTable'>
      <Table
        { ...props }
        onChange={(pager, filter, sort) =>
          props.tableProps(pager.current, sort, props.name).then(() => {
            props.update(pager.current)
          })
        }
        columns={ columns }
      />
    </div>
  )
}

AddressTable.propTypes = { };

export default AddressTable;
