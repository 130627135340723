import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import Table from '../../components/simple/Table'
import './CardTable.scss';
import columns from './table.settings'
import CsvDownload from 'react-json-to-csv'
import { DownloadOutlined } from '@ant-design/icons'
  
function CardTable(props) {
  let { dashboard, name, loading } = props;
  return (
    <div className={`CardTable ${ name }`}>
      <Table columns={columns[name]} data={dashboard[name]} { ...props }/>
      { !loading && <CsvDownload data={dashboard[name].exportable || dashboard[name].result} filename={ `${ name }.csv` }><DownloadOutlined /></CsvDownload> }
    </div>
  );
}

export default connect((state) => ({
  dashboard: state.get('dashboard')
}), (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(CardTable);