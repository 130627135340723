import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getClientStudents, tableProps, putStudents } from '../../../reducers/students/actions';
import Table from '../../../components/simple/Table';
import TyphographyTitle from '../../../components/simple/TypographyTitle'; 
import SearchInput from '../../../components/simple/SearchInput'; 
import { Modal } from 'antd';

import './StudentsModal.scss';

const StudentsModal = props => {

  const { match, studentsReducer, visible, selection } = props;

  const { clientId, groupId } = match.params;
  
  const { clientStudents, orderBy, page, loading } = studentsReducer;

  const [ selectedStudents, setSelectedStudents ] = useState([ ]);
  
  const [ searchQuery, setSearchQuery ] = useState();

  const [ okButtonDisabled, setOkButtonDisabled ] = useState(true)

  const update = (p) => props.getClientStudents(clientId, p || page.clientStudents, orderBy.clientStudents, searchQuery);

  useEffect(_ => update(), [ ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    update();
  }, [ searchQuery ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    setSelectedStudents(
      (selection || [ ]).map(item => ({
        ...item,
        saved: true
      }))
    );
  }, [ selection ])

  useEffect(_ => {
    const studentAssignmentValidation = () => {
      const clientsSelected = [];
      if (!selectedStudents.length) {
        setOkButtonDisabled(true);
        return
      }
      selectedStudents.forEach(student => {
          !clientsSelected.includes(student.client_id) 
          && clientsSelected.push(student.client_id);
      });
      const clientsOfDifferentMatrix = clientsSelected.filter(client => client.id !== parseInt(clientId));
      const isOkButtonDisabled = !(clientsOfDifferentMatrix.length > 1 || clientsSelected.includes(parseInt(clientId)));
      setOkButtonDisabled(isOkButtonDisabled);
    }
    studentAssignmentValidation()
  }, [clientId, selectedStudents]);
  
  const columns = [
    {
      title: 'Nombre del empleado',
      dataIndex: 'name'
    }, {
      title: 'Correo electrónico',
      dataIndex: 'email'
    }, {
      title: 'Empleado nid',
      dataIndex: 'nid'
    }, {
      title: 'Nivel',
      dataIndex: 'level'
    }, {
      title: 'Empresa',
      dataIndex: 'client_name'
    }
  ];
  
  const selectedRowKeys = [];

  selectedStudents.forEach(selectedStudent => {
    const index = (clientStudents?.result || []).findIndex(student => student.id === selectedStudent.id);

    if (index > -1) {
      selectedRowKeys.push(index)
    }
  });

  const submit = _ => {
    props.putStudents(clientId, groupId, selectedStudents).then(props.modalProps.onCancel);
  }

  return (
    <Modal 
      { ...props.modalProps }
      confirmLoading={ loading.PUT_STUDENTS?.state } 
      visible={ visible } 
      className='StudentsModal' 
      okText='Asignar' 
      onOk={ submit } 
      okButtonProps={{ disabled: okButtonDisabled }}>
        <TyphographyTitle level={5}>Lista de alumnos</TyphographyTitle>
        <p>De la siguiente lista selecciona los alumnos que deseas vincular a este grupo</p>
        <SearchInput onChange={ setSearchQuery } placeholder='Buscar por empleado'/>
        <Table
          { ...props }
          rowSelection={{
            type: 'checkbox',
            onSelect: (row, selected) => {
              if (selected) {
                setSelectedStudents(selectedStudents.concat([ row ]));
              } else {
                setSelectedStudents(selectedStudents.filter(student => student.id !== row.id));
              }
            },
            onSelectAll: (selected, rows) => {
              if (selected) {
                setSelectedStudents(selectedStudents.concat(rows));
              } else {
                let filteredSelection = selectedStudents;
                
                (clientStudents?.result || []).forEach(row => {
                  filteredSelection = filteredSelection.filter(student => student.id !== row.id)
                });
                setSelectedStudents(filteredSelection);
              }
            },
            selectedRowKeys
          }}
          onChange={(pager, filter, sort) => 
            props.tableProps(pager.current, sort, 'clientStudents').then(() => {
              update(pager.current)
            })
          }
          loading={ studentsReducer.loading.GET_CLIENT_STUDENTS?.state }
          columns={columns}
          data={ clientStudents }
        />
    </Modal>
  )
}

StudentsModal.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ tableProps, getClientStudents, putStudents }, dispatch);

const mapStateToProps = state => ({
  studentsReducer: state.get('studentsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsModal);
