import * as ACTION from "./types";
import { tableSortProps } from '../../reducers/helpers/table';

const initialState = {
  loading: { },
  errors: { },
  sort: { },
  page: {
    clientStudents: 1
  },
  orderBy: {
    clientStudents: {
      column: "created",
      order: "ASC"
    }
  },
};

const reducer = (state = initialState, action) => {
  switch(action.type) {

    case ACTION.LOADING_GET_CLIENT_STUDENTS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_STUDENTS: { state: true } } }
    case ACTION.RECEIVE_GET_CLIENT_STUDENTS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_STUDENTS: undefined }, clientStudents: action.result.data.result }
    case ACTION.ERROR_GET_CLIENT_STUDENTS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_STUDENTS: { state: false, detail: action.error } } }

    case ACTION.LOADING_PUT_STUDENTS:
      return { ...state, loading: { ...state.loading, PUT_STUDENTS: { state: true } } }
    case ACTION.RECEIVE_PUT_STUDENTS:
      return { ...state, loading: { ...state.loading, PUT_STUDENTS: undefined } }
    case ACTION.ERROR_PUT_STUDENTS:
      return { ...state, loading: { ...state.loading, PUT_STUDENTS: { state: false, detail: action.error } } }

    case ACTION.TABLE_PROPS:
      return { ...tableSortProps(state, action) };

    default:
      return state;
  }
}

export default reducer;
