import * as ACTION from './types';
import { userService } from '../../services/user.service'

export function localSession() {
	return (dispatch) => {
		return userService.localSession().then(data => {
			if (data)
				dispatch({ type: ACTION.AVAILABLE });
			else
				dispatch({ type: ACTION.UNAVAILABLE });
		}, err => {
				dispatch({ type: ACTION.UNAVAILABLE });
		});
	};
}

export function login(data) {
  return {
		types: [
			ACTION.LOADING_SET_LOGIN,
			ACTION.RECEIVE_SET_LOGIN,
			ACTION.ERROR_SET_LOGIN,
		],
		promise: userService.login(data),    
  }
}
