import React from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import './Breadcrumb.scss';

function Breadcrumb(props) {
  const { routes } = props;
  return (
    <AntBreadcrumb separator=">" className='Breadcrumb'>
      {
        (routes || [ ]).map((route, index)=>{
          return(
            <AntBreadcrumb.Item key={index}>
              {
                route.url
                ? <Link to={route.url}>{route.label}</Link>
                : route.label
              }
            </AntBreadcrumb.Item>
          );
        })
      }
    </AntBreadcrumb>
  );
}


export default Breadcrumb;
