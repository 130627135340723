import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { saveAddress } from '../../../reducers/address/actions';
import {
  Checkbox,
  Form,
  Input
} from 'antd';
import FormWrapper from '../../../components/custom/FormWrapper';
import Gmaps from '../../../components/custom/Gmaps';
import SearchLocationInput from '../../../components/custom/SearchLocationInput';
import { useHistory } from "react-router-dom";

import './FormGroupAddress.scss';

const FormGroupAddress = props => {

  const { addressesReducer, match } = props;
  
  const { clientId } = match.params;

  const [ form ] = Form.useForm();

  const [ values, setValues ] = useState({ });

  const history = useHistory();
  
  const updateMapUrl = _ => {

    const { coordinates } = values;
    
    const url_map = coordinates && `https://www.google.com/maps/search/?api=1&query=${ coordinates }`;

    if (values.url_map !== url_map) {
      const addressValues = {
        ...values,
        url_map
      };
  
      form.setFieldsValue(addressValues);
      setValues(addressValues);
    }
  }

  const onChange = formValue => setValues({
    client_id: Number(clientId),
    ...values,
    ...formValue
  });
  
  const onFinish = _ => props.saveAddress(clientId, values);

  const setConflictValue = _ => setValues({
    ...values,
    conflict: !values.conflict
  })

  const getCoordinates = _ => {
    
    const coordinates = (values.coordinates || '').split(','),
          lat = parseFloat(coordinates[0]) || 0,
          lng = parseFloat(coordinates[1]) || 0;

    return {
      latitude: lat,
      longitude: lng,
      lat,
      lng
    };
  }

  const onAddressChange = place => {

    const { formatted_address: address, geometry } = place || { };

    const { lat, lng } = geometry?.location || { };

    const coordinates = lat && `${ lat() }, ${ lng() }`;

    const addressValues = {
      ...values,
      address,
      coordinates
    };

    form.setFieldsValue(addressValues);
    setValues(addressValues);
  }

  useEffect(_ => {
    if (addressesReducer.saved) {
      history.push({
        pathname: `/clients/edit/${clientId}`,
        //search: '?activeKey=2',
        state: {
          activeKey: 2, 
        },
      });
    }
  }, [ addressesReducer.saved ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => updateMapUrl(), [ values ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='FormGroupAddress'>
      <FormWrapper
        { ...props }
        name='address-info'
        form={ form }
        initialValues={ values }
        onValuesChange={ onChange }
        onFinish={ onFinish }
        loading={ addressesReducer.loading.SAVE_ADDRESS?.state }
        error={ addressesReducer.errors.SAVE_ADDRESS?.detail }
        cancelButtonText='Regresar'
      >
        <Form.Item
          label='Nombre'
          name='name'
          rules={[
            { required: true },
          ]}
        >
          <Input placeholder='Nombre de la dirección'/>
        </Form.Item>
        <Form.Item
          label='Dirección'
          name='address'
          rules={[
            { required: true },
          ]}
        >
          <SearchLocationInput onAddressChange={ onAddressChange }/>
        </Form.Item>
        <Gmaps center={ getCoordinates() } markers={[ getCoordinates() ]} />
        <Form.Item
          label='Coordenadas (latitud y longitud)'
          name='coordinates'
          rules={[
            { required: true },
            {
              pattern: /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g,
              message: 'Ingresa unas coordenadas validas',
            }
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label='URL google maps'
          name='url_map'
          rules={[
            { required: true },
          ]}
        >
          <Input readOnly/>
        </Form.Item>
        <Form.Item
          name='conflict'
        >
          <Checkbox onChange={ setConflictValue }>Dirección con conflictos de GPS</Checkbox>
        </Form.Item>
      </FormWrapper>
    </div>
  )
}

FormGroupAddress.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ saveAddress }, dispatch);

const mapStateToProps = state => ({
  addressesReducer: state.get('addressesReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(FormGroupAddress);
