import React, { useEffect, useState } from 'react';
import { Col } from 'antd'
import Select from '../../simple/Select';
import AdvancedRange from '../AdvancedRange'
import { filtersKey } from '../../../contants/filters';
import './DynamicFilters.scss';

let advancedFilters = [
    {
        value: 'week',
        label: 'Semana'
    },
    {
        value: 'month',
        label: 'Mes'
    },
    {
        value: 'trimester',
        label: 'Trimestre'
    },
    {
        value: 'range',
        label: 'Rango'
    }
];

function DynamicFilters(props) {
  const { onChange, onRangeChange, data, loading, rangeProps } = props,
        [ filters, setFilters ] = useState({ }),
        updateFilter = name => value => {
          setFilters({
            ...filters,
            [name]: value
          });
        },
        map = list => (list || []).map(item => ({ label: item.name || item.value , value: item.id || item.nip || item.uid }))
  // eslint-disable-next-line
  useEffect(() => onChange && onChange(filters), [ filters ]);
  const   [ range, setRange ] = useState('month');
  return (
    <div className={ `DynamicFilters ${ loading ? 'loading' : '' }` }>
      <Col>
        { !!onRangeChange && (
          <div className='RangeContainer'>
            <Select
                selectProps={{
                    showSearch: false,
                    value: range,
                    onChange: type => {
                      setRange(type);
                    }
                }}
                items={advancedFilters}
            />
            <AdvancedRange { ...rangeProps } allowClear={true} onChange={ onRangeChange } mode={ range }/>
          </div>
        )}
        {
          Object.keys(data?.catalogues || { }).map((key, index) => {
            let placeholder, name;
            const assignProperties = (p, n) => {
              placeholder = p;
              name = n;
            };
            switch(key) {
              case filtersKey.GROUPS:
                assignProperties('Grupo', 'groupId');
              break;
              case filtersKey.CANCELLATION_TYPES:
                assignProperties('Tipo', 'cancellationType');
              break;
              case filtersKey.STATUS:
                assignProperties('Estado', 'status');
              break;
              case filtersKey.TEACHERS:
                assignProperties('Profesor', 'teacherId');
              break;
              case filtersKey.PRIORITYS:
                assignProperties('Prioridad', 'priorityId');
              break;
              case filtersKey.RESPONSABLES:
                assignProperties('Responsable', 'coordinatorId');
              break;
              case filtersKey.TYPES:
                assignProperties('Tipo', 'typeId');
              break;
              case filtersKey.REASONS:
                assignProperties('Motivo', 'reasonId');
              break;
              case filtersKey.SCORES:
                assignProperties('Calificación', 'score');
              break;
              case filtersKey.STUDENTS:
                assignProperties('Alumno', 'studentId');
              break;
              case filtersKey.ISSUE_TYPES:
                assignProperties('Tipo', 'issueId');
              break;
              case filtersKey.REPOSITIONS:
                assignProperties('Reposición', 'repositionType');
              break;
              case filtersKey.RESULTS:
                assignProperties('Resultado', 'resultId');
              break;
              case filtersKey.INCIDENT_TYPES:
                assignProperties('Incidencia', 'typeId');
              break;
              case filtersKey.LEVELS:
                assignProperties('Nivel', 'level_id');
              break;
              case filtersKey.MATERIALS:
                assignProperties('Material', 'material_id');
              break;
              case filtersKey.TYPES_MATERIAL:
                assignProperties('Tipo', 'type_id');
              break;
              case filtersKey.TYPES_:
                assignProperties('Tipo', 'type_id');
              break;
              case filtersKey.STUDENTS_:
                assignProperties('Alumno', 'student_id');
              break;
              case filtersKey.CLIENTS:
                assignProperties('Empresa', 'client_id');
              break;
              case filtersKey.REASONS_:
                assignProperties('Motivo', 'reason_id');
              break;
              case filtersKey.EVENTS:
                assignProperties('Evento', 'type_event');
              break;
              default:
                assignProperties('Cliente', 'clientId');
              break;
            }
            if (props.name === 'tickets' && key === 'responsables') {
              return <div/>;
            }
            return (
              <Select
                key={index}
                selectProps={{
                  className: 'Filters__type',
                  placeholder,
                  onChange: updateFilter(name),
                  allowClear: true
                }}
                items={map((data?.catalogues || { })[key])}
              />
            )
          })
        }
      </Col>
    </div>
  );
}

export default DynamicFilters;
