import React from 'react';
import { Select as AntSelect } from 'antd';
import './Select.scss';

function Select(props) {
  const { items, selectProps, label } = props;
  return (
    <div className='Select'>
      { label && <label>{label}</label>}
      <AntSelect className='Select__input' showSearch filterOption= {(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} {...selectProps}>
        {
          (items || [ ]).map((item, index) => <AntSelect.Option key={index} value={item.value}>{item.label}</AntSelect.Option>)
        }
      </AntSelect>
    </div>
  );
}

export default Select;