import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getHolidays, saveHolidays, deleteHoliday } from '../../../reducers/settings/actions';
import FormHolidays from '../../../components/custom/FormHolidays/FormHolidays';

import './FormAdminHolidays.scss';

const FormAdminHolidays = props => {

  const { settingsReducer } = props;

  const { holidays, loading } = settingsReducer;

  return (
    <div className='FormAdminHolidays'>
      <FormHolidays
        { ...props }
        holidays={ (holidays || [ ]).filter(holiday => holiday.type === 'speak') }
        getHolidays={ props.getHolidays }
        saveHolidays={ props.saveHolidays }
        deleteHoliday={ props.deleteHoliday }
        loadingGet={ loading.GET_HOLIDAYS?.state }
        loadingSave={ loading.SAVE_HOLIDAYS?.state }
        loadingDelete={ loading.DELETE_HOLIDAY?.state }
      />
    </div>
  )
}

FormAdminHolidays.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({
  getHolidays,
  saveHolidays,
  deleteHoliday
}, dispatch);

const mapStateToProps = state => ({
  settingsReducer: state.get('settingsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(FormAdminHolidays);
