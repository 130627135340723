import React from 'react';
import { Button as AntButton } from 'antd';

import './Button.scss';

function Button(props) {
  const { children } = props;
  return(
    <AntButton {...props} className={`MyButton ${ props.className }`}>
      { children }
    </AntButton>
  );
}

Button.defaultProps = {
  children: '',
}

export default Button;