export const filtersKey = {
	GROUPS: 'groups',
	CANCELLATION_TYPES: 'cancellationTypes',
	STATUS: 'status',
	TEACHERS: 'teachers',
	PRIORITYS: 'prioritys',
	RESPONSABLES: 'responsables',
	TYPES: 'types',
	REASONS: 'reasons',
	SCORES: 'scores',
	STUDENTS: 'students',
	ISSUE_TYPES: 'issueTypes',
	REPOSITIONS: 'repositions',
	RESULTS: 'results',
	INCIDENT_TYPES: 'incidentTypes',
	LEVELS: 'levels',
	MATERIALS: 'materials',
	TYPES_MATERIAL: 'typesMaterial',
	TYPES_: 'Types',
	STUDENTS_: 'Students',
	CLIENTS: 'Clients',
	REASONS_: 'Reasons',
	EVENTS: 'Events',
};
