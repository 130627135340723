import React, { useEffect, useState } from "react";
import { Form } from "antd";
import FormClassSuspension from "../FormClassSuspension";
import WarningClassSuspension from "../WarningClassSuspension";
import { classSuspensionFlow } from "../../../contants";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as Actions from "../../../reducers/schedule/actions";
import { getEvents } from "../../../reducers/group-event/actions";
import { setActiveKey } from "../../../containers/Classes/actions";

const ClassSuspensionModal = (props) => {

	const { classReducer, scheduleReducer, getCatalogReasonsSuspensionClass, groupEventReducer } = props;

	const { classDetail } = classReducer;

  const { catalogReasonSuspension, loading, responseSuspensionClass } = scheduleReducer;

  const { filters } = groupEventReducer;

  const [values, setValues] = useState({ files: {} });

	const [payload, setPayload] = useState({})

  const [form] = Form.useForm();

  const [stateFlow, setStateFlow] = useState(classSuspensionFlow.MODAL_SUSPENSION);

  useEffect(() => {
    getCatalogReasonsSuspensionClass();
  }, []);

  return (
    <>
      {stateFlow === classSuspensionFlow.MODAL_SUSPENSION && (
        <FormClassSuspension
          {...props}
          form={form}
          values={values}
          setValues={setValues}
					payload={payload}
					setPayload={setPayload}
          setStateFlow={setStateFlow}
					reasonCatalog={catalogReasonSuspension}
        />
      )}

      {stateFlow === classSuspensionFlow.WARNING && (
        <WarningClassSuspension 
					{...props} 
					setStateFlow={setStateFlow} 
					payload={payload}
					clientName={classDetail?.client?.name}
					groupName={classDetail?.group?.name}
          groupId={classDetail?.group?.id}
          loadingBtnOk={loading?.SUSPENSION_CLASS}
          fetchStatus={responseSuspensionClass?.result}
          filters={filters}
				/>
      )}
    </>
  );
};

ClassSuspensionModal.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, getEvents, setActiveKey }, dispatch);
const mapStateToProps = state => ({
  scheduleReducer: state.get('scheduleReducer'),
  groupEventReducer: state.get("groupEventReducer"),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassSuspensionModal);
