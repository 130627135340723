import client from '../axios';

class JustificationsService {
    constructor() {
        this.client = client;
    }

    getJustifications(groupId, range = {}, filters, page, orderBy) {
        return client.get(`/v1/auth/clients/0/groups/${groupId}/historic-students`, {
            params: {
                filters: {
                    startdate: range.startdate,
                    enddate: range.enddate,
                    ...filters
                },
                pagination: {
                    page,
                    limit: 10,
                    orderBy: orderBy.column,
                    sort: orderBy.order
                }
            }
        });
    };

    deleteEvidence(idEvidence) {
        return client.delete(`/v1/auth/evidence/${idEvidence}`);
    };

    putEvidence(idStudent, payload) {
        return client.put(`/v1/auth/evidence/${idStudent}`, payload);
    };

}

export const justificationsService = new JustificationsService();