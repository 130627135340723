function sortMapper(order) {
    if(order === 'descend') {
        return 'DESC';
    } else {
        return 'ASC';
    }
}

export function tableSortProps(state, action) {
    let orderBy = state.orderBy || { };
    let page = state.page || { };

    if (Object.keys(action.sort || { }).length) {
        if (action.name && typeof orderBy === 'object' && typeof page === 'object') {
            orderBy[action.name] = orderBy[action.name] || { };
            page[action.name] = page[action.name] || { };

            orderBy[action.name].column = action.sort.field;
            orderBy[action.name].order = sortMapper(action.sort.order);
        } else {
            orderBy.column = action.sort.field;
            orderBy.order = sortMapper(action.sort.order);
        }
    }
    page = action.name ? { ...page, [action.name]: action.page } : action.page;
    state =  { ...state, orderBy, page };

    return state;
}

export function selectedRowKeys(data, selection) {
    return (data || [ ]).map(
        (d, i) => ({ d, i })
    ).filter(t => !!(selection || [ ]).find(i => i.id === t.d.id))
    .map(t => t.i);
}

export function setSortDirection(action, state) {
    if (Object.keys(action.sort || { }).length) {
        state.orderBy[action.name].column = action.sort.field;
        if(action.sort.order === 'descend') {
            state.orderBy[action.name].order = 'DESC';
        } else {
            state.orderBy[action.name].order = 'ASC';
        }
    }
    return state;
}