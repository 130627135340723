import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Card from '../../../components/simple/Card';
import Breadcrumb from '../../../components/simple/Breadcrumb';
import CircleGraph from '../../../components/simple/CircleGraph';
import GaugeChart from 'react-gauge-chart'
import {
  BarChart, Bar, YAxis, CartesianGrid, Legend, ResponsiveContainer
} from 'recharts';
import * as moment from 'moment';
import * as Actions from '../../../reducers/test-detail/actions';
import './ExamDetail.scss';

function ExamDetail(props){
  let { match, getExam, testDetailReducer } = props;
  let { groupId, classId, examId } = match.params;
  let { detail } = testDetailReducer;
  let [ gaugeChart, setGaugeChart ] = useState();
  useEffect(() => {
      getExam(groupId, examId);
      // eslint-disable-next-line
  }, [ ])
  if (detail.examNID !== Number(examId))
    return <div/>;
  if (gaugeChart)
    gaugeChart.svg._groups[0][0].remove();
  return (
    <div className='ExamDetail'>
      <div className='ExamDetail__breadcrumb'>
        <Breadcrumb
          routes={[
            { label:'Calendario de clases', url: '/classes', },
            { label:'Detalle de clase', url: `/group/${ groupId }/class/${ classId }` },
            { label:'Examen' }
          ]}
        />
      </div>

      <Row className='ExamDetail__row'>
        <Col className='ExamDetail__col' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className='ExamDetail__info'>
            <Card>
              <div className='ExamDetailCard__header'>
                <div className='ExamDetailCard__header__level'>
                  <CircleGraph level={ detail.groupLevel } porcentage={(detail.currentClass * 100) / detail.totalClassesOfLevel} />
                </div>
                <div className='ExamDetailCard__header__title'>
                  <h1>{ detail.studentName }</h1>
                </div>
              </div>
              <div className='ExamDetailCard__data'>
                <Row className='ExamDetail__row'>
                  <Col className='ExamDetail__col' xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                    <ul className='ExamDetailCard__data__list'>    
                      <li>
                        <dl>
                          <dt>Fecha evaluación:</dt>
                          <dd style={{
                            textTransform: 'capitalize'
                          }}>{ moment(detail.examDate).format('MMMM DD [del] YYYY') }</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>Tipo:</dt>
                          <dd>{ detail.examType }</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>Nivel evaluado:</dt>
                          <dd>{ detail.examLevel }</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>Resultado:</dt>
                          <dd>{ detail.isApproved ? 'Aprobado' : '' }</dd>
                        </dl>
                      </li>
                    </ul>
                  </Col>
                  { detail.score && (
                    <Col  className='ExamDetail__col gaugeChart' xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <GaugeChart ref={setGaugeChart} id={`gaugeChart`} nrOfLevels={25} colors={["#3794FC", "#dee5ef"]} textColor={'#505050'} arcPadding={.06} cornerRadius={1} arcWidth={0.3} percent={ detail.score * .01} />
                      <label>Mínimo: { detail.minAverage }</label>
                    </Col>
                  )}
                  <Col className='ExamDetail__col' xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                    <ResponsiveContainer width='100%' height={200}>
                      <BarChart
                        width={320}
                        height={160}
                        data={[
                          {
                            CO: detail.oralUnderstanding,
                            CE: detail.writtenUnderstanding,
                            EO: detail.oralExpression,
                            EE: detail.writtenExpression
                          }
                        ]}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis type="number" domain={[0, 25]}/>
                        <Legend />
                        <Bar dataKey="CO" fill="#30B9D6"/>
                        <Bar dataKey="CE" fill="#6130D6"/>
                        <Bar dataKey="EO" fill="#9AC774"/>
                        <Bar dataKey="EE" fill="#D9C325"/>
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
                <Row className='ExamDetail__row'>
                  <Col className='ExamDetail__col' xs={24}>
                    <h2>Resultados</h2>
                    <div className="ExamDetailCard__data__results">
                    <Row className='ExamDetail__row'>
                      <Col className='ExamDetail__col' xs={24} sm={24} md={6}>
                        <div className="ExamDetailCard__data__results__detail ExamDetailCard__data__results__detail__co">Comprensión oral: { detail.oralUnderstanding}</div>
                      </Col>
                      <Col className='ExamDetail__col' xs={24} sm={24} md={6}>
                        <div className="ExamDetailCard__data__results__detail ExamDetailCard__data__results__detail__ce">Comprensión escrita: { detail.writtenUnderstanding }</div>
                      </Col>
                      <Col className='ExamDetail__col' xs={24} sm={24} md={6}>
                        <div className="ExamDetailCard__data__results__detail ExamDetailCard__data__results__detail__eo">Expresión oral: { detail.oralExpression }</div>
                      </Col>
                      <Col className='ExamDetail__col' xs={24} sm={24} md={6}>
                        <div className="ExamDetailCard__data__results__detail ExamDetailCard__data__results__detail__ee">Expresión escrita: { detail.writtenExpression }</div>
                      </Col>
                    </Row>
                    </div>
                  </Col>
                </Row>
                <Row className='ExamDetail__row'>
                  <Col className='ExamDetail__col' xs={24}>
                    <h2>Observaciones</h2>
                  </Col>
                </Row>
                <Row className='ExamDetail__row'>
                  <Col className='ExamDetail__col' xs={24} sm={24} md={12}>
                    <ul className='ExamDetailCard__data__obs__list'>    
                      <li>
                        <dl>
                          <dt>Fortalezas</dt>
                          <dd>{ detail.strengths }</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>Áreas de oportunidad</dt>
                          <dd>{ detail.weaknesses }</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>Recomendaciones</dt>
                          <dd>{ detail.recommendations }</dd>
                        </dl>
                      </li>
                    </ul>
                  </Col>
                  <Col className='ExamDetail__col' xs={24} sm={24} md={12}>
                  <ul className='ExamDetailCard__data__obs__list'>    
                      <li>
                        <dl>
                          <dt>Estatus</dt>
                          <dd>{ detail.studentStatus }</dd>
                        </dl>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}


ExamDetail.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  testDetailReducer: state.get('testDetailReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamDetail);

