import * as ACTION from "./types";
import { tableSortProps } from '../../reducers/helpers/table';

const dataState = {
    loading: { },
    errors: { },
    absences: {
      result: []
    },
    delays: {
      result: []
    },
    cancellations: {
      result: [],
      catalogues: {}
    },
    sort: { },
    range: { },
    page: 1,
    orderBy: {
        column: "date",
        order: "DESC"
    },
};
const dashboard = (state = dataState, action) => {
    switch (action.type) {
        case ACTION.LOADING_ABSENCES:
          return { ...state, loading: { ...state.loading, ABSENCES: { state: true } } }
        case ACTION.RECEIVE_ABSENCES:
          return { ...state, loading: { ...state.loading, ABSENCES: undefined }, absences: action.result.data }
        case ACTION.ERROR_ABSENCES:
          return { ...state, loading: { ...state.loading, ABSENCES: { state: false, detail: action.error } } }
        
        case ACTION.LOADING_DELAYS:
          return { ...state, loading: { ...state.loading, DELAYS: { state: true } } }
        case ACTION.RECEIVE_DELAYS:
          return { ...state, loading: { ...state.loading, DELAYS: undefined }, delays: action.result.data }
        case ACTION.ERROR_DELAYS:
          return { ...state, loading: { ...state.loading, DELAYS: { state: false, detail: action.error } } }

        case ACTION.LOADING_CANCELLATIONS:
          return { ...state, loading: { ...state.loading, CANCELLATIONS: { state: true } } }
        case ACTION.RECEIVE_CANCELLATIONS:
          return { ...state, loading: { ...state.loading, CANCELLATIONS: undefined }, cancellations: action.result.data }
        case ACTION.ERROR_CANCELLATIONS:
          return { ...state, loading: { ...state.loading, CANCELLATIONS: { state: false, detail: action.error } } }
        
        case ACTION.SET_SORT:
          return { ...state, sort: { ...state.sort, [action.name]: action.value } }
        case ACTION.SET_RANGE:
          return { ...state, range: { ...state.range, [action.name]: action.value } }
        
        case ACTION.TABLE_PROPS:
          return { ...tableSortProps(state, action) };
          
        default:
            return state;
    }
};

export default dashboard;