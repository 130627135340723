import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import LoginDisplay from './LoginDisplay';

import * as Actions from './actions';

function LoginPage(props){

  //-----> STATES
  const [ userData, setUserData ] = useState({ 
    user: '', 
    password: '', 
    isRemember: false
  });
  
  //-----> EFFECTS
  useEffect(() => {
    props.localSession();
    let loginData = localStorage.getItem('loginData');
    if (loginData)
      setUserData(JSON.parse(loginData))
    
      // eslint-disable-next-line
  }, []);

  //-----> FUNCTIONS
  function onSubmit() {
    if (userData.isRemember)
      localStorage.setItem('loginData', JSON.stringify(userData));
    props.login(userData);
  }
  
  function onChangeValue(e) {
    if(e.target.name === 'isRemember'){
      setUserData({ ...userData, [e.target.name]: e.target.checked });
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  }

  return (
    props.session
    ? <Redirect to="/dashboard"/>
    : <LoginDisplay 
        onSubmit={onSubmit}
        onChange={onChangeValue}
        userData={userData}
      />
  );
}

export default connect((state) => ({
  session: state.get('authReducer').session,
}), (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(LoginPage);
