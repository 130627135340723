import React, { useEffect } from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import MSIcon from '../../../assets/icons/ms-icon.svg';
import { notification } from 'antd';

import './ConflictsModal.scss';

const ConflictsModal = props => {

  const { conflicts, itemToDelete, loading, onDeleteConfirmed, editRoute, itemToDeleteState, ignoreConflicts, notificationDescription, modalConflictsTitle, modalConfirmationTitle } = props;

  const hasConflicts = !!conflicts?.length;

  const onOk = _ => {
    onDeleteConfirmed().then(_ => itemToDeleteState({
      ...itemToDelete,
      done: true
    }));  
  }

  const onCancel = _ => {
    itemToDeleteState(null);
    ignoreConflicts();
  }

  useEffect(_ => {
    if (itemToDelete?.done && !hasConflicts) {
      notification['success']({
        description: notificationDescription,
        placement: 'bottomRight'
      })
      itemToDeleteState(null);
    }
  }, [ hasConflicts, itemToDelete, itemToDeleteState, notificationDescription ]);

  return (
    <ConfirmationModal
      { ...props }
      title={ hasConflicts ? modalConflictsTitle : modalConfirmationTitle }
      visible={ !!itemToDelete || hasConflicts }
      modalProps={{
        onOk,
        okText: hasConflicts ? 'Verificar y continuar' : 'Continuar',
        onCancel,
        confirmLoading: loading,
        className: 'ConflictsModal'
      }}
    >
      <ul>
        { (conflicts || [ ]).map((item, index) => {
          
          const isMS = item.type === 'msa';
          const href = isMS ? '#' : editRoute(item);
          const target = isMS ? null : '_blank';

          return (
            <li key={ index }>
              <a target={ target } rel="noreferrer" href={ href } style={{ cursor: isMS && 'default' }}>
                { item.group_name && `${ item.group_name } - ` }{ item.client_name }
              </a>
              { isMS && <img alt="type ms" src={ MSIcon }/>}
            </li>
          )
        }) }
      </ul>
    </ConfirmationModal>
  );
}

ConflictsModal.propTypes = {};

export default ConflictsModal;
