import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getHolidays, saveHolidays, deleteHoliday } from '../../../reducers/clients/actions';
import FormHolidays from '../../../components/custom/FormHolidays/FormHolidays';

import './FormClientHolidays.scss';

const FormClientHolidays = props => {

  const { clientsReducer } = props;

  const { holidays, loading } = clientsReducer;

  const { match } = props;
  
  const { clientId } = match.params;
  
  return (
    <div className='FormClientHolidays'>
      <FormHolidays
        { ...props }
        holidays={ holidays }
        clientId={ clientId }
        getHolidays={ props.getHolidays }
        saveHolidays={ props.saveHolidays }
        deleteHoliday={ props.deleteHoliday }
        loadingGet={ loading.GET_CLIENT_HOLIDAYS?.state }
        loadingSave={ loading.SAVE_CLIENT_HOLIDAYS?.state }
        loadingDelete={ loading.DELETE_CLIENT_HOLIDAY?.state }
      />
    </div>
  )
}

FormClientHolidays.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({
  getHolidays,
  saveHolidays,
  deleteHoliday
}, dispatch);

const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(FormClientHolidays);
