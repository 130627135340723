import React from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import './ResultColumn.scss';

function ResultColumn(props) {
  const { isApproved } = props;
  return (
    <div className={isApproved ? 'ResultColumn__on' : 'ResultColumn__off'}>
      { isApproved ? <CheckCircleFilled /> : <CloseCircleFilled /> }
    </div>
  );
}

export default ResultColumn;