import React from 'react';
import { Button } from 'antd';

import './IconButton.scss';

function IconButton(props) {
  return(
    <div className={ `IconButton ${ props.className }` }>
      <Button type="text" {...props } icon={ null }>
        { props.label }
      </Button>
      <Button {...props } shape="circle" icon={ props.icon }/>
    </div>
  );
}

IconButton.defaultProps = {
  children: '',
}

export default IconButton;