import React, { useEffect } from 'react';
import AutocompleteSearch from '../../components/simple/AutocompleteSearch';
import FilterTeachers from './FilterTeachers';
import Button from '../../components/simple/Button';
import Propover from '../../components/simple/Popover';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import { PlusCircleOutlined } from '@ant-design/icons';
import './SearchTeachers.scss';
function SearchTeachers(props) {
  let { handleVisible, showFilters, tags, update, allTeachers, getAllTeachers, applyFilter, onSubmit } = props;
  useEffect(() => {
    getAllTeachers();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='SearchTeachers'>
      <AutocompleteSearch
        dataSource={[{
            title: 'teachers',
            children: allTeachers
        }]}
        onSelect={item => update('teachers', item).then(() => applyFilter().then(onSubmit) )}
        placeholder='Buscar por nombre de profesor'
      />
      <Propover onVisibleChange={state => {
        if (!state)
          handleVisible(false);
      }} visible={ showFilters } placement="rightBottom" trigger="click" content={
        showFilters ? <FilterTeachers { ...props }/> : <div/>
      }>
        <Button onClick={() => handleVisible(true) } icon={ <PlusCircleOutlined/> }>{ tags.length ? `Filtros (${ tags.length })` : 'Filtros' }</Button>
      </Propover>
    </div>
  );
}

export default connect((state) => {
  state = state.get('teachers');
  return ({
    showFilters: state.showFilters,
    tags: state.tags,
    allTeachers: state.allTeachers,
  });
}, (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(SearchTeachers);
