import * as ACTION from './types';
import { classesService } from '../../services/classes.service'

export function addData(data) {
  return {
    type: ACTION.ADD_DATA,
    data
  }
}

export function downloadClientReport(client, range, addDelays) {
  classesService.clientReport(client, range, addDelays)
  return {
    type: ACTION.DOWNLOAD_CLIENT_REPORT
  }
}

export function getClasses(payload) {
    return {
      types: [
        ACTION.LOADING_GET_CLASSES,
        ACTION.RECEIVE_GET_CLASSES,
        ACTION.ERROR_GET_CLASSES,
      ],
      promise: classesService.getClasses(payload)
    }
}

export function getCatalogs(payload) {
  return {
    types: [
      ACTION.LOADING_GET_CATALOGS,
      ACTION.RECEIVE_GET_CATALOGS,
      ACTION.ERROR_GET_CATALOGS,
    ],
    promise: classesService.getCatalogs(payload)
  }
}

export function getClientGroups(clientId, startDate, endDate) {
  return {
    types: [
      ACTION.LOADING_GET_CLIENT_GROUPS,
      ACTION.RECEIVE_GET_CLIENT_GROUPS,
      ACTION.ERROR_GET_CLIENT_GROUPS,
    ],
    promise: classesService.getClientGroups(clientId, startDate, endDate)
  }
}

export function clearClientGroups() {
  return {
    type: ACTION.CLEAR_CLIENT_GROUPS
  }
}

export function getClass(idClass) {
  return {
    types: [
      ACTION.LOADING_GET_CLASS,
      ACTION.RECEIVE_GET_CLASS,
      ACTION.ERROR_GET_CLASS,
    ],
    promise: classesService.getClass(idClass)
  }
}

export function setActiveKey(key) {
  return {
    type: ACTION.ACTIVE_KEY,
    key
  }
}

export function updateReplacementPolicy(groupId, replacementPolicy) {
  return {
    types: [
      ACTION.LOADING_UPDATE_POLICY,
      ACTION.RECEIVE_UPDATE_POLICY,
      ACTION.ERROR_UPDATE_POLICY,
    ],
    promise: classesService.updateReplacementPolicy(groupId, replacementPolicy)
  }
}

export function getReplacementPolicies() {
  return {
    types: [
      ACTION.LOADING_REPLACEMENT_POLICIES,
      ACTION.RECEIVE_REPLACEMENT_POLICIES,
      ACTION.ERROR_REPLACEMENT_POLICIES,
    ],
    promise: classesService.getReplacementPolicies()
  }
}

export function getGroupStudents(clientId, classNID) {
  return {
    types: [
      ACTION.LOADING_GET_STUDENTS,
      ACTION.RECEIVE_GET_STUDENTS,
      ACTION.ERROR_GET_STUDENTS,
    ],
    promise: classesService.getGroupStudents(clientId, classNID)
  }
}

export function saveStudents(clientId, groupId, props) {
  return {
    types: [
      ACTION.LOADING_SAVE_STUDENTS,
      ACTION.RECEIVE_SAVE_STUDENTS,
      ACTION.ERROR_SAVE_STUDENTS,
    ],
    promise: classesService.saveStudents(clientId, groupId, {
      ...props,
      start_date: props.range.startDate ? props.range.startDate.format('YYYY-MM-DD') : null,
      end_date: props.range.endDate ? props.range.endDate.format('YYYY-MM-DD') : null
    })
  }
}


export function deleteStudents(clientId, groupId, props) {
  return {
    types: [
      ACTION.LOADING_DELETE_STUDENTS,
      ACTION.RECEIVE_DELETE_STUDENTS,
      ACTION.ERROR_DELETE_STUDENTS,
    ],
    promise: classesService.deleteStudents(clientId, groupId, {
      ...props,
      start_date: props.range.startDate ? props.range.startDate.format('YYYY-MM-DD') : null,
      end_date: props.range.endDate ? props.range.endDate.format('YYYY-MM-DD') : null
    })
  }
}

export function getGroupCalendar(clientId, groupId, range) {
  return {
    types: [
      ACTION.LOADING_GET_GROUP_CALENDAR,
      ACTION.RECEIVE_GET_GROUP_CALENDAR,
      ACTION.ERROR_GET_GROUP_CALENDAR,
    ],
    promise: classesService.getGroupCalendar(clientId, groupId, range)
  }
}

export function getStudyPlan(clientId, groupId) {
  return {
    types: [
      ACTION.LOADING_GET_STUDY_PLAN,
      ACTION.RECEIVE_GET_STUDY_PLAN,
      ACTION.ERROR_GET_STUDY_PLAN,
    ],
    promise: classesService.getStudyPlan(clientId, groupId)
  }
}