import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import Modal from '../../simple/Modal';

import './ModalClosable.scss';

function ModalClosable(props) {
  const { children, anchor, onClose } = props;
  return(
    <div className='ModalClosable'>
      <Modal
        modalProps={{
          closable: false,
          footer: null,
          ...props
        }}
        content={
          <div className='ModalClosable__body'>
            {onClose && 
              <button className='ModalClosable__body__close' onClick={onClose}>
                <CloseOutlined />
              </button>
            }
            {children}
          </div>
        }>
        {anchor}
      </Modal>
    </div>
  );
}

export default ModalClosable;