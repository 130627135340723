import { DatePicker } from 'antd';
import React from 'react';
import './AdvancedRange.scss';
import moment from 'moment';

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

class AdvancedRange extends React.Component {
  state = {
    trimesterHover: null,
    range: {}
  }
  componentWillReceiveProps(data) {
    if (data.mode !== this.props.mode || (!this.state.range.start && this.props.defaults)) {
      if (data.mode === 'range')
        this.onChange([ this.state.start || moment(), this.state.end || moment() ], data.mode);
      else
        this.onChange(this.state.start || moment(), data.mode);
    }
  }
  componentDidMount() {
    if (this.props.defaults)
      this.onChange(moment(), this.props.mode);
  }
  onChange(start, mode) {
    let end;
    if (typeof mode !== 'string' || mode.split('-').length > 1)
      mode = this.props.mode;
    
    switch(mode) {
      case 'month':
        start = start.startOf('month');
        end = moment(start).endOf('month');
      break;
      case 'trimester':
        start = start.startOf('month');
        end = moment(start).add(2, 'months').endOf('month');
      break;
      case 'range':
        end = start[1]
        start = start[0]
      break;
      default:
        start = start ? start.startOf('week') : null;
        end = start ? moment(start).endOf('week') : null;
      break;
    }
    if (start && end) {
      start = start.set({ hour: 0, minute: 0, second: 0,millisecond: 0 });
      end = end.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
    }
    let range = {
      start,
      end,
      startdate: start ? start.valueOf() : null,
      enddate: end ? end.valueOf() : null
    }
    this.setState({
      range
    });
    this.props.onChange(range);
  }
  render() {
    const { props, state } = this,
          { mode, allowClear, start: defaultStart, end: defaultEnd } = props,
          { range, trimesterHover } = state,
          onChange = this.onChange.bind(this),
          customFormat = `DD-MM-YYYY [-] [${ range.end ? range.end.format('DD-MM-YYYY') : (moment(defaultEnd).format('DD-MM-YYYY') || '') }]`;
    
    const same = (a, b) => {
      return a.format('DD-MM-YYYY') === b.format('DD-MM-YYYY')
    };

    return (
      <div className='AdvancedRange'>
        {
          (() => {
            switch(mode) {
              case 'month':
                return <MonthPicker
                        defaultValue={defaultStart}
                        value={range.start }
                        allowClear={allowClear}
                        format={customFormat}
                        onChange={onChange}
                        placeholder="Selecciona mes"
                      />
              case 'trimester':
                return (
                  <MonthPicker
                    value={ range.start }
                    allowClear={allowClear}
                    monthCellRender={month => {
                      const isHighlighted = date => same(date, moment(trimesterHover)) || same(date, moment(trimesterHover).add(1, 'month')) || same(date, moment(trimesterHover).add(2, 'months'));
                      const isSelected = date => same(date, moment(range.start)) || same(date, moment(range.start).add(1, 'month')) || same(date, moment(range.start).add(2, 'months'));

                      return <div
                      className={`custom-month ${
                        trimesterHover && isHighlighted(month) && 'highlight'
                      } ${
                        range.start && isSelected(month) && 'selected-highlight'
                      }`}
                      onMouseEnter={() => {
                          this.setState({
                            trimesterHover: month
                          })
                        }}
                      >{ month.format('MMMM') }</div>
                    }}
                    format={customFormat}
                    dropdownClassName='trimester-picker'
                    onChange={onChange}
                    placeholder="Selecciona mes"
                  />
                );
              case 'range':
                return (
                  <RangePicker
                    separator='-'
                    value={[ range.start, range.end ]}
                    allowClear={allowClear}
                    onChange={onChange}
                    className='range-picker'
                    format={'DD-MM-YYYY'}
                    dropdownClassName='range-picker'
                    placeholder={['Fecha inicio', 'Fecha fin']}
                  />
                );
              default:
                return (
                  <WeekPicker
                    value={ range.start }
                    allowClear={allowClear}
                    dropdownClassName='week-picker'
                    className='week-picker'
                    format={customFormat}
                    onChange={onChange}
                    placeholder="Selecciona semana"
                  />
                );
            }
          })()
        }
      </div>
    )
  }
}

export default AdvancedRange;