import * as ACTION from "./types";

const initialState = {
  loading: { },
  errors: { },
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case ACTION.LOADING_CREATE_TEACHER:
      return { ...state, loading: { ...state.loading, CREATE_TEACHER: { state: true } } }
    case ACTION.RECEIVE_CREATE_TEACHER:
      return { ...state, loading: { ...state.loading, CREATE_TEACHER: undefined }, teacherCreated: action.result.data.result }
    case ACTION.ERROR_CREATE_TEACHER:
      return { ...state, loading: { ...state.loading, CREATE_TEACHER: { state: false, detail: action.error } } }

    case ACTION.LOADING_EDIT_TEACHER:
      return { ...state, loading: { ...state.loading, EDIT_TEACHER: { state: true } } }
    case ACTION.RECEIVE_EDIT_TEACHER:
      return { ...state, loading: { ...state.loading, EDIT_TEACHER: undefined } }
    case ACTION.ERROR_EDIT_TEACHER:
      return { ...state, loading: { ...state.loading, EDIT_TEACHER: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_TEACHER:
      return { ...state, loading: { ...state.loading, GET_TEACHER: { state: true } } }
    case ACTION.RECEIVE_GET_TEACHER:
      return { ...state, loading: { ...state.loading, GET_TEACHER: undefined }, teacher: action.result.data.result }
    case ACTION.ERROR_GET_TEACHER:
      return { ...state, loading: { ...state.loading, GET_TEACHER: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_BY_LANGUAGE:
      return { ...state, loading: { ...state.loading, GET_BY_LANGUAGE: { state: true } } }
    case ACTION.RECEIVE_GET_BY_LANGUAGE:
      return { ...state, loading: { ...state.loading, GET_BY_LANGUAGE: undefined }, teachersByLang: action.result.data.result }
    case ACTION.ERROR_GET_BY_LANGUAGE:
      return { ...state, loading: { ...state.loading, GET_BY_LANGUAGE: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_TARIFFS: { state: true } } }
    case ACTION.RECEIVE_GET_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_TARIFFS: undefined }, tariffs: action.result.data.tariffs }
    case ACTION.ERROR_GET_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_TARIFFS: { state: false, detail: action.error } } }
      
    case ACTION.LOADING_VALIDATE_EMAIL:
      return { ...state, loading: { ...state.loading, VALIDATE_EMAIL: { state: true } } }
    case ACTION.RECEIVE_VALIDATE_EMAIL:
      return { ...state, loading: { ...state.loading, VALIDATE_EMAIL: undefined }, invalidEmail: action.result.data.result.exist }
    case ACTION.ERROR_VALIDATE_EMAIL:
      return { ...state, loading: { ...state.loading, VALIDATE_EMAIL: { state: false, detail: action.error } } }

    case ACTION.SET_EMAIL_AS_VALID:
      return { ...state, loading: { ...state.loading, VALIDATE_EMAIL: { state: false } }, invalidEmail: false }

    default:
      return state;
  }
}

export default reducer;
