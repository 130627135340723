import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getClient } from '../../../reducers/clients/actions';
import { Card, Row, Col } from 'antd';
import Breadcrumb from '../../../components/simple/Breadcrumb';
import Tabs from '../../../components/simple/Tabs';
import FormGroupClientSettings from '../FormGroupClientSettings';
import FormClientHolidays from '../FormClientHolidays';
import AddressesList from '../AddressesList';
import { useLocation } from "react-router-dom";

import './ClientConfig.scss';

const ClientConfig = props => {

  const { match, clientsReducer } = props;
  
  const { clientId } = match.params;
  
  const { client } = clientsReducer;

  const [ activeKey, setActiveKey ] = useState(0);

  const location = useLocation();

  const getBreadcrumbRoutes = _ => {
    const routes = [ { label: 'Lista de clientes', url: `/clients` } ];

    if (clientId) {
      routes.push({ label: client?.name, url: `/clients/detail/${ clientId }` });
      routes.push({ label: 'Configurar cliente' });
    } else {
      routes.push({ label: 'Nuevo cliente' });
    }

    return routes;
  }

  useEffect(_ => {
    if (clientId) {
      props.getClient(clientId);
    }
    location?.state?.activeKey && setActiveKey(location.state.activeKey);
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div className='ClientConfig'>
      <Breadcrumb
        routes={ getBreadcrumbRoutes() }
      />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Tabs
              defaultActiveKey={ activeKey }
              activeKey={ activeKey.toString() }
              onChange={ setActiveKey }
              items={
                [
                  {
                    value: 0,
                    key:'0',
                    label: 'Datos generales',
                    children: <FormGroupClientSettings { ...props }/>
                  },
                  {
                    value: 1,
                    key:'1',
                    disabled: !clientId,
                    label: 'Días inhábiles',
                    children: <FormClientHolidays { ...props }/>
                  },
                  {
                    value: 2,
                    key:'2',
                    disabled: !clientId,
                    label: 'Direcciones físicas',
                    children: <AddressesList { ...props }/>
                  }
                ]
              }
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

ClientConfig.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getClient }, dispatch);

const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientConfig);
