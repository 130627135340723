export const LANGUAGES_LOADING = 'TEACHERS::LANGUAGES_LOADING'
export const LANGUAGES_RECEIVE = 'TEACHERS::LANGUAGES_RECEIVE'
export const LANGUAGES_ERROR = 'TEACHERS::LANGUAGES_ERROR'

export const USERS_LOADING = 'TEACHERS::USERS_LOADING'
export const USERS_RECEIVE = 'TEACHERS::USERS_RECEIVE'
export const USERS_ERROR = 'TEACHERS::USERS_ERROR'

export const ALL_TEACHERS_LOADING = 'TEACHERS::ALL_TEACHERS_LOADING'
export const ALL_TEACHERS_RECEIVE = 'TEACHERS::ALL_TEACHERS_RECEIVE'
export const ALL_TEACHERS_ERROR = 'TEACHERS::ALL_TEACHERS_ERROR'

export const UPDATE = 'TEACHERS::UPDATE'

export const APPLY_FILTER = 'TEACHERS::APPLY_FILTER'

export const TOGGLE_FILTERS = 'TEACHERS::TOGGLE_FILTERS'

export const REMOVE_FILTER = 'TEACHERS::REMOVE_FILTER'

export const CLEAR_FILTERS = 'TEACHERS::CLEAR_FILTERS'

export const TABLE_PROPS = 'TEACHERS::TABLE_PROPS'

export const ROW_SELECTION = 'TEACHERS::ROW_SELECTION'