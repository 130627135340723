import React from 'react';
import { fakeSort } from '../../utils/table';

const tableSettings = {
    incidents: [
        {
            title: 'Creación',
            dataIndex: 'date',
            key: 'date',
            ...fakeSort
        },
        {
            title: 'Descripción',
            dataIndex: 'type',
            key: 'type',
            render: type => <div>{ type.value }</div>,
            ...fakeSort
        },
        {
            title: 'Profesor',
            dataIndex: 'teacher',
            key: 'teacher',
            render: client => <div>{ client.name }</div>,
            ...fakeSort
        },
        {
            title: 'Cliente',
            dataIndex: 'client',
            key: 'client',
            render: client => <div>{ client.name }</div>,
            ...fakeSort
        },
        {
            title: 'Grupo',
            dataIndex: 'group',
            key: 'group',
            render: group => <div>{ group.name }</div>,
            ...fakeSort
        }
    ],
};

export default tableSettings;