import { Button, Divider, notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { activeKeyDetailPanels as keys, daysWeek, editScheduleFlow } from "../../../contants";

import "./ConfirmEditSchedule.scss";

const ConfirmEditSchedule = (props) => {
  const {
    setStateFlow,
    initialData = {},
    endData = {},
    scheduleReducer,
    resetStateSchedule,
    editSchedule,
    onClose,
    groupId,
    fetchStatus,
    getEvents,
    filters,
    setActiveKey,
  } = props;

  const { groupListWithConflict, loading } = scheduleReducer;

  const [acceptFinish, setAcceptFinish] = useState(false);

  const onFinish = () => {
    editSchedule(endData, groupId).then(() => setAcceptFinish(true));
  };

  const previous = () => {
    if (groupListWithConflict.length) {
      setStateFlow(editScheduleFlow.WARNING_CONFLICT);
    } else {
      resetStateSchedule();
      setStateFlow(editScheduleFlow.MODAL_EDITION);
    }
  };

  useEffect(() => {
    if (fetchStatus === "success" && acceptFinish) {
      notification["success"]({
        description: "Se han guardado los datos con éxito",
        placement: "bottomRight",
      });

      (getEvents && Object.keys(filters).length) && getEvents(
        filters?.range, 
        filters?.filters, 
        filters?.page, 
        filters?.orderBy, 
        filters?.entity
      );
      setActiveKey(keys.KEY_EVENTS);
      resetStateSchedule();
      onClose();
    }
  }, [ fetchStatus, acceptFinish ]);

  return (
    <div className="confirm-edit-schedule">
      <h1>Confirmar edición</h1>

      <div>
        <div>
          <h1 className="subtitle-modal">
            Verifica los ajustes para continuar
          </h1>
        </div>
        <div className="container-content">
          <div className="section-modal">
            <div>
              <h1>Agenda actual</h1>
              <div className="content-section">
                <p>
                  <b>Clases por semana: </b>
                  {initialData?.days?.length}
                </p>
                {(initialData.days || []).map((item, key) => (
                  <section key={key}>
                    <p>
                      <b>{daysWeek[item.day]}</b>
                    </p>
                    <p>
                      <b>Horario: </b>
                      {moment(
                        `${item.initial_hour}:${item.initial_minute}`,
                        "HH:mm"
                      ).format("hh:mm a")}
                      {" a "}
                      {moment(
                        `${item.final_hour}:${item.final_minute}`,
                        "HH:mm"
                      ).format("hh:mm a")}
                    </p>
                  </section>
                ))}
              </div>
            </div>
          </div>
          <Divider className="divider" type="vertical" />
          <div className="section-modal">
            <div>
              <h1>Agenda nueva</h1>
              <div className="content-section">
                <p>
                  <b>A partir del: </b>
                  {moment(endData?.date).format("DD/MM/YYYY")}
                </p>
                <p>
                  <b>Clases por semana: </b>
                  {endData.quantity}
                </p>
                {(endData.days || []).map((item, key) => (
                  <section key={key}>
                    <p>
                      <b>{daysWeek[item.day]}</b>
                    </p>
                    <p>
                      <b>Horario: </b>
                      {moment(
                        `${item.initial_hour}:${item.initial_minute}`,
                        "HH:mm"
                      ).format("hh:mm a")}
                      {" a "}
                      {moment(
                        `${item.final_hour}:${item.final_minute}`,
                        "HH:mm"
                      ).format("hh:mm a")}
                    </p>
                  </section>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-actions">
        <Button shape="round" onClick={() => previous()}>
          Regresar
        </Button>

        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          loading={loading?.EDIT_SCHEDULE?.state}
          onClick={() => onFinish()}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default ConfirmEditSchedule;
