import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

export default function InputPassword(props){
  const { name } = props;
  return(
    <Input.Password
      name={name}
      className='InputPassword'
      {...props}
    />
  );
}

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
}