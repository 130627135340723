export const TABLE_PROPS = 'CLIENTS::TABLE_PROPS';

export const LOADING_GET_CLIENTS = 'CLIENTS::LOADING_GET_CLIENTS';
export const RECEIVE_GET_CLIENTS = 'CLIENTS::RECEIVE_GET_CLIENTS';
export const ERROR_GET_CLIENTS = 'CLIENTS::ERROR_GET_CLIENTS';

export const LOADING_GET_CLIENT = 'CLIENTS::LOADING_GET_CLIENT';
export const RECEIVE_GET_CLIENT = 'CLIENTS::RECEIVE_GET_CLIENT';
export const ERROR_GET_CLIENT = 'CLIENTS::ERROR_GET_CLIENT';

export const LOADING_EDIT_CLIENT = 'CLIENTS::LOADING_EDIT_CLIENT';
export const RECEIVE_EDIT_CLIENT = 'CLIENTS::RECEIVE_EDIT_CLIENT';
export const ERROR_EDIT_CLIENT = 'CLIENTS::ERROR_EDIT_CLIENT';

export const LOADING_CREATE_CLIENT = 'CLIENTS::LOADING_CREATE_CLIENT';
export const RECEIVE_CREATE_CLIENT = 'CLIENTS::RECEIVE_CREATE_CLIENT';
export const ERROR_CREATE_CLIENT = 'CLIENTS::ERROR_CREATE_CLIENT';

export const LOADING_GET_TARIFFS = 'CLIENTS::LOADING_GET_TARIFFS';
export const RECEIVE_GET_TARIFFS = 'CLIENTS::RECEIVE_GET_TARIFFS';
export const ERROR_GET_TARIFFS = 'CLIENTS::ERROR_GET_TARIFFS';

export const LOADING_GET_PLANS = 'CLIENTS::LOADING_GET_PLANS';
export const RECEIVE_GET_PLANS = 'CLIENTS::RECEIVE_GET_PLANS';
export const ERROR_GET_PLANS = 'CLIENTS::ERROR_GET_PLANS';

export const LOADING_GET_CLIENT_HOLIDAYS = 'SETTINGS::LOADING_GET_CLIENT_HOLIDAYS';
export const RECEIVE_GET_CLIENT_HOLIDAYS = 'SETTINGS::RECEIVE_GET_CLIENT_HOLIDAYS';
export const ERROR_GET_CLIENT_HOLIDAYS = 'SETTINGS::ERROR_GET_CLIENT_HOLIDAYS';

export const LOADING_SAVE_CLIENT_HOLIDAYS = 'SETTINGS::LOADING_SAVE_CLIENT_HOLIDAYS';
export const RECEIVE_SAVE_CLIENT_HOLIDAYS = 'SETTINGS::RECEIVE_SAVE_CLIENT_HOLIDAYS';
export const ERROR_SAVE_CLIENT_HOLIDAYS = 'SETTINGS::ERROR_SAVE_CLIENT_HOLIDAYS';

export const LOADING_DELETE_CLIENT_HOLIDAY = 'SETTINGS::LOADING_DELETE_CLIENT_HOLIDAY';
export const RECEIVE_DELETE_CLIENT_HOLIDAY = 'SETTINGS::RECEIVE_DELETE_CLIENT_HOLIDAY';
export const ERROR_DELETE_CLIENT_HOLIDAY = 'SETTINGS::ERROR_DELETE_CLIENT_HOLIDAY';

export const LOADING_GET_ALL_CLIENTS = 'CLIENTS::LOADING_GET_ALL_CLIENTS';
export const RECEIVE_GET_ALL_CLIENTS = 'CLIENTS::RECEIVE_GET_ALL_CLIENTS';
export const ERROR_GET_ALL_CLIENTS = 'CLIENTS::ERROR_GET_ALL_CLIENTS';

export const LOADING_GET_VALIDATE_CLIENT_NAME = 'CLIENTS::LOADING_GET_VALIDATE_CLIENT_NAME';
export const RECEIVE_GET_VALIDATE_CLIENT_NAME = 'CLIENTS::RECEIVE_GET_VALIDATE_CLIENT_NAME';
export const ERROR_GET_VALIDATE_CLIENT_NAME = 'CLIENTS::ERROR_GET_VALIDATE_CLIENT_NAME';