import React, { useEffect, useState } from 'react';
import Table from '../../simple/Table'
import DynamicFilters from '../DynamicFilters'
import './TableContainer.scss';


function TableContainer(props) {
  let { state,
    name,
    tableProps,
    columns,
    refreshTrigger,
    entity,
    saveFilters,
    wantSaveFilters = false,
    rangeProps,
  } = props;
  
  let {
    user,
    page,
    orderBy
  } = state;

  let [ filters, setFilters ] = useState({})
  let [ range, setRange ] = useState({})
  let update = tableName => () => {
    const action = props[`get${ tableName.charAt(0).toUpperCase() + tableName.slice(1) }`];

    (saveFilters && wantSaveFilters) && saveFilters({
      range: range[tableName], 
      filters: filters[tableName], 
      page: page[tableName], 
      orderBy: orderBy[tableName], 
      entity: (entity || user || {}).id,
    });

    if (action) {
      action(range[tableName] || rangeProps, filters[tableName], page[tableName], orderBy[tableName], (entity || user || {}).id)
    }
  }
  let getPage = (tablePage, tableFilters, tableName) => {
    const hasFilters = !!tableFilters[tableName];
    const currentPage = tablePage[tableName] || 1;
    tablePage = hasFilters ? 1 : currentPage;
    return tablePage
  }
  // eslint-disable-next-line
  useEffect(update(name), [ refreshTrigger, range[name], filters[name], page[name], orderBy[name]?.column, orderBy[name]?.order ]);
  return (
    
    <div className='TableContainer'>
      <DynamicFilters
        { ...props }
        onChange={tableFilters => {
          tableProps(name, getPage(page, filters, name)).then(() => setFilters({ ...filters, [name]: tableFilters }));
        }}
        onRangeChange={data => setRange({ ...range, [name]: data})}
        data={state[name]}
      />
      <Table data={state[name]} onChange={(pager, filter, sort) => tableProps(name, pager.current, sort) } { ...props } columns={columns[name]}/>
    </div>
  );
}

export default TableContainer;
