import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Map } from 'immutable';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import createStore from './store';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

import './index.scss';

const store = createStore(Map(window.REDUX__STATE));

ReactDOM.render(
  <ConfigProvider locale={ esES }>
    <Provider store={ store }>
      <Routes />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
