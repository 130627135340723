import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCompletedGroups, tableProps } from '../../../reducers/groups/actions';
import { withRouter } from 'react-router-dom';
import Table from '../../../components/simple/Table';
import moment from 'moment';
import { fakeSort } from '../../../utils/table';
import IconButton from '../../../components/simple/IconButton/IconButton';
import { ArrowRightOutlined, FileFilled } from '@ant-design/icons';
import ImagePreview from '../../../components/simple/ImagePreview';

import './CompletedGroupList.scss';

function CompletedGroupList(props) {
  
  let { searchQuery, clientId, groupsReducer } = props;

  const { completedGroups, orderBy, page } = groupsReducer;

  const update = (p) => props.getCompletedGroups(clientId, p || page.completedGroups, orderBy.completedGroups, searchQuery);

  useEffect(_ => update(), [ ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => update(), [ searchQuery ]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: 'Nombre grupo',
      dataIndex: 'name',
      ...fakeSort
    },
    {
      title: 'Fecha de inicio',
      dataIndex: 'initial_date',
      render: date => date ? moment(date).format('DD/MM/YYYY') : 'No especificado',
      ...fakeSort
    },
    {
      title: 'Curso',
      dataIndex: 'course'
    },
    {
      title: 'Nivel',
      dataIndex: 'level'
    },
    {
      title: 'Profesor',
      dataIndex: 'teacher'
    },
    {
      title: 'Evidencias',
      dataIndex: 'evidences',
      key: 'evidences',
      render: data => (
        (data || []).length ? 
        <ImagePreview 
          data={data}
          isIcon={true} 
          component={FileFilled} 
        /> : null
      ),
    },
    {
      align: 'right',
      title: 'Detalle',
      dataIndex: 'detail',
      render: (detail, { id, class_id }) => <IconButton
        onClick={() => {
          props.history.push(`/group/${id}/class/${class_id}`);
        }}
        className='secondary'
        icon={ <ArrowRightOutlined /> }
      />
    }
  ];
  
  return (
    <div className='CompletedGroupList'>
      { props.children }
      <Table
        { ...props }
        onChange={(pager, filter, sort) => 
          props.tableProps(pager.current, sort, 'completedGroups').then(() => {
            update(pager.current)
          })
        }
        loading={ !!groupsReducer.loading.GET_COMPLETED_GROUPS?.state }
        columns={ columns }
        data={ completedGroups }
      />
    </div>
  );
}

export default withRouter(connect((state) => ({
  groupsReducer: state.get('groupsReducer')
}), (dispatch) => {
  return bindActionCreators({ getCompletedGroups, tableProps }, dispatch);
})(CompletedGroupList));