import React from 'react';
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons'
import { fakeSort } from '../../utils/table';

let ticketCols = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'Creación',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (createdDate, data) => (
            <div className='date'>
                <div>
                    { data.priority.id === 1 && <CaretUpFilled style={{ color: data.priority.style }}/> }
                    { data.priority.id === 2 && <CaretUpFilled style={{ color: data.priority.style }}/> }
                    { data.priority.id === 3 && <CaretDownFilled style={{ color: data.priority.style }}/> }
                    { createdDate } <span className='from-now'>{ data.fromNow }</span></div>
            </div>
        ),
        ...fakeSort
    },
    {
        title: 'Incidencia',
        dataIndex: 'type',
        key: 'type',
        ...fakeSort
    },
    {
        title: 'Cliente',
        dataIndex: 'client',
        key: 'client',
        render: client => <div>{ client.name }</div>,
        ...fakeSort
    },
    {
        title: 'Grupo',
        dataIndex: 'group',
        key: 'group',
        render: group => <div>{ group ? group.name : '' }</div>,
        ...fakeSort
    },
    {
        title: 'Profesor',
        dataIndex: 'teacher',
        key: 'teacher',
        render: teacher => <div>{ teacher ? teacher.name : '' }</div>,
        ...fakeSort
    },
    {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        render: status => <div className='status' style={{ backgroundColor: status.style }}>{ status.value }</div>,
        ...fakeSort
    },
];

const tableSettings = {
    tickets: ticketCols,
    allTickets: (() => {
        let columns = ticketCols.map(item => item);
        columns.splice(3, 0, {
            title: 'Responsable',
            dataIndex: 'responsable',
            key: 'responsable',
            render: responsable => <div>{ responsable.name }</div>,
            ...fakeSort
        });
        return columns;  
    })(),
};

export default tableSettings;