import * as ACTION from "./types";
import moment from 'moment';
import { tableSortProps } from '../../reducers/helpers/table';

const dataState = {
    loading: { },
    errors: { },
    incidents: {
      result: [],
      catalogues: {}
    },
    sort: { },
    range: { },
    page: {
      incidents: 1
    },
    totals: {},
    orderBy: {
      incidents: {
          column: "date",
          order: "ASC"
      },
    },
};

let addFromNow = collection => ({ ...collection, result: (collection.result || []).map(item => ({ ...item, date: moment(item.date).format('DD/MM/YYYY HH:mm'), fromNow: moment(item.date).fromNow()}))})

const incidents = (state = dataState, action) => {
    switch (action.type) {
        case ACTION.LOADING_INCIDENTS:
          return { ...state, loading: { ...state.loading, INCIDENTS: { state: true } } }
        case ACTION.RECEIVE_INCIDENTS:
          return { ...state, loading: { ...state.loading, INCIDENTS: undefined }, incidents: addFromNow(action.result.data) }
        case ACTION.ERROR_INCIDENTS:
          return { ...state, loading: { ...state.loading, INCIDENTS: { state: false, detail: action.error } } }
          
        case ACTION.TABLE_PROPS:
          return { ...tableSortProps(state, action) };
            
        default:
            return state;
    }
};

export default incidents;
