import GER from '../assets/img/aleman.jpg';
import ESP from '../assets/img/espanol_mx.jpg';
import FRA from '../assets/img/frances.jpg';
import ING from '../assets/img/ingles_usa.jpg';
import ITA from '../assets/img/italiano.jpg';
import POR from '../assets/img/portugues.jpg';

export function selectFlagFile(flag){
  switch(flag){
    case 1:
      return ING;
    case 2:
      return ESP;
    case 3:
      return FRA;
    case 4:
      return GER;
    case 5:
      return ITA;
    case 6:
      return POR;
    default:
      return ESP;
  }
}