import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Row,
  Col
} from 'antd';
import { getClient } from '../../../reducers/clients/actions';
import Card from '../../../components/simple/Card';
import Tabs from '../../../components/simple/Tabs';
import Breadcrumb from '../../../components/simple/Breadcrumb';
import FormGroupInfo from '../FormGroupInfo';
import FormGroupSchedule from '../FormGroupSchedule';
import FormGroupDetail from '../FormGroupDetail';
import FormGroupAddress from '../FormGroupAddress';

import './GroupConfig.scss';
import FormGroupMaterials from '../FormGroupMaterials/FormGroupMaterials';

const GroupConfig = props => {

  const { match, clientsReducer, groupsReducer, location: { state } } = props;
  
  const { clientId, groupId } = match.params;
  
  const { client } = clientsReducer;

  const { group } = groupsReducer;

  const [ activeKey, setActiveKey ] = useState(1);

  useEffect(_ => {
    if (clientId) {
      props.getClient(clientId);
    }
    return () => {
      props.history.replace({ state: {} });
    }
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='GroupConfig'>
      <Breadcrumb
        routes={[
          { label: 'Lista de clientes', url: `/clients` },
          { label: clientId ? client?.name : 'Detalle', url: `/clients/detail/${ clientId }` },
          { label: groupId ? 'Configurar grupo' : 'Nuevo grupo' }
        ]}
      />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Tabs
              defaultActiveKey={ activeKey }
              onChange={ setActiveKey }
              items={[
                {
                  value: 0,
                  key:'0',
                  label: 'Datos generales',
                  children: <FormGroupInfo { ...props }/>
                }, {
                  value: 1,
                  key:'1',
                  disabled: !groupId || group?.mode !== 2,
                  label: 'Direcciones',
                  children: <FormGroupAddress { ...props }/>
                }, {
                  value: 2,
                  key:'2',
                  disabled: !groupId,
                  label: 'Agenda',
                  children: <FormGroupSchedule { ...props }/>
                }, {
                  value: 3,
                  key:'3',
                  disabled: !groupId || (state?.statusGroup === 'completed'),
                  label: 'Profesor',
                  children: <FormGroupDetail { ...props }/>
                }, {
                  value: 4,
                  key:'4',
                  disabled: groupId && group?.level_id ? state?.statusGroup === 'completed' ? true : false : true,
                  label: 'Material',
                  children: <FormGroupMaterials { ...props }/>
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

GroupConfig.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getClient }, dispatch);

const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer'),
  groupsReducer: state.get('groupsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupConfig);
