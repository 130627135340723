import React from 'react';
import * as ACTION from "./types";
import moment from 'moment';
import { selectedRowKeys, tableSortProps } from '../../reducers/helpers/table';
import { StarOutlined, FlagOutlined, UsergroupAddOutlined, CalendarOutlined, UserOutlined } from '@ant-design/icons';

const dataState = {
    loading: { },
    showFilters: false,
    errors: { },
    languages: [ ],
    tags: [ ],
    data: { },
    page: 1,
    orderBy: {
        column: "name",
        order: "ASC"
    },
    form: {
        full_name: null,
        language: null,
        score: null,
        startDate: moment(),
        endDate: null,
        startTime: null,
        endTime: null,
        days: [],
        dateFilterType: 'range',
        period: null
    },
    filter: {
        full_name: "",
        languages: [],
        score: null,
        periods: [],
        teachers: [],
        teacherTypes: [],
        type: ''
    },
    allTeachers: [],
    selection: [],
    selectedRowKeys: [],
    scores: [
        { value: 1, label: '1 estrella' },
        { value: 2, label: '2 estrellas' },
        { value: 3, label: '3 estrellas' },
        { value: 4, label: '4 estrellas' },
    ],
    teacherTypes: [
        { label: 'Planta', value: 'planta' },
        { label: 'Freelance', value: 'freelance' },
    ],
    dateTypes: [
        { label: 'Rango de fechas', value: 'range' },
        { label: 'Fecha única', value: 'date' },
    ],
    days: [
        { label: 'Lunes', value: 1 },
        { label: 'Martes', value: 2 },
        { label: 'Miércoles', value: 3 },
        { label: 'Jueves', value: 4 },
        { label: 'Viernes', value: 5 },
        { label: 'Sábado', value: 6 },
    ]
};

const teachers = (state = dataState, action) => {
    let filter;
    switch (action.type) {
        case ACTION.LANGUAGES_LOADING:
            return { ...state, loading: { ...state.loading, languages: true } };
        case ACTION.LANGUAGES_ERROR:
            return { ...state, loading: { ...state.loading, languages: false }, errors: { ...state.errors, languages: action.error } };
        case ACTION.LANGUAGES_RECEIVE:
            let languages = [];
            action.result.data.result.map(item => {
                languages.push({
                    value: item.id,
                    label: item.name
                });
                return item;
            });
            return { ...state, loading: { ...state.loading, languages: null }, languages };
        
        case ACTION.ALL_TEACHERS_LOADING:
            return { ...state, loading: { ...state.loading, ALL_TEACHERS_LOADING: true } };
        case ACTION.ALL_TEACHERS_ERROR:
            return { ...state, loading: { ...state.loading, ALL_TEACHERS_LOADING: false }, errors: { ...state.errors, ALL_TEACHERS_LOADING: action.error } };
        case ACTION.ALL_TEACHERS_RECEIVE:
            return { ...state, loading: { ...state.loading, ALL_TEACHERS_LOADING: null }, allTeachers: action.result.data.result.map(item => ({ ...item, title: item.name })) };

        case ACTION.USERS_LOADING:
            return { ...state, loading: { ...state.loading, data: true } };
        case ACTION.USERS_ERROR:
            return { ...state, loading: { ...state.loading, data: false }, data: {}, errors: { ...state.errors, data: action.error } };
        case ACTION.USERS_RECEIVE:
            return {
                ...state,
                loading: { ...state.loading, data: null },
                data: action.result.data,
                selectedRowKeys: selectedRowKeys(action.result.data.result, state.selection)
            };
        case ACTION.UPDATE:
            if (action.single)
                return { ...state, filter: { ...state.filter, [action.name]: action.value }};
            else {
                state = { ...state, form: { ...state.form, [action.name]: action.value }}
                if (state.form.dateFilterType === 'date')
                    state.form.endDate = state.form.startDate;
                let period = {
                    days: state.form.days.length ? state.form.days : [],
                    time_start: state.form.startTime ? state.form.startTime.format('HH:mm:ss') : '',
                    time_end: state.form.endTime ? state.form.endTime.format('HH:mm:ss') : '',
                    time_start_format: state.form.startTime ? state.form.startTime.format('HH:mm a') : '',
                    time_end_format: state.form.endTime ? state.form.endTime.format('HH:mm a') : '',
                    date_start: state.form.startDate && state.form.endDate ? state.form.startDate.valueOf() : 0,
                    date_end: state.form.endDate ? state.form.endDate.valueOf() : 0,
                    date_start_format: state.form.startDate && state.form.endDate ? state.form.startDate.format('DD/MM/YYYY') : '',
                    date_end_format: state.form.endDate && state.form.dateFilterType !== 'date' ? state.form.endDate.format('DD/MM/YYYY') : '',
                }
                let days = (() => {
                    let output = '';
                    period.days.forEach(day => output += ' ' + state.days[day - 1].label)
                    if (output !== '')
                        output += ' ';
                    return output;
                })()
                if (days !== '' || period.date_start_format !== '' || period.date_end_format !== '' || period.time_start_format !== '' || period.time_end_format !== '') {
                    period.id = moment().valueOf();
                    period.label = `${ period.date_start_format }${ period.date_start_format && period.date_end_format ? ' - ' : '' }${ period.date_end_format } ${ days } ${ period.time_start_format } ${ period.time_start_format && period.time_end_format ? ' - ' : '' } ${ period.time_end_format }`;
                } else {
                    period = null;
                }
                return { ...state, form: { ...state.form, period} };
            }
        case ACTION.TOGGLE_FILTERS:
            return { ...state, showFilters: action.state };
        case ACTION.APPLY_FILTER:
            state.filter.score = state.form.score || state.filter.score;
            if (state.form.score) {
                state.tags = state.tags.filter(item => item.type !== 'score');
                state.tags.push({
                    label: state.form.score,
                    type: 'score',
                    clearAll: true,
                    icon: <StarOutlined />
                })
            }
            if (state.form.language) {
                state.filter.languages.push(state.form.language);
                state.tags.push({
                    ...state.languages.find(item => item.value === state.form.language),
                    type: 'languages',
                    id: state.form.language,
                    icon: <FlagOutlined />
                })
            }
            state.filter.type = state.filter.teacherTypes && state.filter.teacherTypes.length && state.filter.teacherTypes.length !== 2 ? state.filter.teacherTypes[0] : '';
            state.tags = state.tags.filter(item => item.type !== 'teacherTypes');
            if (state.filter.type && state.filter.type !== '') {
                state.tags.push({
                    ...state.teacherTypes.find(item => item.value === state.filter.type),
                    type: 'teacherTypes',
                    clearAll: true,
                    icon: <UsergroupAddOutlined />
                });
            }
            if (state.form.period) {
                state.filter.periods.push(state.form.period);
                state.tags.push({
                    label: state.form.period.label,
                    type: 'periods',
                    id: state.form.period.id,
                    icon: <CalendarOutlined />
                });
            }
            if (state.form.teachers) {
                state.filter.teachers.push(Number(state.form.teachers.id));
                state.tags.push({
                    label: state.form.teachers.label,
                    type: 'teachers',
                    id: state.form.teachers.id,
                    icon: <UserOutlined />
                });
            }
            return { ...state, form: {
                    language: null,
                    score: null,
                    startDate: moment(),
                    endDate: null,
                    startTime: null,
                    endTime: null,
                    days: [],
                    period: null,
                    dateFilterType: 'range'
                },
                page: 1
            };
        case ACTION.REMOVE_FILTER:
            let tags = state.tags;
            tags.splice(action.index, 1);
            filter = state.filter;
            if (action.item.clearAll) {
                if (action.item.type === 'teacherTypes')
                    filter.type = '';
                else if (action.item.type === 'score')
                    filter.score = null;
                filter[action.item.type] = dataState.filter[action.item.type];
            }
            else
                filter[action.item.type] = filter[action.item.type].filter(item => {
                    return item.id ? item.id !== action.item.id : item !== Number(action.item.id);
                });
            return { ...state, tags, filter, page: 1 };
        case ACTION.CLEAR_FILTERS:
            filter = state.filter;
            filter.full_name= '';
            filter.languages= [];
            filter.score= null;
            filter.periods= [];
            filter.teachers= [];
            filter.teacherTypes= [];
            filter.type= '';
            return { ...state, tags: [], selection: [], page: 1, filter }
        case ACTION.TABLE_PROPS:
            return { ...tableSortProps(state, action) };

        case ACTION.ROW_SELECTION:
            let selection = state.selection
                                 .filter(a => state.data.result.find(b => a.id === b.id) ? false : true) // clear current page's teachers before append new selection
                                 .concat(action.items);
            return { ...state,
                selection,
                selectedRowKeys: selectedRowKeys(state.data.result, selection)
            };
        default:
            return state;
    }
};

export default teachers;