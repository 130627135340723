import client from "../axios";

class ScheduleService {
  constructor() {
    this.client = client;
  }

  editSchedule(data, groupId, conflictCheck) {
    return client.patch(`v2/group/id/${groupId}/schedule?conflict-check=${conflictCheck}`, data);
  }

  getCatalogReasonsEditSchedule() {
    return client.get(`v2/group/reason-schedule`);
  }

  suspensionClass(data, groupId) {
    return client.patch(`v2/group/id/${groupId}/suspension-class`, data);
  }

  getCatalogReasonsSuspensionClass() {
    return client.get(`v2/group/reason-suspension`);
  }
}

export const scheduleService = new ScheduleService();
