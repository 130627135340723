import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { getStudyPlans, getLevels } from '../../../reducers/study-plans/actions';
import { createMaterial } from '../../../reducers/materials/actions';
import FormWrapper from '../../../components/custom/FormWrapper';
import { PlusOutlined } from '@ant-design/icons';
import Tabs from '../../../components/simple/Tabs';
import MaterialsState from '../MaterialsState/MaterialsState';

import './FormMaterials.scss';

const { Option } = Select;

const FormMaterials = props => {

  const { studyPlanReducer, materialsReducer, isRequiereAllValues = true } = props;
  
  const { catalog : studyPlansCatalog, levels : studyPlanLevels, loading: studyPlanLoading } = studyPlanReducer;

  const { loading: materialsLoading } = materialsReducer;

  const [ values, setValues ] = useState({ });
  
  const [ activeTab, setActiveTab ] = useState(1);

  const [ form ] = Form.useForm();

  const onChange = formValue => {

    const spid = formValue?.course_id;

    if (spid) {
      props.getLevels(spid);
    }

    setValues({
      ...values,
      ...formValue
    });
  }

  const onFinish = () => {
    props.createMaterial(values).then(() => {
      setActiveTab(form.getFieldValue('course_id'));
      form.setFieldsValue({
        course_id: null,
        level_id: null,
        name: null
      });
      setValues({ });
    })
  }

  useEffect(_ => {
    props.getStudyPlans();
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='FormMaterials'>
      <FormWrapper
        { ...props }
        name='material-form'
        form={ form }
        initialValues={ values }
        onValuesChange={ onChange }
        onFinish={ onFinish }
        hideCancel
        saveButtonText='Agregar'
        saveButtonProps={{
          icon: <PlusOutlined />
        }}
        loading={ materialsLoading.CREATE_MATERIAL?.state }
      >
        <Form.Item>
          <Form.Item
            className='inline'
            label='Curso'
            name='course_id'
            rules={[
              { required: isRequiereAllValues },
            ]}
          >
            <Select loading={ studyPlanLoading.GET_STUDY_PLAN_LEVELS?.state } placeholder='Seleccionar el curso'>
              { (studyPlansCatalog || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.name }</Option>) }
            </Select>
          </Form.Item>
          <Form.Item
            className='inline'
            label='Agregar un nivel'
            name='level_id'
            rules={[
              { required: isRequiereAllValues },
            ]}
          >
            <Select disabled={ !values.course_id } loading={ studyPlanLoading.GET_STUDY_PLAN_LEVELS?.state } placeholder='Seleccionar el curso'>
              { (studyPlanLevels || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.name }</Option>) }
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label='Nombre del material'
          name='name'
          rules={[
            { required: isRequiereAllValues },
          ]}
        >
          <Input placeholder='Escribe el nombre del material'/>
        </Form.Item>
      </FormWrapper>
      <Tabs
        defaultActiveKey={ activeTab }
        activeKey={ activeTab.toString() }
        onChange={ setActiveTab }
        destroyInactiveTabPane={ true }
        items={ 
          (studyPlansCatalog || [ ]).map((item, index) => ({
            value: item.id,
            key: index,
            label: item.name,
            children: <MaterialsState courseId={ item.id } refresh={ materialsLoading.CREATE_MATERIAL?.state }/>
          }))
        }
      />
    </div>
  )
}

FormMaterials.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({
  getStudyPlans,
  getLevels,
  createMaterial
}, dispatch);

const mapStateToProps = state => ({
  settingsReducer: state.get('settingsReducer'),
  studyPlanReducer: state.get('studyPlanReducer'),
  materialsReducer: state.get('materialsReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormMaterials);
