import client from '../axios';

class MaterialsService {
  constructor() {
    this.client = client;
  }
  
  createMaterial = data => client.post(`/v2/material`, [ data ]);

  getActiveMaterials = (cid, filters, page, orderBy, searchQuery) => client.get(`/v2/material/course/${ cid }/active`, {
    params: {
      filters: {
          ...(filters || {}),
          name: searchQuery || null
      },
      pagination: {
          page,
          limit: 10,
          orderBy: orderBy?.column,
          sort: orderBy?.order
      }
    }
  });

  getInactiveMaterials = (cid, filters, page, orderBy, searchQuery) => client.get(`/v2/material/course/${ cid }/inactive`, {
    params: {
      filters: {
          ...(filters || {}),
          name: searchQuery || null
      },
      pagination: {
          page,
          limit: 10,
          orderBy: orderBy?.column,
          sort: orderBy?.order
      }
    }
  });

  getMaterialsByLevel = lid => client.get(`/v2/material/level/${ lid }`);

  getGroupMaterials = gid => client.get(`/v2/material/group/${ gid }`)
  
  getMaterialsRecord(gid, range={}, filters, page, orderBy) {
    return client.get(`/v2/material/group/${ gid }/historic`, {
      params: {
        filters: {
            start_date: range.startdate,
            end_date: range.enddate,
            ...filters
        },
        pagination: {
            page,
            limit: 10,
            orderBy: orderBy.column,
            sort: orderBy.order
        }
      }
    })
  }

  activateMaterials = materials => client.put(`/v2/material/active`, {
    materials
  });

  inactivateMaterial = material_id => client.put(`/v2/material/inactive`, {
    material_id
  });
}

export const materialsService = new MaterialsService();
