import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Menu, Dropdown, Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import './FixedAvatar.scss';
import { userService } from '../../services/user.service'

function FixedAvatar(props) {

  const [ redirect, setRedirect ] = useState(false);

  const logout = () => {
    userService.logout();
    setRedirect(true);
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={logout}>Cerrar sesión</Menu.Item>
    </Menu>
  );

  if(redirect){
    return (
      <Redirect to='/login' />
    )
  }

  const session = localStorage.getItem('@SPEAK::SESSION');
  const userName = session ? JSON.parse(session).data.result.name : '';
  return (
    <div className='FixedAvatar'>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className='FixedAvatar__block'>
          <Avatar icon={ <UserOutlined/> } />
          {userName}
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  );
}

export default FixedAvatar;
