import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './actions';
import './LockForm.scss';
import { DatePicker, Switch, Row, Radio, Col } from 'antd'
import Button from '../../components/simple/Button';
import Select from '../../components/simple/Select';

function LockForm(props) {
    let { state, update, updateRange, saveLock, selection, done, back } = props;
    let {
        lockRange,
        form,
        loading,
        errors
    } = state;
    const setTeacher = () => {
        if (selection.length === 1)
            update('teacher')(selection[0].id ? selection[0].id : selection[0])
    };
    let save = (ignoringConflicts) => {
        let days;
        if (form.repeatType === 'week')
          days = [1, 2, 3, 4, 5, 6, 7];
        else {
          let weekday;
          if (lockRange.start.format('dddd') === 'domingo') {
            weekday = 1;
          } else {
            weekday = lockRange.start.weekday() + 2;
          }
          days = [ weekday ];
        }
        saveLock(form.teacher, {
            startDatetime: lockRange.start.valueOf(),
            endDatetime: lockRange.end.valueOf(),
            isRepeatable: form.repeat,
            days,
            ignoringConflicts
        }).then(done);
    }
    useEffect(() => {
        setTeacher();
        // eslint-disable-next-line
    },[]);
    if (errors.lock && errors.lock.type === "speak.application") {
        return (
            <div className='LockForm'>
                <h1>No es posible bloquear las siguientes fechas porque tienen clases agendadas</h1>
                { errors.lock.message.replace('[', '').replace(']', '').replace(/'/g, '').split(',').map((item, index) => {
                    return (
                        <p key={index}>
                            <i className="material-icons">calendar_today</i>
                            <span>{ item }</span>
                        </p>
                    )
                }) }
                <Row>
                    <Button onClick={back} type="primary" block loading={ loading.lock }>Regresar</Button>
                </Row>
                <Row>
                    <Button onClick={() => save(true)} type="link" block loading={ loading.lock }>Omitir fechas y guardar</Button>
                </Row>
            </div>
        )
    }
    return (
        <div className='LockForm'>
            <h1>Crear bloqueo</h1>
            { selection.length > 1 && (
                <Row>
                    <Select items={selection.map(item => ({
                        ...item,
                        value: item.id,
                        label: item.name
                    }))} selectProps={{
                        placeholder: "Selecciona un profesor",
                        onChange: update('teacher'),
                        value: form.teacher
                    }}/>
                </Row>
            )}
            <Row>
                <label>Todo el día</label>
                <Switch checked={ form.allDay } onChange={update('allDay')} />
            </Row>
            <Row>
                <DatePicker.RangePicker 
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={['Inicia', 'Termina']}
                    value={[ lockRange.start, lockRange.end ]}
                    disabled={form.allDay}
                    separator='-'
                    className='range-picker'
                    dropdownClassName='range-picker'
                    onChange={value => {
                        updateRange('start')(value[0]);
                        updateRange('end')(value[1]);
                    }}
                    onOk={value => {
                        updateRange('start')(value[0]);
                        updateRange('end')(value[1]);
                    }}
                />
            </Row>
            <div className={`repeat-group ${ form.repeat && 'fill' }`}>
                <Row>
                    <label>Repetir</label>
                    <Switch checked={ form.repeat } onChange={update('repeat')} />
                </Row>
                { form.repeat && (
                    <Row>
                        <Col>
                            <Radio.Group name='repeatType' value={form.repeatType} onChange={input => {
                                update('repeatType')(input.target.value);
                            }} options={[
                                {
                                    label: `Todos los ${ lockRange.start.format('dddd') } en el mismo horario`,
                                    value: 'day'
                                }, {
                                    label: 'Todos los días en el mismo horario',
                                    value: 'week'
                                }
                            ]}/>
                        </Col>
                    </Row>
                )}
            </div>
            <Row>
                <Button onClick={() => save(false)} type="primary" loading={ loading.lock ? true : false }>Guardar</Button>
            </Row>
        </div>
    );
}

LockForm.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
    state: state.get('teacher')
});

export default connect(mapStateToProps, mapDispatchToProps)(LockForm);
