import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getClients, tableProps } from '../../reducers/clients/actions';
import { Row, Col, Card } from 'antd';
import TyphographyTitle from '../../components/simple/TypographyTitle'; 
import ClientList from './ClientList';
import { PlusOutlined } from '@ant-design/icons';
import IconButton from '../../components/simple/IconButton/IconButton';
import SearchInput from '../../components/simple/SearchInput';

import './ClientsPage.scss';

const ClientsPage = props => {

  const [ searchQuery, setSearchQuery ] = useState();
  
  const { clientsReducer } = props;

  const { filter, page, orderBy, clients } = clientsReducer;
  
  const update = (p) => {
    props.getClients(filter, p || page.clientList, orderBy.clientList, searchQuery);
  };

  useEffect(() => {
    update();
  }, [ searchQuery ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='ClientsPage'>
      <TyphographyTitle level={4}>Lista de clientes</TyphographyTitle>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <SearchInput onChange={ setSearchQuery } placeholder='Buscar por cliente'/>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <IconButton
                  label='Nuevo cliente'
                  onClick={() => {
                    props.history.push(`/clients/new`);
                  }}
                  icon={ <PlusOutlined /> }
                />
              </Col>
            </Row>
            <ClientList
              onChange={(pager, filter, sort) =>
                props.tableProps(pager.current, sort, 'clientList').then(() => {
                  update(pager.current)
                })
              }
              data={ clients }
              searchQuery={ searchQuery }
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

ClientsPage.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getClients, tableProps }, dispatch);
const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);
