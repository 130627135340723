import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { 
  getIncompleteGroups, 
  setIncompleteGroupsSelection, 
  setOneIncompleteGroupSelection, 
  activateGroups, 
  tableProps,
  getCompletedGroups,
} from '../../../reducers/groups/actions';
import { withRouter } from 'react-router-dom';
import Table from '../../../components/simple/Table';
import moment from 'moment';
import { ArrowRightOutlined, CheckCircleFilled } from '@ant-design/icons';
import IconButton from '../../../components/simple/IconButton/IconButton';
import ActivateGroupButton from './ActivateGroupButton';
import { fakeSort } from '../../../utils/table';

import './IncompleteGroupList.scss';
import Modal from '../../../components/simple/Modal';
import EvidenceActivateModal from './ModalActivateEvidence/EvidenceActivateModal';

function IncompleteGroupList(props) {
  
  let { searchQuery, clientId, groupsReducer, tableProps } = props;

  const { incompleteGroups, orderBy, page, selectedIncompleteGroupRowKeys, selectedIncompleteGroups, loading } = groupsReducer;

  const [ captureEvidenceVisible, setCaptureEvidenceVisible ] = useState(false);

  const [ ListFiles, setListFiles ] = useState([]);

  const update = (p) => {
    props.getIncompleteGroups(clientId, p || page.incompleteGroups, orderBy.incompleteGroups, searchQuery);
    props.getCompletedGroups(clientId, p || page?.completedGroups, orderBy?.completedGroups, searchQuery);
    setCaptureEvidenceVisible(false);
  };

  useEffect(update, [ searchQuery ]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: 'Nombre grupo',
      dataIndex: 'name',
      ...fakeSort
    },
    {
      title: 'Fecha de inicio',
      dataIndex: 'initial_date',
      render: date => date ? moment(date).format('DD/MM/YYYY') : 'No especificado',
      ...fakeSort
    },
    {
      title: 'Curso',
      dataIndex: 'course',
      render: value => value || 'No especificado',
    },
    {
      title: 'Nivel',
      dataIndex: 'level',
      render: value => value || 'No especificado',
    },
    {
      title: 'Profesor',
      dataIndex: 'teacher',
      render: value => value || 'No especificado',
    },
    {
      title: 'Validado',
      dataIndex: 'is_ready',
      align: 'center',
      render: value => <CheckCircleFilled className={ !!value && 'active' } />
    },
    {
      align: 'right',
      render: (name, { id }) => <IconButton
        onClick={() => {
          props.history.push(`/clients/${ clientId }/group/${ id }`);
        }}
        className='secondary'
        icon={ <ArrowRightOutlined /> }
      />
    }
  ];

  const selectedListLength = (selectedIncompleteGroupRowKeys || [ ]).length;

  const onActiveGroup = () => {
    props.activateGroups(selectedIncompleteGroups.map(item => item.id), ListFiles).then(update);
  };
  
  return (
    <div className='IncompleteGroupList'>
      { props.children }
      <Table
        { ...props }
        onChange={(pager, filter, sort) => 
          tableProps(pager.current, sort, 'incompleteGroups').then(() => {
            update(pager.current)
          })
        }
        rowSelection = {{
          type: 'radio',
          onChange: (...selectionParams) => {
            props.setOneIncompleteGroupSelection(...selectionParams);
          },
          getCheckboxProps: record => ({
            disabled: !record.is_ready,
            name: record.name,
          }),
          selectedRowKeys: selectedIncompleteGroupRowKeys
        }}
        loading={ !!groupsReducer.loading.GET_INCOMPLETE_GROUPS?.state }
        columns={ columns }
        data={ incompleteGroups }
        rightAction={ 
          <ActivateGroupButton 
            onSubmitted={ update } 
            value={ selectedIncompleteGroups } 
            currentSelected={ selectedIncompleteGroupRowKeys } 
            disabled={ !selectedListLength }
            submit={ () => setCaptureEvidenceVisible(true) }
          /> 
        }
      />

      {
        captureEvidenceVisible && 
        <Modal
          visible={ captureEvidenceVisible }
          modalProps={{
            placement: "left",
            closable: false,
            onOk: onActiveGroup,
            onCancel: () => setCaptureEvidenceVisible(false),
            cancelText: 'Regresar',
            okText: 'Activar grupo',
            title: 'Activar grupo',
            confirmLoading: loading?.ACTIVATE_GROUPS?.state,
            okButtonProps: {
              disabled: !ListFiles.length
            }
          }}
          content={ <EvidenceActivateModal onListFiles={ files => setListFiles(files) } /> }
        />
      }
    </div>
  );
}

export default withRouter(connect((state) => ({
  groupsReducer: state.get('groupsReducer')
}), (dispatch) => {
  return bindActionCreators({ 
    tableProps, 
    getIncompleteGroups, 
    setIncompleteGroupsSelection, 
    setOneIncompleteGroupSelection, 
    activateGroups,
    getCompletedGroups,
  }, dispatch);
})(IncompleteGroupList));