import React from 'react'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../reducers/group-cancellation/actions';

import TableContainer from '../../../components/custom/TableContainer'
import './Cancelations.scss';
import columns from '../../TeacherDetail/table.settings'

function TableCancelations(props){
  let { match, state } = props;
  let {
    loading,
  } = state;
  return(
    <TableContainer downloadDisabled={ true } entity={ match.params } loading={ loading.CANCELLATIONS && loading.CANCELLATIONS.state } name='cancellations' columns={ columns } { ...props }/>
  );
}

TableCancelations.propTypes = {}

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  state: state.get('groupCancellationReducer'), 
});

export default connect(mapStateToProps, mapDispatchToProps)(TableCancelations);
