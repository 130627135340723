import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Avatar, Row, Col } from 'antd';
import { WarningFilled, CalendarOutlined, IdcardOutlined, UserOutlined } from '@ant-design/icons';
import * as Actions from './actions';
import Select from '../../components/simple/Select';
import ModalClosable from '../../components/custom/ModalClosable';
import './TicketForm.scss';
  
function TicketForm(props) {
    let { tickets, getCoordinatorsOptions, getStatusOptions, data, updateTicket, onChange, visible } = props;
    let { coordinators, status, loading } = tickets;
    let [ responsable, setResponsable ] = useState();
    let [ state, setState ] = useState();
    
    loading = loading.UPDATE_TICKET && loading.UPDATE_TICKET.state;

    useEffect(() => {
        getCoordinatorsOptions();
        getStatusOptions();
        if (visible) {
            setResponsable(data.responsable.uid);
            setState(data.status.id);
        }
        // eslint-disable-next-line
    }, [ visible ])

    useEffect(() => {
        if (responsable !== undefined && state !== undefined)
            updateTicket(data.id, responsable, state).then(onChange)
            // eslint-disable-next-line
    }, [responsable, state])

    return (
        <ModalClosable
            width={500}
            wrapClassName='TicketForm'
            { ...props }
        >
            <Row className='head'>
                <Avatar size={56} icon={ <UserOutlined/> }/>
                <Col>
                    <label className='bold'>Responsable</label>
                    <Select
                        selectProps={{
                            loading,
                            placeholder: 'Sin asignar',
                            onChange: value => setResponsable(value || 0),
                            value: responsable,
                            allowClear: true
                        }}
                        items={coordinators}
                    />
                </Col>
                <Col>
                    <label className='bold'>Estado</label>
                    <Select
                        selectProps={{
                            loading,
                            placeholder: 'Sin atender',
                            onChange: value => setState(value),
                            value: state
                        }}
                        items={status}
                    />
                </Col>
            </Row>
            <Row className='body'>
                <Col>
                    <h3>{ data.type }</h3>
                    <label className='bold'><WarningFilled style={{ color: data.priority.style }}/>{ data.priority.value }</label>
                    <label><CalendarOutlined/>{ data.createdDate } <span>{ data.fromNow }</span></label>
                    <label><IdcardOutlined/>{ data.client.name }</label>
                    { data.teacher && <label><UserOutlined/>{ data.teacher.name }</label>}
                    { data.group && <label>{ data.group.name }</label>}
                    <p>{ data.description }</p>
                </Col>
            </Row>
        </ModalClosable>
    );
}

export default connect((state) => ({
    tickets: state.get('tickets')
}), (dispatch) => {
    return bindActionCreators(Actions, dispatch);
})(TicketForm);