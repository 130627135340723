import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CheckCircleFilled, CloseCircleFilled, ArrowRightOutlined } from '@ant-design/icons';
import * as Actions from './actions';
import { withRouter } from 'react-router-dom';
import Table from '../../components/simple/Table';
import ScoreStarts from '../../components/simple/ScoreStarts';
import Flags from '../../components/simple/Flags';
import './TableTeachers.scss';
import IconButton from '../../components/simple/IconButton/IconButton';
import { fakeSort } from '../../utils/table';

function TableTeachers(props) {
  
  let { data, loading, onChange, rowSelection, availability } = props;

  const [ columns, setColumns ] = useState([]);

  useEffect(() => {
    let dynamicColumns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: state => (
          <div>{state}</div>
        ),
        ...fakeSort
      }, {
        title: 'Idiomas',
        dataIndex: 'languages',
        key: state => (
          <div>{state}</div>
        ),
        render: value => <Flags value={value}/>
      }, {
        title: 'Horas',
        dataIndex: 'totalHoursOfMonth',
        render: totalHoursOfMonth => <div style={{ textAlign: 'center' }}>{ totalHoursOfMonth }</div>,
        ...fakeSort,
      }, {
        title: 'Bloqueos',
        dataIndex: 'hasUnavailableEvents',
        ...fakeSort,
        render: hasUnavailableEvents => <div style={{ color: hasUnavailableEvents ? '#D75F77' : '#c3c3c3', textAlign: 'center' }}>{ hasUnavailableEvents ? <CheckCircleFilled/> : <CloseCircleFilled/> }</div>
      }, {
        title: 'Calificación',
        dataIndex: 'score',
        key: state => (
          <div>{state}</div>
        ),
        render: value => <ScoreStarts numberOfStars={value}/>,
        ...fakeSort,
      }, {
        title: 'Tipo',
        dataIndex: 'type',
        key: state => (
          <div>{state}</div>
        ),
      }, {
        title: 'Teléfono',
        dataIndex: 'phone',
        key: state => (
          <div>{state}</div>
        ),
      }, {
        title: 'E-mail',
        dataIndex: 'email',
        key: state => (
          <div>{state}</div>
        ),
      },
      {
        align: 'right',
        render: (name, { id }) => <IconButton
          onClick={() => {
            props.history.push(`/teachers/detail/${ id }`);
          }}
          className='secondary'
          icon={ <ArrowRightOutlined /> }
        />
      }
    ];
    if (availability) {
      dynamicColumns.splice(2, 0,  {
        title: 'Disponibilidad %',
        dataIndex: 'percentage',
        key: state => (
          <div>{state}</div>
        ),
        ...fakeSort,
      });
    }

    setColumns(dynamicColumns);
    
  }, [ availability, props.history ])
  return (
    <div className='TableTeachers'>
      { props.children }
      <Table
        onChange={onChange}
        rowSelection={rowSelection}
        columns={columns}
        loading={loading.data ? true : false}
        data={data}
      />
    </div>
  );
}

export default withRouter(connect((state) => {
  state = state.get('teachers');
  return ({
    loading: state.loading,
  });
}, (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(TableTeachers));