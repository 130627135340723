import React, { useEffect, useState } from 'react';
import Select from '../../../components/simple/Select';
import Button from '../../../components/simple/Button';
import DateRange from '../../../components/custom/DateRange';
import { Col, Row } from 'antd';
import './AddForm.scss';
import InputFile from '../../../components/simple/InputFile/InputFile';
import ItemFileList from '../../../components/custom/ItemFileList/ItemFileList';
import InputButton from '../../../components/custom/InputButton/InputButton';
import { validationFile } from '../../../utils/validationFile';
import { useManageFile } from '../../../customhooks';

function AddForm(props) {
  const {
    classReducer,
    client,
    group,
    nid,
    getGroupStudents,
    saveStudents,
    students
  } = props,
  {
    groupStudents,
    loading
  } = classReducer,
  [
    formData,
    setFormData
  ] = useState({
    students_id: [ ],
    range: null,
    attachments: [ ],
  });

  const {
    currentFile,
    currentFileName,
    listFile,
    setCurrentFileName,
    setListFile,
    handleInputFile,
    handleInputFilePaste,
    checkNameFile
  } = useManageFile({});

  const updateForm = name => value => {
    setFormData({
      ...formData,
      [name]: value
    })
  },
  save = () => {
    formData.students_id = formData.students_id.map(i => Number(i));
    saveStudents(client.id, group.id, { ...formData, attachments: listFile }).then(() => {
      if (!loading.SAVE_STUDENTS) {
        props.close();
      }
    })
  };

  useEffect(() => {
    getGroupStudents(client.id, nid);
    // eslint-disable-next-line
  },[ ]);
  return (
    <div className="AddForm">
      <h1>Dar de alta alumnos</h1>
      <Col>
        <Row>
          <label>Alumno(s)</label>
          <Select
              selectProps={{
                  mode: 'multiple',
                  placeholder: 'Buscar alumno',
                  onChange: items => updateForm('students_id')(items.map(i => Number(i.split('-')[0])))
              }}
              items={groupStudents?.result ? (
                groupStudents.result
                  .filter(item => !students.find(s => s.id === item.id) || students.find(s => s.id === item.id).isAvailableToRegister )
                  .map(item => ({
                      ...item,
                      value: `${ item.nid }-${ item.name }`,
                      label: item.name
                    })
                  )
              ) : []}
          />
        </Row>
      </Col>
      <DateRange setToday={true} rows={true} update={updateForm('range')} labels={true}/>
      <Col>
        <Row>
          <label>Subir evidencia</label>
          <InputFile 
            accept=".png,.jpg,.pdf" 
            beforeUpload={validationFile}
            disabled={listFile.length >= 5 ? true : false}
            customRequest={(data) => handleInputFile(data)}
            showUploadList={false}
            handlePaste={handleInputFilePaste}
          />
        </Row>
      </Col>

      {
        currentFile &&
        <InputButton
          currentValue={currentFileName}
          setCurrentValue={setCurrentFileName}
          onClickButton={checkNameFile}
          title="Cambia el nombre de la imagen y valídala dando click al icono"
        />
      }

      {
        listFile.map((data, index) => 
          <ItemFileList
            key={data.uid || index}
            base64={data.receipt}
            fileType={data.mime_type}
            uid={data.uid}
            nameFile={data.name}
            listFile={listFile}
            onRemove={setListFile}
          />
        ) 
      }
      
      { loading.SAVE_STUDENTS && !loading.SAVE_STUDENTS.state && (
        <Row>
          <span className="message">{ loading.SAVE_STUDENTS?.detail?.response?.data ? loading.SAVE_STUDENTS.detail.response.data?.message : 'Tiempo de espera excedido.' }</span>
        </Row>
      )}
      <Button loading={ loading.SAVE_STUDENTS && loading.SAVE_STUDENTS.state ? true : false } onClick={save} type={'primary'}>Aceptar</Button>
    </div>
  );
}


export default AddForm;
