import * as ACTION from "./types";

const initialState = {
  loading: {},
  errors: {},
  detail: {}
};

const reducer = (state = initialState, action) => {
  switch(action.type){
    case ACTION.EXAM_LOADING:
      return { ...state, loading: { ...state.loading, EXAM_LOADING: { status: true} }};
    case ACTION.EXAM_ERROR:
      return { ...state, loading: { ...state.loading, EXAM_LOADING: undefined }, errors: { ...state.errors}};
    case ACTION.EXAM_RECEIVE:
      return { ...state, detail: action.result.data.result, loading: { ...state.loading, EXAM_LOADING: { state: false  } } };
    default:
      return state;
  }
}

export default reducer;
