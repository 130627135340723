import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormTariffs from '../../../components/custom/FormTariffs';
import { getClientTariffs, saveClientTariffs, deleteClientTariff, ignoreClientTariffConflicts } from '../../../reducers/settings/actions';

const FormClientTariffs = props => {
  
  const { settingsReducer } = props;

  const { clientTariffs, loading, clientTariffConflicts } = settingsReducer;
  
  const getClientEditRoute = client => `/clients/edit/${ client.id }`;

  return (
    <div className='FormClientTariffs'>
      <FormTariffs
        { ...props }
        inputLabel='Agregar tarifas clientes'
        getConflictConfirmationTitle={tariff => `La tarifa ${ tariff } no puede eliminarse porque se encuentra asignada a los siguientes clientes`}
        getEditRoute={ getClientEditRoute }
        saveTariffs={ props.saveClientTariffs }
        deleteTariff={ props.deleteClientTariff }
        ignoreTariffConflicts={ props.ignoreClientTariffConflicts }
        getTariffs={ props.getClientTariffs }
        tariffs={ clientTariffs }
        tariffConflicts={ clientTariffConflicts?.clients }
        loadingGet={ loading.GET_CLIENT_TARIFFS?.state }
        loadingSave={ loading.SAVE_CLIENT_TARIFFS?.state }
        loadingDelete={ loading.DELETE_CLIENT_TARIFF?.state }
      />
    </div>
  )
}

FormClientTariffs.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({
  getClientTariffs,
  saveClientTariffs,
  deleteClientTariff,
  ignoreClientTariffConflicts
}, dispatch);

const mapStateToProps = state => ({
  settingsReducer: state.get('settingsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(FormClientTariffs);
