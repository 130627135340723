import * as ACTION from './types';

import { incidentsService } from '../../services/incidents.services';

export function getIncidents(range, filters, page, orderBy) {
  return {
    types: [
      ACTION.LOADING_INCIDENTS,
      ACTION.RECEIVE_INCIDENTS,
      ACTION.ERROR_INCIDENTS,
    ],
    promise: incidentsService.getIncidents(range, filters, page, orderBy)
  }
}

export function tableProps(name, page, sort) {
  return {
    type: ACTION.TABLE_PROPS,
    name,
    page,
    sort
  }
}