import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './actions';
import './Tabs.scss';
import Tabs from '../../components/simple/Tabs';
import Table from './Table';
import Calendar from './Calendar';

function TeacherTabs(props) {
    let {
      teacher,
      setActiveKey,
    } = props;
    let {
      loading,
      activeKey,
    } = teacher;
    return (
        <Tabs
          defaultActiveKey={activeKey}
          items={[
            { value: 0, key:'0', label: 'Calendario', children: <Calendar { ...props }/>},
            { value: 1, key:'1', label: 'Cancelaciones', children: <Table { ...props } name='cancellations' loading={ loading.CANCELLATIONS && loading.CANCELLATIONS.state }/> },
            { value: 2, key:'2', label: 'Retardos', children: <Table { ...props } name='delays' loading={ loading.DELAYS && loading.DELAYS.state }/> },
            { value: 3, key:'3', label: 'Calificaciones', children: <Table { ...props } name='scores' loading={ loading.SCORES && loading.SCORES.state }/> },
            { value: 4, key:'4', label: 'Uso App', children: <Table { ...props } name='issues' loading={ loading.ISSUES && loading.ISSUES.state }/> }
          ]}
          onChange={setActiveKey}
        />
    );
}

TeacherTabs.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
    teacher: state.get('teacher'),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherTabs);
