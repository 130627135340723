import React, { useState } from 'react';
import { Divider } from 'antd';

import './ClassDetail.scss';
import Gmaps from '../../custom/Gmaps';

function ClassDetail(props) {
  const { clientName, classGroup, scheduleTime, teacher, language, students, event, latitude, longitude, address, isVirtualClass } = props;
  const { id, type } = event;

  const [ itemsVisibles, setItemsVisibles ] = useState({
    number: 3,
    visible: false,
  });

  function selectTypeEvent(type) {
    const json = {
      1: 'Observación programada',
      2: 'Presentación programada',
      3: 'Clase muestra programada',
    }
    return json[type];
  }

  function defineAssintance(students, limit) {
    const long = students ? students.length : 0;
    const data = students ? students.slice(0, limit) : [];
    const items = data.map((student, index) => <label key={ index }>{student.name.toLowerCase()} <span className='attendance'>{student.attendance}</span></label>);
    return (
      <div className='ClassDetail__event__list'>
        {items}
        { long > limit
          ? <span onClick={toggleList(long)}>Ver más</span>
          : ( long > 3 ? <span onClick={toggleList(long)}>Ver menos</span> : null )
        }
      </div>
    );
  }

  const toggleList = (total) => () => {
    if(itemsVisibles.visible === false){
      setItemsVisibles({ number: total, visible: true });
      return null;
    }
    setItemsVisibles({ number: 3, visible: false });
  }

  return (
    <div className='ClassDetail'>

      <div className='ClassDetail__date'>
        <i className="material-icons">card_travel</i>
        <label>{clientName} {classGroup}</label>
      </div>

      <div className='ClassDetail__date'>
        <i className="material-icons">schedule</i>
        <label>{scheduleTime}</label>
      </div>

      {isVirtualClass && (
        <div className='ClassDetail__date'>
          <i className="material-icons">videocam</i>
          <label>Videoconferencia</label>
        </div>
      )}

      <div className='ClassDetail__date'>
        <i className="material-icons">person_outline</i>
        <label>{teacher}</label>
      </div>

      <div className='ClassDetail__date'>
        <i className="material-icons">chat_bubble_outline</i>
        <label>{language}</label>
      </div>
      { !!(address || [ ]).length && (
        <div className='ClassDetail__date'>
          <a target="blank" href={`https://www.google.com/maps?q=${latitude},${longitude}`}>
            <i className="material-icons">location_on</i>
            <label>{address}</label>
          </a>
        </div>
      )}
      { latitude !== 0 && longitude !== 0 && (
        <div className='ClassDetail__map'>
          <Gmaps
            markers={[
              { latitude, longitude },
            ]}
          />
        </div>
      )}
      {
        id !== 0
        ? <div className='ClassDetail__event'>
            <Divider />
            <div className='ClassDetail__event__type'>
              <span />
              <label>{selectTypeEvent(type)}</label>
            </div>
            <div className='ClassDetail__event__info'>
              <label>{event.teacher}</label>
              <i className="material-icons">edit</i>
            </div>
          </div>
        : null
      }
      {
        !!students &&
        <div className='ClassDetail__event'>
          <Divider />
          <div className='ClassDetail__event__type'>
            <i className="material-icons">people</i>
            <label>Alumnos registrados</label>
          </div>
          {
            defineAssintance(students, itemsVisibles.number)
          }
        </div>
      }
    </div>
  );
}

export default ClassDetail;
