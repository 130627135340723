import * as ACTION from './types';
import { classesService } from '../../services/classes.service'
import { teacherService } from '../../services/teacher.service'

export function getAgenda(teachers, range) {
  return {
    types: [
      ACTION.LOADING,
      ACTION.RECEIVE,
      ACTION.ERROR,
    ],
    promise: classesService.getAgenda(teachers, range)
  }    
}

export function getTeacher(id) {
  return {
    types: [
      ACTION.TEACHER_LOADING,
      ACTION.TEACHER_RECEIVE,
      ACTION.TEACHER_ERROR,
    ],
    promise: classesService.getTeacher(id)
  }
}

export function changeWeek(data) {
  return {
    type: ACTION.ADD_DATA,
    data
  }
}

export function setLockRange(range) {
  return {
    type: ACTION.LOCK_RANGE,
    range
  }
}


export function setRange(name, value) {
  return {
    type: ACTION.SET_RANGE,
    name,
    value
  }
}

export function toggleLockForm() {
  return {
    type: ACTION.TOGGLE_LOCK_FORM
  }
}

export function update(name) {
  return dispatch => {
    return value => {
      dispatch({
        type: ACTION.UPDATE_PROPERY,
        name,
        value
      })
    }
  }
}

export function updateRange(name) {
  return dispatch => {
    return value =>{
      dispatch({
        type: ACTION.UPDATE_RANGE,
        name,
        value
      })
    }
  }
}

export function setActiveKey(key) {
  return {
    type: ACTION.ACTIVE_KEY,
    key
  }
}

export function tableProps(name, page, sort) {
  return {
    type: ACTION.TABLE_PROPS,
    name,
    page,
    sort
  }
}

export function saveLock(id, data) {
  return {
    types: [
      ACTION.LOCK_LOADING,
      ACTION.LOCK_RECEIVE,
      ACTION.LOCK_ERROR,
    ],
    promise: classesService.saveLock(id, data)
  }
}

export function back() {
  return {
    type: ACTION.CLEAR_ERROR
  }
}

export function removeLock(id, event, all) {
  return {
    types: [
      ACTION.REMOVE_LOCK_LOADING,
      ACTION.REMOVE_LOCK_RECEIVE,
      ACTION.REMOVE_LOCK_ERROR,
    ],
    promise: classesService.removeLock(id, event, all)
  }
}

export function setColors(teachers) {
  return {
    type: ACTION.SET_COLORS,
    teachers
  }
}

export function getDelays(range, filters, page, orderBy, teacherId) {
  return {
    types: [
      ACTION.LOADING_DELAYS,
      ACTION.RECEIVE_DELAYS,
      ACTION.ERROR_DELAYS,
    ],
    promise: teacherService.getDelays(teacherId, range, filters, page, orderBy)
  }
}

export function getCancellations(range, filters, page, orderBy, teacherId) {
  return {
    types: [
      ACTION.LOADING_CANCELLATIONS,
      ACTION.RECEIVE_CANCELLATIONS,
      ACTION.ERROR_CANCELLATIONS,
    ],
    promise: teacherService.getCancellations(teacherId, range, filters, page, orderBy)
  }
}

export function getScores(range, filters, page, orderBy, teacherId) {
  return {
    types: [
      ACTION.LOADING_SCORES,
      ACTION.RECEIVE_SCORES,
      ACTION.ERROR_SCORES,
    ],
    promise: teacherService.getScores(teacherId, range, filters, page, orderBy)
  }
}

export function getIssues(range, filters, page, orderBy, teacherId) {
  return {
    types: [
      ACTION.LOADING_ISSUES,
      ACTION.RECEIVE_ISSUES,
      ACTION.ERROR_ISSUES,
    ],
    promise: teacherService.getIssues(teacherId, range, filters, page, orderBy)
  }
}