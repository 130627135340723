import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../Classes/actions';
import Tabs from '../../components/simple/Tabs';
import TableCancelations from './Cancelations/Cancelations';
import TableTests from './Tests/TableTests';
import GroupCalendar from './GroupCalendar';
import TableMaterials from './TableMaterials/TableMaterials';
import TableAddDeleteJustifications from './AddDeleteJustifications';
import GroupEvent from './GroupEvent';
import { activeKeyDetailPanels as keys } from '../../contants';

import './DetailPanels.scss';

function DetailPanels(props) {
  let { setActiveKey, classReducer } = props;
  let { activeKey } = classReducer;
  
  return (
    <div className='DetailPanels'>
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        items={[ 
          { value: 0, key: keys.KEY_CANCELATIONS, label: 'Cancelaciones', children: <TableCancelations {...props} /> },
          { value: 1, key: keys.KEY_TESTS, label: 'Exámenes', children: <TableTests {...props} /> },
          { value: 2, key: keys.KEY_CALENDAR, label: 'Calendario', children: <GroupCalendar {...props} /> },
          { value: 3, key: keys.KEY_JUSTIFICATIONS, label: 'Altas/Bajas', children: <TableAddDeleteJustifications {...props} /> },
          { value: 4, key: keys.KEY_MATERIALS, label: 'Material', children: <TableMaterials {...props} /> },
          { value: 5, key: keys.KEY_EVENTS, label: 'Eventos', children: <GroupEvent {...props} /> },
        ]}
        onChange={setActiveKey}
      />
    </div>
  );
}


const mapDispatchToProps = dispatch => bindActionCreators(Actions, dispatch);
const mapStateToProps = state => ({
  classReducer: state.get('classReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailPanels);
