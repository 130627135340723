import { Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { editScheduleFlow } from "../../../contants";

import './ConflictWarningSchedule.scss';

const ConflictWarningSchedule = (props) => {

  const { 
    setStateFlow, 
    scheduleReducer, 
    resetStateSchedule,
    teacher,
  } = props;

  const { groupListWithConflict } = scheduleReducer;

  const previous = () => {
    resetStateSchedule();
    setStateFlow(editScheduleFlow.MODAL_EDITION);
  };

  return (
    <div className="warning-conflict" >
      <ExclamationCircleOutlined className="icon-warning" />

      <h1>¡Atención!</h1>

      <p>
        Estas a punto de generar un conflicto con la(s) siguiente(s) clase(s):
      </p>

      <div className="container-list">
        {(groupListWithConflict || []).map((item, key) => (
          <section key={key}>
            <a className="item-list"
              target={'_blank'} 
              rel="noreferrer" 
              href={`/classes?id=${teacher?.id}&category=${'teachers'}&label=${teacher?.name}`}>
                {item.client_name} - {item.group_name}
            </a><br/>
          </section>
        ))}
      </div>

      <div>
        <Button shape="round" onClick={() => previous()}>
          Regresar
        </Button>

        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          onClick={() => setStateFlow(editScheduleFlow.CONFIRMATION_EDITION)}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default ConflictWarningSchedule;
