import moment from 'moment-timezone';
import React from 'react';
import './CalendarClientHolidays.scss';

const CalendarClientHolidays = (props) => {

  const { mode, holidays, currentDate } = props;
  
  const mapHolidays = date => {
    return (holidays || [])
    .filter(holiday => holiday.date === date.format('YYYY-MM-DD') && holiday.type === 'client')
    .map((holiday) => (
      (holiday.clients || [ ]).map((client, index) => (
        <div className={ `holiday client` } key={ index }>
          Día inhábil { client.client_name }
        </div>
      ))
    ))
  }

  const daysOfWeek = _ => {
    
    const startOfWeek = moment(currentDate, 'YYYY-MM-DD').startOf('week').subtract(1, 'day');
    
    const days = [];
    
    for (let index = 0; index < 6; index++) {
      
      const dayOfWeek = startOfWeek.add(1, 'day');

      days.push(
        <div key={ index } className={ `${ mode } CalendarHeaders__day CalendarHeaders__day__alone ${ currentDate === dayOfWeek.format('YYYY-MM-DD') && 'selected-day' }` }>
          { mapHolidays(dayOfWeek) }
        </div>
      )
    }

    return days;
  }

  return (
    <div className={ `CalendarClientHolidays CalendarHeaders holidays-wrapper` }>
      <div className='CalendarHeaders__empty'/>
      { !!(holidays || [ ]).length && daysOfWeek() }
    </div>
  )
}

export default CalendarClientHolidays;
