import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LoadingPage from '../Loading/LoadingPage'
import * as Actions from '../Login/actions';

function Index(props) {
	const { children } = props;
	if(!props.session){
		props.localSession();
	}
	return (
		props.session
		? children
		: <LoadingPage timeout={5000} redirect='/login'/>
	);
}

export default connect((state) => {
		return { session: state.get('authReducer').session }
	}, (dispatch) => {
		return bindActionCreators(Actions, dispatch);
	})(Index);