import * as ACTION from "./types";
import moment from 'moment';
import randomColor from 'randomcolor';
import { tableSortProps } from '../../reducers/helpers/table';

const initialState = {
  loading: {},
  errors: {},
  currentDate: moment().startOf('week'),
  intervals: [],
  colors: {},
  user: null,
  lockRange: null,
  showLockForm: false,
  form: {
    repeatType: 'day',
    removeAll: false
  },
  activeKey: '0',
  delays: {
    result: [],
    catalogues: []
  },
  cancellations: {
    result: [],
    catalogues: []
  },
  scores: {
    result: [],
    catalogues: []
  },
  issues: {
    result: [],
    catalogues: []
  },
  range: { },
  page: {
    delays: 1,
    cancellations: 1,
    scores: 1,
    issues: 1
  },
  orderBy: {
    delays: {
      column: "scheduledDate",
      order: "DESC"
    },
    cancellations: {
      column: "date",
      order: "DESC"
    },
    scores: {
      column: "scheduledDate",
      order: "DESC"
    },
    issues: {
      column: "scheduledDate",
      order: "DESC"
    }
  },
};

const reducer = (state = initialState, action) => {
  switch(action.type){
    case ACTION.LOADING:
      return { ...state, loading: { ...state.loading, agenda: true }};
    case ACTION.LOCK_LOADING:
      return { ...state, loading: { ...state.loading, lock: true }};

    case ACTION.LOCK_RECEIVE:
        return { ...state, loading: { ...state.loading, lock: null }, errors: { ...state.errors, lock: null }, form: { teacher: state.form.teacher, repeatType: 'day', removeAll: false }};
    case ACTION.LOCK_ERROR:
      return { ...state, loading: { ...state.loading, lock: null }, errors: { ...state.errors, lock: action.error.response.data } };

    case ACTION.CLEAR_ERROR:
      return { ...state, errors: { ...state.errors, lock: null }};

    case ACTION.REMOVE_LOCK_LOADING:
      return { ...state, loading: { ...state.loading, removeLock: true }};
    case ACTION.REMOVE_LOCK_RECEIVE:
        return { ...state, loading: { ...state.loading, removeLock: null }, form: { ...state.form, toDelete: null }};
    case ACTION.REMOVE_LOCK_ERROR:
      return { ...state, loading: { ...state.loading, removeLock: null }};

    case ACTION.ERROR:
      return { ...state, loading: { ...state.loading, agenda: false }, errors: { ...state.errors, agenda: action.error }};
    case ACTION.RECEIVE:
      let intervals = [];
      if (action.result.data.result) {
        action.result.data.result = action.result.data.result.map(date => {
          date.classes.map(item => {
            if (!state.colors[item.teacherID])
              state.colors[item.teacherID] = randomColor({ luminosity: 'light'});
            intervals.push({
              ...item,
              start: moment(item.scheduleDate + item.scheduleTime.split(' - ')[0], 'DD-MM-YYYY HH:mm'),
              end: moment(item.scheduleDate + item.scheduleTime.split(' - ')[1], 'DD-MM-YYYY HH:mm'),
              color: state.colors[item.teacherID],
              isUnavailable: item.type === 'unavailable',
              title: item.type === 'unavailable' ? item.title : item.clientName
            });
            return item;
          });
          return date;
        });
      }
      return { ...state, intervals, loading: { ...state.loading, agenda: false } };

    case ACTION.TEACHER_LOADING:
      return { ...state, user: null, loading: { ...state.loading, user: true }};
    case ACTION.TEACHER_ERROR:
      return { ...state, loading: { ...state.loading, user: false }, errors: { ...state.errors, user: action.error }};

    case ACTION.TEACHER_RECEIVE:
      return { ...state, user: action.result.data.result, loading: { ...state.loading, user: false } };

    case ACTION.ADD_DATA:
      state.currentDate = action.data;
      return {
        ...state,
        intervals: []
      };

    case ACTION.LOCK_RANGE:
      return {
        ...state,
        lockRange: action.range,
        showLockForm: true
      };

    case ACTION.TOGGLE_LOCK_FORM:
      return {
        ...state,
        showLockForm: !state.showLockForm,
        form: { ...state.form, allDay: false, repeat: false, repeatType: 'day' }
      };

    case ACTION.UPDATE_PROPERY:
      if (action.name === 'allDay') {
        if (action.value) {
          state.lockRange.tmp_start = moment(state.lockRange.start);
          state.lockRange.tmp_end = moment(state.lockRange.end);
          state.lockRange.start.set({hour:0,minute:0});
          state.lockRange.end = moment(state.lockRange.start).set({hour:23,minute:59}); 
        } else {
          state.lockRange.start = state.lockRange.tmp_start;
          state.lockRange.end = state.lockRange.tmp_end;
        }
      }
      return {
        ...state,
        form: {
          ...state.form,
          [action.name]: action.value
        }
      }

    case ACTION.UPDATE_RANGE:
      return {
        ...state,
        lockRange: {
          ...state.lockRange,
          [action.name]: action.value
        }
      }

    case ACTION.ACTIVE_KEY:
        return { ...state, activeKey: action.key };
        
    case ACTION.SET_COLORS:
      action.teachers.map(item => state.colors[item] = randomColor());
      return { ...state };

    case ACTION.LOADING_DELAYS:
      return { ...state, loading: { ...state.loading, DELAYS: { state: true } } }
    case ACTION.RECEIVE_DELAYS:
      return { ...state, loading: { ...state.loading, DELAYS: undefined }, delays: action.result.data }
    case ACTION.ERROR_DELAYS:
      return { ...state, loading: { ...state.loading, delays: { result: [], catalogues: [] }, DELAYS: { state: false, detail: action.error } } }
    
    case ACTION.LOADING_CANCELLATIONS:
      return { ...state, loading: { ...state.loading, CANCELLATIONS: { state: true } } }
    case ACTION.RECEIVE_CANCELLATIONS:
      return { ...state, loading: { ...state.loading, CANCELLATIONS: undefined }, cancellations: action.result.data }
    case ACTION.ERROR_CANCELLATIONS:
      return { ...state, loading: { ...state.loading, cancellations: { result: [], catalogues: [] }, CANCELLATIONS: { state: false, detail: action.error } } }

    case ACTION.LOADING_SCORES:
      return { ...state, loading: { ...state.loading, SCORES: { state: true } } }
    case ACTION.RECEIVE_SCORES:
      return { ...state, loading: { ...state.loading, SCORES: undefined }, scores: action.result.data }
    case ACTION.ERROR_SCORES:
      return { ...state, loading: { ...state.loading, scores: { result: [], catalogues: [] }, SCORES: { state: false, detail: action.error } } }
    

  case ACTION.LOADING_ISSUES:
      return { ...state, loading: { ...state.loading, ISSUES: { state: true } } }
  case ACTION.RECEIVE_ISSUES:
    return { ...state, loading: { ...state.loading, ISSUES: undefined }, issues: action.result.data }
  case ACTION.ERROR_ISSUES:
    return { ...state, loading: { ...state.loading, issues: { result: [], catalogues: [] }, ISSUES: { state: false, detail: action.error } } }

    case ACTION.TABLE_PROPS:
      return { ...tableSortProps(state, action) };

    default:
      return state;
  }
}

export default reducer;
