import client from '../axios';

class GroupEventService {
    constructor() {
        this.client = client;
    }

    getEvents(groupId, range = {}, filters, page, orderBy) {
        return client.get(`v2/group/id/${groupId}/events`, {
            params: {
                filters: {
                    startdate: range.startdate,
                    enddate: range.enddate,
                    ...filters
                },
                pagination: {
                    page,
                    limit: 10,
                    orderBy: orderBy.column,
                    sort: orderBy.order
                }
            }
        });
    };

}

export const groupEventService = new GroupEventService();