import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Card from '../../components/simple/Card';

import Breadcrumb from '../../components/simple/Breadcrumb';
import ClassDetailCard from '../../components/custom/ClassDetailCard';
import DetailPanels from './DetailPanels';
import ClasseStudents from './ClasseStudents';
import ClasseEvent from './ClasseEvent';

import { getTypeEvidence } from '../../reducers/groups/actions';
import * as Actions from '../Classes/actions';
import './ClasseDetail.scss';

function ClasseDetail(props){
  const { match, classReducer, updateReplacementPolicy, downloadClientReport } = props;
  const { loading, classDetail, replacementPolicies } = classReducer;
  let { classId } = match.params

  const update = () => {
    props.getClass(classId);
    props.getReplacementPolicies();
  }
  useEffect(() => {
    if(!classDetail || !classDetail.result){
      update();
    }
    // eslint-disable-next-line
  },[]);
  return (
    <div className='ClasseDetail'>
      <div className='ClasseDetail__breadcrumb'>
        <Breadcrumb
          routes={[
            { label:'Calendario de clases', url: '/classes', },
            { label:'Detalle' }
          ]}
        />
      </div>
      <Col>
        <Row>
          <Col xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}>
            <div className='ClasseDetail__info'>
              <Card>
                <ClassDetailCard
                  { ...props }
                  {...classDetail}
                  gettingClass={loading.GET_CLASS}
                  replacementPolicies={replacementPolicies}
                  updateReplacementPolicy={updateReplacementPolicy}
                  downloadClientReport={downloadClientReport}
                  refresh={update}
                />
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
            <Card>
              <ClasseEvent {...classDetail} />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}>
            <div className='ClasseDetail__panel'>
              <Card>
                <DetailPanels { ...props }/>
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
            <Card>
              <ClasseStudents
                { ...props }
                { ...classDetail }
                update={update}
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </div>
  );
}


ClasseDetail.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, getTypeEvidence }, dispatch);
const mapStateToProps = state => ({
  classReducer: state.get('classReducer'),
  groupsReducer: state.get('groupsReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClasseDetail);

