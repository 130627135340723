import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './actions';
import TableContainer from '../../components/custom/TableContainer'
import columns from './table.settings'
  
function Table(props) {
  return (
    <TableContainer columns={columns} { ...props }/>
  );
}

export default connect((state) => ({
  state: state.get('tickets')
}), (dispatch) => {
  return bindActionCreators(Actions, dispatch);
})(Table);