import React from 'react';
import { Spin } from 'antd';
import './Spinner.scss';

function Spinner(props) {
  if (props.custom) {
    return (
      <div className="spinner">
        <div className="blobs">
          <div className="blob"></div>
          <div className="blob"></div>
        </div>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
              <feBlend in2="goo" in="SourceGraphic" result="mix" />
            </filter>
          </defs>
        </svg>
      </div>
    );
  }
  return (
    <Spin className='center' {...props} />
  )
}

export default Spinner;