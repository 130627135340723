import React from 'react';
import { Button } from 'antd';

import './DangerButton.scss';

function DangerButton(props) {
  const { children } = props;
  return(
    <Button type="danger" className='DangerButton' {...props}>
      { children }
    </Button>
  );
}

DangerButton.defaultProps = {
  children: '',
}

export default DangerButton;