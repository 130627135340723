import React from 'react';
import { Row, Table as AntTable } from 'antd';
import './Table.scss';

function Table(props) {
  let { columns, data, rightAction } = props;
  if (!data) {
    data = { };
  }

  data.result = (data.result || []).map((item, index) => ({
    ...item,
    key: index
  }));

  const pagination = data.pagination || data;
  let pagerCount = (pagination.page * pagination.limit),
      from = (pagerCount - pagination.limit) + 1,
      to = pagination.next ? (pagerCount + pagination.limit) - data.result.length : pagination.total_elements;

  return (
    <div className="Table">
      <Row>
        { !!pagination.total_elements && !!pagination.limit && (
          <div className='n-of-n'>Mostrando del { from } al { to } de { pagination.total_elements }</div>
        )}
        { rightAction }
      </Row>
      <AntTable
        columns={columns}
        dataSource={data.result}
        pagination={pagination.total_elements && pagination.limit ? {
            total: pagination.total_elements,
            pageSize: pagination.limit,
            current: pagination.page,
            showSizeChanger:false,
            showQuickJumper:true,
        } : false }
        {...props}
      />
    </div>
  );
}

export default Table;
