import { DatePicker, Form, Input, notification, Select, TimePicker } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { editScheduleFlow } from "../../../contants";
import { useManageFile } from "../../../customhooks";
import { validationFile } from "../../../utils/validationFile";
import CellDatePicker from "../../simple/CellDatePicker";
import InputFile from "../../simple/InputFile/InputFile";
import FormWrapper from "../FormWrapper";
import InputButton from "../InputButton/InputButton";
import ItemFileList from "../ItemFileList/ItemFileList";
import ScheduleSelect from "../ScheduleSelect";

import './FormEditSchedule.scss';

const { Option } = Select;

const FormEditSchedule = (props) => {
  const {
    form,
    values,
    setValues,
    reasonCatalog,
    schedule,
    setSchedule,
    setStateFlow,
    editSchedule,
    scheduleReducer,
    classReducer,
    onClose,
    groupId,
  } = props;

  const { groupListWithConflict, isResponseConflict, loading } = scheduleReducer;

  const { classesOrigin } = classReducer;

  const [ timeScheduleFields, setTimeScheduleFields ] = useState([]);

  const [ isValidateForm, setIsValdateForm ] = useState([]);

  const {
    currentFile,
    currentFileName,
    listFile,
    setCurrentFileName,
    setListFile,
    handleInputFile,
    handleInputFilePaste,
    checkNameFile,
  } = useManageFile({ listFilesDefault: schedule?.current?.attachments || [] });

  const onChange = (formValue) => {
    setValues({
      ...values,
      ...formValue,
    });
  };

  const validateForm = () => {
    const values = form.getFieldsValue(Object.keys(form.getFieldsValue() || []).map(key => key));
    const fieldsValidation = Object.keys(values).filter(item => !values[item]);
    const isValidate = fieldsValidation.length ? false : true;
    setIsValdateForm(isValidate);
  };

  const onFinish = () => {
    const conflictCheck = true;

    const payload = {
      days: mapSchedule(),
      attachments: listFile,
      initialDate: moment(values.date).format('YYYY-MM-DD'),
      reason: values.reason,
      quantity: Number(values.quantity),
    };

    setSchedule({ ...schedule, current: payload });

    editSchedule(payload, groupId, conflictCheck);
  };

  const clearSchedulFields = () => {
    const formValues = values;

    Object.keys(values).forEach((key) => {
      const isSchedulField = key.indexOf("--") > -1;

      if (isSchedulField) {
        const index = key.split("--")[0];

        if (index >= values.quantity) {
          form.setFieldsValue({ [key]: null });
          formValues[key] = null;
        }
      }
    });

    onChange(formValues);
  };

  const renderScheduleFields = () => {
    const dynamicFieldList = [];

    clearSchedulFields();

    for (let i = 0; i < (values?.quantity || 0); i++) {
      dynamicFieldList.push(
        <Form.Item key={i} className="week-config">
          <Form.Item
            className="inline"
            label={"Día"}
            name={`${i}--day`}
            rules={[
              {
                required: true,
                message: "Ingresa un día de la semana",
              },
            ]}
          >
            <ScheduleSelect placeholder="Seleccionar" />
          </Form.Item>
          <Form.Item
            className="inline"
            label={"Hora"}
            name={`${i}--time`}
            rules={[
              {
                required: true,
                message: "Ingresa un rango de horas válido",
              },
            ]}
          >
            <TimePicker.RangePicker
              popupClassName="group-schedule-timepicker"
              minuteStep={15}
              use12Hours
              format="h:mm a"
            />
          </Form.Item>
        </Form.Item>
      );
    }
    setTimeScheduleFields(dynamicFieldList);
  };

  const mapSchedule = () => {
    const days = {};

    Object.keys(values).forEach((key) => {
      if (key.indexOf("--") > -1) {
        const keyName = key.split("--");

        if (keyName[1] === "time" && values[key]) {
          days[keyName[0]] = {
            ...days[keyName[0]],
            initial_hour: Number(values[key][0].format("HH")),
            final_hour: Number(values[key][1].format("HH")),
            initial_minute: Number(values[key][0].format("mm")),
            final_minute: Number(values[key][1].format("mm")),
          };
        }

        if (keyName[1] === "day" && values[key]) {
          days[keyName[0]] = {
            ...days[keyName[0]],
            [keyName[1]]: values[key],
          };
        }
      }
    });

    return Object.values(days);
  };

  useEffect(validateForm, [onChange]);

  useEffect(renderScheduleFields, [values?.quantity]);

  useEffect(() => {
    const flow = () => {
      if (groupListWithConflict?.length) {
        setStateFlow(editScheduleFlow.WARNING_CONFLICT);
      } else {
        setStateFlow(editScheduleFlow.CONFIRMATION_EDITION);
      }
    };
    isResponseConflict && flow();
  }, [ groupListWithConflict, isResponseConflict ]);

  useEffect(() => {
    loading?.EDIT_SCHEDULE?.detail && notification["warning"]({
      description: "Se presentó un error inesperado",
      placement: "bottomRight",
    });
  }, [ loading?.EDIT_SCHEDULE?.detail ]);

  return (
    <div className="edit-schedule-modal">
      <h1>Editar agenda</h1>

      <div>
        <FormWrapper
          {...props}
          name="group-schedule"
          form={form}
          initialValues={values}
          onValuesChange={onChange}
          onFinish={onFinish}
          loading={loading?.EDIT_SCHEDULE?.state}
          cancelButtonText="Regresar"
          saveButtonText="Continuar"
          saveButtonProps={{ disabled: !listFile.length || !isValidateForm }}
          onRedirectCancelButton={() => onClose()}
        >
          <div className="container-form">
            <Form.Item
              label="A partir de"
              name="date"
              rules={[
                { required: true, message: "Por favor ingresar una fecha" },
              ]}
            >
              <DatePicker
                className="date-picker"
                format="DD/MM/YYYY"
                placeholder="dd/mm/aaaa"
                dateRender={(date) => CellDatePicker(date, classesOrigin)}
              />
            </Form.Item>

            <Form.Item
              label="Numero de clases por semana"
              name="quantity"
              rules={[{ required: true }]}
            >
              <Input type="number" min={1} max={7} />
            </Form.Item>

            <div className="container-field-dynamic">
              {timeScheduleFields}
            </div>

            <Form.Item
              label="Motivo"
              name="reason"
              rules={[{ required: true }]}
            >
              <Select
                className="select-evidence"
                placeholder="Seleccionar"
                loading={loading?.GET_CATALOG_REASONS?.state}
              >
                {(reasonCatalog || []).map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Subir evidencia" name="files">
              <InputFile
                accept=".png,.jpg,.pdf"
                beforeUpload={validationFile}
                disabled={listFile.length >= 5 ? true : false}
                customRequest={(e) => handleInputFile(e)}
                showUploadList={false}
                handlePaste={handleInputFilePaste}
              />
            </Form.Item>

            {currentFile && (
              <InputButton
                currentValue={currentFileName}
                setCurrentValue={setCurrentFileName}
                onClickButton={checkNameFile}
                title="Cambia el nombre de la imagen y valídala dando click al icono"
              />
            )}

            {listFile.map((itemFile, index) => (
              <ItemFileList
                key={itemFile.uid || index}
                base64={itemFile.receipt}
                fileType={itemFile.mime_type}
                uid={itemFile.uid}
                nameFile={itemFile.name}
                listFile={listFile}
                onRemove={setListFile}
              />
            ))}
          </div>
        </FormWrapper>
      </div>
    </div>
  );
};

export default FormEditSchedule;
