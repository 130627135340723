export const editScheduleFlow = {
  MODAL_EDITION: 1,
  WARNING_CONFLICT: 2,
  CONFIRMATION_EDITION: 3,
};

export const daysWeek = {
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
  7: 'Domingo',
}
