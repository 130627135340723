import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import './ConfirmationModal.scss';

const ConfirmationModal = props => {

  const { visible, values, labels, title } = props;

  const [ fields, setFields ] = useState([ ]);
  
  useEffect(_ => {
    const mappedFields = Object.keys((labels || { })).map((key, index) => {
      const label = labels[key];
      
      return (
        <Row key={ index }>
          <Col xs={ 12 }>
            { label.title }:
          </Col>
          <Col xs={ 12 }>
            <b>{ (values || { })[key] }</b>
          </Col>
        </Row>
      );
    });

    setFields(mappedFields);
  }, [ values, labels ]);

  return (
    <Modal { ...props.modalProps } visible={ visible } className={ `ConfirmationModal ${ props.modalProps?.className }` }>
      <ExclamationCircleOutlined />
      { !!title && <h1>{ title }</h1> }
      { fields }
      { props.children }
    </Modal>
  )
}

ConfirmationModal.propTypes = {};

export default ConfirmationModal;
