import { groupEventService } from "../../services/group-event.service";
import * as ACTION from "./types";

export function tableProps(name, page, sort) {
    return {
        type: ACTION.GROUP_EVENT_TABLEPROPS,
        name,
        page,
        sort
    }
};

export function saveFilters(filters) {
    return { type: ACTION.SAVE_FILTERS, filters }
};

export function getEvents(range, filters, page, orderBy, groupId) {
    return {
        types: [
            ACTION.GROUP_EVENT_LOADING,
            ACTION.GROUP_EVENT_RECEIVE,
            ACTION.GROUP_EVENT_ERROR,
        ],
        promise: groupEventService.getEvents(groupId, range, filters, page, orderBy),
    }
};
