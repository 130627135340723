import React, { useEffect, useState } from "react";
import FormEditSchedule from "../FormEditSchedule";
import { editScheduleFlow } from "../../../contants";
import ConflictWarningSchedule from "../ConflictWarningSchedule";
import ConfirmEditSchedule from "../ConfirmEditSchedule";
import { Form } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroupCompleted } from "../../../reducers/groups/actions";
import * as Actions from "../../../reducers/schedule/actions";
import { setActiveKey } from "../../../containers/Classes/actions";
import { getEvents } from "../../../reducers/group-event/actions";
import moment from "moment";

import "./EditScheduleModal.scss";

function EditScheduleModal(props) {

  const {
    scheduleReducer,
    groupsReducer,
    getGroupCompleted,
    match: { params },
    resetStateSchedule,
    getClasses,
    getCatalogReasonsEditSchedule,
    groupEventReducer
  } = props;

  const { catalogReasonEdit, responseEditSchedule } = scheduleReducer;

  const { group } = groupsReducer;

  const { filters } = groupEventReducer;

  const { id: groupId } = params;

  const [values, setValues] = useState({
    quantity: 1,
    files: {},
  });

  const [form] = Form.useForm();

  const [stateFlow, setStateFlow] = useState(editScheduleFlow.MODAL_EDITION);

  const [schedule, setSchedule] = useState({ initial: {}, current: {} });

  const fetchClasses = () => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().endOf('month').add(1, 'M').format('YYYY-MM-DD');
    const payload = {
      startdate: startOfMonth,
      enddate: endOfMonth,
      clients: [],
      teachers: [],
      languages: [],
      groups: [Number(groupId)],
      levels: [],
    };
    getClasses(payload);
  }

  useEffect(() => {
    getCatalogReasonsEditSchedule();
    fetchClasses();
    return () => {
      resetStateSchedule();
    }
  }, []);

  useEffect(() => {
    groupId && getGroupCompleted(groupId);
  }, [groupId]);

  useEffect(() => {
    setSchedule({ ...schedule, initial: { days: group?.schedule } });
  }, [group]);

  return (
    <>
      {stateFlow === editScheduleFlow.MODAL_EDITION && (
        <FormEditSchedule
          {...props}
          form={form}
          values={values}
          setValues={setValues}
          reasonCatalog={catalogReasonEdit}
          setStateFlow={setStateFlow}
          schedule={schedule}
          setSchedule={setSchedule}
          groupId={groupId}
        />
      )}

      {stateFlow === editScheduleFlow.WARNING_CONFLICT && (
        <ConflictWarningSchedule {...props} setStateFlow={setStateFlow} />
      )}

      {stateFlow === editScheduleFlow.CONFIRMATION_EDITION && (
        <ConfirmEditSchedule
          {...props}
          setStateFlow={setStateFlow}
          initialData={schedule.initial}
          endData={schedule.current}
          groupId={groupId}
          fetchStatus={responseEditSchedule?.result}
          filters={filters}
        />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...Actions, getGroupCompleted, getEvents, setActiveKey }, dispatch);
const mapStateToProps = (state) => ({
  scheduleReducer: state.get("scheduleReducer"),
  groupsReducer: state.get("groupsReducer"),
  groupEventReducer: state.get("groupEventReducer"),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditScheduleModal);
