import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getClient } from '../../../reducers/clients/actions';
import { Card, Row, Col } from 'antd';
import Breadcrumb from '../../../components/simple/Breadcrumb';
import FormGroupAddress from '../FormGroupAddress';

import './AddressConfig.scss';


const AddressConfig = props => {

  const { match, clientsReducer } = props;
  
  const { clientId } = match.params;
  
  const { client } = clientsReducer;

  useEffect(_ => {
    if (clientId) {
      props.getClient(clientId);
    }
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <div className='AddressConfig'>
      <Breadcrumb
        routes={[
          { label: 'Lista de clientes', url: `/clients` },
          { label: clientId ? client?.name : 'Detalle', url: `/clients/detail/${ clientId }` },
          { label: 'Direcciones físicas', url: `/clients/edit/${ clientId }`  },
          { label: 'Configurar dirección' }
        ]}
      />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <FormGroupAddress { ...props }/>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

AddressConfig.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getClient }, dispatch);

const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressConfig);
