import client from '../axios';

class ClientService {
    constructor() {
        this.client = client;
    }
    createClient = data => client.post('v2/client', data);
    editClient = (cid, data) => client.put(`/v2/client/id/${ cid }`, data);
    getClients = (filters, page, orderBy, searchQuery) => client.get('v2/client', {
        params: {
            filters: {
                ...(filters || {}),
                name: searchQuery || null
            },
            pagination: {
                page,
                limit: 10,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        }
    });
    getAllClients = () => client.get('v2/client');
    getClient = cid => client.get(`v2/client/id/${ cid }`);
    getTariffs = _ => client.get(`/v2/tariff/client`);
    getPlans = _ => client.get(`/v2/tariff/plans`);

    getHolidays = (startdate, enddate, cid) => client.get(`v2/holiday/client/${ cid }`, {
        params: {
            startdate,
            enddate
        }
    });

    saveHolidays = (holidays, cid) => client.post(`v2/holiday/client/${ cid }`, {
        holidays
    });

    deleteHolidays = (hid) => client.delete(`v2/holiday/id/${ hid }/client`);

    getAddresses = (cid, filters, page, orderBy, searchQuery) => client.get(`v2/address/client/${ cid }`, {
        params: {
            filters: {
                ...(filters || {}),
                name: searchQuery || null
            },
            pagination: {
                page,
                limit: 5,
                orderBy: orderBy.column,
                sort: orderBy.order
            }
        }
    });

    getValidateClientName = (clientName) => client.get('v2/client/validate', {
        params: {
            name: clientName,
        }
    });
}

export const clientService = new ClientService();