import IconButton from "../../simple/IconButton/IconButton";
import { CloseCircleOutlined } from '@ant-design/icons';
import './ItemFileList.scss';
import ImagePreview from "../../simple/ImagePreview";

const ItemFileList = (props) => {
  
  const {
    id,
    uid,
    fileType,
    base64,
    url,
    nameFile,
    listFile,
    onRemove,
    actionDelete
  } = props;

  const ImageComponent = () => <img src={base64 || url} alt="avatar of evidence" />;

  return (
    <div className="container-item">
      <div className="list-file">
        <div className="container-image-name">
          <div className="crop-image">
            {fileType === "application/pdf" ? (
              <embed
                src={base64}
                type={fileType}
                width="40px"
                height="40px"
              />
            ) : (
              <ImagePreview 
                data={[{url: url || base64, name: nameFile}]}
                component={ImageComponent} 
              />
            )}
          </div>
          <label className="label-list">{nameFile}</label>
        </div>
        <IconButton
          onClick={() =>
            (
              onRemove && listFile
                ? onRemove(listFile.filter((file) => file.uid !== uid))
                : actionDelete(id)
            )
          }
          icon={<CloseCircleOutlined />}
        />
      </div>
    </div>
  );
};

export default ItemFileList;
