import * as ACTION from "./types";

const initialState = {
  loading: { },
  errors: { },
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case ACTION.LOADING_GET_HOLIDAYS:
      return { ...state, loading: { ...state.loading, GET_HOLIDAYS: { state: true } } }
    case ACTION.RECEIVE_GET_HOLIDAYS:
      return { ...state, loading: { ...state.loading, GET_HOLIDAYS: undefined }, holidays: action.result.data.result }
    case ACTION.ERROR_GET_HOLIDAYS:
      return { ...state, loading: { ...state.loading, GET_HOLIDAYS: { state: false, detail: action.error } } }

    case ACTION.LOADING_SAVE_HOLIDAYS:
      return { ...state, loading: { ...state.loading, SAVE_HOLIDAYS: { state: true } } }
    case ACTION.RECEIVE_SAVE_HOLIDAYS:
      return { ...state, loading: { ...state.loading, SAVE_HOLIDAYS: undefined } }
    case ACTION.ERROR_SAVE_HOLIDAYS:
      return { ...state, loading: { ...state.loading, SAVE_HOLIDAYS: { state: false, detail: action.error } } }

    case ACTION.LOADING_DELETE_HOLIDAY:
      return { ...state, loading: { ...state.loading, DELETE_HOLIDAY: { state: true } } }
    case ACTION.RECEIVE_DELETE_HOLIDAY:
      return { ...state, loading: { ...state.loading, DELETE_HOLIDAY: undefined } }
    case ACTION.ERROR_DELETE_HOLIDAY:
      return { ...state, loading: { ...state.loading, DELETE_HOLIDAY: { state: false, detail: action.error } } }

    case ACTION.LOADING_GET_TEACHER_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_TEACHER_TARIFFS: { state: true } } }
    case ACTION.RECEIVE_GET_TEACHER_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_TEACHER_TARIFFS: undefined }, teacherTariffs: action.result.data.tariffs }
    case ACTION.ERROR_GET_TEACHER_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_TEACHER_TARIFFS: { state: false, detail: action.error } } }

    case ACTION.LOADING_SAVE_TEACHER_TARIFFS:
      return { ...state, loading: { ...state.loading, SAVE_TEACHER_TARIFFS: { state: true } } }
    case ACTION.RECEIVE_SAVE_TEACHER_TARIFFS:
      return { ...state, loading: { ...state.loading, SAVE_TEACHER_TARIFFS: undefined } }
    case ACTION.ERROR_SAVE_TEACHER_TARIFFS:
      return { ...state, loading: { ...state.loading, SAVE_TEACHER_TARIFFS: { state: false, detail: action.error } } }

    case ACTION.LOADING_DELETE_TEACHER_TARIFF:
      return { ...state, loading: { ...state.loading, DELETE_TEACHER_TARIFF: { state: true } } }
    case ACTION.RECEIVE_DELETE_TEACHER_TARIFF:
      return { ...state, loading: { ...state.loading, DELETE_TEACHER_TARIFF: undefined }, teacherTariffConflicts: action.result?.data?.result }
    case ACTION.ERROR_DELETE_TEACHER_TARIFF:
      return { ...state, loading: { ...state.loading, DELETE_TEACHER_TARIFF: { state: false, detail: action.error } } }

    case ACTION.IGNORE_TEACHER_TARIFF_CONFLICTS:
      return { ...state, teacherTariffConflicts: null }

    case ACTION.LOADING_GET_CLIENT_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_TARIFFS: { state: true } } }
    case ACTION.RECEIVE_GET_CLIENT_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_TARIFFS: undefined }, clientTariffs: action.result.data.tariffs }
    case ACTION.ERROR_GET_CLIENT_TARIFFS:
      return { ...state, loading: { ...state.loading, GET_CLIENT_TARIFFS: { state: false, detail: action.error } } }

    case ACTION.LOADING_SAVE_CLIENT_TARIFFS:
      return { ...state, loading: { ...state.loading, SAVE_CLIENT_TARIFFS: { state: true } } }
    case ACTION.RECEIVE_SAVE_CLIENT_TARIFFS:
      return { ...state, loading: { ...state.loading, SAVE_CLIENT_TARIFFS: undefined } }
    case ACTION.ERROR_SAVE_CLIENT_TARIFFS:
      return { ...state, loading: { ...state.loading, SAVE_CLIENT_TARIFFS: { state: false, detail: action.error } } }

    case ACTION.LOADING_DELETE_CLIENT_TARIFF:
      return { ...state, loading: { ...state.loading, DELETE_CLIENT_TARIFF: { state: true } } }
    case ACTION.RECEIVE_DELETE_CLIENT_TARIFF:
      return { ...state, loading: { ...state.loading, DELETE_CLIENT_TARIFF: undefined }, clientTariffConflicts: action.result?.data?.result }
    case ACTION.ERROR_DELETE_CLIENT_TARIFF:
      return { ...state, loading: { ...state.loading, DELETE_CLIENT_TARIFF: { state: false, detail: action.error } } }

    case ACTION.IGNORE_CLIENT_TARIFF_CONFLICTS:
      return { ...state, clientTariffConflicts: null }

    default:
      return state;
  }
}

export default reducer;
