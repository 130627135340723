export const LOADING_GET_HOLIDAYS = 'SETTINGS::LOADING_GET_HOLIDAYS';
export const RECEIVE_GET_HOLIDAYS = 'SETTINGS::RECEIVE_GET_HOLIDAYS';
export const ERROR_GET_HOLIDAYS = 'SETTINGS::ERROR_GET_HOLIDAYS';

export const LOADING_SAVE_HOLIDAYS = 'SETTINGS::LOADING_SAVE_HOLIDAYS';
export const RECEIVE_SAVE_HOLIDAYS = 'SETTINGS::RECEIVE_SAVE_HOLIDAYS';
export const ERROR_SAVE_HOLIDAYS = 'SETTINGS::ERROR_SAVE_HOLIDAYS';

export const LOADING_DELETE_HOLIDAY = 'SETTINGS::LOADING_DELETE_HOLIDAY';
export const RECEIVE_DELETE_HOLIDAY = 'SETTINGS::RECEIVE_DELETE_HOLIDAY';
export const ERROR_DELETE_HOLIDAY = 'SETTINGS::ERROR_DELETE_HOLIDAY';

export const LOADING_GET_TEACHER_TARIFFS = 'SETTINGS::LOADING_GET_TEACHER_TARIFFS';
export const RECEIVE_GET_TEACHER_TARIFFS = 'SETTINGS::RECEIVE_GET_TEACHER_TARIFFS';
export const ERROR_GET_TEACHER_TARIFFS = 'SETTINGS::ERROR_GET_TEACHER_TARIFFS';

export const LOADING_SAVE_TEACHER_TARIFFS = 'SETTINGS::LOADING_SAVE_TEACHER_TARIFFS';
export const RECEIVE_SAVE_TEACHER_TARIFFS = 'SETTINGS::RECEIVE_SAVE_TEACHER_TARIFFS';
export const ERROR_SAVE_TEACHER_TARIFFS = 'SETTINGS::ERROR_SAVE_TEACHER_TARIFFS';

export const LOADING_DELETE_TEACHER_TARIFF = 'SETTINGS::LOADING_DELETE_TEACHER_TARIFF';
export const RECEIVE_DELETE_TEACHER_TARIFF = 'SETTINGS::RECEIVE_DELETE_TEACHER_TARIFF';
export const ERROR_DELETE_TEACHER_TARIFF = 'SETTINGS::ERROR_DELETE_TEACHER_TARIFF';

export const IGNORE_TEACHER_TARIFF_CONFLICTS = 'SETTINGS::IGNORE_TEACHER_TARIFF_CONFLICTS';

export const LOADING_GET_CLIENT_TARIFFS = 'SETTINGS::LOADING_GET_CLIENT_TARIFFS';
export const RECEIVE_GET_CLIENT_TARIFFS = 'SETTINGS::RECEIVE_GET_CLIENT_TARIFFS';
export const ERROR_GET_CLIENT_TARIFFS = 'SETTINGS::ERROR_GET_CLIENT_TARIFFS';

export const LOADING_SAVE_CLIENT_TARIFFS = 'SETTINGS::LOADING_SAVE_CLIENT_TARIFFS';
export const RECEIVE_SAVE_CLIENT_TARIFFS = 'SETTINGS::RECEIVE_SAVE_CLIENT_TARIFFS';
export const ERROR_SAVE_CLIENT_TARIFFS = 'SETTINGS::ERROR_SAVE_CLIENT_TARIFFS';

export const LOADING_DELETE_CLIENT_TARIFF = 'SETTINGS::LOADING_DELETE_CLIENT_TARIFF';
export const RECEIVE_DELETE_CLIENT_TARIFF = 'SETTINGS::RECEIVE_DELETE_CLIENT_TARIFF';
export const ERROR_DELETE_CLIENT_TARIFF = 'SETTINGS::ERROR_DELETE_CLIENT_TARIFF';

export const IGNORE_CLIENT_TARIFF_CONFLICTS = 'SETTINGS::IGNORE_CLIENT_TARIFF_CONFLICTS';