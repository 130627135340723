import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { activateGroups } from '../../../../reducers/groups/actions';
import { withRouter } from 'react-router-dom';
import Button from '../../../../components/simple/Button';

import './ActivateGroupButton.scss';

function ActivateGroupButton(props) {
    
    const { currentSelected, groupsReducer, submit } = props;

    const { loading } = groupsReducer;
    
    return (
        <Button { ...props } onClick={ submit } className='ActivateGroupButton' type='primary' loading={ loading.ACTIVATE_GROUPS?.state }>Activar grupos ({ (currentSelected || [ ]).length })</Button>
    );
}

export default withRouter(connect((state) => ({
  groupsReducer: state.get('groupsReducer')
}), (dispatch) => {
  return bindActionCreators({ activateGroups }, dispatch);
})(ActivateGroupButton));