import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createClient, editClient, getTariffs, getPlans, getClient, getAllClients, getValidateClientName } from '../../../reducers/clients/actions';
import FormWrapper from '../../../components/custom/FormWrapper';
import { Row, Col, Radio, Select, notification, Form, Checkbox} from 'antd';
import { onEdit } from './onEdit';
import { onCreateClient } from './onCreate';
import { getReplacementPolicies } from '../../Classes/actions';

import './FormGroupClientSettings.scss';
import InputValidate from '../../../components/simple/InputValidate';

const { Option } = Select;

let emailValidationTimeout;

const listNamePath = [
  'type',
  'name',
  'same_tariff',
  'plan0',
  'tariffs0',
  'plan-1',
  'plan-2',
  'plan-3',
  'tariffs-1',
  'tariffs-2',
  'tariffs-3',
  'policy_id',
];

const FormGroupClientSettings = props => {

  const { clientsReducer, match, classReducer } = props;

  const { clientId } = match.params;

  const { tariffsCatalog, plansCatalog, client, allClients, existClientName, loading } = clientsReducer;

  const { replacementPolicies } = classReducer;

  const [ form ] = Form.useForm();

  const [ values, setValues ] = useState({});
  const [ editValues, setEditValues ] = useState({});
  const [ customValidationValues, setCustomValidationValues ] = useState({});
  const [isSend, setIsSend] = useState(false);
  
  const onChange = formValue => {
    validateIfNameExists(formValue?.name);
    isSend && customValidation();
    return setValues({
        ...values,
        ...formValue
    });
  };

  const onFinish = _ => {
    !isSend && setIsSend(true);
    if (customValidation()) {
      return;
    };
    
    if(clientId){

      if (values.hasOwnProperty('parent_company') && values['parent_company'] !== undefined && values['parent_company'] !== '' && values['parent_company'] !== null) {
          const parent = allClients.filter(item => item.id === values['parent_company']);
          editValues['parent_company'] = parent[0].id;
      } else {
          editValues['parent_company'] = null;
      }

      if (values.same_tariff === true) {
        let newValue = { ...editValues };
        newValue.plans_tariff[0].id = values.plan0;
        newValue.plans_tariff[0].tariff = values.tariffs0;
        const descriptionPlan = plansCatalog.filter(plan => plan.id === values.plan0);
        newValue.plans_tariff[0].description = descriptionPlan[0].description;
        newValue.plans_tariff.splice(1, editValues.plans_tariff.length);
        setEditValues({ ...values, ...newValue });
      } else {
        const tariffs = onEdit(values, editValues, plansCatalog);
        setEditValues({ ...values, ...editValues, tariffs });
      }
    } else {
      setValues( onCreateClient(values) )
    };

    if (clientId) {
      if (!editValues.policies_option) {
        delete editValues['policy_id'];
        setEditValues(editValues);
      }
    } else {
      if (!values.policies_option) {
        delete values['policy_id'];
        setValues(values);
      }
    };

    (clientId ? props.editClient(clientId, editValues) : props.createClient(values)).then(res => {
      notification['success']({
        description: 'Se han guardado los datos con éxito',
        placement: 'bottomRight'
      })
      clientId ? props.history.push(`/clients/detail/${clientId}`) : props.history.push(`/clients`);
    });
  }

  const validateIfNameExists = name => {
    clearTimeout(emailValidationTimeout);
    name && (emailValidationTimeout = setTimeout(() => props.getValidateClientName(name), 1000));
  }

  const customValidation = () => validateTariff() ? true : false;

  const validateTariff = () => {
    const plansSelected = Object.keys(form.getFieldsValue()).filter((key)=>key.includes('plan-') && form.getFieldValue(key) );
    if (form.getFieldValue('same_tariff') === false && plansSelected.length <= 1) {
      setCustomValidationValues({ ...customValidationValues, validationTariff: true });
      return true;
    } else {
      setCustomValidationValues({ ...customValidationValues, validationTariff: false });
      return false;
    }
  }

  useEffect(_ => {
    props.getTariffs();
    props.getPlans();
    props.getAllClients();
    props.getReplacementPolicies();
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    if (clientId) {
      props.getClient(clientId);
    }
  }, [ clientId ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    let initialValues = clientId ? client : { same_tariff: true };
    if(initialValues['parent_company'] === 0){
      initialValues['parent_company'] = null;
    }
    if(initialValues['policy_id'] === 0){
      initialValues['policy_id'] = null;
      initialValues['policies_option'] = false;
    } else {
      initialValues['policy_id'] = clientId ? client['policy_id'] : 1;
      initialValues['policies_option'] = true;
    }
    if(initialValues !== undefined && initialValues.hasOwnProperty('plans_tariff') && initialValues.plans_tariff != null){
      setEditValues({...initialValues})
      
      if(initialValues.same_tariff){
        initialValues[`plan0`] = initialValues.plans_tariff[0]['id']
          initialValues[`tariffs0`] = initialValues.plans_tariff[0]['tariff']
      }else{
        Object.keys(initialValues.plans_tariff).forEach(plan =>{
          initialValues[`plan-${initialValues.plans_tariff[plan]['id']}`] = true
          initialValues[`tariffs-${initialValues.plans_tariff[plan]['id']}`] = initialValues.plans_tariff[plan]['tariff']
        })
      }
      
    }
    form.setFieldsValue(initialValues);
    onChange(initialValues);
  }, [ form, client, clientId ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //logic to evalute if an tariff is already selected
    const keyTariffs = Object.keys(values).filter(key =>key.includes('tariffs-'))
    const arrayTariffs = []
    keyTariffs.forEach(key =>{
      if (!arrayTariffs.includes(values[key])  && values[key]){
        arrayTariffs.push(values[key] )
      }else{
        values[key] = undefined
        form.setFieldsValue(values)
      }
    })

    setEditValues(values);

  }, [ onChange ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='FormGroupClientSettings'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <FormWrapper
            { ...props }
            loading={ clientId ? loading?.EDIT_CLIENT?.state : loading?.CREATE_CLIENT?.state }
            form={form}
            onValuesChange={ onChange }
            onFinish={ onFinish }
            name='client-form'
            listNames={listNamePath}
            isCustomValidation={true}
            cancelButtonText='Regresar'
          >
            <Form.Item
              label="Tipo de cliente"
              name="type"
              rules={[
                { required: true },
              ]}
            >
              <Radio.Group>
                <Radio value={1}>Empresa</Radio>
                <Radio value={2}>Particular</Radio>
              </Radio.Group>
            </Form.Item>
            <InputValidate
              label="Nombre de la empresa"
              name="name"
              placeholder="Escribe el nombre de la empresa"
              validateHelpText="El nombre de la empresa ya existe"
              stateValidation={loading?.GET_VALIDATE_CLIENT_NAME?.state}
              invalidValue={existClientName}
              rules={[
                { required: true },
              ]}
            />
            <Form.Item
              label="Selecciona con qué empresa está relacionado (opcional)"
              name='parent_company'
              rules={[
                { required: false },
              ]}
            >
              <Select showSearch placeholder='Seleccionar'>
                { (allClients || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.name }</Option>) }
              </Select>
            </Form.Item>
            
            <Form.Item
              label="¿Deseas aplicar la misma tarifa a todos los grupos de este cliente?"
              name="same_tariff"
              rules={[
                { required: true },
              ]}
            >
              <Radio.Group>
                <Radio value={ true }>Sí</Radio>
                <Radio value={ false }>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.plan0 !== currentValues.plan0 || prevValues.tariffs0 !== currentValues.tariffs0 }
            >
              {({ getFieldValue }) => getFieldValue('same_tariff') && (
                <>
                  <Form.Item
                    label="Elige el tipo de plan"
                    name='plan0'
                    rules={[
                      { required: true },
                    ]}
                  >
                    <Select showSearch placeholder='Selecciona plan'>
                      {(plansCatalog || []).map((item, index) =><Option key={index} value={item.id}>{item.description}</Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Tarifa de cliente"
                    name='tariffs0'
                    rules={[
                      { required: true },
                    ]}
                  >
                    <Select  listHeight={160} showSearch placeholder='Selecciona tarifa cliente'>
                      {(tariffsCatalog || []).map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                    </Select>
                  </Form.Item>
                </>
              )}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.same_tariff !== currentValues.same_tariff}
            >
              {({ getFieldValue }) => getFieldValue('same_tariff') === false && (
                <>
                  <div className='ant-form-item-label'>
                    <label>
                      Selecciona por lo menos 2 planes con sus respectivas tarifas (No se puede repetir una tarifa)
                    </label>
                  </div>
                  {customValidationValues?.validationTariff &&
                    <div role="alert" className="ant-form-item-explain ant-form-item-explain-error mb-8">
                      Configura mínimo dos tarifas
                    </div>
                  }
                  <Row justify="space-around">
                    {
                      (plansCatalog || []).map((item, index) => {
                        return (
                          <Col key={index} span={6} >
                            <Form.Item
                                label="Tarifa de cliente"
                                key={`plan-${item.id}`}
                                name={`plan-${item.id}`}
                                valuePropName="checked"
                            >
                              <Checkbox value={values[`plan-${item.id}`]}>
                                {`Plan ${item.description}`}
                              </Checkbox>
                            </Form.Item>
                            <Form.Item
                              label="Tarifa de cliente"
                              name={`tariffs-${item.id}`}
                              key={`tariffs-${item.id}`}
                              rules={[
                                { required: values[`plan-${item.id}`] },
                              ]}
                            >
                                <Select showSearch listHeight={160} disabled={!values[`plan-${item.id}`]}  placeholder='Selecciona tarifa cliente'>
                                  {(tariffsCatalog || []).map((tariff, guide) => <Option key={guide} value={tariff}>{tariff}</Option>)}
                                </Select>
                            </Form.Item>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </>
              )}
            </Form.Item>
            
            <Form.Item
              label="¿Deseas aplicar la misma política de cancelación a todos los grupos de este cliente?"
              name="policies_option"
              rules={[
                { required: true },
              ]}
            >
              <Radio.Group>
                <Radio value={ true }>Sí</Radio>
                <Radio value={ false }>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.policies_option !== currentValues.policies_option}
            >
              {({ getFieldValue }) => getFieldValue('policies_option') && (
              <>
                <Form.Item
                  label="Elige la política de cancelación"
                  name='policy_id'
                  rules={[
                    { required: true },
                  ]}
                >
                  <Select 
                    showSearch 
                    placeholder='Selecciona política de cancelación'
                  >
                    {(replacementPolicies || []).map((item, index) =><Option key={index} value={item.id}>{item.name}</Option>)}
                  </Select>
                </Form.Item>
              </>
            )}
            </Form.Item>
          </FormWrapper>
        </Col>
      </Row>
      
    </div>
  )
}

FormGroupClientSettings.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ createClient, editClient, getTariffs, getPlans, getClient, getAllClients, getValidateClientName, getReplacementPolicies }, dispatch);

const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer'),
  classReducer: state.get('classReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormGroupClientSettings);
