import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import FormWrapper from '../../../components/custom/FormWrapper';
import Tags from '../../../components/simple/Tags/Tags';
import { PlusOutlined } from '@ant-design/icons';
import ConflictsModal from '../ConflictsModal';

import './FormTariffs.scss';

const FormTariffs = props => {
  
    const { tariffs, tariffConflicts, loadingGet, loadingSave, loadingDelete, getEditRoute, inputLabel, getConflictConfirmationTitle } = props;

    const [ form ] = Form.useForm();
    
    const [ values, setValues ] = useState({ });
    
    const [ tariffToDelete, setTariffToDelete ] = useState();
    
    const mapTariffs = _ => values.tariffs;
  
    const mapTags = list => (list || [ ]).map(item => {
  
      const value = item.value || item;
  
      return ({
        ...item,
        label: `$ ${ value }`,
        value
      })
    });
  
    const onChange = formValue => {
      setValues({
        ...values,
        ...formValue
      });
    }

    const getUnsavedTariffs = _ => values.tariffs.filter(item => !item.category).map(tariff => Number(tariff.value));
    
    const onFinish = _ => props.saveTariffs(getUnsavedTariffs()).then(
      () => notification['success']({
        description: 'Tarifa guardada con éxito',
        placement: 'bottomRight'
      })
    );

    const addTariff = _ => {
      
      const tariffAlreadyExists = !!(values?.tariffs || [ ]).find(tariff => tariff.value === Number(values.tariff));

      const unsavedTariffs = (values.tariffs || [ ]);

      if (tariffAlreadyExists) {
        notification['warning']({
          description: `La tarifa ${ values.tariff } ya existe`,
          placement: 'bottomRight'
        })
      } else {
        unsavedTariffs.push({
          value: values.tariff
        })
      }
      
      setValues({
        ...values,
        tariff: null,
        tariffs: mapTags(unsavedTariffs)
      })

      form.setFieldsValue({
        tariff: null
      })
    }

    const onTagDelete = tag => setTariffToDelete(tag);

    const onDeleteConfirmed = () => props.deleteTariff(tariffToDelete.value);
  
    useEffect(_ => {
      setValues({
        ...values,
        tariffs: [ ]
      });

      props.getTariffs();
    }, [ loadingSave, loadingDelete  ]);  // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(_ => {
      setValues({
        ...values,
        tariffs: mapTags(
          (tariffs || [ ]).map(value => ({
            value,
            category: 'saved'
          })
        ))
      })
    }, [ tariffs ]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
      <div className='FormTariffs'>
        <FormWrapper
          { ...props }
          loading={ loadingGet || loadingSave }
          form={ form }
          initialValues={ values }
          onValuesChange={ onChange }
          onFinish={ onFinish }
          name='tariff-form'
          cancelButtonText='Regresar'
        >
          <div className='input-container'>
            <Form.Item
              label={ inputLabel }
              name='tariff'
            >
              <Input size='large' placeholder='Agrega una tarifa'/>
            </Form.Item>
            <Form.Item
              className='ignore-validator'
            >
              <Button
                type="primary"
                icon={ <PlusOutlined /> }
                shape={ 'round' }
                size='large'
                onClick={ addTariff }
              >Agregar</Button>
            </Form.Item>
          </div>
          <Tags
            className='gray'
            list={ mapTariffs() }
            onDelete={ onTagDelete }
          />
        </FormWrapper>
        <ConflictsModal
          itemToDeleteState={ setTariffToDelete }
          modalConfirmationTitle={ `¿Estas seguro que deseas eliminar la tarifa ${ tariffToDelete?.label.replace(/ /g, "") } de la lista?` }
          modalConflictsTitle={ getConflictConfirmationTitle(tariffToDelete?.label) }
          notificationDescription={ `Tarifa ${ tariffToDelete?.label } eliminada con éxito` }
          itemToDelete={ tariffToDelete }
          conflicts={ tariffConflicts }
          editRoute={ getEditRoute }
          loading={ loadingDelete }
          ignoreConflicts={ props.ignoreTariffConflicts }
          onDeleteConfirmed={ onDeleteConfirmed }
        />
    </div>
  )
}

FormTariffs.propTypes = {};

export default FormTariffs;
