import client from '../axios';

class AddressService {
  constructor() {
    this.client = client;
  }

  getAddresses = (cid, filters, page, orderBy, searchQuery) => client.get(`v2/address/client/${ cid }`, {
    params: {
      filters: {
          ...(filters || {}),
          name: searchQuery || null
      },
      pagination: {
          page,
          limit: 5,
          orderBy: orderBy?.column,
          sort: orderBy?.order
      }
    }
  });
  
  getAllAddresses = (cid) => client.get(`v2/address/client/${ cid }`);

  getGroupAddresses = (gid, filters, page, orderBy, searchQuery) => client.get(`v2/group/id/${ gid }/address`, {
    params: {
      filters: {
          ...(filters || {}),
          name: searchQuery || null
      },
      pagination: {
          page,
          limit: 5,
          orderBy: orderBy?.column,
          sort: orderBy?.order
      }
    }
  });

  getGroupCompletedAddresses = (gid, filters, page, orderBy, searchQuery) => client.get(`v2/group/id/${ gid }/address-completed`, {
    params: {
      filters: {
          ...(filters || {}),
          name: searchQuery || null
      },
      pagination: {
          page,
          limit: 5,
          orderBy: orderBy?.column,
          sort: orderBy?.order
      }
    }
  });

  saveAddress = (cid, address) => client.post(`v2/address/client/${ cid }`, address);

  linkGroupAddress = (gid, addresses) => client.put(`v2/group/id/${ gid }/address`, addresses);

  linkGroupCompletedAddress = (gid, addresses) => client.put(`v2/group/id/${ gid }/address-completed`, addresses);

  deleteAddress = aid => client.delete(`v2/address/id/${ aid }`);

  deleteAddressInGroupCompleted = (gid, aid) => client.delete(`v2/group/id/${ gid }/address-completed/${ aid }`);

  updateConflictAddress = (aid, addresses) => client.put(`v2/address/id/${ aid }`, addresses);

}

export const addressService = new AddressService();