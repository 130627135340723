import React from 'react';

import './CalendarMoreClass.scss';

function CalendarMoreClass(props) {
  const { label, onClick } = props;
  return (
    <div className='CalendarMoreClass' onClick={onClick}>
      {label}
    </div>
  );
}

export default CalendarMoreClass;
