import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Form,
  Select,
} from 'antd';
import { getMaterialsByLevel, getGroupMaterials } from '../../../reducers/materials/actions';
import { asignMaterials } from '../../../reducers/groups/actions';
import FormWrapper from '../../../components/custom/FormWrapper';
import ConfirmationModal from '../../../components/custom/ConfirmationModal/ConfirmationModal';
import { useHistory } from 'react-router';

import './FormGroupMaterials.scss';


const { Option } = Select;

const FormGroupMaterials = props => {

  const [ confirmationVisibility, setConfirmationVisibility ] = useState(false);

  const { groupsReducer, materialsReducer, match, location: { state } } = props;
  
  const { clientId, groupId } = match.params;
  
  const { group } = groupsReducer;

  const { levelMaterials, groupMaterials } = materialsReducer;
  
  const [ form ] = Form.useForm();

  const [ values, setValues ] = useState({ });

  const history = useHistory();

  const onChange = formValue => setValues({
    ...values,
    ...formValue
  });

  const onFinish = _ => {
    setConfirmationVisibility(true);
  };

  const findMaterial = id => (levelMaterials || [ ]).find(item => item.id === id || item.name_material === id);

  const mapSelection = () => {
    const materialsSelected = [ ];
    
    if (values.material_base) {
      materialsSelected.push({
        id: values.material_base,
        type: 1,
        ...findMaterial(values.material_base)
      });
    }

    (values.material_extra || [ ]).forEach(id => materialsSelected.push({
      id,
      type: 2,
      ...findMaterial(id),
    }));

    return materialsSelected;
  }
  
  const onAsignConfirmed = () => {
    props.asignMaterials(groupId, mapSelection());

    setConfirmationVisibility(false);
  }

  const materialOptions = materials => (materials || [ ]).map((item, index) => <Option key={ index } value={ item.id }>{ item.name_material }</Option>)

  const setFormValue = () => {
    
    const formValue = {
      material_extra: [ ]
    };

    (groupMaterials || []).forEach(item => {
      if (item.type === 1) {
        formValue.material_base = item.id;
      } else {
        formValue.material_extra.push(item.name_material);
      }
    });

    setValues(formValue);

    form.setFieldsValue(formValue);
  }

  const onRedirectCancelButton = () => {
    if (state?.statusGroup === 'completed') {
      history.goBack();
    } else {
      history.push({
        pathname: `/clients/detail/${clientId}`,
        state: {
          activeKey: 1, 
        },
      });
    }
    
  };

  useEffect(_ => {
    props.getMaterialsByLevel(group?.level_id);
    props.getGroupMaterials(groupId);
  }, [ group ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(setFormValue, [ groupMaterials ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='FormGroupMaterials'>
      <FormWrapper
        { ...props }
        name='group-materials'
        form={ form }
        initialValues={ values }
        onValuesChange={ onChange }
        onFinish={ onFinish }
        loading={ groupsReducer.loading.ASSIGN_MATERIALS?.state }
        cancelButtonText='Regresar'
        onRedirectCancelButton={ onRedirectCancelButton }
      >
        <Form.Item
          rules={[
            { required: true },
          ]}
          label='Seleccionar material base'
          name='material_base'
        >
          <Select
            placeholder='Seleccionar material'
            showSearch
            loading={ materialsReducer.loading.GET_MATERIALS_BY_LEVEL?.state }
          >
            { materialOptions(levelMaterials) }
          </Select>
        </Form.Item>
        <Form.Item
          label='Seleccionar material extra'
          name='material_extra'
        >
          <Select
            placeholder='Seleccionar material'
            showSearch
            disabled={ !values.material_base }
            mode="multiple"
            loading={ materialsReducer.loading.GET_MATERIALS_BY_LEVEL?.state }
          >
            { materialOptions((levelMaterials || []).filter(item => item.id !== values.material_base)) }
          </Select>
        </Form.Item>
      </FormWrapper>
      <ConfirmationModal
        visible={ !!confirmationVisibility }
        title={ `¿Estas seguro que deseas asignar los siguientes materiales?` }
        modalProps={{
          onOk: onAsignConfirmed,
          onCancel: _ => setConfirmationVisibility(false)
        }}
      >
        <ul>
          { mapSelection().map(item => (
            <li>
              { item.name_level } { item.name_material } - { item.type === 1 ? 'Base' : 'Extra' }
            </li>
          )) }
        </ul>
      </ConfirmationModal>
    </div>
  )
}

FormGroupMaterials.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getMaterialsByLevel, asignMaterials, getGroupMaterials }, dispatch);

const mapStateToProps = state => ({
  groupsReducer: state.get('groupsReducer'),
  materialsReducer: state.get('materialsReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormGroupMaterials);
