import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';
import Spinner from '../../components/simple/Spinner';
import './LoadingPage.scss';

function LoadingPage(props) {
  const { timeout, redirect } = props;
  const [redirectState, setRedirectState] = useState(false);

  const timeoutControl = setTimeout(()=>{
    setRedirectState(true);
  }, timeout);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutControl);
    }
  }, [])

  return (
    <div className='LoadingPage'>
      <Spinner custom={true} size='large'/>
      { redirectState && <Redirect to={redirect} /> }
    </div>
  );
}

LoadingPage.propTypes = {
  timeout: PropTypes.number.isRequired,
  redirect: PropTypes.string.isRequired,
};

export default LoadingPage;