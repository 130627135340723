import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getGroup, getGroupCompleted, saveSchedule, saveScheduleInGroupCompleted, resetLoadingState } from '../../../reducers/groups/actions';
import {
  Form,
  Input,
  TimePicker,
  DatePicker,
  notification
} from 'antd';
import moment from 'moment-timezone';
import FormWrapper from '../../../components/custom/FormWrapper';

import './FormGroupSchedule.scss';
import ConfirmEdit from '../ConfirmEdit';
import ScheduleSelect from '../../../components/custom/ScheduleSelect';

const { RangePicker } = DatePicker;

const FormGroupSchedule = props => {

  const { groupsReducer, match, location: { state } } = props;
  
  const { clientId, groupId } = match.params;
  
  const { group, loading: loadingGroup } = groupsReducer;

  const [ form ] = Form.useForm();

  const [ values, setValues ] = useState({
    quantity: 1
  });

  const [ timeScheduleFields, setTimeScheduleFields ] = useState([]);

  const [ confirmEditGroupVisible, setConfirmEditGroupVisible ] = useState(false);

  const [ schedule, setSchedule ] = useState({initial: {}, current: {}});

  const clearSchedulFields = _ => {
    
    const formValues = values;

    Object.keys(values).forEach(key => {
      
      const isSchedulField = key.indexOf('--') > -1;

      if (isSchedulField) {
        
        const index = key.split('--')[0];

        if (index >= values.quantity) {
          formValues[key] = null;
        }
      }
    });

    onChange(formValues);
  }

  const renderScheduleFields = _ => {
    const dynamicFieldList = [];
    
    clearSchedulFields();

    for (let i = 0; i < (values?.quantity || 0); i++) {
      dynamicFieldList.push(
        <Form.Item key={ i } className='week-config'>
          <Form.Item
            className='inline'
            label={ 'Día' }
            name={ `${ i }--day` }
            rules={[
              {
                required: true,
                message: 'Ingresa un día de la semana'
              }
            ]}
          >
            <ScheduleSelect disabled={ state?.statusGroup === 'completed' } placeholder='Seleccionar' />
          </Form.Item>
          <Form.Item
            className='inline'
            label={ 'Hora' }
            name={ `${ i }--time` }
            rules={[
              {
                required: true,
                message: 'Ingresa un rango de horas válido'
              }
            ]}
          >
            <TimePicker.RangePicker 
              disabled={ state?.statusGroup === 'completed' } 
              popupClassName='group-schedule-timepicker' 
              minuteStep={ 15 } 
              use12Hours 
              format="h:mm a"
            />
          </Form.Item>
        </Form.Item>
      );
    }
    setTimeScheduleFields(dynamicFieldList);
  }

  const onChange = formValue => {
    
    form.validateFields();

    setValues({
      ...values,
      ...formValue
    });
  }

  const mapSchedule = _ => {
    const days = { };

    Object.keys(values).forEach(key => {
      if (key.indexOf('--') > -1) {
        const keyName = key.split('--');

        if (keyName[1] === 'time') {
          days[keyName[0]] = {
            ...days[keyName[0]],
            initial_hour: Number(values[key][0].format('HH')),
            final_hour: Number(values[key][1].format('HH')),
            initial_minute: Number(values[key][0].format('mm')),
            final_minute: Number(values[key][1].format('mm')),
          }
        } else {
          days[keyName[0]] = {
            ...days[keyName[0]],
            [keyName[1]]: values[key]
          }
        }
      }
    });

    return Object.values(days)
  }

  const mapSchedulePayload = () => ({
    "initial_date": values.range[0].format("YYYY-MM-DD"),
    "final_date": values.range[1].format("YYYY-MM-DD"),
    "days": mapSchedule()
  });
  
  const onFinish = () => {
    const dataInitial = { 
      initial_date: parseDate(group?.initial_date),
      final_date: parseDate(group?.final_date)
    };

    const data = mapSchedulePayload();
    
    if (state?.statusGroup === 'completed') {
      delete data.days;
      setSchedule({initial: dataInitial, current: data});
      setConfirmEditGroupVisible(true);
    } else {
      props.saveSchedule(groupId, data);
    };
  };

  const onEditGroupCompleted = () => props.saveScheduleInGroupCompleted(groupId, schedule?.current)
  .then(() => {
    setConfirmEditGroupVisible(false);
    getGroupById();
  });

  const getGroupById = () => groupId && state?.statusGroup === 'completed' ? props.getGroupCompleted(groupId) : props.getGroup(groupId);

  const onRedirectCancelButton = () => {
    if (state?.statusGroup === 'completed') {
      props.history.goBack();
    } else {
      props.history.push({
        pathname: `/clients/detail/${clientId}`,
        state: {
          activeKey: 1, 
        },
      });
    }
    
  }

  const parseMoment = value => moment(value, 'YYYY-MM-DD');

  const parseDate = value => moment(value).format('YYYY-MM-DD');

  const refactoryDateFormat = (dates) => {
    let newDates = {};
    Object.keys(dates || {}).forEach(key => {
      if (key === 'initial_date' || key === 'final_date') {
        newDates[key] = moment(dates[key], 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
    });
    return newDates;
  };

  const getRange = _ => {
    const initialDate = state?.statusGroup === 'completed' ? parseMoment(parseDate(group?.initial_date)) : parseMoment(group?.initial_date);
    const finalDate = state?.statusGroup === 'completed' ? parseMoment(parseDate(group?.final_date)) : parseMoment(group?.final_date);
    
    if (initialDate.isValid() && finalDate.isValid()) {
      return [ initialDate, finalDate ]
    }
  };

  useEffect(() => {
    if (!loadingGroup?.EDIT_SCHEDULE) {
      notification['success']({
        description: 'Se han guardado los cambios con éxito',
        placement: 'bottomRight'
      });
    }
  }, [ loadingGroup?.EDIT_SCHEDULE ]);
  
  useEffect(renderScheduleFields, [ values?.quantity ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    const schedule = group?.schedule || [ ];

    const scheduleFields = { };

    schedule.forEach((event, index) => {
      scheduleFields[`${ index }--day`] = event.day;
      scheduleFields[`${ index }--time`] = [
        moment().set('hours', event.initial_hour).set('minutes', event.initial_minute),
        moment().set('hours', event.final_hour).set('minutes', event.final_minute)
      ]
    });

    const initialValues = groupId ? {
      ...values,
      range: getRange(),
      quantity: schedule.length || null,
      ...scheduleFields
    } : values;

    form.setFieldsValue(initialValues);

    onChange(initialValues);
  }, [ form, group, groupId ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      props.resetLoadingState();
    }
  }, [])
  
  return (
    <div className='FormGroupSchedule'>
      <FormWrapper
        { ...props }
        name='group-schedule'
        form={ form }
        initialValues={ values }
        onValuesChange={ onChange }
        onFinish={ onFinish }
        loading={ groupsReducer.loading.SAVE_SCHEDULE?.state || groupsReducer.loading.GET_GROUP?.state }
        cancelButtonText='Regresar'
        onRedirectCancelButton={ onRedirectCancelButton }
        saveButtonProps={{ disabled: false }}
      >
        <Form.Item
          label='Número de clases por semana'
          name='quantity'
          rules={[
            { required: true },
          ]}
        >
          <Input disabled={ state?.statusGroup === 'completed' } type='number' min={ 1 } max={ 7 }/>
        </Form.Item>
        { timeScheduleFields }
        <Form.Item
          label={
            <div>
              <label>Fechas de clases</label>
              { state?.statusGroup === 'completed' && <p className="sub-label">Se podrá editar hasta 1 día antes de la fecha seleccionada</p> }
            </div>
          }
          name='range'
          rules={[
            {
              required: true,
              message: 'Ingresa un rango de fechas válido'
            }
          ]}
        >
          <RangePicker format='DD/MM/YYYY'/>
        </Form.Item>
      </FormWrapper>
      <ConfirmEdit 
        visible={ confirmEditGroupVisible }
        loading={ loadingGroup?.EDIT_SCHEDULE?.state }
        onCancel={ setConfirmEditGroupVisible }
        onOk={ onEditGroupCompleted }
        initialData={ refactoryDateFormat(schedule.initial) } 
        endData={ refactoryDateFormat(schedule.current) } 
        mapKeyReview={{ initial_date: 'Inicio', final_date: 'Fin' }}
      />
    </div>
  )
}

FormGroupSchedule.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getGroup, getGroupCompleted, saveSchedule, saveScheduleInGroupCompleted, resetLoadingState }, dispatch);

const mapStateToProps = state => ({
  groupsReducer: state.get('groupsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(FormGroupSchedule);
