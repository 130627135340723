import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActiveMaterials, getInactiveMaterials, activateMaterials, inactivateMaterial, ignoreMaterialConflicts } from '../../../reducers/materials/actions';
import MaterialTable from '../MaterialTable/MaterialTable';
import { Col, Row, Button } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import ConflictsModal from '../../../components/custom/ConflictsModal';

import './MaterialsState.scss';

const MaterialsState = props => {

  const [ materialToInactivate, setMaterialToInactivate ] = useState();
  
  const [ activeMaterialsSelection, setActiveMaterialsSelection ] = useState();

  const [ inactiveMaterialsSelection, setInactiveMaterialsSelection ] = useState();

  const { materialsReducer, refresh } = props;

  const { activeMaterials, inactiveMaterials, loading, materialConflicts, inactiveSucceed, activeSucceed } = materialsReducer;

  const onInactivate = () => setMaterialToInactivate(inactiveMaterialsSelection[0]);

  const onInactivateConfirmed = () => props.inactivateMaterial(inactiveMaterialsSelection.map(material => material.id)[0]);

  const onActivate = () => props.activateMaterials(activeMaterialsSelection.map(material => material.id));

  useEffect(() => {
    if (inactiveSucceed) {
      setInactiveMaterialsSelection();
    }
  }, [ inactiveSucceed ])

  return (
    <div className='MaterialsState'>
      <Row>
        <Col xs={ 11 } sm={ 11 } md={ 11 } lg={ 11 } xl={ 11 } xxl={ 11 }>
          <h2>Activos</h2>
          <MaterialTable
            { ...props }
            onSelectionChange={ setInactiveMaterialsSelection }
            selectionType='radio'
            name='activeMaterialsTable'
            loading={ loading.GET_ACTIVE_MATERIALS?.state || loading.ACTIVATE_MATERIAL?.state }
            refresh={ refresh || loading.ACTIVATE_MATERIALS?.state || loading.INACTIVATE_MATERIAL?.state }
            action={ props.getActiveMaterials }
            data={ activeMaterials }
            hasSucceded={ inactiveSucceed }
          />
        </Col>
        <Col className='buttons-wrapper' xs={ 2 } sm={ 2 } md={ 2 } lg={ 2 } xl={ 2 } xxl={ 2 }>
          <Button type="primary" disabled={ !(inactiveMaterialsSelection || []).length } onClick={ onInactivate } icon={ <ArrowRightOutlined /> }/>
          <Button type="primary" disabled={ !(activeMaterialsSelection || []).length } onClick={ onActivate } icon={ <ArrowLeftOutlined /> }/>
        </Col>
        <Col xs={ 11 } sm={ 11 } md={ 11 } lg={ 11 } xl={ 11 } xxl={ 11 }>
          <h2>Inactivos</h2>
          <MaterialTable
            { ...props }
            onSelectionChange={ setActiveMaterialsSelection }
            selectionType='checkbox'
            name='inactiveMaterialsTable'
            loading={ loading.GET_INACTIVE_MATERIALS?.state || loading.INACTIVATE_MATERIALS?.state }
            refresh={ refresh || loading.ACTIVATE_MATERIALS?.state || loading.INACTIVATE_MATERIAL?.state }
            action={ props.getInactiveMaterials }
            data={ inactiveMaterials }
            hasSucceded={ activeSucceed }
          />
        </Col>
      </Row>
      <ConflictsModal
          itemToDeleteState={ setMaterialToInactivate }
          modalConfirmationTitle={ `¿Estas seguro que deseas pasar a inactivo el siguiente material?: ${ materialToInactivate?.name_material }` }
          modalConflictsTitle={ `No se puede cambiar a inactivo ${ materialToInactivate?.name_material } porque se encuentra asignado a uno o varios grupos` }
          notificationDescription={ `Se ha cambiado el material ${ materialToInactivate?.name_material } a inactivos` }
          itemToDelete={ materialToInactivate }
          conflicts={ materialConflicts?.groups }
          editRoute={ group => `/clients/${ group.client_id }/group/${ group.id }` }
          loading={ loading.INACTIVATE_MATERIAL?.state }
          ignoreConflicts={ props.ignoreMaterialConflicts }
          onDeleteConfirmed={ onInactivateConfirmed }
        />
    </div>
  )
}

MaterialsState.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({
  getActiveMaterials,
  getInactiveMaterials,
  activateMaterials,
  inactivateMaterial,
  ignoreMaterialConflicts
}, dispatch);

const mapStateToProps = state => ({
  materialsReducer: state.get('materialsReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialsState);
