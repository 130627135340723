import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { tableProps } from '../../../reducers/materials/actions';
import Table from '../../../components/simple/Table';
import { fakeSort } from '../../../utils/table';
import { Input } from 'antd';

import './MaterialTable.scss';

let searchTimeout;

const MaterialTable = props => {

  const [ searchQuery, setSearchQuery ] = useState('');
  
  const [ selectedMaterials, setSelectedMaterials ] = useState([ ]);

  const { materialsReducer, courseId, data, name, selectionType, hasSucceded } = props;

  const { filter, page, orderBy } = materialsReducer;

  const columns = [
    {
      title: 'Nivel',
      dataIndex: 'name_level',
      ...fakeSort
    },
    {
      title: 'Nombre',
      dataIndex: 'name_material',
      ...fakeSort
    }
  ];

  const onSearchChange = e => {
    clearTimeout(searchTimeout);
    
    searchTimeout = setTimeout(_ => setSearchQuery(e.target.value), 500);
  }

  const update = p => props.action(courseId, filter, p || page[name], orderBy[name], searchQuery);

  useEffect(update, [ searchQuery, props.refresh ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => props.onSelectionChange(selectedMaterials), [ selectedMaterials ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasSucceded) {
      setSelectedMaterials([ ]);
    }
  }, [ hasSucceded ]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const selectedRowKeys = [];

  selectedMaterials.forEach(selectedMaterial => {
    const index = (data?.result || []).findIndex(material => material.id === selectedMaterial.id);

    if (index > -1) {
      selectedRowKeys.push(index)
    }
  });

  return (
    <div className='MaterialTable'>
      <Input onChange={ onSearchChange } placeholder='Buscar'/>
      <Table
        { ...props }
        rowSelection={{
          type: selectionType,
          onSelect: (row, selected) => {
            if (selected) {
              if (selectionType === 'radio') {
                setSelectedMaterials([ row ]);
              } else {
                setSelectedMaterials(selectedMaterials.concat([ row ]));
              }
            } else {
              setSelectedMaterials(selectedMaterials.filter(material => material.id !== row.id));
            }
          },
          onSelectAll: (selected, rows) => {
            if (selected) {
              setSelectedMaterials(selectedMaterials.concat(rows));
            } else {
              let filteredSelection = selectedMaterials;
              
              (data?.result || []).forEach(row => {
                filteredSelection = filteredSelection.filter(material => material.id !== row.id)
              });
              setSelectedMaterials(filteredSelection);
            }
          },
          selectedRowKeys
        }}
        onChange={(pager, filter, sort) =>
          props.tableProps(pager.current, sort, name).then(() => {
            update(pager.current)
          })
        }
        data={ data }
        columns={ columns }
      />
    </div>
  )
}

MaterialTable.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({
  tableProps
}, dispatch);

const mapStateToProps = state => ({
  materialsReducer: state.get('materialsReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialTable);
