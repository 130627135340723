import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

function TypographyTitle(props){
  const { children } = props;
  return(
    <Typography.Title className='TypographyTitle' {...props}>
      {children}
    </Typography.Title>
  );
}

TypographyTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
}

export default TypographyTitle;