import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../reducers/justifications/actions';
import { getTypeEvidence } from '../../../reducers/groups/actions';
import TableContainer from '../../../components/custom/TableContainer';
import './AddDeleteJustification.scss'
import EditModal from './EditModal'
import ImagePreview from '../../../components/simple/ImagePreview';
import { FileFilled } from '@ant-design/icons';
import moment from 'moment';

function TableAddDeleteJustifications(props){

  const columns = {
  
    justifications: [
      {
        title: 'Fecha',
        dataIndex: 'date',
        key: 'date',
        render: (value) => moment(value).format('DD/MM/YYYY')
      }, {
        title: 'Alumno',
        dataIndex: 'student_name',
        key: 'student_name',
      }, {
        title: 'Empresa',
        dataIndex: 'client_name',
        key: 'client_name',
      }, {
        title: 'Tipo',
        dataIndex: 'type_name',
        key: 'type_name',
        render: (value, data) =>
          <>
            {`${value} `}<EditModal { ...props } data={data}/>
          </>
      }, {
        title: 'Motivo',
        key: 'reason',
        dataIndex: 'reason',
      },{
        title: 'Evidencias',
        dataIndex: 'evidences',
        key: 'evidences',
        render: data => (
          <ImagePreview 
            data={data}
            isIcon={true} 
            component={FileFilled} 
          />
        ),
      }
    ]
  };

  let { match, state } = props;
  let {
    loading,
  } = state;
  
  return(
    <TableContainer
      downloadDisabled={ true }
      entity={ match.params }
      loading={ loading.JUSTIFICATIONS_LOADING && loading.JUSTIFICATIONS_LOADING.state }
      name='justifications'
      columns={ columns }
      wantSaveFilters={ true }
      { ...props }
    />
  );
}

TableAddDeleteJustifications.propTypes = {}

const mapDispatchToProps = dispatch => bindActionCreators({ ...Actions, getTypeEvidence }, dispatch);
const mapStateToProps = state => ({
  state: state.get('justificationsReducer'),
  groupsReducer: state.get('groupsReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableAddDeleteJustifications);
