import { addressService } from '../../services/address.service';
import * as ACTION from './types';

export function tableProps(page, sort, name) {
  return {
    type: ACTION.TABLE_PROPS,
    page,
    sort,
    name
  }
}

export function getAddresses(clientId, data, page, order, searchQuery) {
  return {
    types: [
      ACTION.LOADING_GET_ADDRESSES,
      ACTION.RECEIVE_GET_ADDRESSES,
      ACTION.ERROR_GET_ADDRESSES,
    ],
    promise: addressService.getAddresses(clientId, data, page, order, searchQuery)
  }
}

export function getAllAddresses(clientId) {
  return {
    types: [
      ACTION.LOADING_GET_ALL_ADDRESSES,
      ACTION.RECEIVE_GET_ALL_ADDRESSES,
      ACTION.ERROR_GET_ALL_ADDRESSES,
    ],
    promise: addressService.getAllAddresses(clientId)
  }
}

export function getGroupAddresses(groupId) {
  return {
    types: [
      ACTION.LOADING_GET_GROUP_ADDRESSES,
      ACTION.RECEIVE_GET_GROUP_ADDRESSES,
      ACTION.ERROR_GET_GROUP_ADDRESSES,
    ],
    promise: addressService.getGroupAddresses(groupId)
  }
}

export function getGroupCompletedAddresses(groupId) {
  return {
    types: [
      ACTION.LOADING_GET_GROUP_COMPLETED_ADDRESSES,
      ACTION.RECEIVE_GET_GROUP_COMPLETED_ADDRESSES,
      ACTION.ERROR_GET_GROUP_COMPLETED_ADDRESSES,
    ],
    promise: addressService.getGroupCompletedAddresses(groupId)
  }
}

export function saveAddress(clientId, data) {
    return {
      types: [
        ACTION.LOADING_SAVE_ADDRESS,
        ACTION.RECEIVE_SAVE_ADDRESS,
        ACTION.ERROR_SAVE_ADDRESS,
      ],
      promise: addressService.saveAddress(clientId, data)
    }
}

export function linkGroupAddress(groupId, data) {
  return {
    types: [
      ACTION.LOADING_LINK_GROUP_ADDRESS,
      ACTION.RECEIVE_LINK_GROUP_ADDRESS,
      ACTION.ERROR_LINK_GROUP_ADDRESS,
    ],
    promise: addressService.linkGroupAddress(groupId, data)
  }
}

export function linkGroupCompletedAddress(groupId, data) {
  return {
    types: [
      ACTION.LOADING_LINK_GROUP_COMPLETED_ADDRESS,
      ACTION.RECEIVE_LINK_GROUP_COMPLETED_ADDRESS,
      ACTION.ERROR_LINK_GROUP_COMPLETED_ADDRESS,
    ],
    promise: addressService.linkGroupCompletedAddress(groupId, data)
  }
}

export function deleteAddress(addressId) {
  return {
    types: [
      ACTION.LOADING_DELETE_ADDRESS,
      ACTION.RECEIVE_DELETE_ADDRESS,
      ACTION.ERROR_DELETE_ADDRESS,
    ],
    promise: addressService.deleteAddress(addressId)
  }
}

export function deleteAddressInGroupCompleted(groupId, addressId) {
  return {
    types: [
      ACTION.LOADING_DELETE_ADDRESS_IN_GROUP_COMPLETED,
      ACTION.RECEIVE_DELETE_ADDRESS_IN_GROUP_COMPLETED,
      ACTION.ERROR_DELETE_ADDRESS_IN_GROUP_COMPLETED,
    ],
    promise: addressService.deleteAddressInGroupCompleted(groupId, addressId)
  }
}

export function updateConflictAddress(addressId, data) {
  return {
      types: [
          ACTION.LOADING_CONFLICT_ADDRESS,
          ACTION.RECEIVE_CONFLICT_ADDRESS,
          ACTION.ERROR_CONFLICT_ADDRESS,
      ],
      promise: addressService.updateConflictAddress(addressId, data)
  }
}

export function ignoreAddressConflicts() {
  return {
    type: ACTION.IGNORE_ADDRESS_CONFLICTS
  }
}
