import { settingsService } from '../../services/settings-service';
import * as ACTION from './types';

export function getHolidays(startDate, endDate) {
  return {
    types: [
      ACTION.LOADING_GET_HOLIDAYS,
      ACTION.RECEIVE_GET_HOLIDAYS,
      ACTION.ERROR_GET_HOLIDAYS,
    ],
    promise: settingsService.getHolidays(startDate, endDate)
  }
}

export function saveHolidays(startDate, endDate) {
  return {
    types: [
      ACTION.LOADING_SAVE_HOLIDAYS,
      ACTION.RECEIVE_SAVE_HOLIDAYS,
      ACTION.ERROR_SAVE_HOLIDAYS,
    ],
    promise: settingsService.saveHolidays(startDate, endDate)
  }
}

export function deleteHoliday(hid) {
  return {
    types: [
      ACTION.LOADING_DELETE_HOLIDAY,
      ACTION.RECEIVE_DELETE_HOLIDAY,
      ACTION.ERROR_DELETE_HOLIDAY,
    ],
    promise: settingsService.deleteHolidays(hid)
  }
}

export function getTeacherTariffs() {
  return {
    types: [
      ACTION.LOADING_GET_TEACHER_TARIFFS,
      ACTION.RECEIVE_GET_TEACHER_TARIFFS,
      ACTION.ERROR_GET_TEACHER_TARIFFS,
    ],
    promise: settingsService.getTeacherTariffs()
  }
}

export function saveTeacherTariffs(tariffs) {
  return {
    types: [
      ACTION.LOADING_SAVE_TEACHER_TARIFFS,
      ACTION.RECEIVE_SAVE_TEACHER_TARIFFS,
      ACTION.ERROR_SAVE_TEACHER_TARIFFS,
    ],
    promise: settingsService.saveTeacherTariffs(tariffs)
  }
}

export function deleteTeacherTariff(tariff) {
  return {
    types: [
      ACTION.LOADING_DELETE_TEACHER_TARIFF,
      ACTION.RECEIVE_DELETE_TEACHER_TARIFF,
      ACTION.ERROR_DELETE_TEACHER_TARIFF,
    ],
    promise: settingsService.deleteTeacherTariff(tariff)
  }
}

export function ignoreTeacherTariffConflicts() {
  return {
    type: ACTION.IGNORE_TEACHER_TARIFF_CONFLICTS
  }
}

export function getClientTariffs() {
  return {
    types: [
      ACTION.LOADING_GET_CLIENT_TARIFFS,
      ACTION.RECEIVE_GET_CLIENT_TARIFFS,
      ACTION.ERROR_GET_CLIENT_TARIFFS,
    ],
    promise: settingsService.getClientTariffs()
  }
}

export function saveClientTariffs(tariffs) {
  return {
    types: [
      ACTION.LOADING_SAVE_CLIENT_TARIFFS,
      ACTION.RECEIVE_SAVE_CLIENT_TARIFFS,
      ACTION.ERROR_SAVE_CLIENT_TARIFFS,
    ],
    promise: settingsService.saveClientTariffs(tariffs)
  }
}

export function deleteClientTariff(tariff) {
  return {
    types: [
      ACTION.LOADING_DELETE_CLIENT_TARIFF,
      ACTION.RECEIVE_DELETE_CLIENT_TARIFF,
      ACTION.ERROR_DELETE_CLIENT_TARIFF,
    ],
    promise: settingsService.deleteClientTariff(tariff)
  }
}

export function ignoreClientTariffConflicts() {
  return {
    type: ACTION.IGNORE_CLIENT_TARIFF_CONFLICTS
  }
}
