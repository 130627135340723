import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Breadcrumb from '../../components/simple/Breadcrumb';
import { getClient } from '../../reducers/clients/actions';
import { getIncompleteGroups } from '../../reducers/groups/actions';
import { Row, Col, Card } from 'antd';
import './ClientDetail.scss';
import CompletedGroupList from './CompletedGroupList';
import IncompleteGroupList from './IncompleteGroupList';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import IconButton from '../../components/simple/IconButton/IconButton';
import Button from '../../components/simple/Button';
import Tabs from '../../components/simple/Tabs';
import SearchInput from '../../components/simple/SearchInput';
import { useLocation } from "react-router-dom";

const ClientDetail = props => {

  const [ searchQuery, setSearchQuery ] = useState();
  
  const { match, clientsReducer, groupsReducer } = props;

  const { clientId } = match.params;

  const location = useLocation();

  const incompletedGroupsLength = groupsReducer.incompleteGroups?.pagination.total_elements || 0;

  const [activeKey, setActiveKey] = useState(0)

  useEffect(_ => {
    props.getClient(clientId);
    props.getIncompleteGroups(clientId, 1, { });
    location?.state?.activeKey && setActiveKey(location.state.activeKey);
  }, [ ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='ClientDetail'>
      <Breadcrumb
        routes={[
          { label:'Lista de clientes', url: '/clients', },
          { label:'Detalle' }
        ]}
      />
      <Card>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <h2>{ clientsReducer.client?.name }</h2>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Button
              className='edit-btn'
              onClick={ _ => props.history.push(`/clients/edit/${ clientId }`) }
              icon={ <EditOutlined /> }
            >Editar</Button>
          </Col>
        </Row>
      </Card>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <SearchInput onChange={ setSearchQuery } placeholder='Buscar por grupo'/>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <IconButton
                  label='Agregar grupo'
                  onClick={() => {
                    props.history.push(`/clients/${ clientId }/group/new`);
                  }}
                  icon={ <PlusOutlined /> }
                />
              </Col>
            </Row>
            <Tabs
              activeKey={ activeKey.toString() }
              onChange={ setActiveKey }
              items={[
                {
                  value: 0,
                  key:'0',
                  label: 'Grupos activos',
                  children: <CompletedGroupList clientId={ clientId } { ...props } searchQuery={ searchQuery }/>
                }, {
                  value: 1,
                  key:'1',
                  label: `Grupos pendientes (${ incompletedGroupsLength })`,
                  disabled: !incompletedGroupsLength,
                  children: <IncompleteGroupList clientId={ clientId } { ...props } searchQuery={ searchQuery }/>
                }
              ]}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

ClientDetail.propTypes = {};

const mapDispatchToProps = dispatch => bindActionCreators({ getIncompleteGroups, getClient }, dispatch);

const mapStateToProps = state => ({
  clientsReducer: state.get('clientsReducer'),
  groupsReducer: state.get('groupsReducer')
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetail);
