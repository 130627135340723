import { notification } from 'antd';

export const validationFile = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "application/pdf";
  if (!isJpgOrPng) {
    notification["warning"]({
      description: "¡Solo puede cargar archivos JPG/PNG/PDF",
      placement: "bottomRight",
    });
  }
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    notification["warning"]({
      description: "¡La imagen debe tener un tamaño inferior a 5 MB!",
      placement: "bottomRight",
    });
  }
  return isJpgOrPng && isLt5M;
};
