import * as ACTION from './types';
import { classesService } from '../../services/classes.service'

export function getExam(group, exam) {
  return {
    types: [
      ACTION.EXAM_LOADING,
      ACTION.EXAM_RECEIVE,
      ACTION.EXAM_ERROR,
    ],
    promise: classesService.getExamDetail(group, exam)
  }
}