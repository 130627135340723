import React from 'react';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import './InputEmail.scss';

function InputEmail(props) {
  const { name } = props;
  return(
    <div className='InputEmail'>
      <label>Email</label>
      <Input
        placeholder='correo@electronico.com'
        suffix={
          <Tooltip title="Extra information">
            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          </Tooltip>
        }
        name={name}
        {...props}
      />
    </div>
  );
}

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
}

export default InputEmail;