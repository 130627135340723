import { useState } from "react";
import { getBase64 } from "../utils/base64";
import { validationFile } from "../utils/validationFile";

export function useManageFile ({
	fileDefault = undefined, 
	currentFileNameDefault = '', 
	listFilesDefault = [],
  limitFileCount = 5
}) {
  const [currentFile, setCurrentFile] = useState(fileDefault);
  const [currentFileName, setCurrentFileName] = useState(currentFileNameDefault);
  const [listFile, setListFile] = useState(listFilesDefault);
  const [ limitFile, setLimitFile ] = useState(limitFileCount);

  const handleInputFile = (data) => {
    let nameFile = data.file.name.split(".");
    nameFile.pop();
    setCurrentFileName(nameFile.join("."));
    setCurrentFile(data.file);
  };

  const handleInputFilePaste = (e) => {
    e.preventDefault();
    if (!e.clipboardData?.files[0]?.name) {
      return;
    }
    let files = e.clipboardData.files;
    files[0]["uid"] = new Date().toISOString();
    const isValidate = validationFile(files[0]);
    if (!isValidate) {
      return;
    }
    let nameFile = files[0].name.split(".");
    nameFile.pop();
    setCurrentFileName(nameFile.join("."));
    setCurrentFile(files[0]);
  };

  const checkNameFile = async () => {
    if (listFile.length < limitFile) {
      let image = await getBase64(currentFile);
      let nameFile = currentFile ? currentFile.name.split(".") : "";
      let nameWithExtension = `${currentFileName}.${nameFile.pop()}`;

      const file = {
        name: nameWithExtension,
        mime_type: currentFile.type,
        uid: currentFile?.uid,
        receipt: image,
      };
      setListFile([file, ...listFile]);
    }

    setCurrentFile(undefined);
    setCurrentFileName("");
  };

  return {
    currentFile,
    currentFileName,
    listFile,
    limitFile,
    setCurrentFile,
    setCurrentFileName,
    setListFile,
    setLimitFile,
    handleInputFile,
    handleInputFilePaste,
    checkNameFile,
  };
};
