import React from 'react';

import './NoFoundPage.scss';

function NoFoundPage(){
  return (
    <div className='NoFoundPage'>
      <h1>404</h1>
      <h2>Page not found</h2>
      <p>Lo sentimos, la pagina que solicitaste no fue encontrada. Regresa a la pagina principal o contacta a soporte técnico.</p>
    </div>
  );
}

export default NoFoundPage;
