import { studyPlanService } from '../../services/study-plan.service';
import * as ACTION from './types';

export function getStudyPlans() {
    return {
      types: [
        ACTION.LOADING_GET_CATALOG,
        ACTION.RECEIVE_GET_CATALOG,
        ACTION.ERROR_GET_CATALOG,
      ],
      promise: studyPlanService.getStudyPlans()
    }
}

export function getLevels(spid) {
  return {
    types: [
      ACTION.LOADING_GET_STUDY_PLAN_LEVELS,
      ACTION.RECEIVE_GET_STUDY_PLAN_LEVELS,
      ACTION.ERROR_GET_STUDY_PLAN_LEVELS,
    ],
    promise: studyPlanService.getLevels(spid)
  }
}