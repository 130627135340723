import React, { useEffect } from 'react';

import InputCheckBox from '../../simple/InputCheckBox';
import AutocompleteSearch from '../../simple/AutocompleteSearch';

import { getCatalogs } from '../../../containers/Classes/actions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Tags from '../../simple/Tags';
import { getInitEndWeek } from '../../../utils/dateFormats';

import './FilterClasses.scss';

function FilterClasses(props) {


  const { classReducer, removeButton } = props;

  const { catalogs, filters, currentDate } = classReducer;

  const {
    isTrial,
    isPresentation,
    isObservation,
    tags
  } = filters;

  const preparePayLoad = () => {
    const dates = getInitEndWeek(currentDate);

    const payload = {
      startdate: dates.init,
      enddate: dates.end,
      clients: filters.clients,
      teachers: filters.teachers,
      languages: filters.languages,
      groups: filters.groups,
      levels: filters.levels,
    };

    const { clients, startdate, enddate } = payload;
    
    if (clients.length) {
      props.getClientGroups(clients[0], startdate, enddate);
    } else {
      props.clearClientGroups();
    }
    
    props.getCatalogs(payload);
    props.onFiltersChange(payload);
  }

  useEffect(() => {

      const params= new URLSearchParams(window.location.search);

      if (
        params.get('id') && 
        params.get('category') && 
        params.get('label')
      ) {
        onSelect({id: params.get('id'), category: params.get('category'), label: params.get('label')});
      };

      if (props.clientId) {
        onSelect({
          id: props.clientId,
          category: 'clients',
          label: '123'
        })
      };

      preparePayLoad();
    },
    // eslint-disable-next-line
    [ ]
  );

  useEffect(
    _ => preparePayLoad(),
    // eslint-disable-next-line
    [
      currentDate,
      filters.clients.length,
      filters.groups.length,
      filters.teachers.length,
      filters.languages.length,
      filters.levels.length,
      filters.tags.length,
    ]
  );

  const onSelect = ({ id, category, label }) => {
    if(filters[category].indexOf(id) === -1){
      
      if(category !== 'levels'){
        filters[category].push(Number(id));
      }else{
        filters[category].push(id);
      }
      if(!filters.tags[id]){ filters.tags[id] = {} }
      filters.tags[id].label = label;
      filters.tags[id].id = id;
      filters.tags[id].category = category;
      
      props.addData(filters);
    }
  }

  const onDelete = item => {
    const { id, category } = item;
    const catalog = filters[category].filter(catalogItem => catalogItem !== id);
    const tagList = filters.tags;

    delete tagList[id];
    
    props.addData({ filters: { ...filters, [category]: catalog, tags: tagList }});
  }

  const onChangeChecked = (e) => {
    filters[e.target.name] = e.target.checked;
    props.addData({ filters });
  }

  let autocompleteDataSource = (
    (
      classReducer.clientGroups || [ ]
    ).length ?
        [ { title: 'groups', children: classReducer.clientGroups } ]
        :
        [ ]
  ).concat(catalogs?.result || [ ]);

  if (props.ignoreClients) {
    autocompleteDataSource = autocompleteDataSource.filter(item => item.title !== 'clients');
  }

  return(
    <div className='FilterClasses'>
      <div className='FilterClasses__container'>
        <div className='FilterClasses__search'>
          <AutocompleteSearch
            dataSource={ autocompleteDataSource }
            onSelect={ onSelect }
            placeholder='Buscar por profesor, nivel, idioma, cliente…'
          />
        </div>
        <div className='FilterClasses__checkboxes'>
          <InputCheckBox key={0} onChange={ onChangeChecked } checked={isTrial} name='isTrial' className='FilterClasses__checkboxes__check FilterClasses__green'>Muestra</InputCheckBox>
          <InputCheckBox key={1} onChange={ onChangeChecked } checked={isPresentation} name='isPresentation' className='FilterClasses__checkboxes__check FilterClasses__purple'>Presentación</InputCheckBox>
          <InputCheckBox key={2} onChange={ onChangeChecked } checked={isObservation} name='isObservation' className='FilterClasses__checkboxes__check FilterClasses__pink'>Observación</InputCheckBox>
        </div>
      </div>
      <Tags removeButton={removeButton} list={ tags } onDelete={ onDelete }/>
    </div>
  );
}

FilterClasses.propTypes = {}

const mapDispatchToProps = dispatch => bindActionCreators({ getCatalogs }, dispatch);

const mapStateToProps = state => ({
  classReducer: state.get('classReducer'),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterClasses);
