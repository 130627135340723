
export default function promiseMiddlware() {
  return next => (action) => {
    
    const { promise, types, data } = action;
    if (!promise) {
      return Promise.resolve(next(action));
    }
    const [REQUEST, SUCCESS, FAILURE] = types;
    
    next({ type: REQUEST });
    
    return promise.then(
      (result) => {
        next({ result, type: SUCCESS, data });
      },
      (error) => {
        next({ error, type: FAILURE });
      }
    );
  };
}
